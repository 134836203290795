import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'app-page-not-found',
  templateUrl: '../error-message/error-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent extends ErrorMessageComponent {
  errorImgName = '404';
  errorImgWidth = 600;
  errorTitle = 'Page Not Found';
  errorMessage = 'We couldn’t find what you’re looking for.';
  errorCode = '404';
}
