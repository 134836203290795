import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent implements OnInit {
  @Input() requestObject: string;
  @Input() heading: string;
  @Input() message: string;
  @Input() message2: string;
  @Input() message3: string;
  @Input() noText = 'Cancel';
  @Input() yesText = 'Yes, I\'m sure';
  @Input() lightTheme: boolean;
  @Input() showCancel: boolean = true;

  public onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public onAccept(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
