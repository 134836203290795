import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbsService, IBreadcrumb } from '@bo/ng-breadcrumbs';

@Component({
  selector: 'app-page-title-header',
  templateUrl: './page-title-header.component.html',
  styleUrls: []
})
export class PageTitleHeaderComponent implements OnInit, OnDestroy {

  constructor(public service: BreadcrumbsService) {}

  crumb: IBreadcrumb;
  subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(
      this.service.crumbs$.subscribe(crumbs => {
        this.crumb = crumbs[crumbs.length - 1];
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
