import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { S3Destination, UploadedFileWithMeta } from '../../api/interface/api.models';
import { CropUploadSettings, UploadedFile } from '@bo/ng-uploads';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppState } from '../../../store/states';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: []
})
export class UploadImageComponent implements AfterViewInit {
  @Input() width: number;
  @Input() height: number;
  @Input() cropIsRounded: boolean;
  // S3 bucket key for the uploaded image component
  @Output() imageStored = new EventEmitter<string>();

  cropperSettings$: Observable<CropUploadSettings>;
  errors: string[];
  previewUrl: string;

  constructor(
    private store: Store,
  ) {
  }

  ngAfterViewInit(): void {
    this.cropperSettings$ = this.store.select(AppState.userProfile).pipe(
      map(userProfile => {
        return {
          destination: S3Destination.images,
          fileOwner: {owner_id: userProfile.id},
          width: this.width,
          height: this.height,
          rounded: !!this.cropIsRounded,
          filename: 'avatar',
          fileType: 'image/jpeg',
        } as CropUploadSettings;
      })
    );
  }

  pictureUploaded($event: UploadedFile) {
    this.errors = null;
    // file.meta is appended to file as part of the BO upload library
    // tslint:disable-next-line:variable-name
    const _event = $event as UploadedFileWithMeta;
    this.previewUrl = _event.file?.meta?.download_url;
    if (this.previewUrl) {
      this.imageStored.emit(this.previewUrl);
    }
  }

  closeError(error) {
    this.errors = this.errors.filter(item => item !== error);
  }

  onChange() {
    this.previewUrl = undefined;
    this.imageStored.emit();
  }
}
