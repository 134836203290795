import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AppToastrService {

  /* TODO: upgrade this to support Bootstrap 5 some time.
     Documentation to read:
     https://github.com/scttcper/ngx-toastr/issues/887
     https://gist.github.com/Eonasdan/db173d060cb98abf3eb031c078a1329c
  */

  constructor(private toastr: ToastrService) {
  }

  clear(toastId?: number) {
    this.toastr.clear(toastId);
  }

  success(message?: string, title?: string): ActiveToast<any> {
    return this.toastr.success(message || 'Request has been made.', title);
  }

  error(message?: string, title?: string): ActiveToast<any> {
    return this.toastr.error(message || 'An error occurred.', title);
  }

  warning(message?: string, title?: string): ActiveToast<any> {
    return this.toastr.warning(message || '', title);
  }

  info(message?: string, title?: string): ActiveToast<any> {
    return this.toastr.info(message || '', title);
  }

  rocSwitch(message?: string): ActiveToast<any> {
    return this.toastr.success(message || 'Switched ROC\'s', '', {positionClass: 'toast-top-center', messageClass: 'roc-switch-toaster'});
  }

  timeoutError(): ActiveToast<any> {
    return this.toastr.error('The request has timed out.');
  }

  /**
   * Displays a toaster when a API error occurs.
   *
   * The function will attempt to get the error from the following responses.
   *
   * `"An error occurred"`
   * `["An error occurred"]`
   * `{"detail": "An error occurred"}`
   * `{"detail": ["An error occurred"]}`
   * `{"message": "An error occurred"}`
   * `{"message": ["An error occurred"]}`
   *
   * {HttpErrorResponse} errorResp
   */
  apiError(errorResp: HttpErrorResponse): ActiveToast<any> {
    // Exclude all errors caught by request interceptors.
    // 500 >=, 401 and 403 errors are handled by interceptors.
    if (errorResp.status >= 300 && errorResp.status < 500 && errorResp.status !== 401) {
      const error = errorResp.error;
      let msg = null;
      if (typeof error === 'string') {
        msg = error;
      } else if (Array.isArray(error) && error.length > 0) {
        msg = error[0];
        // Test if isArray before assuming error is an object. Array is also an object.
      } else if (typeof error === 'object') {
        const keys = ['detail', 'message'];
        for (const key of keys) {
          if (error.hasOwnProperty(key)) {
            if (Array.isArray(error[key]) && error[key].length > 0) {
              msg = error[key][0];
            } else if (typeof error[key] === 'string') {
              msg = error[key];
            }
            break;
          }
        }
      } else {
        throw new Error('Not implemented.');
      }

      return this.toastr.error(msg || 'An error occurred.', '');
    }
  }
}
