const ROC_ACTION_PREFIX = '[ROC]';

export class GetUserLinkedROCs {
  static readonly type = `${ROC_ACTION_PREFIX} Get User Linked ROCs`;
}

/*
 * Switch the user's view to the provided ROC.
 */
export class SwitchROC {
  static readonly type = `${ROC_ACTION_PREFIX} Switch ROC`;

  constructor(public readonly rocId: number) {
  }
}

/*
 * Persists the currently selected ROC to local storage.
 * Left as a separate action to allow flexibility with regards to
 * when/where the selected ROC is persisted.
 */
export class PersistSelectedROC {
  static readonly type = `${ROC_ACTION_PREFIX} Persist Selected ROC`;
}

/*
 * Clears the selected ROC from storage.
 */
export class ClearSelectedROC {
  static readonly type = `${ROC_ACTION_PREFIX} Clear Selected ROC`;
}
