import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumsService } from '@bo/ng-enums';
import { DocumentUploadComponent } from '../../upload-document/upload.component';
import { DocumentService } from '../../../../modules/roc/pages/documents/services/document.service';
import { AppToastrService } from '../../../services/toaster.service';
import { DocumentResourceTypes, DocumentType, SafetyManagementDocumentsTypes } from '../../../api/interface/api.enums';
import { AuditReportDocumentUploadData, Document, UserProfile } from '../../../api/interface/api.models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { OperationApiService } from '../../../../modules/roc/pages/operations/services/operation-api.service';
import { EnumLabelService } from '../../../services/enum-label.service';

@Component({
  selector: 'app-document-upload-modal',
  templateUrl: 'upload-document-modal.component.html'
})

export class DocumentUploadModalComponent extends DocumentUploadComponent implements OnInit {

  @Input() droneId: number;
  @Input() operationId: number;
  @Input() fileType: any;
  @Input() remoteOperatorId: number;
  @Input() userId: number;
  @Input() forLabel: string;
  @Input() hideStartDateField: boolean;
  @Input() hideEndDateField: boolean;
  @Input() hideFileTypeField: boolean;
  @Input() hideCrewField: boolean;
  @Input() documentResourceType: DocumentResourceTypes;
  @Input() titleField: string;
  @Input() singleDocumentType: any;
  @Input() promptToAutoArchive: boolean;

  public onClose = new Subject<boolean>();
  isAlreadyExpired: boolean;
  uploadedDocument: Document;

  protected fields = ['title', 'file_type', 'valid_from', 'expire_on'];

  constructor(
    protected store: Store,
    protected documentService: DocumentService,
    protected toastrService: AppToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected enumService: EnumsService,
    protected enumLabelService: EnumLabelService,
    protected bsModalRef: BsModalRef,
    protected operationApiService: OperationApiService,
  ) { super( store, documentService, toastrService, router, route, enumService, enumLabelService, operationApiService); }

  ngOnInit() {
    super.ngOnInit();
    this.onClose = new Subject();
    if (!this.hideFileTypeField) {
      this.getDocumentsTypes(this.documentTypes);
    }
  }

  override getNewDocumentUploadData(userProfile: UserProfile): AuditReportDocumentUploadData {
    const data = super.getNewDocumentUploadData(userProfile);
    return {
      ...data,
      file_type: this.fileType ?? this.form.value.file_type,
      drone_id: this.droneId,
      operation: this.operationId,
      uploaded_for: this.selectedMemberId,
      resourcetype: this.documentResourceType,
    };
  }

  override createForm(): void {
    this.subscriptions.add(
      this.buildFromOptions(
        this.getFormOptions(),
        this.getInitialData()).subscribe(() => {
        if (this.hideStartDateField) { this.form.get('valid_from').disable() }
        if (this.hideEndDateField) { this.form.get('expire_on').disable(); }
        if (this.hideFileTypeField) { this.form.get('file_type').disable(); }
        if (this.titleField) {
          this.form.get('title').setValue(this.titleField);
        }
        this.selectedMemberId = this.userId;
        // Hide expires on field for ID & Crew Briefing documents and enables on other fields
        this.form.controls.file_type.valueChanges.subscribe((value) => {
          this.hideEndDateField = value === DocumentType.IDENTITY_DOCUMENT || value === SafetyManagementDocumentsTypes.CREW_BRIEFING;
          this.hideEndDateField ? this.form.get('expire_on').disable() : this.form.get('expire_on').enable();
        });

        if (this.promptToAutoArchive) {
          this.form.get('expire_on').valueChanges.subscribe(value => {
            if (value) { this.isAlreadyExpired = this.checkIfExpired(value); }
          })
        }
      })
    );
  }

  checkIfExpired(date: Date) {
    if (date) {
      const expiryDate: Date = new Date(date);
      const today: Date = new Date();
      return expiryDate < today;
    } else { return false }
  }

  public onCancel(): void {
    this.bsModalRef.hide();
    this.onClose.next(false);
  }

  override handleSubmitSuccess(document) {
    this.overrideDeactivateGuard = true;
    if (this.promptToAutoArchive) {
      this.isAlreadyExpired = this.checkIfExpired(document.expire_on);
    }
    this.uploadedDocument = document;
    this.toastrService.success(`"${document.title}" has been uploaded.`);
    this.bsModalRef.hide();
    this.onClose.next(true);
  }

  handleSubmitError(error: any) {
    super.handleSubmitError(error);
    if (error.error.occurrence) {
      this.toastrService.error(`This meeting was removed after this Audit was created.`, `Meeting not found`);
    }
  }
}
