import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AirspaceValidationRequest, AirspaceValidationResponse } from '../../../api/interface/api.models';
import { AirspaceAreaType, AirspaceMapObjectType } from '../../../api/interface/api.enums';
import { Circle, MapObject, MapObjectType, Marker, Polygon, ShapeType } from '../google-maps.model';
import { OperationApiService } from '../../../../modules/roc/pages/operations/services/operation-api.service';
import { messageFactory, MSG_NO_PROBLEMS_FOUND, MSG_VALIDATING } from './message-utils';

export interface MapObjectValidation {
  errorMessage: string;           // User facing error message
  airspaceName: string;           // FAR43
  airspaceType: AirspaceAreaType; // FAR
  mapObjectName: string;          // Take-off marker
  mapObjectType: MapObjectType;   // LANDING
}

@Injectable()
export class GoogleMapsValidationService {
  private subscriptions = new Subscription();

  /**
   * Tracks the validation messages for each shape on the map.
   * type: <shape.id, MapObjectValidation>
   */
  private mapObjectValidations = new Map<number, MapObjectValidation[]>();
  /** User facing error messages that are show on the map. */
  validationMessage$ = new Subject<string>();
  invalidAirspaceTypes$ = new Subject<MapObjectValidation[]>();

  constructor(private operationApiService: OperationApiService) { }

  public validate(mapObject: MapObject, shapeType: ShapeType): void {
    this.validationMessage$.next(MSG_VALIDATING);

    const request = this.createValidationRequest(mapObject, shapeType);
    this.subscriptions.add(
      this.operationApiService.validateAirspace(request).subscribe(
        () => {
          this.clear(mapObject);
        },
        error => {
          this.removeMapObjectValidationMessage(mapObject);
          for (const validationError of error.error as AirspaceValidationResponse[]) {
            this.addMapObjectValidationMessage(mapObject, shapeType, validationError);
          }
          this.publishMessages();
        })
    );
  }

  public destroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Clears validation error messages for a map object once it's been removed from the map.
   */
  public clear(mapObject: MapObject): void {
    this.removeMapObjectValidationMessage(mapObject);
    // Has no errors
    if (this.mapObjectValidations.size === 0) {
      this.publishClearMessages();
    } else {
      this.publishMessages();
    }
  }

  private publishClearMessages() {
    this.validationMessage$.next(MSG_NO_PROBLEMS_FOUND);
    this.invalidAirspaceTypes$.next([]);
  }

  private removeMapObjectValidationMessage(mapObject: MapObject) {
    this.mapObjectValidations.delete(mapObject.id);
  }

  private addMapObjectValidationMessage(mapObject: MapObject, shapeType: ShapeType, response: AirspaceValidationResponse) {
    const message = messageFactory(mapObject, shapeType, response);
    const mapObjectValidation = {
      errorMessage: message,
      airspaceType: response.airspace_type,
      airspaceName: response.name,
      mapObjectName: mapObject.identifier.mapObjectName,
      mapObjectType: mapObject.identifier.mapObjectType,
    } as MapObjectValidation;
    const existingValidations = this.mapObjectValidations.get(mapObject.id) ?? [];

    this.mapObjectValidations.set(mapObject.id, [...existingValidations, mapObjectValidation]);
  }

  private publishMessages() {
    if (this.mapObjectValidations.size === 0) {
      return;
    }
    this.publishValidationMessages();
    this.publishMapObjectValidations();
  }

  private publishValidationMessages() {
    const reducer = (prev: string, curr: string, index: number, all: string[]) => {
      return [prev, curr].join('');
    };
    const messages = Array.from(this.mapObjectValidations.values())
      .map(shapeValidation => shapeValidation.map(validation => validation.errorMessage))
      .flat()
      .reverse()  // reverse the messages (latest first).
      .reduce(reducer);
    this.validationMessage$.next(messages);
  }

  private publishMapObjectValidations() {
    const result = Array.from(this.mapObjectValidations.values())
      .flat()
      .map(shapeValidation => {
        return {
          airspaceName: shapeValidation.airspaceName,
          airspaceType: shapeValidation.airspaceType,
          mapObjectName: shapeValidation.mapObjectName,
          mapObjectType: shapeValidation.mapObjectType,
        } as MapObjectValidation;
      });
    this.invalidAirspaceTypes$.next(result);
  }

  private createValidationRequest(mapObject: MapObject, shapeType: ShapeType): AirspaceValidationRequest {
    const request = {} as AirspaceValidationRequest;

    switch (shapeType) {
      case google.maps.drawing.OverlayType.CIRCLE:
        request.type = AirspaceMapObjectType.CIRCLE;
        const circle = mapObject as Circle;
        request.radius = +circle.radius();
        request.center = circle.centerString();
        break;
      case google.maps.drawing.OverlayType.POLYGON:
        request.type = AirspaceMapObjectType.POLYGON;
        request.path = (mapObject as Polygon).pathString();
        break;
      case google.maps.drawing.OverlayType.POLYLINE:
        request.type = AirspaceMapObjectType.POLYLINE;
        request.path = (mapObject as Polygon).pathString();
        break;
      case google.maps.drawing.OverlayType.MARKER:
        request.type = AirspaceMapObjectType.POINT;
        request.point = (mapObject as Marker).positionString();
        break;
    }

    return request;
  }
}
