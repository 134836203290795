import { parsePathToLatLngLiteralArr, parsePointToLatLng } from './utils/value-utils';
import { midPoint } from './utils/geometry-utils';

// See: https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
const COORDINATE_PRECISION = 9;

export type Coordinate = [number, number];

/**
 * Shape on the map other than a `google.maps.Marker`, so that we can use the `setEditable(boolean)`
 * method.
 */
export type EditableShape = (
  google.maps.Circle |
  google.maps.Polygon |
  google.maps.Polyline
) & { type: google.maps.drawing.OverlayType };

/**
 *
 */
export type MapObjectShape = google.maps.MVCObject & (
  google.maps.Circle |
  google.maps.Polygon |
  google.maps.Polyline |
  google.maps.Marker
);

export type PolyShape = google.maps.MVCObject & (
  google.maps.Polygon |
  google.maps.Polyline
);

export type ShapeType = (
  google.maps.drawing.OverlayType.CIRCLE |
  google.maps.drawing.OverlayType.POLYGON |
  google.maps.drawing.OverlayType.POLYLINE |
  google.maps.drawing.OverlayType.MARKER
);

export enum ZoneMarkerType {
  TAKEOFF = 'Take-off',
  LANDING = 'Landing',
  EMERGENCY_LANDING = 'Emergency Landing',
}

export enum MapObjectType {
  FLIGHT_AREA = 'Flight Area',
  TAKEOFF = 'Take-off',
  ALTERNATIVE_TAKEOFF = 'Alternative Take-off',
  LANDING = 'Landing',
  ALTERNATIVE_LANDING = 'Alternative Landing',
  EMERGENCY_LANDING = 'Emergency Landing',
  HAZARD = 'Hazard',
  POINT_OF_INTEREST = 'Point of Interest',
  INCIDENT_REPORT = 'Report',
}

export interface MapObjectIdentifier {
  /** */
  mapObjectName?: string;
  mapObjectType?: MapObjectType;
  /** Used to identify the shape type for deletion. e.g. Delete CIRCLE. */
  shapeType?: ShapeType;
  zIndex?: number;
}

export interface ChangeMapObjectIdentifier {
  oldIdentifier: MapObjectIdentifier;
  newIdentifier: MapObjectIdentifier;
}

/**
 * Creates a identifier comparator for a mapObject.
 */
export function mapObjectIdentifierComparator(identifier: MapObjectIdentifier): (mapObject) => boolean {
  return mapObject => identifierComparator(identifier)(mapObject.identifier);
}

/**
 * Creates a identifier comparator for another identifier.
 */
export function identifierComparator(identifier2: MapObjectIdentifier): (MapObjectIdentifier) => boolean {
  if (!identifier2) {
    return () => false;
  }
  return identifier1 => {
    return identifier1.mapObjectName === identifier2.mapObjectName
      && (identifier1.mapObjectType === identifier2.mapObjectType
        || identifier1.shapeType === identifier2.shapeType
      );
  };
}

export interface DeleteMapObject {
  identifier: MapObjectIdentifier;
  redraw?: boolean;
  redrawProperties?: MapObjectProperties;
}

export interface MapObjectProperties {
  readonly?: boolean;
  disabled?: boolean;
  colour?: string;
  zIndex?: number;
}

export interface MarkerObjectProperties extends MapObjectProperties {
  markerIcon?: google.maps.Icon;
}

export interface FieldChange {
  identifier: MapObjectIdentifier;
  properties?: MapObjectProperties;
}

export function mergeProperties(fieldChange: FieldChange, readonly: boolean) {
  return {...fieldChange.properties, readonly: fieldChange.properties?.readonly || readonly} as MapObjectProperties;
}

export interface CircleFieldChange extends FieldChange {
  coordinates: string;
  radius: number | string;
}

export interface PolyFieldChange extends FieldChange {
  paths: string;
}

export interface MarkerFieldChange extends FieldChange {
  coordinates: string;
  properties?: MarkerObjectProperties;
}

export interface MapSettings {
  coordinates: google.maps.LatLng | google.maps.LatLngLiteral;
  zoom: number;
}

export abstract class MapObject {
  id = Math.random();

  protected constructor(
    protected mapObject: MapObjectShape,
    public identifier: MapObjectIdentifier,
    protected tooltip = new google.maps.InfoWindow({
      disableAutoPan: true,
    }),
  ) {}

  // Mutators

  setIdentifier(identifier: MapObjectIdentifier) {
    this.identifier = identifier;
  }

  changeAppearance(properties: MapObjectProperties) {
    // TODO Implement disabled (same as readonly, but is also grayed out).
    this.mapObject.setOptions({
      strokeColor: properties.colour,
      fillColor: properties.colour,
      editable: !properties.readonly,
      draggable: !properties.readonly,
    } as google.maps.CircleOptions);
  }

  remove() {
    this.mapObject.setMap(null);
    this.mapObject = null;
  }

  focus() {
    this.map.panTo(this.getCenter());
    this.showTooltip();
  }

  tooltipString(): string {
    return `<div class="map-tooltip">
                <div class="title">${this.identifier.mapObjectType}</div>
                ${this.conditionalMapObjectNameDisplay()}
            </div>`;
  }

  protected conditionalMapObjectNameDisplay(): string {
    if ([
      MapObjectType.POINT_OF_INTEREST,
      MapObjectType.HAZARD,
      MapObjectType.INCIDENT_REPORT,
    ].includes(this.identifier.mapObjectType)) {
      return this.identifier.mapObjectName;
    }
    return '';
  }

  abstract configureTooltipPosition(): void;

  protected setTooltipContent() {
    this.tooltip.setContent(this.tooltipString());
  }

  showTooltip() {
    this.setTooltipContent();
    this.configureTooltipPosition();
    this.tooltip.open({ map: this.map });
  }

  closeTooltip() {
    this.tooltip.close();
  }

  // Accessors

  abstract getCenter(): google.maps.LatLng;

  get map(): google.maps.Map {
    return this.mapObject?.getMap() as google.maps.Map;
  }

  get name(): string {
    return this.identifier.mapObjectName;
  }

  isClear(): boolean {
    return this.mapObject == null;
  }
}

export class Circle extends MapObject {
  constructor(
    protected mapObject: google.maps.Circle,
    public identifier: MapObjectIdentifier
  ) {
    super(mapObject, identifier);
  }

  // Mutators

  setCircle(circle: google.maps.Circle) {
    this.mapObject = circle;
  }

  setCenterFromString(input: string) {
    const center = parsePointToLatLng(input);
    this.setCenter(center);
  }

  setCenter(center: google.maps.LatLng) {
    if (this.mapObject.getCenter().equals(center)) {
      return;
    }
    this.setTooltipContent();
    this.mapObject.setCenter(center);
  }

  setRadius(radius: number) {
    if (this.mapObject.getRadius() === radius) {
      return;
    }
    this.mapObject.setRadius(radius);
  }

  // Accessors

  configureTooltipPosition() {
    this.tooltip.setOptions({ pixelOffset: new google.maps.Size(0, 10) });
    this.tooltip.setPosition(this.mapObject.getCenter());
  }

  /**
   * Form field representation.
   * Returns: "-33.970262484,18.70736399"
   */
  centerString(): string {
    return this.mapObject ? this.mapObject.getCenter().toUrlValue(COORDINATE_PRECISION) : '';
  }

  getCenter(): google.maps.LatLng {
    return this.mapObject.getCenter();
  }

  radius(): number | string {
    return this.mapObject ? this.mapObject.getRadius() : '';
  }
}

abstract class PolyObject extends MapObject {
  protected constructor(
    protected mapObject: PolyShape,
    public identifier: MapObjectIdentifier
  ) {
    super(mapObject, identifier);
  }

  // Mutators

  setPathFromString(input: string) {
    const path = parsePathToLatLngLiteralArr(input);
    this.setPath(path);
  }

  setPath(path: google.maps.LatLngLiteral[]) {
    this.setTooltipContent();
    this.mapObject.setPath(path);
  }

  // Accessors

  getPath(): google.maps.MVCArray {
    return this.mapObject.getPath();
  }

  getShape(): PolyShape {
    return this.mapObject;
  }

  path(): Coordinate[] {
    return this.mapObject
      ? this.mapObject.getPath().getArray()
        .map((point: google.maps.LatLng) => ([point.lat(), point.lng()]))
      : [];
  }

  pathString(): string {
    return this.path().join(',');
  }
}

export class Polygon extends PolyObject {
  constructor(
    protected mapObject: google.maps.Polygon,
    public identifier: MapObjectIdentifier
  ) {
    super(mapObject, identifier);
  }

  configureTooltipPosition() {
    this.tooltip.setOptions({ pixelOffset: new google.maps.Size(-5, -5) });
    this.tooltip.setPosition(this.getCenter());
  }

  getCenter(): google.maps.LatLng {
    const bounds = new google.maps.LatLngBounds();
    this.getPath().forEach((point: google.maps.LatLng) => {
      bounds.extend(point);
    });
    return bounds.getCenter();
  }
}

export class Polyline extends PolyObject {
  constructor(
    protected mapObject: google.maps.Polyline,
    public identifier: MapObjectIdentifier
  ) {
    super(mapObject, identifier);
  }

  configureTooltipPosition() {
    this.tooltip.setOptions({ pixelOffset: new google.maps.Size(0, -3) });
    this.tooltip.setPosition(this.getCenter());
  }

  getCenter(): google.maps.LatLng {
    const pathPoints = this.mapObject.getPath().getArray();
    const middleIndex = Math.floor(pathPoints.length / 2);
    // If polyline has an even number of points, then use the midpoint between those two points.
    if (pathPoints.length % 2 === 0) {
      const [start, end] = [middleIndex - 1, middleIndex];
      return midPoint(pathPoints[start], pathPoints[end]);
    }
    // If polyline has an odd number of points, then use the center.
    return pathPoints[middleIndex];
  }
}

export class Marker extends MapObject {
  constructor(
    protected mapObject: google.maps.Marker,
    public identifier: MapObjectIdentifier,
  ) {
    super(mapObject, identifier);
  }

  // Mutators

  setPosition(position: google.maps.LatLng) {
    this.mapObject.setPosition(position);
    this.setTooltipContent();
  }

  setPositionFromString(input: string) {
    const inputParts = input.split(',', 2);
    const lat = parseFloat(inputParts[0]);
    const lng = parseFloat(inputParts[1]);
    const position = new google.maps.LatLng(lat, lng);
    this.setPosition(position);
  }

  changeAppearance(properties: MarkerObjectProperties) {
    this.mapObject.setOptions({
      icon: properties.markerIcon,
      draggable: !properties.readonly,
      zIndex: properties.zIndex
    });
  }

  // Accessors

  configureTooltipPosition(): void {
    const anchor = (this.mapObject.getIcon() as google.maps.Icon).anchor;
    this.tooltip.setOptions({ pixelOffset: new google.maps.Size(-1, -anchor.y - 1) });
    this.tooltip.setPosition(this.mapObject.getPosition());
  }

  getCenter(): google.maps.LatLng {
    return this.mapObject.getPosition();
  }

  /**
   * Form field representation.
   * Returns: "-33.970262484,18.70736399"
   */
  positionString(): string {
    return this.mapObject ? this.mapObject.getPosition().toUrlValue(COORDINATE_PRECISION) : '';
  }
}
