/* tslint:disable:variable-name */
/*
Please follow the Angular Style guide and best practices.
https://angular.io/guide/styleguide#data-services
 */

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseApiService } from '@bo/ng-api';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import {
  IncidentReportRequest,
  IncidentDetail,
  IncidentPhoto,
  HazardReportRequest,
  IncidentInvestigationReport,
  IncidentInvestigationOverview,
  IncidentInvestigationDetailsRequest,
  IncidentInvestigationRiskRequest,
  IncidentInvestigationRisk,
  IncidentInvestigationCorrectiveRequest,
  IncidentInvestigationCorrective,
  IncidentInvestigationImplementationRequest,
  IncidentInvestigationImplementation,
  IncidentInvestigationEvaluation,
  IncidentInvestigationEvaluationRequest,
  InvestigationReviewRequest, InvestigationReportedToCaaRequest, InvestigationReportedToCaa
} from 'src/app/shared/api/interface/api.models';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../modules/roc/store/states';
import { UserLinkedROCMapped } from '../../../modules/roc/store/models';
import { AddRocParamsToApiQuery, UserSelectedROCMixin } from '../../../modules/roc/store/helpers';
import { Router } from '@angular/router';
import { FileDownloaderService } from '../../services/file-downloader.service';

@Injectable()
export class ReportApiService extends BaseApiService implements UserSelectedROCMixin {
  baseUrl = environment.apiRoot;

  URL_INCIDENTS_REPORTS = 'incident-report/incidents';
  URL_INCIDENT_ACCIDENT_REPORTS = 'incident-report/accident-report';
  URL_HAZARD_REPORTS = 'incident-report/hazard-report';
  URL_INCIDENT_REPORT_PHOTOS = 'incident-report/incidents-photos';
  URL_RENDER_ACCIDENT_PDF = 'render_accident_pdf'; // relative to URL_INCIDENTS_REPORTS
  URL_RENDER_HAZARD_PDF = 'render_hazard_pdf'; // relative to URL_INCIDENTS_REPORTS

  URL_INVESTIGATION = 'investigation';
  URL_INVESTIGATION_FILES = 'investigation-files';

  // all below are relative to URL_INVESTIGATION
  URL_INVESTIGATION_OVERVIEW = 'overview'
  URL_INVESTIGATION_RISK = 'risk'
  URL_INVESTIGATION_CORRECTIVE = 'corrective'
  URL_INVESTIGATION_IMPLEMENTATION = 'implementation'
  URL_INVESTIGATION_EVALUATION = 'evaluation'
  URL_INVESTIGATION_REVIEW = 'review'
  URL_INVESTIGATION_ACCEPT = 'accept'
  URL_INVESTIGATION_DECLINE = 'decline'
  URL_INVESTIGATION_REPORTED = 'submission'
  URL_INVESTIGATION_PENDING_REQUESTS = 'pending_requests'
  URL_RENDER_INVESTIGATION_PDF = 'render_investigation_pdf'
  URL_DOWNLOAD_ZIP = 'download_zip'

  public incidentsUpdated$ = new Subject<void>();
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  rocId: number;
  rocUser: number;

  constructor(
    protected http: HttpClient,
    protected router: Router,
    private fileDownloader: FileDownloaderService,
  ) {
    super(http);
  }

  getROCData() {
    this.selectedROC$.pipe(
      first(roc => !!roc),
      map(roc => {
        this.rocId = roc.id;
        this.rocUser = roc.roc_user.id;
      }),
    ).subscribe();
  }

  private mapDataWithParams<T>(data:T): T & { remote_operator: number; roc_user: number } {
    this.getROCData();
    return {...data, remote_operator: this.rocId, roc_user: this.rocUser};
  }

  // ========================== OPERATION INCIDENT / ACCIDENT / HAZARD REPORTS ================================//

  /**
   * Gets all incident reports for current operation
   *
   * @param queryParams
   * @return Observable<[IncidentDetail]>
   */

  @AddRocParamsToApiQuery()
  getIncidents(queryParams?: HttpParams): Observable<IncidentDetail[]> {
    const url = this.getUrl(this.URL_INCIDENTS_REPORTS);
    return this.http.get<IncidentDetail[]>(url, {params: queryParams}).pipe(
      map(data => data as IncidentDetail[]),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Gets all incident reports - needs a different param
   *
   * @param queryParams
   * @return Observable<[IncidentDetail]>
   */

  @AddRocParamsToApiQuery()
  getOperationIncidents(queryParams?: HttpParams): Observable<IncidentDetail[]> {
    return this.http.get<IncidentDetail[]>(this.getUrl(this.URL_INCIDENTS_REPORTS), {params: queryParams}).pipe(
      map(data => data as IncidentDetail[]),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Creates an incident report
   *
   * @param data
   * @return Observable<IncidentReportRequest>
   */
  createIncidentReport(data: IncidentReportRequest): Observable<IncidentReportRequest> {
    return this.http.post(this.getUrl([this.URL_INCIDENT_ACCIDENT_REPORTS]), data).pipe(
      map(response => response as IncidentReportRequest),
      tap(() => this.incidentsUpdated$.next())
    );
  }

  /**
   * Creates an hazard report
   *
   * @param data
   * @return Observable<IncidentReportRequest>
   */
  createHazardReport(data: HazardReportRequest): Observable<HazardReportRequest> {
    return this.http.post(this.getUrl([this.URL_HAZARD_REPORTS]), data).pipe(
      map(response => response as HazardReportRequest),
      tap(() => this.incidentsUpdated$.next())
    );
  }

  /**
   * Update an incident report
   *
   * @param id
   * @param data
   * @param queryParams
   * @return Observable<IncidentReportRequest | >
   */
  updateIncidentReport(id: number, data: any, queryParams?: HttpParams): Observable<any> {
    const url = this.getUrl([this.URL_INCIDENTS_REPORTS, id]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response),
      tap(() => this.incidentsUpdated$.next()),
    );
  }

  /**
   * Gets incident report detail
   *
   * @param id
   * @param queryParams
   */
  @AddRocParamsToApiQuery()
  getIncidentReport(queryParams: HttpParams, id: number): Observable<IncidentDetail> {
    const url = this.getUrl([this.URL_INCIDENTS_REPORTS, id]);
    return this.http.get(url, {params: queryParams}).pipe(
      map(data => data as unknown as IncidentDetail),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Deletes an incident report
   *
   * @param queryParams
   * @param id
   */
  @AddRocParamsToApiQuery()
  deleteIncidentReport(queryParams: HttpParams, id: number): Observable<any> {
    const url = this.getUrl([this.URL_INCIDENTS_REPORTS, id]);
    return this.http.delete(url, {params: queryParams}).pipe(
      tap(() => this.incidentsUpdated$.next()),
    );
  }

  @AddRocParamsToApiQuery()
  getIncidentReportPhotos(queryParams: any): Observable<IncidentPhoto[]> {
    const url = this.getUrl(this.URL_INCIDENT_REPORT_PHOTOS);
    return this.http.get(url, {params: queryParams}).pipe(
      map(data => data as IncidentPhoto[])
    );
  }

  deleteIncidentReportPhoto(id: number) {
    return this.http.delete(this.getUrl([this.URL_INCIDENT_REPORT_PHOTOS, id]));
  }

  /**
   * Download the flight folio as a PDF.
   */
  @AddRocParamsToApiQuery()
  downloadIncidentReportPDF(queryParams: HttpParams, id: number, name: string) {
    const url = this.getUrl([this.URL_INCIDENTS_REPORTS, id, this.URL_RENDER_ACCIDENT_PDF]);
    return this.http.get(url, {responseType: 'blob', params: queryParams}).pipe(
      tap((blob: Blob) => {
        const filename = `Investigation-Report-for-${name}.pdf`;
        this.fileDownloader.download(blob, filename);
      }),
    );
  }

  /**
   * Download the flight folio as a PDF.
   */
  @AddRocParamsToApiQuery()
  downloadHazardReportPDF(queryParams: HttpParams, report: HazardReportRequest) {
    const url = this.getUrl([this.URL_INCIDENTS_REPORTS, report.id, this.URL_RENDER_ACCIDENT_PDF]);
    return this.http.get(url, {responseType: 'blob', params: queryParams}).pipe(
      tap((blob: Blob) => {
        const filename = `Investigation-Report-for-${report.id}.pdf`;
        this.fileDownloader.download(blob, filename);
      }),
    );
  }

  // ========================== INVESTIGATION REPORTS ========================================================//

  /**
   * Gets all investigation reports created after operation complete
   *
   * @param queryParams
   * @return Observable<[IncidentInvestigationReport]>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReports(queryParams?: HttpParams): Observable<IncidentInvestigationReport[]> {
    const url = this.getUrl(this.URL_INVESTIGATION);
    return this.http.get<IncidentInvestigationReport[]>(url, {params: queryParams}).pipe(
      map(data => data as IncidentInvestigationReport[]),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Get specific investigation report detail
   *
   * @param queryParams
   * @param reportId
   * @return Observable<IncidentInvestigationReport>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReportDetail(queryParams: HttpParams, reportId: number): Observable<IncidentInvestigationReport> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId]);
    return this.http.get<IncidentInvestigationReport>(url, {params: queryParams}).pipe(
      map(data => data as IncidentInvestigationReport),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Get Investigation Report Overview - section (1 of 6) of investigation report
   *
   * @param queryParams
   * @param reportId
   * @return Observable<IncidentInvestigationOverview>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReportSectionOverview(queryParams: HttpParams, reportId: number): Observable<IncidentInvestigationOverview> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_OVERVIEW]);
    return this.http.get<IncidentInvestigationOverview>(url, {params: queryParams}).pipe(
      map(data => data as IncidentInvestigationOverview),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Get Investigation Report Risk Assessment - section (2 of 6) of investigation report
   *
   * @param queryParams
   * @param reportId
   * @return Observable<IncidentInvestigationRisk>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReportSectionRisk(queryParams: HttpParams, reportId: number): Observable<IncidentInvestigationRisk> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_RISK]);
    return this.http.get<IncidentInvestigationRisk>(url, {params: queryParams}).pipe(
      map(data => data as IncidentInvestigationRisk),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Get Investigation Report Corrective Action - section (3 of 6) of investigation report
   *
   * @param queryParams
   * @param reportId
   * @return Observable<IncidentInvestigationCorrective>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReportSectionCorrective(queryParams: HttpParams, reportId: number): Observable<IncidentInvestigationCorrective> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_CORRECTIVE]);
    return this.http.get<IncidentInvestigationCorrective>(url, {params: queryParams}).pipe(
      map(data => data as IncidentInvestigationCorrective),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Get Investigation Report Implementation Phase - section (4 of 6) of investigation report
   *
   * @param queryParams
   * @param reportId
   * @return Observable<IncidentInvestigationImplementation>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReportSectionImplementation(queryParams: HttpParams, reportId: number): Observable<IncidentInvestigationImplementation> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_IMPLEMENTATION]);
    return this.http.get<IncidentInvestigationImplementation>(url, {params: queryParams}).pipe(
      map(data => data as IncidentInvestigationImplementation),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Get Investigation Report Evaluation Phase - section (5 of 6) of investigation report
   *
   * @param queryParams
   * @param reportId
   * @return Observable<IncidentInvestigationEvaluation>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReportSectionEvaluation(queryParams: HttpParams, reportId: number): Observable<IncidentInvestigationEvaluation> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_EVALUATION]);
    return this.http.get<IncidentInvestigationEvaluation>(url, {params: queryParams}).pipe(
      map(data => data as IncidentInvestigationEvaluation),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Get Investigation Reported To CAA Details - section (6 of 6) of investigation report
   *
   * @param queryParams
   * @param reportId
   * @return Observable<InvestigationReportedToCaa>
   */
  @AddRocParamsToApiQuery()
  getInvestigationReportedToCaa(queryParams: HttpParams, reportId: number): Observable<InvestigationReportedToCaa> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_REPORTED]);
    return this.http.get<InvestigationReportedToCaa>(url, {params: queryParams}).pipe(
      map(data => data as InvestigationReportedToCaa),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Download the flight folio as a PDF.
   */
  @AddRocParamsToApiQuery()
  downloadInvestigationReportPDF(queryParams: HttpParams, report: IncidentInvestigationReport,) {
    const url = this.getUrl([this.URL_INVESTIGATION, report.id, this.URL_RENDER_INVESTIGATION_PDF]);
    return this.http.get(url, {responseType: 'blob', params: queryParams}).pipe(
      tap((blob: Blob) => {
        const filename = `Investigation-Report-for-${report.incident.reference_number}.pdf`;
        this.fileDownloader.download(blob, filename);
      }),
    );
  }

  /**
   * Update Investigation Report Overview section (1)
   *
   * @param id
   * @param data
   * @param queryParams
   * @return Observable<IncidentInvestigationDetailsRequest>
   */
  updateInvestigationReportSectionOverview(id: number, data: any, queryParams?: HttpParams): Observable<IncidentInvestigationDetailsRequest> {
    const url = this.getUrl([this.URL_INVESTIGATION, id, this.URL_INVESTIGATION_OVERVIEW]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationOverview)
    );
  }

  /**
   * Update Investigation Report Risk Assessment section (2)
   *
   * @param id
   * @param data
   * @param queryParams
   * @return Observable<IncidentInvestigationRiskRequest>
   */
  updateInvestigationReportSectionRisk(id: number, data: any, queryParams?: HttpParams): Observable<IncidentInvestigationRiskRequest> {
    const url = this.getUrl([this.URL_INVESTIGATION, id, this.URL_INVESTIGATION_RISK]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationRisk)
    );
  }

  /**
   * Update Investigation Report Corrective Action section (3)
   *
   * @param id
   * @param data
   * @param queryParams
   * @return Observable<IncidentInvestigationCorrectiveRequest>
   */
  updateInvestigationReportSectionCorrective(id: number, data: any, queryParams?: HttpParams): Observable<IncidentInvestigationCorrectiveRequest> {
    const url = this.getUrl([this.URL_INVESTIGATION, id, this.URL_INVESTIGATION_CORRECTIVE]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationCorrective)
    );
  }

  /**
   * Update Investigation Report Implementation Phase section (4)
   *
   * @param id
   * @param data
   * @param queryParams
   * @return Observable<IncidentInvestigationImplementationRequest>
   */
  updateInvestigationReportSectionImplementation(id: number, data: any, queryParams?: HttpParams): Observable<IncidentInvestigationImplementationRequest> {
    const url = this.getUrl([this.URL_INVESTIGATION, id, this.URL_INVESTIGATION_IMPLEMENTATION]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationImplementation)
    );
  }

  /**
   * Update Investigation Report Evaluation Phase section (5)
   *
   * @param id
   * @param data
   * @param queryParams
   * @return Observable<IncidentInvestigationEvaluationRequest>
   */
  updateInvestigationReportSectionEvaluation(id: number, data: any, queryParams?: HttpParams): Observable<IncidentInvestigationEvaluationRequest> {
    const url = this.getUrl([this.URL_INVESTIGATION, id, this.URL_INVESTIGATION_EVALUATION]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationEvaluation)
    );
  }

  /**
   * Update Investigation Reported To CAA section (6)
   *
   * @param id
   * @param data
   * @param queryParams
   * @return Observable<InvestigationReportedToCaaRequest>
   */
  updateInvestigationReportedToCaa(id: number, data: any, queryParams?: HttpParams): Observable<InvestigationReportedToCaaRequest> {
    const url = this.getUrl([this.URL_INVESTIGATION, id, this.URL_INVESTIGATION_REPORTED]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as InvestigationReportedToCaa)
    );
  }

  deleteInvestigationFile(id: number) {
    return this.http.delete(this.getUrl([this.URL_INVESTIGATION_FILES, id]));
  }

  /**
   * Send Review request to Accountable Manager, updates report with review details.
   *
   * @param reportId
   * @param data
   * @param queryParams
   * @return Observable<[IncidentInvestigationReport]>
   */
  sendInvestigationReportReview(reportId: number, data: InvestigationReviewRequest,  queryParams?: HttpParams): Observable<IncidentInvestigationReport> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_REVIEW]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationReport)
    );
  }

  /**
   * Send Accept response to Review Request
   *
   * @param reportId
   * @param data
   * @param queryParams
   * @return Observable<[IncidentInvestigationReport]>
   */
  onApproveInvestigationReport(reportId: number, data: any,  queryParams?: HttpParams): Observable<IncidentInvestigationReport> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_ACCEPT]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationReport)
    );
  }

  /**
   * Send Decline response to Review Request
   *
   * @param reportId
   * @param data
   * @param queryParams
   * @return Observable<[IncidentInvestigationReport]>
   */
  onDeclineInvestigationReport(reportId: number, data: any,  queryParams?: HttpParams): Observable<IncidentInvestigationReport> {
    const url = this.getUrl([this.URL_INVESTIGATION, reportId, this.URL_INVESTIGATION_DECLINE]);
    return this.http.put(url, this.mapDataWithParams(data), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response as IncidentInvestigationReport)
    );
  }

  /**
   * Gets pending report review requests for task manager
   *
   * @param queryParams
   * @return Observable<[IncidentDetail]>
   */
  @AddRocParamsToApiQuery()
  getPendingInvestigationReviewRequests(queryParams?: HttpParams): Observable<any[]> {
    const url = this.getUrl([this.URL_INVESTIGATION, this.URL_INVESTIGATION_PENDING_REQUESTS]);

    return this.http.get<any[]>(url, {params: queryParams}).pipe(
      map(data => data as any[]),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        if (error.status === 500) {
          this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }


  /**
   * Request to download zip file of the investigation folder that includes attachments.
   * @param queryParams
   * @param IncidentId
   * @param reference
   */
  @AddRocParamsToApiQuery()
  requestInvestigationFolderZip(queryParams: HttpParams, IncidentId: number, reference: string) {
    const url = this.getUrl([this.URL_INVESTIGATION, IncidentId, this.URL_DOWNLOAD_ZIP]);
    return this.http.get(url, { responseType: 'blob', params: queryParams}).pipe(
      tap((blob: Blob) => {
        const filename = `Investigation-Report-${reference}.zip`;
        this.fileDownloader.download(blob, filename);
      }),
    );
  }
}
