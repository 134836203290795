import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumsService } from '@bo/ng-enums';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentUploadModalComponent } from '../../../../../../shared/components/modals/upload-modal/upload-document-modal.component';
import { DocumentService } from '../../../documents/services/document.service';
import { AppToastrService } from '../../../../../../shared/services/toaster.service';
import { OperationApiService } from '../../../operations/services/operation-api.service';
import { MeetingMinutesDocumentUploadData, UserProfile } from '../../../../../../shared/api/interface/api.models';
import moment from 'moment';
import { EnumLabelService } from '../../../../../../shared/services/enum-label.service';
import { MeetingMinuteTypes } from '../../../../../../shared/api/interface/api.enums';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-meeting-minutes-document-upload',
  templateUrl: '../../../../../../shared/components/modals/upload-modal/upload-document-modal.component.html'
})
export class MeetingMinutesDocumentUploadModalComponent extends DocumentUploadModalComponent implements OnInit {
  @Input() meetingType: MeetingMinuteTypes;
  @Input() meetingOccurrenceId: number;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() meetingTitle: string;
  @Input() allDay: boolean;
  hideStartDateField = true;
  hideEndDateField = true;
  hideFileTypeField = true;

  public onClose = new Subject<boolean>();

  constructor(
    protected store: Store,
    protected documentService: DocumentService,
    protected toastrService: AppToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected enumService: EnumsService,
    protected enumLabelService: EnumLabelService,
    protected bsModalRef: BsModalRef,
    protected operationApiService: OperationApiService,
  ) { super( store, documentService, toastrService, router, route, enumService, enumLabelService, bsModalRef,
    operationApiService); }

  ngOnInit() {
    super.ngOnInit();
    this.onClose = new Subject();
    this.singleDocumentType = this.fileType;
    if ( this.allDay ) {
      this.titleField = `${this.meetingTitle} ${moment(this.startDate).format('YYYY-MM-DD')}`;
    } else {
      this.titleField = `${this.meetingTitle} ${moment(this.startDate).format('YYYY-MM-DD HH:mm')}`
    }
    this.userUploadPermission = this.isQualityOfficer || this.isSafetyOfficer || this.userPermissionFullAccess;
  }

  override getNewDocumentUploadData(userProfile: UserProfile): MeetingMinutesDocumentUploadData {
    const data = super.getNewDocumentUploadData(userProfile);
    return {
      ...data,
      occurrence: this.meetingOccurrenceId,
      valid_from: this.startDate,
      meeting_type: this.meetingType,
    };
  }
}
