import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { IncidentDetail, UserProfile } from 'src/app/shared/api/interface/api.models';
import { ReportApiService } from 'src/app/shared/api/services/report-api.service';
import { OperationService } from "../../../../modules/roc/pages/operations/services/operation.service";
import { catchError } from 'rxjs/operators';
import { ModalService } from '../../../services/modal.service';
import { AppToastrService } from '../../../services/toaster.service';
import { Select } from '@ngxs/store';
import { AppState } from '../../../../store/states';
import { ROCState } from 'src/app/modules/roc/store/states';
import { UserLinkedROCMapped } from 'src/app/modules/roc/store/models';
import { DocumentPreviewModalComponent } from '../../modals/document-preview-modal/document-preview-modal.component';
import { ReportResourceType } from '../../../api/interface/api.enums';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-incident-report-detail',
  templateUrl: './incident-report-detail.component.html',
})
export class OperationIncidentReportDetailComponent implements OnInit, OnDestroy {
  @Select(AppState.userProfile) userProfile$: Observable<UserProfile>;
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;
  @Input() incidentId: number;
  @Input() operationId: number;
  @Input() operationDetails: any;

  //display vars
  showBackUrl: boolean;
  showViewMap: boolean;
  showOperationHeader: boolean = false;
  operationPublished: boolean;

  userPermissionReadOnly: boolean;
  userPermissionSubscribed: boolean;
  protected subscriptions = new Subscription();

  reportResourceType = ReportResourceType.INCIDENT_ACCIDENT_REPORTS;
  incident$: Observable<IncidentDetail>;
  isLoadingStatus: boolean;

  constructor(
    protected reportApiService: ReportApiService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected operationService: OperationService,
    protected modalService: ModalService,
    protected toastrService: AppToastrService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.selectedROC$.subscribe(selectedROC => {
        if (selectedROC) {
          this.userPermissionReadOnly = selectedROC.user_permissions.operation?.execution_phase === 'read_only';
          this.userPermissionSubscribed = selectedROC.has_roc_subscription;
        }
      })
    )

    if (this.incidentId) {
      this.getIncidentReport(this.incidentId);
    } else if (isNaN(this.incidentId) || this.incidentId === 0) {
      this.toastrService.error(`The requested report ID is invalid.`);
    }
  }

  getIncidentReport(incidentId: number) {
    let params = new HttpParams();
    params = params.appendAll({
      'resourcetype': this.reportResourceType,
      'operation': this.operationId,
    });
    this.incident$ = this.reportApiService.getIncidentReport(params, incidentId).pipe(
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView();
  }

  highlightOnMap() {}

  onViewPhoto(photo) {
    let modalRef;
    modalRef = this.modalService.open(DocumentPreviewModalComponent, {class:'modal-fullscreen'});
    modalRef.content.src = photo.file;
    modalRef.content.description = photo.description;
  }

  onDelete(report: IncidentDetail) { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
