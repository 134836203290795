import { Observable } from 'rxjs';
import { MapObjectProperties, MarkerObjectProperties } from './google-maps.model';

export const COLOUR_DEFAULT = '#FB8953';
export const COLOUR_HAZARD = 'rgba(255,115,28,0.7)'; // #FF731C
export const COLOUR_DISABLED = 'rgba(98,97,97,0.37)';
export const COLOUR_INCIDENT = 'rgba(255,0,0,0.7)'; // #FF0000

// The default position on the map: Cape Town International Airport
export const defaultMapCoordinates: google.maps.LatLngLiteral = { lat: -33.9726837, lng: 18.6033858 };

// Create a bounding box with sides ~10km away from the center point.
export function defaultSearchBounds(coordinates: google.maps.LatLngLiteral) {
  return {
    north: coordinates.lat + 0.1,
    south: coordinates.lat - 0.1,
    east: coordinates.lng + 0.1,
    west: coordinates.lng - 0.1,
  };
}

// The default zoom level
export const defaultMapZoom = 10;

// The zoom level used when a user searches a location
export const searchMapZoom = 15;

export function loadKmlLayer(map: google.maps.Map, url: string): Observable<void> {
  const kmlLayer = new google.maps.KmlLayer({ url, map });

  return new Observable<void>(observer => {
    google.maps.event.addListener(kmlLayer, 'status_changed', () => {
      // When the KML layer has finished loading.
      // https://developers.google.com/maps/documentation/javascript/reference/kml#KmlLayer.status_changed
      if (kmlLayer.getStatus() === google.maps.KmlLayerStatus.OK) {
        observer.next();
      } else {
        observer.error(kmlLayer.getStatus());
      }
      observer.complete();
    });
  });
}

// --------------------------------------------------- Shape Options ---------------------------------------------------

function shapeOptionsFactory({ readonly = false,
                                      disabled = false,
                                      colour = COLOUR_DEFAULT,
                                      zIndex = 0 } = {} as MapObjectProperties) {
  // TODO Implement disabled (same as readonly, but is also grayed out).
  return {
    strokeWeight: 3,
    strokeColor: colour,
    fillOpacity: 0.35,
    fillColor: colour,
    editable: !readonly,
    draggable: !readonly,
    zIndex
  };
}

export function markerOptionsFactory({ readonly = false,
                                       disabled = false,
                                       markerIcon,
                                       zIndex = 0} = {} as MarkerObjectProperties): google.maps.MarkerOptions {
  // TODO Implement disabled (same as readonly, but is also grayed out).
  return {
    draggable: !readonly,
    icon: markerIcon,
    zIndex
  } as google.maps.MarkerOptions;
}

export function circleOptionsFactory(properties?: MapObjectProperties): google.maps.CircleOptions {
  return {...shapeOptionsFactory(properties)} as google.maps.CircleOptions;
}

export function polygonOptionsFactory(properties?: MapObjectProperties): google.maps.PolygonOptions {
  return {...shapeOptionsFactory(properties)} as google.maps.PolygonOptions;
}

export function polylineOptionsFactory(properties?: MapObjectProperties): google.maps.PolylineOptions {
  return {
    ...shapeOptionsFactory(properties),
    strokeWeight: 5,
  } as google.maps.PolylineOptions;
}

// -------------------------------------------------- Shape Factories --------------------------------------------------

export function markerFactory(position: google.maps.LatLng,
                              map: google.maps.Map,
                              properties: MarkerObjectProperties): google.maps.Marker {
  const options = {
    position,
    map,
    draggable: !properties.readonly,
    icon: properties.markerIcon,
    zIndex: properties.zIndex
  } as google.maps.MarkerOptions;
  return new google.maps.Marker(options);
}

export function circleFactory(radius: number,
                              center: google.maps.LatLng,
                              map: google.maps.Map,
                              properties: MapObjectProperties): google.maps.Circle {
  return new google.maps.Circle({radius, center, map, ...circleOptionsFactory(properties)});
}

export function polygonFactory(paths: google.maps.LatLngLiteral[],
                               map: google.maps.Map,
                               properties: MapObjectProperties): google.maps.Polygon {
  return new google.maps.Polygon({paths, map, ...polygonOptionsFactory(properties)});
}

export function polylineFactory(path: google.maps.LatLngLiteral[],
                                map: google.maps.Map,
                                properties: MapObjectProperties): google.maps.Polyline {
  return new google.maps.Polyline({path, map, ...polylineOptionsFactory(properties)});
}
