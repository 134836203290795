import { MapObject, Marker } from '../google-maps.model';
import { AirspaceValidationResponse } from '../../../api/interface/api.models';
import { AirspaceAreaType } from '../../../api/interface/api.enums';

export const MSG_NO_PROBLEMS_FOUND = `<li class="message-top">
                                        <span class="align-middle">Flight area valid</span>
                                        <div class="message-icon">
                                            <div class="row-completion-status__badge complete">
                                                <i class="bi bi-check-circle"></i>
                                            </div>
                                        </div>
                                      </li>`;
export const MSG_VALIDATING = `<li class="message-top">
                                 <span class="align-middle">Validating flight area ...</span>
                                 <div class="message-icon">
                                   <div class="spinning-loader"><i class="bi-arrow-clockwise"></i></div>
                                 </div>
                               </li>`;

/**
 * Produces validation messages:
 * “❌ Flight area overlaps MARGATE GENERAL FLYING AREA GND - 8000FT ALT Freq”
 * “❌ Landing marker is placed at BHISHO GENERAL FLYING AREA GND - FL075”
 * “✅ No problems found”
 */
export function messageFactory(mapObject: MapObject,
                               shapeType: google.maps.drawing.OverlayType,
                               response: AirspaceValidationResponse): string {
  const description = response.description;
  let message = '';

  switch (shapeType) {
    case google.maps.drawing.OverlayType.CIRCLE:
      message = `<li>Flight area overlaps ${description}`;
      break;
    case google.maps.drawing.OverlayType.POLYGON:
      message = `<li>Flight area overlaps ${description}`;
      break;
    case google.maps.drawing.OverlayType.POLYLINE:
      message = `<li>Flight area overlaps ${description}`;
      break;
    case google.maps.drawing.OverlayType.MARKER:
      const marker = mapObject as Marker;
      message = `<li>${marker.name} marker is placed on ${description}`;
      break;
  }

  switch (response.airspace_type) {
    case AirspaceAreaType.RESTRICTED:
      message += `<br><div class="message-icon text-warning"><i class="bi bi-exclamation-circle-fill"></i></div>
                  Restricted area: <strong>${response.name}</strong></li>`;
      break;
    case AirspaceAreaType.DANGER:
      message += `<br><div class="message-icon text-warning"><i class="bi bi-exclamation-circle-fill"></i></div>
                  Danger area: <strong>${response.name}</strong></li>`;
      break;
    case AirspaceAreaType.PROHIBITED:
      message += `<br><div class="message-icon text-danger"><i class="bi bi-dash-circle-fill"></i></div>
                  Prohibited area: <strong>${response.name}</strong></li>`;
      break;
    case AirspaceAreaType.CTR:
      message += `<br><div class="message-icon text-info"><i class="bi bi-info-circle-fill"></i></div>
                 Controlled traffic region: <strong>${response.name}</strong></li>`;
      break;
    case AirspaceAreaType.ATZ:
      message += `<br><div class="message-icon text-info"><i class="bi bi-info-circle-fill"></i></div>
                 ATZ: <strong>${response.name}</strong></li>`;
  }
  return message;
}
