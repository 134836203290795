import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-upload-profile-picture',
  templateUrl: './upload-avatar.component.html',
  styleUrls: []
})
export class UploadAvatarModalComponent implements OnInit {
  @Input() cropRounded: boolean;
  @Input() minWidth = 320; // (minimum) width of image in pixels
  @Input() minHeight = 320; // (minimum) height of image in pixels

  public onClose: Subject<string>
  imageUrl: string;

  constructor(
    private bsModalRef: BsModalRef,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    this.onClose = new Subject<string>();
  }

  onCancel() {
    this.onClose.next();
    this.bsModalRef.hide();
  }

  onImageStored($event: string) {
    this.imageUrl = $event;
  }

  onUpload() {
    this.modalService.onHidden()
      .pipe(take(1))
      .subscribe(() => {
        this.onClose.next(this.imageUrl);
      });
    this.bsModalRef.hide();
  }

}
