import { Pipe, PipeTransform } from '@angular/core';
import { EnumLabelService } from '../services/enum-label.service';
import { SnakeToSentenceCasePipe } from './snake-to-sentence-case.pipe';

@Pipe({
  name: 'enumLabel',
})
export class EnumLabelPipe implements PipeTransform {
  constructor(private readonly enumLabelService: EnumLabelService) {
  }

  transform(value: any): string {
    if (!value) {
      return '';
    }
    // Convert value to string.
    const strValue = typeof value === 'string' ? value : value.toString();

    // Return value from label service, defaulting to snake to sentence case.
    return this.enumLabelService.get(strValue) ?? new SnakeToSentenceCasePipe().transform(strValue);
  }
}
