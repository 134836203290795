/* tslint:disable:variable-name */
/*
Please follow the Angular Style guide and best practices.
https://angular.io/guide/styleguide#data-services
 */

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import {
  City,
  LinkedOperator,
  RemoteOperatorProfile,
  SimpleApiResponse,
  SupportRequest,
  UserAccountEmail,
  UserProfile,
  UserRoles, UserProfileSettings, Delegation, RemoteOperatorBillingDetails
} from 'src/app/shared/api/interface/api.models';
import { AddRocParamsToApiQuery } from '../../../modules/roc/store/helpers';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../modules/roc/store/states';
import { UserLinkedROCMapped } from '../../../modules/roc/store/models';

@Injectable()
export class ApiService extends BaseApiService {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;
  rocId: number;
  rocUser: number;

  baseUrl = environment.apiRoot;

  // Base User Elements
  URL_USER_PROFILE = 'account/profile';

  // User account emails
  URL_USER_EMAIL = 'account/email';
  URL_USER_UPDATE_PRIMARY_EMAIL = 'account/email/update_primary_email';

  // User account settings
  URL_USER_SETTINGS = 'account/user-settings';

  // Remote Operator aka ROC
  URL_ROC_ENTITY = 'roc-entity/roc-entities';
  URL_ROC_BILLING = 'billing'; // Relative to URL_ROC_ENTITY

  // ROC Invitation
  URL_ROC_SEND_INVITATION = 'roc-association/invitations';
  URL_ROC_USER_INVITATION_SEND = 'send'; // Relative to URL_ROC_SEND_INVITATION/id
  URL_ROC_USER_INVITATION_RESEND = 'resend'; // Relative to URL_ROC_SEND_INVITATION/id

  URL_CITIES = 'cities';
  URL_ROC_ADMIN_ROLES = 'roc-user/roc-admin-roles';
  URL_ROC_LINKED_OPERATORS = 'roc-association/roc-linked-operators';

  URL_DELEGATION = 'delegation'
  URL_REVOKE = 'revoke' // relative to URL_DELEGATION/id
  URL_CANCEL = 'cancel' // relative to URL_DELEGATION/id

  URL_SUPPORT = 'support';

  constructor(
    protected http: HttpClient,
  ) { super(http); }

  getROCData() {
    this.selectedROC$.pipe(
      first(roc => !!roc),
      map(roc => {
        this.rocId = roc.id;
        this.rocUser = roc.roc_user.id;
      }),
    ).subscribe();
  }

  private mapDataWithParams<T>(data:T): T & { remote_operator: number; roc_user: number } {
    this.getROCData();
    return {...data, remote_operator: this.rocId, roc_user: this.rocUser};
  }

  /**
   * Get the currently logged-in user's profile
   *
   * @return Observable<UserProfile>
   */
  getUserProfile(): Observable<UserProfile> {
    return this.http.get(this.getUrl(this.URL_USER_PROFILE)).pipe(
      map(data => data as UserProfile)
    );
  }

  updateUserProfile(profile: Partial<UserProfile>): Observable<UserProfile> {
    return this.http.patch<UserProfile>(this.getUrl([this.URL_USER_PROFILE]), profile);
  }

  /**
   * Update the user's signature.
   *
   * @param signature
   * @return Observable<UserProfile>
   */
  updateUserSignature(signature: Partial<UserProfile>): Observable<UserProfile> {
    return this.http.patch<UserProfile>(this.getUrl([this.URL_USER_PROFILE]), signature);
  }

  /**
   * Delete the user's signature.
   */
  deleteUserSignature(): Observable<void> {
    return this.http.patch<void>(this.getUrl(this.URL_USER_PROFILE), {signature: ''});
  }

  /**
   * Get the user's settings.
   *
   * @return Observable<UserProfileSettings>
   */
  getUserSettings(): Observable<UserProfileSettings> {
    return this.http.get(this.getUrl(this.URL_USER_SETTINGS)).pipe(
      map(data => data as UserProfileSettings)
    );
  }

  /**
   * Update the user's settings.
   *
   * @param data
   * @return Observable<UserProfileSettings>
   */
  updateUserSettings(data: UserProfileSettings): Observable<UserProfileSettings> {
    return this.http.put<UserProfileSettings>(this.getUrl([this.URL_USER_SETTINGS]), data);
  }


  /**
   * Get admin roles.
   * @return Observable<Models.UserRoles>
   */

  @AddRocParamsToApiQuery()
  getAdminAssumedRoles(queryParams: HttpParams): Observable<UserRoles> {
    const url = this.getUrl([this.URL_ROC_ADMIN_ROLES])
    return this.http.get<UserRoles>(url, {params: queryParams}).pipe(
      map( data => data as UserRoles)
    );
  }

  /**
   * Update admin roles.
   * @return Observable<Models.UserRoles>
   * @param roles
   * @param rocId
   * @param rocUserId
   */

  updateAdminAssumedRoles(roles: UserRoles, rocId: number, rocUserId: number): Observable<UserRoles> {
    const url = this.getUrl([this.URL_ROC_ADMIN_ROLES])
    const data = { ...roles,
      remote_operator: rocId,
      roc_user: rocUserId,
    }
    return this.http.patch<UserRoles>(url, data);
  }

  /**
   * Delegate your postholder role to another user.
   * @return Observable<Delegation>
   * @param request
   */

  postDelegationRequest(request): Observable<Delegation> {
    const url = this.getUrl([this.URL_DELEGATION]);
    return this.http.post<Delegation>(url, this.mapDataWithParams(request)).pipe(
      map( data => data as Delegation)
    );
  }

  /**
   * Delegate your postholder role to another user.
   * @return Observable<Delegation>
   * @param queryParams
   */

  @AddRocParamsToApiQuery()
  getDelegations(queryParams: HttpParams): Observable<Delegation[]> {
    const url = this.getUrl([this.URL_DELEGATION]);
    return this.http.get<Delegation[]>(url, {params: queryParams});
  }

  /**
   * Revoke Delegation (ends prematurely)
   * @return Observable<Delegation>
   * @param delegationId
   */
  revokeDelegation(delegationId: number): Observable<Delegation> {
    const url = this.getUrl([this.URL_DELEGATION, delegationId, this.URL_REVOKE]);
    return this.http.patch<Delegation>(url, this.mapDataWithParams([])).pipe(
      map(data => data as Delegation)
    );
  }

  /**
   * Cancel Delegation.
   * @return Observable<Delegation>
   * @param requestData
   * @param delegationId
   */
  cancelDelegation(requestData, delegationId: number): Observable<Delegation> {
    const url = this.getUrl([this.URL_DELEGATION, delegationId, this.URL_CANCEL]);
    return this.http.patch<Delegation>(url, this.mapDataWithParams(requestData)).pipe(
      map(data => data as Delegation)
    );
  }

  /**
   * Get User Account emails.
   * @return Observable<UserAccountEmail[]>
   */
  getUserAccountEmails(queryParams: HttpParams): Observable<UserAccountEmail[]> {
    const url = this.getUrl([this.URL_USER_EMAIL]);
    return this.http.get<UserAccountEmail[]>(url, {params: queryParams}).pipe(
      map( data => data)
    );
  }

  /**
   * Create new User Account email.
   * @return Observable<UserAccountEmail>
   * @param data
   */
  postUserAccountEmail(data): Observable<UserAccountEmail> {
    const url = this.getUrl([this.URL_USER_EMAIL]);
    return this.http.post<UserAccountEmail>(url, this.mapDataWithParams(data));
  }

  /**
   * Remove User Account email.
   * @param emailId
   * @return Observable<UserAccountEmail>
   */
  removeUserAccountEmail(emailId): Observable<UserAccountEmail> {
    return this.http.delete<UserAccountEmail>(this.getUrl([this.URL_USER_EMAIL, emailId]));
  }

  /**
   * Set primary User Account email.
   * @param data
   * @return Observable<UserAccountEmail>
   */
  setPrimaryAccountEmail(data): Observable<UserAccountEmail> {
    return this.http.post<UserAccountEmail>(this.getUrl(this.URL_USER_UPDATE_PRIMARY_EMAIL), { email: data});
  }

  /**
   * Gets the ROC user profiles associated with the current user.
   * @return Observable<Models.LinkedOperator[]>
   */
  getLinkedROCUsers(): Observable<LinkedOperator[]> {
    return this.http.get<LinkedOperator[]>(this.getUrl(this.URL_ROC_LINKED_OPERATORS));
  }

  /**
   * Get a remote operator profile
   * @return Observable<RemoteOperatorProfile>
   * @param rocId
   */
  getRemoteOperator(rocId: number): Observable<RemoteOperatorProfile> {
    const url = this.getUrl([this.URL_ROC_ENTITY, rocId]);
    return this.http.get(url).pipe(
      map(data => data as RemoteOperatorProfile)
    );
  }

  /**
   * Update remote operator (ROC) profile
   * @return Observable<RemoteOperatorProfile>
   * @param data
   * @param rocId
   * @param rocUserId
   */
  updateRemoteOperator(data: RemoteOperatorProfile, rocId: number, rocUserId: number):
    Observable<RemoteOperatorProfile> {
    const url = this.getUrl([this.URL_ROC_ENTITY, rocId]);
    data = { ...data,
      remote_operator: rocId,
      roc_user: rocUserId,
    }
    return this.http
      .put<RemoteOperatorProfile>(url, data);
  }

  /**
   * Get remote operator billing details
   * @return Observable<RemoteOperatorBillingDetails>
   * @param rocId
   */
  getBillingDetails(rocId: number): Observable<RemoteOperatorBillingDetails> {
    const url = this.getUrl([this.URL_ROC_ENTITY, rocId, this.URL_ROC_BILLING]);
    return this.http.get(url).pipe(
      map(data => data as RemoteOperatorBillingDetails)
    );
  }

  /**
   * Create remote operator (ROC) billing details
   * @return Observable<RemoteOperatorBillingDetails>
   * @param data
   * @param rocId
   * @param rocUserId
   */
  createBillingDetails(data: RemoteOperatorBillingDetails, rocId: number, rocUserId: number):
    Observable<RemoteOperatorBillingDetails> {
    const url = this.getUrl([this.URL_ROC_ENTITY, rocId, this.URL_ROC_BILLING]);
    data = { ...data,
      remote_operator: rocId,
      roc_user: rocUserId,
    }
    return this.http.post<RemoteOperatorBillingDetails>(url, data);
  }

  /**
   * Update remote operator (ROC) billing details
   * @return Observable<RemoteOperatorBillingDetails>
   * @param data
   * @param rocId
   * @param rocUserId
   */
  updateBillingDetails(data: RemoteOperatorBillingDetails, rocId: number, rocUserId: number):
    Observable<RemoteOperatorBillingDetails> {
    const url = this.getUrl([this.URL_ROC_ENTITY, rocId, this.URL_ROC_BILLING]);
    data = { ...data,
      remote_operator: rocId,
      roc_user: rocUserId,
    }
    return this.http.put<RemoteOperatorBillingDetails>(url, data);
  }

  /**
   * Sends an invitation request
   *
   * @param roc_invitations
   * @param rocId
   * @param queryParams
   * @return Observable<SimpleApiResponse>
   */
  postSendROCInvitation(roc_invitations: any, rocId: string | number, queryParams?: HttpParams): Observable<SimpleApiResponse> {
    const invitationUrl = this.getUrl([this.URL_ROC_SEND_INVITATION, rocId, this.URL_ROC_USER_INVITATION_SEND]);
    return this.http.post(invitationUrl, roc_invitations, {params: queryParams}).pipe(
      map(response => response as SimpleApiResponse)
    );
  }

  /**
   * Sends an invitation request
   *
   * @param invitationId
   * @return Observable<SimpleApiResponse>
   */

  resendROCInvitation(invitationId: number ): Observable<SimpleApiResponse> {
    const invitationUrl = this.getUrl([this.URL_ROC_SEND_INVITATION, this.URL_ROC_USER_INVITATION_RESEND]);
    return this.http.put(invitationUrl, {invitation_id: invitationId}).pipe(
      map(response => response as SimpleApiResponse)
    );
  }

  /**
   * Delete a sent invitation.
   *
   * @param invitationId
   */
  deleteROCInvitation(invitationId: number) {
    const deleteUrl = this.getUrl([this.URL_ROC_SEND_INVITATION, invitationId]);
    return this.http.delete(deleteUrl);
  }

  /**
   * Get the cities list
   *
   * @return Observable<City[]>
   */
  getCities(): Observable<City[]> {
    return this.http.get(this.getUrl(this.URL_CITIES)).pipe(
      map(cities => cities as City[])
    );
  }

  /**
   * Feedback / Contact us Form
   */
  postSupportRequest(requestData: SupportRequest, queryParams?: HttpParams) {
    const url = this.getUrl([this.URL_SUPPORT]);
    return this.http.post(url, requestData, {params: queryParams});
  }

}
