import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html'
})
export class AccountTypeComponent implements OnInit {
  isSubmitted = false;
  selectedType: string;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private meta: Meta,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Sign Up | Free Trial | Flyte Zone');
    this.meta.updateTag({
      name: 'description',
      content: 'Sign up for our free trial for full access to our all-in-one platform for remote piloted aircraft systems (RPAS) flight operations.'
    });
  }

  goBack(): void {
    this.location.back();
  }

  accountSelected(event) {
    this.selectedType = event.target.value;
  }

  submitForm(form: NgForm) {
    this.isSubmitted = true;
    if(!form.invalid) {
      this.router.navigate(['sign-up', 'personal-details'], {queryParams: {type: form.value.type}});
    }
  }
}
