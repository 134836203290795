import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OperationService } from '../../../../modules/roc/pages/operations/services/operation.service';

@Component({
  selector: 'app-operation-page-header',
  templateUrl: './operation-page-header.component.html',
  styleUrls: []
})
export class OperationPageHeaderComponent implements OnInit {
  public operationName$: Observable<string>;

  @Input() pageTitle: string;

  constructor(
    private operationService: OperationService,
  ) {
  }

  ngOnInit(): void {
    this.operationName$ = this.operationService.operationName$;
  }

}
