import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-unsaved-modal',
  templateUrl: './unsaved-modal.component.html',
})
export class UnsavedModalComponent implements OnInit {
  @Input() requestObject: string;
  lightTheme = false;
  public onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef,
    private modalService: ModalService) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public onStay(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public onLeave(): void {
    this.modalService.onHidden()
      .pipe(take(1))
      .subscribe( () => {
        this.onClose.next(true);
      });
    this.bsModalRef.hide();
  }

}
