import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FieldPathDirective, FormFieldComponent, FormOptionsDirective } from '@bo/ng-forms';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-field-select',
  templateUrl: './field-select.component.html',
  providers: [
    {provide: FormFieldComponent, useExisting: forwardRef(() => FieldSelectComponent)}
  ]
})
export class FieldSelectComponent extends FieldComponent implements OnInit {
  @Input() bindValue = 'id';
  @Input() bindLabel = '';
  @Input() inputOptions: any[];
  @Input() inputOptions$: Observable<any[]>;
  @Input() searchable: boolean;
  @Input() addTag: boolean;
  @Input() multiple: boolean;
  @Input() clearable: boolean;
  @Input() required: boolean;
  @Input() closeOnSelect: boolean = true;
  @Output() selectionChange = new EventEmitter<any>();
  @Input() notFoundText = 'No items found';
  loading = false;

  @Input() labelTemplate: TemplateRef<any>;
  @Input() optionTemplate: TemplateRef<any>;

  constructor(
    protected fieldOptionsDirective: FormOptionsDirective,
    protected fieldPathDirective: FieldPathDirective
  ) {
    super(fieldOptionsDirective, fieldPathDirective);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.inputOptions$) {
      this.loading = true;
      this.inputOptions$ = this.inputOptions$.pipe(
        tap(() => this.loading = false)
      );
    }
  }

  onSelectionChange(selection: any) {
    this.selectionChange.next(selection);
  }
}
