/* tslint:disable:variable-name */
/*
Please follow the Angular Style guide and best practices.
https://angular.io/guide/styleguide#data-services
 */

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseApiService } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import {
  CancelSubscriptionRequest,
  MySubscription,
  SubscriptionInvoice,
  SubscriptionPackage
} from 'src/app/shared/api/interface/api.models';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../../store/states';
import { UserLinkedROCMapped } from '../../../../store/models';
import { AddRocParamsToApiQuery, UserSelectedROCMixin } from '../../../../store/helpers';
import { FileDownloaderService } from '../../../../../../shared/services/file-downloader.service';

@Injectable()
export class SubscriptionApiService extends BaseApiService implements UserSelectedROCMixin {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;
  baseUrl = environment.apiRoot;

  URL_PACKAGE = 'package';
  URL_SUBSCRIPTION = 'subscription';
  URL_CANCEL ='cancel' // relative to URL_SUBSCRIPTION/PK
  URL_PAYMENTS ='payments' // relative to URL_SUBSCRIPTION
  URL_DOWNLOAD = 'download' // relative to URL_PAYMENTS/PK


  rocId: number;
  rocUser: number;

  constructor(
    protected http: HttpClient,
    private fileDownloader: FileDownloaderService
  ) {
    super(http);
  }

  getROCData() {
    this.selectedROC$.pipe(
      first(roc => !!roc),
      map(roc => {
        this.rocId = roc.id;
        this.rocUser = roc.roc_user.id;
      }),
    ).subscribe();
  }

  private mapDataWithParams<T>(data:T): T & { remote_operator: number; roc_user: number } {
    this.getROCData();
    return {...data, remote_operator: this.rocId, roc_user: this.rocUser};
  }

  /**
   * Gets all active subscription packages that is available for the requesting ROC
   *
   * @param queryParams
   * @return Observable<[SubscriptionPackage[]]>
   */

  @AddRocParamsToApiQuery()
  getSubscriptionPackages(queryParams?: HttpParams): Observable<SubscriptionPackage[]> {
    const url = this.getUrl(this.URL_PACKAGE);
    return this.http.get<SubscriptionPackage[]>(url, {params: queryParams}).pipe(
      map(data => data as SubscriptionPackage[])
    );
  }

  /**
   * Gets active subscription package detail
   *
   * @param id
   * @param queryParams
   * @return Observable<[SubscriptionPackage]>
   */

  @AddRocParamsToApiQuery()
  getSubscriptionPackage(queryParams: HttpParams, id: number,): Observable<SubscriptionPackage> {
    const url = this.getUrl([this.URL_PACKAGE, id]);
    return this.http.get<SubscriptionPackage>(url, {params: queryParams}).pipe(
      map(data => data as SubscriptionPackage)
    );
  }

  @AddRocParamsToApiQuery()
  getMySubscription(queryParams: HttpParams): Observable<MySubscription[]> {
    const url = this.getUrl([this.URL_SUBSCRIPTION]);
    return this.http.get<MySubscription[]>(url, {params: queryParams}).pipe(
      map(data => data as MySubscription[])
    );
  }

  cancelSubscription(data, id: string,): Observable<CancelSubscriptionRequest>{
    const url = this.getUrl([this.URL_SUBSCRIPTION, id, this.URL_CANCEL]);
    return this.http.post<CancelSubscriptionRequest>(url, this.mapDataWithParams(data));
  }

  @AddRocParamsToApiQuery()
  getSubscriptionInvoices(queryParams: HttpParams): Observable<SubscriptionInvoice[]> {
    const url = this.getUrl(this.URL_PAYMENTS);
    return this.http.get<SubscriptionInvoice[]>(url, {params: queryParams}).pipe(
      map(data => data as SubscriptionInvoice[])
    );
  }

  @AddRocParamsToApiQuery()
  getSubscriptionInvoice(queryParams: HttpParams, invoiceId: string,): Observable<SubscriptionInvoice[]> {
    const url = this.getUrl([this.URL_PAYMENTS, invoiceId]);
    return this.http.get<SubscriptionInvoice[]>(url, {params: queryParams}).pipe(
      map(data => data as SubscriptionInvoice[])
    );
  }

  @AddRocParamsToApiQuery()
  downloadSubscriptionInvoice(queryParams: HttpParams, invoiceId: string,) {
    const url = this.getUrl([this.URL_PAYMENTS, invoiceId, this.URL_DOWNLOAD]);
    return this.http.get(url, {responseType: 'blob', params: queryParams}).pipe(
      tap((blob: Blob) => {
        const filename = `Invoice-${invoiceId}.pdf`;
        this.fileDownloader.download(blob, filename);
      }),
    );
  }
}
