import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'app-server-error',
  templateUrl: '../error-message/error-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerErrorComponent extends ErrorMessageComponent {
  errorImgName = '500';
  errorImgWidth = 350;
  errorTitle = 'Oops, something went wrong';
  errorMessage = 'This page is currently not working properly. Please try reloading the page or try again later.';
  errorCode = '500';
}
