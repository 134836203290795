
export enum DocumentExpiryGroupLabels {
  praircraft_documents = 'PR Aircraft Documents',
  gear_documents = 'RPAS Documents',
  operation_documents = 'Operation Documents',
  personnel_documents = 'Crew Members',
  pilot_documents = 'Pilot Documents',
  quality_manager_documents = 'Quality Management',
  roc_documents = 'ROC Management',
  safety_manager_documents = 'Safety Management',
  security_manager_documents = 'Security Management',
  user_documents = 'My Documents',
}
