import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../api/services/api.service';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AppToastrService } from '../../../services/toaster.service';
import { DocumentService } from '../../../../modules/roc/pages/documents/services/document.service';
import { ModalService } from '../../../services/modal.service';
import { AppState } from '../../../../store/states';
import { ROCCrewMember, UserProfile } from '../../../api/interface/api.models';
import { DocumentPreviewModalComponent } from '../../modals/document-preview-modal/document-preview-modal.component';
import { ConfirmationRemoveModalComponent } from '../../modals/confirmation-remove-modal/confirmation-remove-modal.component';
import {
  DocumentCategory,
  DocumentOptionTypes,
  DocumentResourceTypes,
  DocumentType,
  GearDocumentTypes,
  MeetingMinuteTypes,
  QualityManagementDocumentsTypes,
  ROCRelatedDocumentType,
  SafetyManagementDocumentsTypes
} from '../../../api/interface/api.enums';
import { DocumentUploadModalComponent } from '../../modals/upload-modal/upload-document-modal.component';
import { map } from 'rxjs/operators';
import { SetUserStateFromNavigateToDashboard } from '../../../../store/actions';
import { EnumsService } from '@bo/ng-enums';
import { ROCState } from '../../../../modules/roc/store/states';
import { UserLinkedROCMapped } from '../../../../modules/roc/store/models';
import moment from 'moment';
import { ROCOccurrenceDetailModalComponent } from '../../../../modules/roc/pages/calendar/_components/occurrence-detail-modal/occurrence-detail-modal.component';
import { Location } from '@angular/common';
import { EnumLabelService } from '../../../services/enum-label.service';
import { SnakeToSentenceCasePipe } from '../../../pipes/snake-to-sentence-case.pipe';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html'
})
export class DocumentTableComponent implements OnInit, OnDestroy {
  @Select(AppState.userProfile) userProfile$: Observable<UserProfile>;
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  @ViewChild('filterName') filterName: ElementRef;
  @ViewChild('table') table: any;

  subscriptions = new Subscription();

  //Permissions
  userPermissionAccess: boolean;
  userPermissionGearDocumentsReadOnly: boolean;
  userPermissionFullAccess: boolean;
  userPermissionSubscribed: boolean;
  isIndependentPilot: boolean;
  isPilot: boolean;

  //data-table vars
  tempData = [];
  pageSize = 10;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  isLoading = true;
  pageSizeOptions = [5, 10, 15];
  pageSizeClass: string;
  hideClearBtn = true;
  data = [];
  returnArchived: boolean = false;

  //display vars
  customPageTitle: string;
  tableHeader: string;
  showDocsOutstandingForType: string;
  hideTypeColumn: boolean;
  showRequiredDocsPrompt: boolean;
  showOptionalDocsPrompt: boolean;
  showUploadButton = true;

  documentTypeQuery: DocumentCategory;
  documentResourceType: DocumentResourceTypes;
  droneId: number;
  requiredDocsOutstanding: any[];
  recommendedDocsOutstanding: any[];
  availableDocumentsTypes: any[];
  singleDocumentType: any; // for correct upload modal;
  meetingMinuteType: MeetingMinuteTypes;

  documentOptionTypes: DocumentOptionTypes;
  expanded: any = {};
  timeout: any;
  crewMemberId: number;
  userId: number;
  rocUserId: number;
  expiryReminderSetting: number;
  extendCrewMemberIdQueryParam: boolean;
  hideExpiryColumn: boolean;
  crewMembers: ROCCrewMember[];

  navigationEnd = false;

  constructor(
    protected store: Store,
    protected apiService: ApiService,
    protected toastrService: AppToastrService,
    protected documentService: DocumentService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: ModalService,
    protected enumService: EnumsService,
    protected location: Location,
    protected enumLabelService: EnumLabelService,
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.selectedROC$.subscribe(selectedROC => {
        if (selectedROC) {
          this.userPermissionSubscribed = selectedROC.has_roc_subscription;
          this.isPilot = selectedROC.is_pilot;
        } else {
          // Check user profile for independent pilots not belonging to an ROC
          this.userProfile$.subscribe( profile => {
            this.isPilot = profile.roc_user_linked_entities.roc_user_profile.is_pilot;
          })
        }
      })
    );
    this.subscriptions.add(
      this.userProfile$.subscribe(userProfile => {
        if (userProfile) {
          this.userId = userProfile.id;
        }
      })
    )
    this.subscriptions.add(
      this.apiService.getUserSettings().subscribe( settings => {
        this.expiryReminderSetting = settings.document_reminder_period
      })
    )

    // only for page reload.
    if (!this.navigationEnd) {
      this.fetchDocuments();
      if (this.documentOptionTypes) {
        this.getDocumentsTypes(this.documentOptionTypes);
      }
    }
  }

  public fetchDocuments(){
    this.getDocuments(this.documentTypeQuery, this.documentResourceType, this.returnArchived, this.droneId,
      this.meetingMinuteType, this.crewMemberId);
  }

  public getDocumentsTypes(documentTypes) {
    this.userProfile$.subscribe(userProfile => {
        if (userProfile) {
          return this.enumService.getEnum(documentTypes).pipe(
            map(result =>
              result.map(item => ({file_type: item.name, display_name: item.label }))
            ),
          ).subscribe(result => {
            // remove types that shouldn't be uploadable from the table page
            return this.availableDocumentsTypes = result
              .filter( item => item.file_type !== ROCRelatedDocumentType.MEETING_MINUTE)
              .filter( item => item.file_type !== QualityManagementDocumentsTypes.QMS_MEETING_MINUTES)
              .filter( item => item.file_type !== SafetyManagementDocumentsTypes.SMS_MEETING_MINUTES)
              .filter( item => item.file_type !== GearDocumentTypes.BATTERY_LOG)
          });
        }
        this.store.dispatch(new SetUserStateFromNavigateToDashboard());
      }
    );
  }

  getDocuments(documentType: DocumentCategory, resourceType:DocumentResourceTypes, archived?, gearId?, meetingType?, crewMemberId?) {
    this.documentService.getDocumentsFor(documentType, resourceType, archived, gearId, meetingType, crewMemberId).subscribe(documents => {
        documents.map( document => {
          if (document.expire_on) {
            document.expires_in = this.calcRemainder(document.expire_on);
            // is expiry date less than 3 days from now
            document.expires_flag = this.calcRemainderFlag(document.expire_on);
            document.has_expired = this.calcHasExpired(document.expire_on)
          }
        })
        this.data = documents;
        this.tempData = [...documents];
        this.setPageClasses();
        this.getRequiredOutstandingDocs(this.showDocsOutstandingForType, this.data);
        // this.availableDocumentsTypes = this.getDocumentsTypes(this.documentOptionTypes)
        // get all outstanding documents without the required docs.
        const recommendedDocsOutstandingTemp = this.calcRemainingDocsBalance(this.data, this.availableDocumentsTypes);
        // re-calculate the balance of outstanding docs including the required docs.
        this.recommendedDocsOutstanding = this.calcRemainingDocsBalance(this.requiredDocsOutstanding, recommendedDocsOutstandingTemp);
      }, (error) => {
        this.setDocumentError(error)
      }
    );
  }

  setPageClasses() {
    this.getPageSizeClass(this.pageSize);
    this.isLoading = false;
  }

  setDocumentError(error) {
    if (error.status === 500) {
      this.toastrService.error(`An ${error.statusText} has occurred. Documents could not be retrieved.`);
      this.router.navigate(['/roc/server-error'], {skipLocationChange: true});
    }
  }

  calcRemainder(expDate) {
    const today = moment(new Date());
    expDate = new Date(expDate).setHours(23, 59);
    const diff = moment(expDate).diff(today, 'minutes');
    let inp = new Date(0, 0, 0, 0, diff, 0); // assumes minutes as an input
    let m = inp.getMinutes();
    let h = inp.getHours();
    let d = inp.getDay();
    if (+diff > 1) {
      return `${d > 0 ? d+'d' : '' } ${h > 0 ? h+'h' : '' } ${m}m`;
    } else return null
  }

  calcRemainderFlag(expDate) {
    const today = moment(new Date());
    expDate = new Date(expDate).setHours(23, 59);
    const minutesRemaining = this.expiryReminderSetting * 60 * 24;
    const diff = moment(expDate).diff(today, 'minutes');
    if (+diff > 1) {
      return diff < minutesRemaining;
    }
  }

  calcHasExpired(expDate) {
    const today = moment(new Date());
    expDate = new Date(expDate).setHours(23, 59);
    return moment(expDate) < today
  }

  getRequiredOutstandingDocs(type: string, data: any[]) {
    this.requiredDocsOutstanding = this.documentService.getOutstandingDocumentsFor(type, data);
    this.showRequiredDocsPrompt = !!this.requiredDocsOutstanding.length;
  }

  calcRemainingDocsBalance(existing, required) {
    return required?.filter(req => {
      if (req) { return !existing?.some(exist => (exist.file_type === req.file_type )); }
    })
  }

  onViewArchived() {
    if (this.extendCrewMemberIdQueryParam) {
      this.router.navigate(['roc', 'documents', 'personnel-documents', this.rocUserId, 'documents', 'archived'],
        {queryParams: {memberId: this.crewMemberId}});
    } else {
      this.router.navigate(['archived'], {relativeTo: this.route})
    }
  }

  goBack(): void {
    this.location.back();
  }

  public onUploadFileTypeDocument(fileType, resourceType?) {
    const documentTitle = this.enumLabelService.get(fileType) ?? new SnakeToSentenceCasePipe().transform(fileType);
    let modalRef;
    modalRef = this.modalService.open(DocumentUploadModalComponent, {
      class: 'modal-lg',
      initialState: {
        fileType: fileType,
        hideFileTypeField: true,
        documentResourceType: resourceType ? resourceType : this.documentResourceType,
        droneId: this.droneId,
        userId: this.crewMemberId,
        hideCrewField: !!this.crewMemberId,
        hideStartDateField: this.getHideStartDateField(),
        hideEndDateField: this.getHideEndDateField(fileType),
        promptToAutoArchive: true,
        forLabel: this.tableHeader,
        titleField: documentTitle,
      }
    });
    modalRef.content.onClose.subscribe(
      onClose => {
        if (onClose) {
          const document = modalRef.content.uploadedDocument;
          if (modalRef.content.isAlreadyExpired) {
            this.archiveDocument(document)
          } else {
            this.fetchDocuments();
          }
        }
      }
    );
  }

  public onUploadDocument() {
    let modalRef;
    modalRef = this.modalService.open(DocumentUploadModalComponent, {
      class: 'modal-lg',
      initialState: {
        documentTypes: this.documentOptionTypes,
        documentResourceType: this.documentResourceType,
        droneId: this.droneId,
        userId: this.crewMemberId,
        hideCrewField: !!this.crewMemberId,
        forLabel: this.tableHeader,
        promptToAutoArchive: true,
      }
    });
    modalRef.content.onClose.subscribe( onClose => {
      if (onClose) {
        const document = modalRef.content.uploadedDocument;
        if (modalRef.content.isAlreadyExpired) {
          this.archiveDocument(document)
        } else {
          this.fetchDocuments();
        }
      }
    });
  }

  public deleteDocument(document: any) {
    const modalRef = this.modalService.open(ConfirmationRemoveModalComponent);
    modalRef.content.heading = `Remove "${document.title}"`;
    modalRef.content.action = `remove this document`;
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.documentService.deleteDocument(document).subscribe(
          {
            next: () => {
              this.fetchDocuments();
            },
            complete: () => {
              this.toastrService.success(`"${document.title}" has been removed.`);
            },
            error: () => {
              this.toastrService.error(`An error occurred while removing "${document.title}".`);
            }
          }
        );
      }
    });
  }

  public archiveDocumentPrompt(document: any) {
    const modalRef = this.modalService.open(ConfirmationRemoveModalComponent);
    modalRef.content.heading = `Archive "${document.title}"`;
    modalRef.content.action = `archive this document`;
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.archiveDocument(document)
      }
    });
  }

  archiveDocument(document: any) {
    this.documentService.archiveDocument(document).subscribe(
      {
        next: () => {
          this.fetchDocuments();
        },
        complete: () => {
          this.toastrService.success(`"${document.title}" has been archived.`);
        },
        error: () => {
          this.toastrService.error(`An error occurred while archiving "${document.title}".`);
        }
      }
    );
  }

  public onViewDocument(document) {
    let modalRef;
    modalRef = this.modalService.open(DocumentPreviewModalComponent, {class:'modal-fullscreen modal-dialog-scrollable'});
    modalRef.content.src = document.file;
    modalRef.content.title = document.title;
    modalRef.content.type = document.type;
  }

  public onShowEventDetails(eventId, occId) {
    const modalRef = this.modalService.open(ROCOccurrenceDetailModalComponent, {
      initialState: {
        eventId: eventId,
        occId: occId,
        crewMembers: this.crewMembers
      }
    });
    modalRef.content.onClose.subscribe();
  }

  public toggleViewRequiredDocs(){
    this.showRequiredDocsPrompt = !this.showRequiredDocsPrompt
    this.showOptionalDocsPrompt = false;
  }

  public toggleViewOptionalDocs(){
    this.showOptionalDocsPrompt = !this.showOptionalDocsPrompt
    this.showRequiredDocsPrompt = false;
  }

  public getHideStartDateField() {
    return false
  }

  public getHideEndDateField(fileType) {
    return fileType === DocumentType.IDENTITY_DOCUMENT || fileType === SafetyManagementDocumentsTypes.CREW_BRIEFING;
  }

  public updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter & update the rows
    this.data = this.tempData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.hideClearBtn = !(!!val)
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0
    if (event.key === "Escape") {
      this.clearFilter();
    }
  }

  public clearFilter(){
    this.data = this.tempData;
    this.filterName.nativeElement.value = '';
    this.hideClearBtn = true;
  }

  public onChangePageSize(event){
    this.getPageSizeClass(event);
  }

  protected getPageSizeClass(size){
    if (this.data.length >= size) {
      this.pageSizeClass = 'min-height-' + size;
    } else {
      this.pageSizeClass = '';
    }
  }

  // enable for expandable rows
  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  protected readonly DocumentCategory = DocumentCategory;
}
