import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../api/interface/api.models';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(value: UserProfile, format?: string): any {
    let nameList: Array<string>;
    if (value) {
      nameList = [value.first_name, value.last_name];
      return nameList.filter(i => i).join(' ');
    }
  }

}
