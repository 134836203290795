import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AppState } from '../../../store/states';
import { Observable, Subscription } from 'rxjs';
import { CollapseSideNav, ToggleSideNav } from '../../../store/actions';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: []
})
export class SideNavComponent implements OnInit, OnDestroy {

  @Select(AppState.sideNavOpen) sideNavOpen$: Observable<boolean>;

  sideNavOpen: boolean;
  currentYear = new Date().getFullYear();
  private subscriptions = new Subscription();
  @Input() feedbackLink: string;

  constructor(
    protected store: Store
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.sideNavOpen$.subscribe((sideNavOpen) => {
        this.sideNavOpen = sideNavOpen;
        })
    );
  }

  onSideNavToggleClicked(): void {
    this.store.dispatch(new ToggleSideNav());
  }

  onCloseSideNav(): void {
    this.store.dispatch(new CollapseSideNav());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
