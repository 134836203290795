import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { OperationCrewMemberMapped } from '../../../services/operation.models';

@Component({
  selector: 'app-roc-complete-operation-modal',
  templateUrl: './complete-operation-modal.component.html'
})
export class ROCOperationCompleteOperationModalComponent implements OnInit {
  @Input() heading: Observable<string>;
  @Input() deactivatedUsers: Observable<OperationCrewMemberMapped[]>;

  public onClose = new Subject<boolean>();

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public onAccept(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
