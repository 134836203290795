import { Injectable } from '@angular/core';
import { ApiService } from '../api/services/api.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KMLFile } from '../api/interface/api.models';

@Injectable({providedIn: 'root'})
export class AtnsService extends ApiService {
  baseUrl = environment.apiRoot + 'atns/';
  URL_BASE_KML_FILES = 'kml-files';

  constructor(http: HttpClient) {
    super(http);
  }

  getKMLLayers(): Observable<KMLFile[]> {
    return this.http.get<KMLFile[]>(this.getUrl([this.URL_BASE_KML_FILES]));
  }
}
