/* Document Enums*/

export enum DocumentCategory {
  ROC_RELATED_DOCUMENT = 'ROC_RELATED_DOCUMENT',
  ROLE_SPECIFIC_DOCUMENT = 'ROLE_SPECIFIC_DOCUMENT',
  USER_DOCUMENT = 'USER_DOCUMENT',
  GENERAL_RPAS_OPERATOR_DOCUMENT = 'GENERAL_RPAS_OPERATOR_DOCUMENT',
  GEAR_DOCUMENT = 'GEAR_DOCUMENT',
  OPERATION_RELATED_DOCUMENT = 'OPERATION_RELATED_DOCUMENT',
  LOGBOOK = 'LOGBOOK',
  AUDIT_REPORT = 'AUDIT_REPORT',
}

//TODO: remove MEETING_MINUTE = 'MEETING_MINUTE', when it's removed from BE
export enum ROCRelatedDocumentType {
  ROC_CERTIFICATE = 'ROC_CERTIFICATE',
  ASL = 'ASL',
  OPS_MANUAL = 'OPS_MANUAL',
  ICASA_TYPE_APPROVAL = 'ICASA_TYPE_APPROVAL',
  PROOF_OF_INSURANCE = 'PROOF_OF_INSURANCE',
  OPS_SPEC = 'OPS_SPEC',
  CORRESPONDENCE = 'CORRESPONDENCE',
  MANAGEMENT_REVIEW = 'MANAGEMENT_REVIEW',
  MEETING_MINUTE = 'MEETING_MINUTE',
  HR_DOCUMENT = 'HR_DOCUMENT',
  CLIENT_FILE = 'CLIENT_FILE',
}

export enum PilotSpecificDocuments {
  RPL_CERTIFICATE = 'RPL_CERTIFICATE',
  DANGEROUS_GOOD_CERTIFICATE = 'DANGEROUS_GOOD_CERTIFICATE',
  MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
  AVSEC = 'AVSEC',
  INDUCTION_TRAINING_CERTIFICATE = 'INDUCTION_TRAINING_CERTIFICATE',
}

//TODO: remove SMS_MEETING_MINUTES = 'SMS_MEETING_MINUTES', when it's removed from BE
export enum SafetyManagementDocumentsTypes {
  SAFETY_POLICY = 'SAFETY_POLICY',
  SMS_MEETING_MINUTES = 'SMS_MEETING_MINUTES',
  HAZARD_REPORT = 'HAZARD_REPORT',
  INCIDENT_ACCIDENT_REPORT = 'INCIDENT_ACCIDENT_REPORT',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  INVESTIGATION = 'INVESTIGATION',
  SMS_ACTION_REQUEST = 'SMS_ACTION_REQUEST',
  SMS_ACTION_PLAN = 'SMS_ACTION_PLAN',
  EVALUATION_OF_SAFETY_ACTIVITY = 'EVALUATION_OF_SAFETY_ACTIVITY',
  SMS_ARTICLE = 'SMS_ARTICLE',
  SMS_REGISTERS_AND_LOG_ANALYSIS = 'SMS_REGISTERS_AND_LOG_ANALYSIS',
  SMS_AD_HOC_DOCUMENT = 'SMS_AD_HOC_DOCUMENT',
  CREW_BRIEFING = 'CREW_BRIEFING',
}

export enum SecurityManagementDocumentsTypes {
  SECURITY_INCIDENT_REPORT = 'SECURITY_INCIDENT_REPORT',
  SECURITY_INCIDENT_INVESTIGATION = 'SECURITY_INCIDENT_INVESTIGATION',
  RPAS_LOGON_LOGOFF_FUNCTION = 'RPAS_LOGON_LOGOFF_FUNCTION',
  SECURITY_RELATED_ARTICLE = 'SECURITY_RELATED_ARTICLE',
  CONFIDENTIALITY_AGREEMENT = 'CONFIDENTIALITY_AGREEMENT',
}

//TODO: remove QMS_MEETING_MINUTES = 'QMS_MEETING_MINUTES', when it's removed from BE
export enum QualityManagementDocumentsTypes {
  AUDIT_SCHEDULE = 'AUDIT_SCHEDULE',
  INTERNAL_AUDIT_REPORT = 'INTERNAL_AUDIT_REPORT',
  QMS_ACTION_REQUEST = 'QMS_ACTION_REQUEST',
  QMS_ACTION_PLAN = 'QMS_ACTION_PLAN',
  FOLLOW_UP = 'FOLLOW_UP',
  INSPECTION = 'INSPECTION',
  QMS_REGISTERS_AND_LOG_ANALYSIS = 'QMS_REGISTERS_AND_LOG_ANALYSIS',
  MANAGEMENT_AND_EVALUATION = 'MANAGEMENT_AND_EVALUATION',
  QMS_COURSE_CERTIFICATE = 'QMS_COURSE_CERTIFICATE',
  QUALITY_POLICY = 'QUALITY_POLICY',
  EXTERNAL_AUDIT_REPORT = 'EXTERNAL_AUDIT_REPORT',
  QMS_MEETING_MINUTES = 'QMS_MEETING_MINUTES',
}

export enum GearDocumentTypes {
  RLA_CERTIFICATE = 'RLA_CERTIFICATE',
  SOP_CERTIFICATE = 'SOP_CERTIFICATE',
  CERTIFICATE_OF_REGISTRATION = 'CERTIFICATE_OF_REGISTRATION',
  ICASA_RADIO_LICENSE = 'ICASA_RADIO_LICENSE',
  MAINTENANCE_RECORD = 'MAINTENANCE_RECORD',
  MAINTENANCE_LOG = 'MAINTENANCE_LOG',
  RPAS_MANUAL = 'RPAS_MANUAL',
  RPAS_INSURANCE = 'RPAS_INSURANCE',
  MB_CERTIFICATE = 'MB_CERTIFICATE',
  SCALE_CALIBRATION_CERTIFICATE = 'SCALE_CALIBRATION_CERTIFICATE',
  BATTERY_LOG = 'BATTERY_LOG',
  APPROVED_MAINTENANCE_PROGRAM = 'APPROVED_MAINTENANCE_PROGRAM',
}

export enum PilotLogbookTypes {
  MANUAL_CAPTURE = 'MANUAL_CAPTURE',
  AUTO_GENERATE = 'AUTO_GENERATED',
}

export enum UserDocumentTypes {
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  CURRICULUM_VITAE = 'CURRICULUM_VITAE',
  INDUCTION_TRAINING_CERTIFICATE = 'INDUCTION_TRAINING_CERTIFICATE',
  SAFETY_MANAGEMENT_SYSTEM_CERTIFICATE = 'SAFETY_MANAGEMENT_SYSTEM_CERTIFICATE',
  QUALITY_MANAGEMENT_SYSTEM_CERTIFICATE = 'QUALITY_MANAGEMENT_SYSTEM_CERTIFICATE',
  DELEGATION_OF_AUTHORITY = 'DELEGATION_OF_AUTHORITY',
}

export enum GeneralRPASOperatorDocumentTypes {
  CRIMINAL_CHECK = 'CRIMINAL_CHECK',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  TRAINING_DOCUMENT = 'TRAINING_DOCUMENT',
}

export enum AuditReportDocumentTypes {
  AUDIT_REPORT_ADDITIONAL = 'AUDIT_REPORT_ADDITIONAL',
  SECURITY_INCIDENT_REPORT = 'SECURITY_INCIDENT_REPORT',
  SECURITY_INVESTIGATION_REPORT = 'SECURITY_INVESTIGATION_REPORT',
  REGISTER = 'REGISTER',
  RPAS_FUNCTION = 'RPAS_FUNCTION',
  SECURITY_ARTICLE = 'SECURITY_ARTICLE',
  CONFIDENTIALITY_AGREEMENT = 'CONFIDENTIALITY_AGREEMENT',
}

export enum DocumentResourceTypes {
  USER_SPECIFIC = 'UserSpecificDocument',
  PERSONNEL = 'PersonnelDocument',
  USER_ROLE_SPECIFIC = 'UserRoleSpecificDocument',
  QA_MANAGER = 'QualityManagerDocument',
  SAFETY_MANAGER = 'SafetyManagerDocument',
  SECURITY_MANAGER = 'SecurityManagerDocument',
  PR_AIRCRAFT = 'PRAircraftDocument',
  RPAS_OPERATOR = 'RPASOperatorDocument',
  GEAR = 'GearDocument',
  OPERATION = 'OperationDocument',
  PILOT_LOGBOOK = 'PilotLogbookDocument',
  PILOT_SPECIFIC = 'PilotSpecificDocument',
  GENERATED_AUDIT_REPORT = 'GeneratedAuditReportDocument',
  MEETING_MINUTE = 'MeetingMinute',
}

export enum OperationRelatedDocumentTypes {
  LAND_OWNER_PERMISSION = 'LAND_OWNER_PERMISSION',
  FLEXIBLE_USE_OF_AIRSPACE = 'FLEXIBLE_USE_OF_AIRSPACE',
  FLIGHT_PLAN = 'FLIGHT_PLAN',
  CA10120 = 'CA10120',
  CA10118 = 'CA10118',
  CREW_BRIEFING = 'CREW_BRIEFING',
  AD_HOC = 'AD_HOC',
}

export enum PRAircraftDocumentType {
  RMT_LICENSE = 'RMT_LICENSE'
}


export enum DocumentType {
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  LOP = 'LOP',
  RPL_CERTIFICATE = 'RPL_CERTIFICATE',
  DANGEROUS_GOOD_CERTIFICATE = 'DANGEROUS_GOOD_CERTIFICATE',
  MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
  AVSEC = 'AVSEC',
  AD_HOC = 'AD_HOC',
  ROC_CERTIFICATE = 'ROC_CERTIFICATE',
  ASL = 'ASL',
  OPS_MANUAL = 'OPS_MANUAL',
  ICASA_RADIO_LICENSE = 'ICASA_RADIO_LICENSE',
  PROOF_OF_INSURANCE = 'PROOF_OF_INSURANCE',
  RLA_CERTIFICATE = 'RLA_CERTIFICATE',
  SOP_CERTIFICATE = 'SOP_CERTIFICATE',
  MAINTENANCE_LOG = 'MAINTENANCE_LOG',
  LAND_OWNER_PERMISSION = 'LAND_OWNER_PERMISSION',
  FLEXIBLE_USE_OF_AIRSPACE = 'FLEXIBLE_USE_OF_AIRSPACE',
  FLIGHT_PLAN = 'FLIGHT_PLAN',
  CA10120 = 'CA10120',
  CA10118 = 'CA10118',
  AUDIT_REPORT_ADDITIONAL = 'AUDIT_REPORT_ADDITIONAL',
  RPAS_MAINTENANCE_TECHNICIAN_AUTHORIZATION = 'RPAS_MAINTENANCE_TECHNICIAN_AUTHORIZATION',
  TRAINING_CERTIFICATE = 'TRAINING_CERTIFICATE',
  LETTER_REPORT = 'LETTER_REPORT',
  FLIGHT_AND_DUTY_PERIOD_RECORDS = 'FLIGHT_AND_DUTY_PERIOD_RECORDS',
  CORRESPONDENCE = 'CORRESPONDENCE',
  CLIENT_SPECIFIC = 'CLIENT_SPECIFIC',
  HUMAN_RESOURCE = 'HUMAN_RESOURCE',
  MANAGEMENT_REVIEW = 'MANAGEMENT_REVIEW',
  QMS_MEETING_MINUTES = 'QMS_MEETING_MINUTES',
  SMS_MEETING_MINUTES = 'SMS_MEETING_MINUTES',
}

export enum CalendarTypes {
  SAFETY_MEETING = 'Safety Manager',
  QA_MEETING = 'QA Manager',
  OPERATION = 'Operation',
  MAINTENANCE = 'Maintenance',
}

export enum MeetingMinuteTypes {
  TYPE_ROC = 'TYPE_ROC',
  TYPE_QA = 'TYPE_QA',
  TYPE_SMS = 'TYPE_SMS'
}

export enum meetingFrequency  {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum eventSequence {
  BY_YEAR_DAY = 'BY_YEAR_DAY',
  BY_MONTH = 'BY_MONTH',
  BY_MONTH_DAY = 'BY_MONTH_DAY',
  BY_WEEK_NO = 'BY_WEEK_NO',
  BY_WEEK_DAY = 'BY_WEEK_DAY',
  BY_HOUR = 'BY_HOUR',
  BY_MINUTE = 'BY_MINUTE',
  BY_SECOND = 'BY_SECOND',
}

/* User Enums*/

export enum UserState {
  CREATED = 'CREATED',
  EMAIL_VERIFICATION_PENDING = 'EMAIL_VERIFICATION_PENDING',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  ROC_CREATION_PENDING = 'ROC_CREATION_PENDING',
  ROC_CREATED = 'ROC_CREATED',
  ROC_DOCUMENTS_OUTSTANDING = 'ROC_DOCUMENTS_OUTSTANDING',
  ROC_DOCUMENTS_UPLOADED = 'ROC_DOCUMENTS_UPLOADED',
  GENERAL_DOCUMENTS_OUTSTANDING = 'GENERAL_DOCUMENTS_OUTSTANDING',
  GENERAL_DOCUMENTS_UPLOADED = 'GENERAL_DOCUMENTS_UPLOADED',
  ROLE_SPECIFIC_DOCUMENTS_OUTSTANDING = 'ROLE_SPECIFIC_DOCUMENTS_OUTSTANDING',
  ROLE_SPECIFIC_DOCUMENTS_UPLOADED = 'ROLE_SPECIFIC_DOCUMENTS_UPLOADED'
}

/* ROC Related Enums*/

export enum RemoteOperatorInvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED' // FE use only
}

export enum ROCPostHolders {
  SAFETY_MANAGER = 'SAFETY_MANAGER',
  SECURITY_MANAGER = 'SECURITY_MANAGER',
  QUALITY_MANAGER = 'QUALITY_MANAGER',
  PR_FLIGHT_OPERATIONS = 'PR_FLIGHT_OPERATIONS',
  PR_AIRCRAFT = 'PR_AIRCRAFT',
  ACCOUNTABLE_MANAGER = 'ACCOUNTABLE_MANAGER',
}

export enum DelegationStatus {
  PENDING='PENDING', // upcoming
  IN_PROGRESS = 'IN_PROGRESS', // active
  COMPLETE = 'COMPLETE', // reached end date
  CANCELED = 'CANCELED', // canceled, won't display in audit
  REVOKED = 'REVOKED', // stopped before end date.
  CREATED = 'CREATED' // missing document upload
}

export enum ROCPostSpecialists {
  RPAS_MAINTENANCE_TECHNICIAN = 'RPAS_MAINTENANCE_TECHNICIAN',
  OBSERVER = 'OBSERVER'
}

export enum ROCOperatorUsers {
  ROC_SECONDARY_ADMIN = 'ROC_SECONDARY_ADMIN',
  PILOT = 'PILOT',
  SAFETY_MANAGER = 'SAFETY_MANAGER',
  SECURITY_MANAGER = 'SECURITY_MANAGER',
  QUALITY_MANAGER = 'QUALITY_MANAGER',
  PR_FLIGHT_OPERATIONS = 'PR_FLIGHT_OPERATIONS',
  PR_AIRCRAFT = 'PR_AIRCRAFT',
  ACCOUNTABLE_MANAGER = 'ACCOUNTABLE_MANAGER',
  RPAS_MAINTENANCE_TECHNICIAN = 'RPAS_MAINTENANCE_TECHNICIAN',
}

export enum ROCOperatorUserAdditional {
  ADMIN = 'ADMIN',
  OBSERVER = 'OBSERVER'
}

export enum ROCMemberType {
  REMOTE_OPERATOR_ASSOCIATION = 'REMOTE_OPERATOR_ASSOCIATION',
  REMOTE_OPERATOR_INVITATION = 'REMOTE_OPERATOR_INVITATION'
}

export enum EntityType {
  CC = 'CC',
  PRIVATE = 'PRIVATE',
  NPO = 'NPO',
  PUBLIC = 'PUBLIC',
}

export enum DocumentOptionTypes {
  //FIXME: Need a new one here to include all uploadable documents that doesnt include operation documents
  ALL_DOCUMENTS = 'all-document-types',
  OPERATION_DOCUMENTS = 'operation-related-document-types',
  USER_DOCUMENTS = 'user-document-types',
  GENERAL_RPAS_OPERATOR_DOCUMENTS = 'general-rpas-operator-document-types',
  ROC_RELATED_DOCUMENTS = 'roc-related-document-types',
  GEAR_DOCUMENTS = 'gear-document-types',
  PILOT_DOCUMENTS = 'pilot-specific-documents',
  QUALITY_DOCUMENTS = 'quality-management-document-types',
  SAFETY_DOCUMENTS = 'safety-management-document-types',
  SECURITY_DOCUMENTS = 'security-management-document-types',
  PR_AIRCRAFT_DOCUMENTS = 'pr-aircraft-document-types',
}

/* Operations enums */

// TODO: refactor OperationStatus & OperationStatusInt enums to only use one of them.
export enum OperationStatus {
  DRAFT = 'DRAFT',
  ABORTED = 'ABORTED',
  COMPLETED = 'COMPLETED',
  PUBLISHED = 'PUBLISHED',
}

export enum OperationStatusInt {
  DRAFT = 1,
  ABORTED = 2,
  COMPLETED = 3,
  PUBLISHED = 4,
}

export enum OperationType {
  VLOS = 'VLOS',
  EVLOS = 'EVLOS',
  BVLOS = 'BVLOS',
  RVLOS = 'RVLOS'
}

export enum PermissionRequestStatus {
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  NEEDS_ACTION = 'NEEDS_ACTION',
}

export enum AirspaceMapObjectType {
  POLYGON = 'POLYGON',
  POLYLINE = 'POLYLINE',
  CIRCLE = 'CIRCLE',
  POINT = 'POINT',
}

export enum AirspaceAreaType {
  RESTRICTED = 'FAR',
  DANGER = 'FAD',
  PROHIBITED = 'FAP',
  ATZ = 'ATZ',
  CTR = 'CTR',
}

export enum HazardCompletionStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export enum HazardProbability {
  EXTREMELY_IMPROBABLE = 'EXTREMELY_IMPROBABLE',
  IMPROBABLE = 'IMPROBABLE',
  REMOTE = 'REMOTE',
  OCCASIONAL = 'OCCASIONAL',
  FREQUENT = 'FREQUENT'
}

export enum  HazardSeverity {
  NEGLIGIBLE = 'NEGLIGIBLE',
  MINOR = 'MINOR',
  MAJOR = 'MAJOR',
  HAZARDOUS = 'HAZARDOUS',
  CATASTROPHIC = 'CATASTROPHIC',
}

export enum  HazardRiskRating {
  ACCEPTABLE = 'ACCEPTABLE',
  TOLERABLE = 'TOLERABLE',
  INTOLERABLE = 'INTOLERABLE',
}

export enum PointOfInterestType {
  VEHICLE_ACCESS = 'VEHICLE_ACCESS',
  MUSTER_POINT = 'MUSTER_POINT',
  SPECTATOR_AREA = 'SPECTATOR_AREA',
  CORDONS = 'CORDONS',
}

export enum EmergencyEquipment {
  FIRE_EXTINGUISHER = 'FIRE_EXTINGUISHER',
  MEDICAL_AID_KIT = 'MEDICAL_AID_KIT',
  FIRE_PROOF_SUIT = 'FIRE_PROOF_SUIT',
  CORDON_ROPE = 'CORDON_ROPE'
}

export enum EmergencyServiceType {
  HOSPITAL = 'HOSPITAL',
  POLICE = 'POLICE',
  FIRE = 'FIRE'
}

export enum InFlightTakeoffType {
  PLANNED = 'PLANNED',
  ALTERNATIVE = 'ALTERNATIVE',
}

export enum InFlightLandingType {
  PLANNED = 'PLANNED',
  EMERGENCY = 'EMERGENCY',
  ALTERNATIVE = 'ALTERNATIVE',
}

export enum FlightStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  COMPLETED = 'COMPLETED'
}


/* Gear enums */

export enum GearTypes {
  DRONE = 'DRONE',
  BATTERY = 'BATTERY',
  EQUIPMENT = 'EQUIPMENT',
  PAYLOAD = 'PAYLOAD',
}

export enum PayloadTypes {
  CAMERA = 'CAMERA',
  SPEAKER = 'SPEAKER',
  SPOTLIGHT = 'SPOTLIGHT',
}

export enum ServiceLevels {
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

export enum MaintenanceLogType {
  SCHEDULED = 'SCHEDULED',
  UNSCHEDULED = 'UNSCHEDULED',
}

export enum RPASType {
  FIXED_WING = 'FIXED_WING',
  MULTI_ROTOR = 'MULTI_ROTOR',
}

export enum PowerSource {
  FUEL_SYSTEM = 'FUEL_SYSTEM',
  BATTERY = 'BATTERY',
  HYBRID_PETROL = 'HYBRID_PETROL',
}

/* Incident enums */
export enum IncidentType {
  HAZARD = 'HAZARD',
  INCIDENT = 'INCIDENT',
  ACCIDENT = 'ACCIDENT',
}

export enum ReportResourceType {
  ALL_REPORTS = 'Incident',
  INCIDENT_ACCIDENT_REPORTS = 'AccidentReport',
  HAZARD_REPORTS = 'HazardReport'
}

export enum IncidentInvestigationReportStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
  SUBMITTED = 'SUBMITTED',
}

export enum IncidentInvestigationReportState {
  NEW = 1,
  IN_PROGRESS = 2,
  REVIEW = 3,
  COMPLETED = 4,
  SUBMITTED = 5,
}

export enum InvestigationReviewStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export enum IncidentInvestigationReportDocumentTypes {
  PROOF_SUBMISSION= 'PROOF_SUBMISSION',
  TECHNICAL= 'TECHNICAL',
  PREVIOUS_OCCURRENCE= 'PREVIOUS_OCCURRENCE',
  EVALUATION= 'EVALUATION',
  CAA_SUBMISSION = 'CAA_SUBMISSION'
}

export enum AircraftDamage {
  NONE = 'NONE',
  MINIMAL = 'MINIMAL',
  SERIOUS = 'SERIOUS',
  WRITE_OFF = 'WRITE_OFF',
}

/* Notification enums */
export enum NotificationType {
  REMOTE_OPERATOR_INVITATION = 'REMOTE_OPERATOR_INVITATION',
  NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE',
  HAZARD_SIGN_OFF = 'HAZARD_SIGN_OFF',
  HAZARD_SIGN_OFF_RESPONSE = 'HAZARD_SIGN_OFF_RESPONSE',
  DIRECT_MESSAGE = 'DIRECT_MESSAGE',
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
  MEETING_REMINDER = 'MEETING_REMINDER',
  MEETING_CANCEL = 'MEETING_CANCEL',
  UPLOAD_MEETING_MINUTES = 'UPLOAD_MEETING_MINUTES',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  DOCUMENT_EXPIRY_REMINDER = 'DOCUMENT_EXPIRY_REMINDER',
  DELEGATION_REMINDER = 'DELEGATION_REMINDER',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  SUBSCRIPTION = 'SUBSCRIPTION',
  AUDIT_REPORT = 'AUDIT_REPORT',
  INVESTIGATION_REPORT = 'INVESTIGATION_REPORT',
  INVESTIGATION_REVIEW = 'INVESTIGATION_REVIEW',
}

export enum AuditReportDocumentStatus {
  REQUIREMENT_MET = 'REQUIREMENT_MET',
  DOCUMENT_OUTSTANDING = 'DOCUMENT_OUTSTANDING',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  SIGNATURES_OUTSTANDING = 'SIGNATURES_OUTSTANDING',
  ADDITIONAL_DOCUMENT_UPLOADED = 'ADDITIONAL_DOCUMENT_UPLOADED',
  OPTIONAL = 'OPTIONAL',
}

export enum AuditReportFolderStatus {
  ALL_REQUIREMENTS_MET = 'ALL_REQUIREMENTS_MET',
  OUTSTANDING = 'OUTSTANDING',
}

export enum AuditStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  ALL_REQUIREMENTS_MET = 'ALL_REQUIREMENTS_MET',
  OUTSTANDING = 'OUTSTANDING',
}

export enum AuditReportFolderGroup {
  REQUIRED = 'REQUIRED',
  SYSTEM_GENERATED = 'SYSTEM_GENERATED',
  USER_UPLOADED = 'USER_UPLOADED',
  ADDITIONAL = 'ADDITIONAL'
}

export enum AuditReportFolderTypes {
  AIRWORTHINESS_REPORTS = 'AIRWORTHINESS_REPORTS',
  FLIGHT_OPS_REPORTS = 'FLIGHT_OPS_REPORTS',
  DRONE_SPECIFIC = 'DRONE_SPECIFIC',
  NINETY_DAYS_FILES = 'NINETY_DAYS_FILES',
  OPERATION_SPECIFIC = 'OPERATION_SPECIFIC',
  PILOT_FILES = 'PILOT_FILES',
  ROC_DOCUMENTS = 'ROC_DOCUMENTS',
}

export enum DocumentState {
  ACTIVE = 1,
  PENDING = 2,
  DELETED = 3,
  ARCHIVED = 4
}

/* Subscription Enums*/
export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  EXPIRED = 'EXPIRED',
  OPERATIONS_EXCEEDED = 'OPERATIONS_EXCEEDED',
}

export enum SubscriptionPaymentFrequency {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum SubscriptionTiers {
  TRIAL = 'TRIAL',
  STARTUP = 'STARTUP',
  PROFESSIONAL = 'PROFESSIONAL',
  ENTERPRISE = 'ENTERPRISE',
  CUSTOM = 'CUSTOM',
}
