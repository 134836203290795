import { Component, OnInit } from '@angular/core';
import { AppFormComponent } from '../../../shared/components/forms/app-form-component';
import { AuthApiService } from '../../../shared/api/services/auth-api.service';
import { AppToastrService } from '../../../shared/services/toaster.service';
import { Method } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html'
})
export class EmailVerificationComponent extends AppFormComponent<any> implements OnInit {
  protected fields = [];

  email = '';

  constructor(
    protected authService: AuthApiService,
    protected toastrService: AppToastrService,
    protected activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
    super();
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
  }

  ngOnInit() {
    super.ngOnInit();
    this.titleService.setTitle('Email Verification | Sign Up | Flyte Zone ');
    this.meta.updateTag({
      name: 'description',
      content: 'Complete email verification to activate your Flyte Zone account and experience a seamless RPAS flight operations.'
    });
  }

  createForm(): void {
    const optionsURL = this.authService.URL_REGISTRATION;
    this.subscriptions.add(
      this.buildFromOptions(
        this.authService.getOptions(optionsURL, Method.POST),
      ).subscribe(),
    );
  }

  submitData(): Observable<any> {
    const data = {
      email: this.email,
    };
    return this.authService.resendEmailVerification(data);
  }

  handleSubmitSuccess() {
    this.toastrService.success('Verification email has been resent. ');
  }

}


