import { BaseApiService } from '@bo/ng-api';
import { Injectable } from '@angular/core';
import { Document, RemoveDocumentRequest, SimpleApiResponse, DocumentUploadData, GroupedDocuments
} from '../interface/api.models';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../modules/roc/store/states';
import { UserLinkedROCMapped } from '../../../modules/roc/store/models';
import { AddRocParamsToApiQuery } from '../../../modules/roc/store/helpers';

@Injectable()
export class DocumentsApiService extends BaseApiService {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;
  rocId: number;
  rocUser: number;

  baseUrl = environment.apiRoot + 'documents/';
  URL_DOCUMENTS_OPTIONS = 'documents-options';
  URL_DOCUMENTS = 'documents';
  URL_DOCUMENT_ARCHIVE_DOCUMENT = 'documents/archive-documents';
  URL_DOCUMENT_EXPIRY_LIST = 'documents/document_expiry_list';
  URL_ALL_DOCUMENTS = 'all_documents'

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getROCData() {
    this.selectedROC$.pipe(
      first(roc => !!roc),
      map(roc => {
        this.rocId = roc.id;
        this.rocUser = roc.roc_user.id;
      }),
    ).subscribe();
  }

  private mapDataWithParams<T>(data:T): T & { remote_operator: number; roc_user: number } {
    this.getROCData();
    return {...data, remote_operator: this.rocId, roc_user: this.rocUser};
  }

  /**
   * "Uploads" a new document by sending a document upload request. Note this is not uploading a document rather
   * updating the API with a recently uploaded document.
   *
   * @param documentData
   * @return Observable<Document>
   * @see bo-upload
   */
  uploadDocument(documentData: DocumentUploadData): Observable<Document> {
    const url = this.getUrl([this.URL_DOCUMENTS]);
    return this.http.post(url, this.mapDataWithParams(documentData)).pipe(
      map(document => document as Document)
    );
  }

  /**
   * Return documents for current user based on document type and resourcetype specified in queryParams
   *
   * @return Observable<Document[]>
   * @param queryParams
   */
  getDocuments(queryParams: HttpParams): Observable<Document[]> {
    return this.http.get(this.getUrl(this.URL_DOCUMENTS), {params: queryParams}).pipe(
      map(documents => documents as Document[])
    );
  }

  /**
   * Return all documents uploaded by the user within the current Documents section. Excludes Meeting minutes and operation related documents.
   *
   * @return Observable<Document[]>
   * @param queryParams
   * @param archived
   */
  @AddRocParamsToApiQuery()
  getAllDocuments(queryParams: HttpParams, archived?: boolean): Observable<GroupedDocuments> {
    queryParams = queryParams.append('archived', archived)
    return this.http.get(this.getUrl([this.URL_DOCUMENTS, this.URL_ALL_DOCUMENTS]), {params: queryParams});
  }

  /**
   * Delete document
   *
   * @param request
   * @return Observable<SimpleApiResponse>
   */
  deleteDocument(request: RemoveDocumentRequest): Observable<SimpleApiResponse> {
    return this.http.delete(this.getUrl([this.URL_DOCUMENTS, request.id])).pipe(
      map(response => response as SimpleApiResponse)
    );
  }

  /**
   * Archives the document
   *
   * @param document
   * @return Observable<SimpleApiResponse>
   */
  archiveDocument(document: Document): Observable<SimpleApiResponse> {
    let data = {
      id: document.id,
      file_type: document.type
    };
    data = this.mapDataWithParams(data);
    return this.http.post(this.getUrl(this.URL_DOCUMENT_ARCHIVE_DOCUMENT), data).pipe(
      map(response => response as SimpleApiResponse)
    );
  }

  /**
   * Return documents that are going to expire soon based on user document expire notification setting
   *
   * @return Observable<Document[]>
   */
  getExpiringDocuments(): Observable<GroupedDocuments> {
    return this.http.get(this.getUrl(this.URL_DOCUMENT_EXPIRY_LIST))
  }
}
