import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class APICacheService  {

  cacheDuration = Number(environment.apiCacheDuration) || 0;  // Ensure cast to number or 0
  cache = new Map<string, Observable<HttpEvent<any>>>();

  get(req: HttpRequest<any>): Observable<HttpEvent<any>> | undefined {
    const url = req.urlWithParams;
    const method = req.method;
    const cachedObservable = this.cache.get(this.constructMapKey(url, method));

    if (!cachedObservable) {
      return undefined;
    }

    return cachedObservable;
  }

  put(req: HttpRequest<any>, observable: Observable<HttpEvent<any>>): void {
    const url = req.urlWithParams;
    const method = req.method;
    this.cache.set(this.constructMapKey(url, method), observable);

    observable.pipe(
      delay(this.cacheDuration),
      take(1)
    ).subscribe({
      complete: () => this.cache.delete(this.constructMapKey(url, method))
    });
  }

  removeGet(req: HttpRequest<any>): void {
    const url = req.urlWithParams;
    this.cache.delete(this.constructMapKey(url, 'GET'));
  }

  constructMapKey(url: string, method: string): string {
    return url + '&METHOD=' + method;
  }

}
