import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../../shared/services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppFormComponent } from '../../../../shared/components/forms/app-form-component';
import { RemoteOperatorProfile } from '../../../../shared/api/interface/api.models';
import { AppToastrService } from '../../../../shared/services/toaster.service';
import { Method } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { AuthApiService } from '../../../../shared/api/services/auth-api.service';
import moment from 'moment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roc-create',
  templateUrl: './roc-create.component.html'
})
export class ROCCreateComponent extends AppFormComponent<any> implements OnInit {

  protected fields = ['name', 'contact_number', 'address_line', 'address_line_2', 'city', 'registration_number', 'vat_number', 'roc_registration_date', 'number_of_operations_completed', 'entity_type', 'email'];

  optionsURL = this.authService.URL_ROC_CREATE;
  adminEmail: string;

  constructor(
    private modalService: ModalService,
    private router: Router,
    protected authService: AuthApiService,
    protected toastrService: AppToastrService,
    protected activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
    super();
    this.adminEmail = this.activatedRoute.snapshot.queryParamMap.get('adminEmail');
  }

  ngOnInit() {
    this.titleService.setTitle('ROC Details | Sign Up | Flyte Zone ');
    this.meta.updateTag({
      name: 'description',
      content: 'Sign up for your Flyte Zone profile as an ROC. Provide accurate and up-to-date information to optimise your experience.'
    });
    super.ngOnInit();
  }

  createForm(): void {
    this.subscriptions.add(
      this.buildFromOptions(
        this.authService.getOptions(this.optionsURL, Method.POST),
      ).subscribe()
    );
  }

  submitData(formData: any): Observable<any> {
    const ROCSubmitData: RemoteOperatorProfile = {
      name: this.form.value.name,
      vat_number: this.form.value.vat_number,
      registration_number: this.form.value.registration_number,
      roc_registration_date: moment(this.form.value.roc_registration_date).format('YYYY-MM-DD'),
      entity_type: this.form.value.entity_type,
      number_of_operations_completed: this.form.value.number_of_operations_completed,
      contact_number: this.form.value.contact_number.e164Number,
      email: this.form.value.email,
      address_line: this.form.value.address_line,
      address_line_2: this.form.value.address_line_2,
      roc_city_id: this.form.value.city,
      admin_email: this.adminEmail,
    };
    return this.authService.postRemoteOperator(ROCSubmitData);
  }

  protected handleSubmitError(error: any) {
    super.handleSubmitError(error);
    this.toastrService.error('You do not have permission to create an new ROC');
  }

  handleSubmitSuccess(remoteOperator: RemoteOperatorProfile) {
    this.toastrService.success('You have successfully onboarded! ');
    this.router.navigate(['login']);
  }

}
