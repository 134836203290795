import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../../../../shared/api/services/config.service';

@Component({
  selector: 'app-onboard-base',
  templateUrl: './onboard-base.component.html'
})
export class OnboardBaseComponent implements OnInit{
  baseUrl: string;
  @Input() showWelcomeMessage: boolean

  constructor(
    private config: ConfigService
  ) { }

  ngOnInit() {
    this.baseUrl = this.config.BASE_URL;
  }
}
