import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnumLabelService {
  private enumLabels: Map<string, string> = new Map();

  public set(enumValue: string, label: string) {
    this.enumLabels.set(enumValue, label);
  }

  public get(enumValue: string) {
    return this.enumLabels.get(enumValue);
  }
}
