import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, NgxsOnInit, Select, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/api/services/api.service';
import { AuthState } from 'src/app/shared/auth/store/states';
import { ROCUserProfile, UserPermissions } from '../../../shared/api/interface/api.models';
import { ClearSelectedROC, GetUserLinkedROCs, PersistSelectedROC, SwitchROC } from './actions';
import { ROCStoreModel, UserLinkedROCMapped } from './models';
import { AppToastrService } from '../../../shared/services/toaster.service';


@State<ROCStoreModel>({
  name: 'roc',
  defaults: {
    linkedROCs: [],
    selectedROCID: null,
    finishedLoading: false,
  },
})
@Injectable()
export class ROCState implements NgxsOnInit {
  static readonly SELECTED_ROC_KEY = 'selectedROC';
  @Select(AuthState.loggedIn) private loggedIn$: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly apiService: ApiService,
    protected toastrService: AppToastrService,
    private ngZone: NgZone,
  ) {}

  ngxsOnInit({ dispatch, patchState }: StateContext<ROCStoreModel>): void {
    const whenUserLoggedIn$ = this.loggedIn$.pipe(filter(loggedIn => loggedIn));
    const whenUserLoggedOut$ = this.loggedIn$.pipe(filter(loggedIn => !loggedIn));
    whenUserLoggedIn$.subscribe(() => {
      dispatch(new GetUserLinkedROCs());
    });

    whenUserLoggedOut$.subscribe(() => {
      this.clearState({ patchState });
    });
  }

  private clearState({ patchState }: Partial<StateContext<ROCStoreModel>>) {
    patchState({
      selectedROCID: null,
      linkedROCs: [],
      finishedLoading: false,
    });
  }

  private static getSelectedROC(state: ROCStoreModel): UserLinkedROCMapped | null {
    return state.linkedROCs.find((roc) => roc.id === state.selectedROCID) ?? null;
  }

  /*
   * Selectors
   */

  @Selector()
  public static linkedROCs(state: ROCStoreModel): UserLinkedROCMapped[] {
    return state.linkedROCs;
  }

  @Selector()
  public static selectedROC(state: ROCStoreModel): UserLinkedROCMapped | null {
    return ROCState.getSelectedROC(state);
  }

  @Selector()
  public static selectedROCPermissions(state: ROCStoreModel): UserPermissions | null {
    return ROCState.getSelectedROC(state)?.user_permissions;
  }

  @Selector()
  public static selectedROCUserProfile(state: ROCStoreModel): ROCUserProfile {
    return ROCState.getSelectedROC(state)?.roc_user;
  }

  @Selector()
  public static finishedLoading(state: ROCStoreModel): boolean {
    return state.finishedLoading;
  }

  /*
   * Actions
   */

  @Action(GetUserLinkedROCs)
  public getUserLinkedROCs({ patchState, dispatch }: StateContext<ROCStoreModel>): Observable<UserLinkedROCMapped[]> {
    return this.getMappedLinkedROCs().pipe(
      map((mappedROCs) => {
        const selectedROCIDString = localStorage.getItem(ROCState.SELECTED_ROC_KEY);
        let selectedROCID = selectedROCIDString ? parseInt(selectedROCIDString, 10) : null;

        if (!mappedROCs.some(roc => roc.id === selectedROCID)) {
          selectedROCID = null;
          dispatch(new ClearSelectedROC());
        }

        // Map to our format
        patchState({
          linkedROCs: mappedROCs,
          selectedROCID: selectedROCID ?? mappedROCs[0]?.id, // Only change if we don't already have one selected
          finishedLoading: true,
        });
        return mappedROCs;
      }),
    );
  }

  private getMappedLinkedROCs(): Observable<UserLinkedROCMapped[]> {
    return this.apiService.getLinkedROCUsers().pipe(
      map((rocUsers) => rocUsers
          .filter((user) => !!user?.remote_operator?.id)
          .map((user) => ({
            roc_user: user.roc_user,
            id: user.remote_operator.id,
            name: user.remote_operator.name,
            avatar: user.remote_operator.avatar,
            user_role: user.user_role,
            user_admin_assumed_roles: user.roc_user.admin_assumed_roles,
            is_pilot: (
              user.roc_user.admin_assumed_roles?.has_pilot_role ||
              user.user_role.has_pilot_role
            ),
            is_rmt: (
              user.roc_user.admin_assumed_roles?.has_rpas_maintenance_technician_role ||
              user.user_role.has_rpas_maintenance_technician_role
            ),
            is_admin: (
              user.roc_user.is_admin ||
              user.roc_user.is_secondary_admin ||
              user.roc_user.admin_assumed_roles?.has_secondary_admin_role ||
              user.user_role.has_admin_role ||
              user.user_role.has_secondary_admin_role
            ),
            has_acting_post_holder_role: (
              user.user_role.has_accountable_manager_role ||
              user.user_role.has_flight_operation_manager_role ||
              user.user_role.has_aircraft_manager_role ||
              user.user_role.has_safety_officer_role ||
              user.user_role.has_security_officer_role ||
              user.user_role.has_quality_officer_role
            ),
            user_permissions: user.roc_user.allowed_permissions,
            has_roc_subscription: user.is_subscribed,
          } as UserLinkedROCMapped)),
      ),
    );
  }

  /*
   * Switch the selected ROC to the one with the ID given.
   * Returns the new selected ROC model for convenience.
   * Throws an error if the user does not have a linked ROC with the given ID.
   */
  @Action(SwitchROC)
  public switchSelectedROC({ patchState, getState, dispatch }: StateContext<ROCStoreModel>, { rocId }: SwitchROC): UserLinkedROCMapped {
    const { selectedROCID, linkedROCs } = getState();
    const selectedROC = linkedROCs.find((roc) => roc.id === rocId);
    const selectedROCName = linkedROCs.find((roc) => roc.id === rocId).name;
    const userPermissionFullAccess = linkedROCs.find((roc) => roc.id === rocId).is_admin;
    // Don't update state if the selection remains the same.
    if (rocId === selectedROCID) {
      return selectedROC;
    }

    // Error if an invalid ROC is selected.
    if (!selectedROC) {
      throw Error(`User is not linked to ROC with ID: ${rocId}`);
    }

    patchState({
      selectedROCID: rocId,
    });

    dispatch(new PersistSelectedROC());
    // return user to "base" route of current section
    this.ngZone.run(() => {
      const url = this.router.url;
      switch (true) {
        case (url.includes('roc/overview')): {
          this.router.navigateByUrl('/roc/', {}).then(() => this.router.navigate([`/roc/overview`]))
          break;
        }
        case (url.includes('roc/documents')): {
          this.router.navigateByUrl('/',{ skipLocationChange:true }).then(()=> this.router.navigate([`/roc/documents`]))
          break;
        }
        case (url.includes('roc/operations')): {
          this.router.navigateByUrl('/',{ skipLocationChange:true }).then(() => this.router.navigate([`/roc/operations`]))
          break;
        }
        case (url.includes('roc/gear')): {
          this.router.navigateByUrl('/',{ skipLocationChange:true }).then(() => this.router.navigate([`/roc/gear`]))
          break;
        }
        case (url.includes('roc/meetings')): {
          this.router.navigateByUrl('/',{ skipLocationChange:true }).then(() => this.router.navigate([`/roc/meetings`]))
          break;
        }
        case (url.includes('roc/reports')): {
          this.router.navigateByUrl('/',{ skipLocationChange:true }).then(() => {
            if (userPermissionFullAccess) {
              this.router.navigate([`/roc/reports`])
            } else {
              this.router.navigate([`/roc/overview`])
            }
          })
          break;
        }
        case (this.router.url.includes('roc/manage')): {
          this.router.navigateByUrl('/',{ skipLocationChange:true }).then(() => this.router.navigate([`/roc/manage`]))
          break;
        }
        default: {
          this.router.navigateByUrl('/roc/', {}).then(() => this.router.navigate([`/roc/overview`]))
          break;
        }
      }
    });
    this.toastrService.clear();
    this.toastrService.rocSwitch(`Switched to ${selectedROCName}`);
    return selectedROC;
  }

  @Action(PersistSelectedROC)
  public persistSelectedROC({ getState }: StateContext<ROCStoreModel>): void {
    const { selectedROCID } = getState();
    if (selectedROCID) {
      localStorage.setItem(ROCState.SELECTED_ROC_KEY, selectedROCID.toString());
    }
  }

  @Action(ClearSelectedROC)
  public ClearSelectedROC(): void {
    localStorage.removeItem(ROCState.SELECTED_ROC_KEY);
  }
}
