import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BoApiModule } from '@bo/ng-api';
import { BoEnumsModule } from '@bo/ng-enums';
import { BoFormsModule } from '@bo/ng-forms';
import { BoTableModule } from '@bo/ng-table';
import { BoUploadsModule } from '@bo/ng-uploads';
import { NgSelectModule } from '@ng-select/ng-select';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SideNavItemComponent } from './components/side-nav/side-nav-item/side-nav-item.component';
import { OnboardBaseComponent } from '../pages/onboarding/_components/onboard-base/onboard-base.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderComponent } from './components/header/header.component';
import { HeaderContainerDirective } from './components/header/directives/header-container.directive';
import { PageTitleHeaderComponent } from './components/header/components/page-title/page-title-header.component';
import { BoBreadcrumbsModule } from '@bo/ng-breadcrumbs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { EmptyIndicatorPipe } from './pipes/empty-indicator.pipe';
import { LetDirective } from './directives/ng-let.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxCurrencyModule } from 'ngx-currency';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MomentModule } from 'ngx-moment';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { APICacheService } from './api/services/api-cache.service';
import { CsrfInterceptorService } from './interceptors/csrf-interceptor.service';
import { FieldComponent } from './components/forms/field/field.component';
import { SubmitComponent } from './components/buttons/submit/submit.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { UploadAvatarModalComponent } from './components/modals/upload-avatar/upload-avatar.component';
import { SnakeToSentenceCasePipe } from './pipes/snake-to-sentence-case.pipe';
import { CapitalizeStringPipe } from './pipes/capitalize-string.pipe';
import { DocumentTableComponent } from './components/tables/documents/document-table.component';
import { GroupedDocumentTableComponent } from './components/tables/grouped-documents/grouped-document-table.component';
import { AlertComponent } from './components/alert-boxes/alert/alert.component';
import { PromptBoxComponent } from './components/alert-boxes/prompt-box/prompt-box.component';
import { PromptBoxCompactComponent } from './components/alert-boxes/prompt-box-compact/prompt-box-compact.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { FullNamePipe } from './pipes/full-name.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { UntouchedOnBlurDirective } from './components/forms/untouched-on-blur.directive';
import { SwiperModule } from 'swiper/angular';
import { DrfTimePipe } from './pipes/drf-time.pipe';
import { IntlTelInputComponent } from './components/forms/intl-tel-input/intl-tel-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CitySelectComponent } from './components/forms/city-select/city-select.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { FieldErrorsComponent } from './components/forms/field-errors/field-errors.component';
import { PasswordFieldComponent } from './components/forms/password-field/password-field.component';
import { FieldSelectComponent } from './components/forms/field-select/field-select.component';
import { ConfirmationRemoveModalComponent } from './components/modals/confirmation-remove-modal/confirmation-remove-modal.component';
import { DocumentUploadComponent } from './components/upload-document/upload.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { ReadOnlyEyeComponent } from './components/read-only-eye/read-only-eye.component';
import { CoordinatesSwopComponent } from './components/locations-points-coordinates/swopper/coordinates-swop.component';
import { PointDisplayComponent } from './components/locations-points-coordinates/point-display/point-display.component';
import { PointOfInterestIconComponent } from './components/locations-points-coordinates/point-of-interest-icon/point-of-interest-icon.component';
import { LocationShapeIconComponent } from './components/locations-points-coordinates/shape-icon/location-shape-icon.component';
import { ErrorMessageComponent } from './components/error-messages/error-message/error-message.component';
import { PageNotFoundComponent } from './components/error-messages/page-not-found/page-not-found.component';
import { ServerErrorComponent } from './components/error-messages/server-error/server-error.component';
import { EnumLabelPipe } from './pipes/enum-label.pipe';
import { DocumentPreviewModalComponent } from './components/modals/document-preview-modal/document-preview-modal.component';
import { DocumentUploadModalComponent } from './components/modals/upload-modal/upload-document-modal.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { UnsavedModalComponent } from './components/modals/unsaved-changes/unsaved-modal.component';
import { OperationIncidentReportDetailComponent } from './components/operations/incident-report-detail/incident-report-detail.component';
import { OperationPageHeaderComponent } from './components/operations/operation-page-header/operation-page-header.component';
import { FileUploadModalComponent } from './components/modals/file-upload-modal/file-upload-modal.component';
import { OperationCardLoaderComponent } from './components/operations/operations-card-loader/operation-card-loader.component';
import { FilesAttachmentListComponent } from './components/files-thumbs/files-attachment-list.component';
import { SelectMeetingModalComponent } from './components/modals/select-meeting-modal/select-meeting-modal.component';

@NgModule({
  imports: [
    // BO
    BoBreadcrumbsModule,
    BoFormsModule,
    BoUploadsModule,
    BoEnumsModule,
    BoApiModule,
    BoTableModule,
    BoBreadcrumbsModule,

    RouterModule,
    CommonModule,

    CommonModule,
    RouterModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // 3rd Party
    NgSelectModule,
    NgxCurrencyModule,
    NgxFilesizeModule,
    NgxPaginationModule,
    MomentModule,
    PdfViewerModule,
    Ng2SearchPipeModule,
    SwiperModule,

    // Bootstrap
    AccordionModule,
    PopoverModule,
    ProgressbarModule,
    BsDatepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TimepickerModule,
    TooltipModule,
    AlertModule.forRoot(),
    BsDropdownModule,
    TabsModule,
    ModalModule,
    PaginationModule,
    CollapseModule,

    // Image uploads
    NgxUploaderModule,

    // Angular Date Time Picker
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,

    // ngx-datatables
    // DatatableModule,
    NgxDatatableModule,
    NgxIntlTelInputModule,
  ],
  declarations: [
    // Layout & misc components
    SideNavComponent,
    SideNavItemComponent,
    OnboardBaseComponent,
    HeaderComponent,
    HeaderContainerDirective,
    PageTitleHeaderComponent,
    AlertComponent,
    PromptBoxComponent,
    PromptBoxCompactComponent,
    LoaderComponent,
    AccessDeniedComponent,
    ReadOnlyEyeComponent,
    PointDisplayComponent,
    CoordinatesSwopComponent,
    PointOfInterestIconComponent,
    LocationShapeIconComponent,
    ErrorMessageComponent,
    PageNotFoundComponent,
    ServerErrorComponent,
    StatusBadgeComponent,
    FilesAttachmentListComponent,

    // Buttons & Forms Components
    SubmitComponent,
    FieldComponent,
    UploadImageComponent,
    AvatarComponent,
    UploadAvatarModalComponent,

    // Modals
    ConfirmationModalComponent,
    ConfirmationRemoveModalComponent,
    DocumentPreviewModalComponent,
    DocumentUploadModalComponent,
    FileUploadModalComponent,
    UnsavedModalComponent,
    SelectMeetingModalComponent,

    // Directives
    BackgroundImageDirective,

    // Pipes
    FirstLetterPipe,
    EmptyIndicatorPipe,
    SnakeToSentenceCasePipe,
    CapitalizeStringPipe,
    FullNamePipe,
    EnumLabelPipe,

    // Directives
    LetDirective,

    // Documents > Tables,
    DocumentTableComponent,
   GroupedDocumentTableComponent,
    UntouchedOnBlurDirective,
    DrfTimePipe,
    IntlTelInputComponent,
    CitySelectComponent,
    DocumentUploadComponent,

    GoogleMapsComponent,
    FieldErrorsComponent,
    PasswordFieldComponent,
    FieldSelectComponent,
    SignaturePadComponent,

    // Operations
    OperationIncidentReportDetailComponent,
    OperationPageHeaderComponent,
    OperationCardLoaderComponent,
  ],
  exports: [
    // BO
    BoUploadsModule,
    BoFormsModule,
    BoEnumsModule,
    BoApiModule,
    BoTableModule,

    // Angular
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // 3rd Party
    NgxUploaderModule,
    NgxFilesizeModule,
    NgxPaginationModule,
    NgSelectModule,
    MomentModule,
    PdfViewerModule,
    Ng2SearchPipeModule,
    SwiperModule,

    // Bootstrap
    AccordionModule,
    PopoverModule,
    ProgressbarModule,
    BsDatepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TimepickerModule,
    TooltipModule,
    AlertModule,
    BsDropdownModule,
    TabsModule,
    ModalModule,
    PaginationModule,
    CollapseModule,

    // Layout & misc components
    SideNavComponent,
    SideNavItemComponent,
    OnboardBaseComponent,
    HeaderComponent,
    HeaderContainerDirective,
    PageTitleHeaderComponent,
    AlertComponent,
    PromptBoxComponent,
    PromptBoxCompactComponent,
    LoaderComponent,
    AccessDeniedComponent,
    ReadOnlyEyeComponent,
    PointDisplayComponent,
    CoordinatesSwopComponent,
    PointOfInterestIconComponent,
    LocationShapeIconComponent,
    ErrorMessageComponent,
    PageNotFoundComponent,
    ServerErrorComponent,
    StatusBadgeComponent,
    FilesAttachmentListComponent,

    // Modals
    ConfirmationModalComponent,
    ConfirmationRemoveModalComponent,
    DocumentPreviewModalComponent,
    DocumentUploadModalComponent,
    FileUploadModalComponent,
    UnsavedModalComponent,
    SelectMeetingModalComponent,

    // Buttons & Forms Components
    SubmitComponent,
    FieldComponent,
    UploadImageComponent,
    AvatarComponent,
    // ROCRoleSelectComponent,

    // Angular
    RouterModule,

    // Directives
    BackgroundImageDirective,

    // Pipes
    FirstLetterPipe,
    EmptyIndicatorPipe,
    SnakeToSentenceCasePipe,
    CapitalizeStringPipe,
    DrfTimePipe,
    EnumLabelPipe,

    // App Directives
    LetDirective,

    // Angular Date Time Picker
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,

    // NGX-Datatables
    NgxDatatableModule,
    FullNamePipe,
    UntouchedOnBlurDirective,
    IntlTelInputComponent,
    CitySelectComponent,
    GoogleMapsComponent,
    PasswordFieldComponent,
    FieldSelectComponent,
    DocumentUploadComponent,

    // Signatures
    SignaturePadComponent,
    DocumentTableComponent,
    GroupedDocumentTableComponent,

    // Operations
    OperationIncidentReportDetailComponent,
    OperationPageHeaderComponent,
    OperationCardLoaderComponent,
  ],
  providers: [
    APICacheService,
    // Interceptors
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: CsrfInterceptorService,
        multi: true
      },
      /*{
        provide: HTTP_INTERCEPTORS,
        useClass: APICacheInterceptor,
        multi: true
      }*/
    ],
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: {
        fullPickerInput: 'DD/MM/YYYY HH:mm A',
        datePickerInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
      },
    },
  ]
})
export class SharedModule {

  // Ensure the services are singletons in lazy loaded modules
  // https://angular.io/guide/singleton-services#forroot
  // https://goo.gl/mMTLk6

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
