import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-remove-modal',
  templateUrl: './confirmation-remove-modal.component.html'
})
export class ConfirmationRemoveModalComponent implements OnInit {
  @Input() requestObject: string;
  @Input() heading: string;
  @Input() action: string;
  @Input() additionalMessage1?: string;
  @Input() additionalMessage2?: string;
  @Input() undone: boolean = true;

  public onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public onAccept(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
