import {
  DocumentResourceTypes, GearDocumentTypes, GeneralRPASOperatorDocumentTypes,
  PilotSpecificDocuments, QualityManagementDocumentsTypes,
  ROCRelatedDocumentType, SafetyManagementDocumentsTypes, UserDocumentTypes
} from '../../../../../shared/api/interface/api.enums';

/**
 * These are the required platform documents for the associated entity
 */

export const requiredDocuments = {
  pilot: [
    {
      file_type: PilotSpecificDocuments.RPL_CERTIFICATE,
      resourceType: DocumentResourceTypes.PILOT_SPECIFIC
    },
    {
      file_type: PilotSpecificDocuments.DANGEROUS_GOOD_CERTIFICATE,
      resourceType: DocumentResourceTypes.PILOT_SPECIFIC
    },
    {
      file_type: PilotSpecificDocuments.MEDICAL_CERTIFICATE,
      resourceType: DocumentResourceTypes.PILOT_SPECIFIC
    },
    {
      file_type: PilotSpecificDocuments.AVSEC,
      resourceType: DocumentResourceTypes.PILOT_SPECIFIC
    },
  ],
  roc: [
    {
      file_type: ROCRelatedDocumentType.ROC_CERTIFICATE,
      resourceType: DocumentResourceTypes.RPAS_OPERATOR
    },
    {
      file_type: ROCRelatedDocumentType.ASL,
      resourceType: DocumentResourceTypes.RPAS_OPERATOR
    },
    {
      file_type: ROCRelatedDocumentType.OPS_MANUAL,
      resourceType: DocumentResourceTypes.RPAS_OPERATOR
    },
    {
      file_type: ROCRelatedDocumentType.ICASA_TYPE_APPROVAL,
      resourceType: DocumentResourceTypes.RPAS_OPERATOR
    },
    {
      file_type: ROCRelatedDocumentType.PROOF_OF_INSURANCE,
      resourceType: DocumentResourceTypes.RPAS_OPERATOR
    },
  ],
  safety_manager: [
    {
      file_type: SafetyManagementDocumentsTypes.SAFETY_POLICY,
      resourceType: DocumentResourceTypes.SAFETY_MANAGER
    },
    {
      file_type: SafetyManagementDocumentsTypes.CREW_BRIEFING,
      resourceType: DocumentResourceTypes.SAFETY_MANAGER
    },
  ],
  quality_manager: [
    {
      file_type: QualityManagementDocumentsTypes.QUALITY_POLICY,
      resourceType: DocumentResourceTypes.QA_MANAGER
    },
    {
      file_type: QualityManagementDocumentsTypes.AUDIT_SCHEDULE,
      resourceType: DocumentResourceTypes.QA_MANAGER
    },
  ],
  user: [
    {
      file_type: UserDocumentTypes.IDENTITY_DOCUMENT,
      resourceType: DocumentResourceTypes.USER_SPECIFIC
    },
    {
      file_type: UserDocumentTypes.CURRICULUM_VITAE,
      resourceType: DocumentResourceTypes.USER_SPECIFIC
    },
    {
      file_type: PilotSpecificDocuments.INDUCTION_TRAINING_CERTIFICATE,
      resourceType: DocumentResourceTypes.USER_SPECIFIC
    },
  ],
  drone: [
    {
      file_type: GearDocumentTypes.RLA_CERTIFICATE,
      resourceType: DocumentResourceTypes.GEAR
    },
    {
      file_type: GearDocumentTypes.CERTIFICATE_OF_REGISTRATION,
      resourceType: DocumentResourceTypes.GEAR
    },
    {
      file_type: GearDocumentTypes.RPAS_MANUAL,
      resourceType: DocumentResourceTypes.GEAR
    },
    {
      file_type: GearDocumentTypes.RPAS_INSURANCE,
      resourceType: DocumentResourceTypes.GEAR
    }
  ],
  crew: [
    { file_type: GeneralRPASOperatorDocumentTypes.CRIMINAL_CHECK },
    { file_type: GeneralRPASOperatorDocumentTypes.BACKGROUND_CHECK }
  ]
};


