import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { AddRocParamsToApiQuery, UserSelectedROCMixin} from '../../../store/helpers';
import { ROCState } from '../../../store/states';
import { UserLinkedROCMapped } from '../../../store/models';
import {  Meeting, EventDetail, MeetingOccurrenceRequest, MeetingRequest
} from './meeting.models';
import { filter, map, pluck, switchMap } from 'rxjs/operators';

@Injectable()
export class MeetingsApiService extends BaseApiService implements UserSelectedROCMixin {
  baseUrl = environment.apiRoot;

  URL_MEETING = 'meeting';
  URL_UPCOMING_MEETING = 'meeting/upcoming';
  URL_OCCURRENCE = 'occurrence'; // relative to 'meeting'

  private readonly selectedROCID$: Observable<number>;
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
    this.selectedROCID$ = this.selectedROC$.pipe(
      filter((roc) => !!roc?.id),
      pluck('id'),
    );
  }

  createMeeting(eventRequest: MeetingRequest) {
    return this.selectedROCID$.pipe(
      switchMap( (rocId) =>
        this.http.post(this.getUrl(this.URL_MEETING),{...eventRequest,  remote_operator: rocId})
      )
    );
  }

  updateMeeting(eventId: number, eventRequest: MeetingRequest) {
    return this.selectedROCID$.pipe(
      switchMap( (rocId) =>
        this.http.put(this.getUrl([this.URL_MEETING, eventId]),{...eventRequest,  remote_operator: rocId})
      )
    );
  }

  @AddRocParamsToApiQuery()
  deleteMeeting(queryParams: HttpParams, eventId: number) {
    return this.http.delete(this.getUrl([this.URL_MEETING, eventId]), { params: queryParams})
  }

  @AddRocParamsToApiQuery()
  getEventOccurrences(queryParams: HttpParams): Observable<EventDetail[]> {
    const url = this.getUrl(this.URL_MEETING);
    return this.http.get<EventDetail[]>(url, { params: queryParams}).pipe(
      map( response => response as EventDetail[])
    );
  }

  @AddRocParamsToApiQuery()
  getUpcomingMeetings(queryParams: HttpParams): Observable<EventDetail[]> {
    const url = this.getUrl(this.URL_UPCOMING_MEETING);
    return this.http.get<EventDetail[]>(url, { params: queryParams}).pipe(
      map( response => response as EventDetail[])
    );
  }

  @AddRocParamsToApiQuery()
  getMeetingDetail(queryParams: HttpParams, eventId: number): Observable<Meeting> {
    return this.http.get<Meeting>(this.getUrl([this.URL_MEETING, eventId]));
  }

  updateMeetingOccurrence(eventId: number, request: MeetingOccurrenceRequest): Observable<EventDetail> {
    return this.selectedROCID$.pipe(
      switchMap( (rocId) => {
        const url = this.getUrl([this.URL_MEETING, eventId, this.URL_OCCURRENCE])
        return this.http.put(url, {...request,  remote_operator: rocId}, {observe: 'response'}).pipe(
          map((response: any) => response.body as EventDetail)
        );
      })
    )
  }
}
