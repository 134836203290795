import { Component, OnInit } from '@angular/core';
import { AppFormComponent } from '../../../shared/components/forms/app-form-component';
import { AuthApiService } from '../../../shared/api/services/auth-api.service';
import { AppToastrService } from '../../../shared/services/toaster.service';
import { Method } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { PasswordResetConfirmRequest } from '../../../shared/api/interface/api.models';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-password-reset-confirm',
  templateUrl: './password-reset-confirm.component.html'
})
export class PasswordResetConfirmComponent extends AppFormComponent<PasswordResetConfirmRequest> implements OnInit {

  protected fields = ['new_password1', 'new_password2'];

  passwordResetComplete: boolean;

  constructor(
    protected authService: AuthApiService,
    protected toastrService: AppToastrService,
    protected activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
    super();
  }

  private uid: string;
  private token: string;

  ngOnInit() {
    super.ngOnInit();
    this.titleService.setTitle('Password Confirmation | Request | Flyte Zone');
    this.meta.updateTag({
      name: 'description',
      content: 'Confirm your new password to regain access to Flyte Zone\'s RPAS flight operations platform.'
    });
    this.uid = this.activatedRoute.snapshot.queryParamMap.get('uid');
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
  }

  createForm(): void {
    const optionsURL = this.authService.URL_PASSWORD_RESET_CONFIRM;
    this.subscriptions.add(
      this.buildFromOptions(
        this.authService.getOptions(optionsURL, Method.POST),
      ).subscribe()
    );
  }

  submitData(formData: any): Observable<any> {
    const passwordResetData: PasswordResetConfirmRequest = {
      uid: this.uid,
      token: this.token,
      new_password1: this.form.value.new_password1,
      new_password2: this.form.value.new_password2,
    };
    return this.authService.passwordResetConfirm(passwordResetData);
  }

  handleSubmitSuccess() {
    this.passwordResetComplete = true;
  }

  handleSubmitError(error) {
    super.handleSubmitError(error);
    if (error.error.uid || error.error.token) {
      this.toastrService.error('Reset password link is invalid');
    } else if (error.error.message) {
      this.toastrService.error(error.error.message);
    } else {
      this.toastrService.error('An error occurred while requesting password reset');
    }
  }

}
