/**
 * Enum types that are expected to be received from the backend enums api.
 */

import * as Enums from './api.enums';

export const EnumMapping: Partial<Record<keyof typeof Enums, string>> = {
  ROCOperatorUsers: 'remote-operator-users',
  DocumentCategory: 'document-category',
  PilotSpecificDocuments: 'pilot-specific-documents',
  GearDocumentTypes: 'gear-document-types',
  ROCRelatedDocumentType: 'roc-related-document-types',
  PilotLogbookTypes: 'pilot-logbook-types',
  ROCPostHolders: 'roc-post-holders',
  ROCPostSpecialists: 'roc-specialists',
  IncidentType: 'incident-type',
  AircraftDamage: 'aircraft-damage',
  AirspaceAreaType: 'air-space-type',
  HazardCompletionStatus: 'hazard-completion-status',
  HazardProbability: 'hazard-probability',
  HazardSeverity: 'hazard-severity',
  OperationType: 'operation-type',
  PointOfInterestType: 'point-of-interest-type',
  EmergencyServiceType: 'emergency-service-type',
  EmergencyEquipment: 'emergency-equipment-type',
  FlightStatus: 'operation-flight-state',
  InFlightTakeoffType: 'flight-take-off-type',
  InFlightLandingType: 'flight-landing-type',
  UserDocumentTypes: 'user-document-types',
  GeneralRPASOperatorDocumentTypes: 'general-rpas-operator-document-types',
  GearTypes: 'gear-types',
  PayloadTypes: 'payload-types',
  PowerSource: 'power-source-types',
  RPASType: 'rpas-types',
  DocumentType: 'all-document-types',
  EntityType: 'entity-type',
  PRAircraftDocumentType: 'pr-aircraft-document-types',
};
