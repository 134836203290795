import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import SignaturePad, { Options as SignaturePadOptions } from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
})
export class SignaturePadComponent implements AfterViewInit {
  private signaturePad: SignaturePad;
  @ViewChild('signatureCanvas') signatureCanvas: ElementRef<HTMLCanvasElement>;
  @Input() options: SignaturePadOptions;
  @Input() canSubmit = false;
  @Input() loading = false;
  @Input() allowChanges: boolean;

  private dataUrlInternal: string;

  editMode = false;

  @Input() set dataUrl(value: string) {
    this.dataUrlInternal = value;
    this.currentDataUrl = value;
    if (this.signaturePad) {
      if (value) {
        this.signaturePad.fromDataURL(value);
      } else {
        this.signaturePad.clear();
      }
    }
  }

  get dataUrl(): string {
    return this.dataUrlInternal;
  }

  @Output() beginStroke: EventEmitter<void>;
  @Output() endStroke: EventEmitter<void>;
  @Output() beforeUpdateStroke: EventEmitter<void>;
  @Output() afterUpdateStroke: EventEmitter<void>;
  @Output() save: EventEmitter<string>;
  @Output() delete: EventEmitter<void>;

  private currentDataUrl: string;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly renderer: Renderer2
  ){
    this.beginStroke = new EventEmitter<void>();
    this.endStroke = new EventEmitter<void>();
    this.beforeUpdateStroke = new EventEmitter<void>();
    this.afterUpdateStroke = new EventEmitter<void>();
    this.save = new EventEmitter<string>();
    this.delete = new EventEmitter<void>();
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, this.options);
    this.signaturePad.off();
    this.handleDevicePixelRatio();
    if (this.dataUrl) {
      this.signaturePad.fromDataURL(this.dataUrl);
      this.cdRef.detectChanges();
    }
    this.initialiseListeners();
  }

  enableEditMode() {
    this.editMode = true;
    this.signaturePad.on();
  }

  public disableEditMode() {
    this.editMode = false;
    this.signaturePad.off();
  }

  private initialiseListeners(): void {
    this.signaturePad.addEventListener('beginStroke', () => this.beginStroke.emit());
    this.signaturePad.addEventListener('endStroke', () => {
      this.endStroke.emit();
      this.currentDataUrl = this.toDataUrl();
    });
    this.signaturePad.addEventListener('beforeUpdateStroke', () => this.beforeUpdateStroke.emit());
    this.signaturePad.addEventListener('afterUpdateStroke', () => this.afterUpdateStroke.emit());
  }

  public toDataUrl({mimetype = 'image/png', quality = null}: { mimetype?: string, quality?: number } = {}): string {
    return this.signaturePad.toDataURL(mimetype, quality);
  }

  public clear(): void {
    this.signaturePad.clear();
  }

  public get isEmpty(): boolean {
    return this.signaturePad?.isEmpty() ?? true;
  }

  public reset(): void {
    if (this.dataUrl) {
      this.currentDataUrl = this.dataUrl;
      this.signaturePad.clear();
      this.signaturePad.fromDataURL(this.dataUrl);
    } else {
      this.clear();
    }
  }

  submit(): void {
    this.save.emit(this.toDataUrl());
  }

  get changed(): boolean {
    return this.currentDataUrl !== this.dataUrl;
  }

  @HostListener('window:resize')
  private onWindowResize() {
    this.handleDevicePixelRatio();
  }

  private handleDevicePixelRatio() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const el = this.signatureCanvas.nativeElement;
    this.renderer.setProperty(el, 'width', el.offsetWidth * ratio);
    this.renderer.setProperty(el, 'height', el.offsetHeight * ratio);
    el.getContext('2d').scale(ratio, ratio);
    this.reset();
  }

  cancel() {
    this.reset();
    this.disableEditMode();
  }
}
