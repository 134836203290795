import { Coordinate, MapSettings } from '../google-maps.model';
import { defaultSearchBounds } from '../map-helpers';
import Coordinates from 'coordinate-parser';

/**
 * Pairwise the items in an array.
 * Input: [1,2,3,4,5,6]
 * Returns: [[1, 2], [3, 4], [5, 6]]
 */
export function pairwise<I, R>(array: I[]): R[] {
  return array.reduce((acc, current, index) => {
    const isFirstPair = (index % 2) === 0;

    if (isFirstPair) {
      acc.push([current]);
    } else {
      const lastElement = acc[acc.length - 1];
      lastElement.push(current);
    }
    return acc;
  }, []);
}

/**
 * @param input '-33.872974,18.606132'
 * @return: google.maps.LatLng
 */
export function parsePointToLatLng(input: string): google.maps.LatLng {
  const inputParts = input.split(',', 2);
  const lat = parseFloat(inputParts[0]);
  const lng = parseFloat(inputParts[1]);
  return new google.maps.LatLng(lat, lng);
}

/**
 * @param input '-33.872974,18.606132'
 * @return: [-33.872974,18.606132]
 */
export function parsePointToCoordinate(input: string): Coordinate {
  const inputParts = input.split(',', 2);
  const lat = parseFloat(inputParts[0]);
  const lng = parseFloat(inputParts[1]);
  return [lat, lng];
}

/**
 * @param input '-33.80399881235001,18.47292315351562,-33.83024078122232,18.51412188398437,
 * -33.75948316517105,18.586906307812495'
 * @return: google.maps.LatLngLiteral[]
 */
export function parsePathToLatLngLiteralArr(input: string): google.maps.LatLngLiteral[] {
  const inputArray: string[] = input.split(',');
  const coordinates: Coordinate[] = pairwise<string, Coordinate>(inputArray);

  return coordinates
    .map( (point: Coordinate) => ({ lat: +point[0], lng: +point[1] } as google.maps.LatLngLiteral) );
}

/**
 * @param input '-33.80399881235001,18.47292315351562,-33.83024078122232,18.51412188398437,
 * -33.75948316517105,18.586906307812495'
 * @return: [[-33.80399881235001,18.47292315351562],[-33.83024078122232,18.51412188398437],
 * [-33.75948316517105,18.586906307812495']]
 */
export function parsePathToCoordinateArr(input: string): Coordinate[] {
  const inputArray: number[] = input.split(',').map(coordinate => parseFloat(coordinate));
  return pairwise<number, Coordinate>(inputArray);
}

/**
 * @param input '-35.2707,23.44692'
 * @return: 'POINT(-35.2707 23.44692)'
 */
export function coordinateToPoint(input: string): string {
  return 'POINT(' + input.split(',').join(' ') + ')';
}

/**
 * @param latLng google.maps.LatLng
 * @return: 'POINT(-35.2707 23.44692)'
 */
export function latLngToPoint(latLng: google.maps.LatLng | google.maps.LatLngLiteral): string {
  if (latLng instanceof google.maps.LatLng) {
    return `POINT(${latLng.lat()} ${latLng.lng()})`;
  }
  return `POINT(${latLng.lat} ${latLng.lng})`;
}

export function mapSettingsBoundingBox({ coordinates }: MapSettings) {
  if (coordinates instanceof google.maps.LatLng) {
    return defaultSearchBounds({lat: coordinates.lat(), lng: coordinates.lng()});
  } else {
    return defaultSearchBounds(coordinates);
  }
}

export function latLngBoundingBox(latLng: google.maps.LatLng) {
  return mapSettingsBoundingBox({coordinates: latLng} as MapSettings);
}

/**
 * Checks whether a given coordinate string is a valid degrees, minutes, seconds string.
 * Returns true if valid, else returns false.
 *
 * e.g: 25° 48' 23.2632" S -> true
 *      ABCD -> false
 *
 * @param coordinate - The string in DMS format.
 */
export function isValidDMS(coordinate: string): boolean {
  try {
    const _ = new Coordinates(coordinate);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Converts a decimal coordinate to a degrees, minutes, and seconds representation.
 *
 * e.g:  -25,806462,-25,806462 -> 25° 48' 23.2632" S
 *
 * @param lat - The input latitude in a decimal format.
 * @param lng - The input longitude in a decimal format.
 * @return: A tuple containing the DMS latitude and longitude, respectively.
 */
export function decimalToDMS(lat: number, lng: number): [string, string] {
  const dmsLat = {direction: lat <= 0 ? 'N' : 'S', degrees: 0, minutes: 0, seconds: 0};
  const dmsLng = {...dmsLat, direction: lng >= 0 ? 'E' : 'W'};

  // Remove negative values, we handle those later.
  lat = Math.abs(lat);
  lng = Math.abs(lng);

  // Calculate degrees: integer value of decimal.
  dmsLat.degrees = Math.floor(lat);
  dmsLng.degrees = Math.floor(lng);

  // Minutes: 1 degree = 60 minutes
  dmsLat.minutes = Math.floor((lat - dmsLat.degrees) * 60);
  dmsLng.minutes = Math.floor((lng - dmsLng.degrees) * 60);

  // Seconds: 1 degree = 60 minutes = 3600 seconds
  dmsLat.seconds = (lat - dmsLat.degrees - (dmsLat.minutes / 60) * 3600);
  dmsLng.seconds = (lng - dmsLng.degrees - (dmsLng.minutes / 60) * 3600);

  // Convert into display string.
  const dmsToString = (dms: typeof dmsLat): string => `${dms.degrees}° ${dms.minutes}' ${dms.seconds}" ${dms.direction}`;
  return [dmsToString(dmsLat), dmsToString(dmsLng)];
}

/**
 * Converts a degrees, minutes, and seconds coordinate to a decimal representation.
 *
 * e.g: 25° 48' 23.2632" S -> -25,806462,-25,806462
 *
 * @param dms - The input coordinates in the format D° M' S" N.
 * @return: A tuple containing the decimal latitude and longitude, respectively.
 */
export function dmsToDecimal(dms: string): [number, number] {
  const parsed = new Coordinates(dms);
  return [parsed.getLatitude(), parsed.getLongitude()];
}
