import {Component, Input, ViewChild} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {PDFDocumentProxy, PDFProgressData, PdfViewerComponent} from 'ng2-pdf-viewer';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview-modal.component.html'
})

export class DocumentPreviewModalComponent {
  @ViewChild(PdfViewerComponent)
  private pdfComponent!: PdfViewerComponent;

  @Input() src: string;
  @Input() title?: string;
  @Input() type?: string;
  @Input() description?: string;

  //pdf viewer defaults
  originalSize: boolean = false;
  zoom: number = 1;
  zoomPercentage: number = 100;
  showAll: boolean = false;
  page: number = 1
  rotation = 0;
  pdfQuery = '';

  loading = true;
  loadValue: number;
  numPages: any;
  fileSize: any;
  sizeFormat: string;

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  checkPdf(url) {
    if(url) {
      const [path] = url.split('?');
      return path.slice(-3) === 'pdf'
    }
  }

  onProgress(progressData: PDFProgressData) {
    this.loadValue = Math.ceil((progressData.loaded / progressData.total) * 100)
    if (this.loadValue === 100) {
      this.loading = false
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.numPages = pdf.numPages;
    pdf.getMetadata().then((metaData:any) => {
      this.calcFileSize(+metaData.contentLength)
    })
  }

  searchQueryChanged(newQuery: string) {
    const type = newQuery !== this.pdfQuery ? '' : 'again';
    this.pdfQuery = newQuery;

    this.pdfComponent.eventBus.dispatch('find', {
      type,
      query: this.pdfQuery,
      highlightAll: true,
      caseSensitive: false,
      phraseSearch: true,
      // findPrevious: undefined,
    });
  }

  calcFileSize(bytes: number) {
    if (bytes < 1000) {
      this.sizeFormat = 'bytes'
      this.fileSize = bytes;
    }
    else if (bytes >= 1000 && bytes < 1000000) {
      this.sizeFormat = 'KB'
      this.fileSize =  Math.round((bytes / 1000) * 10)/10;
    }
    else if ( bytes >= 1000000 && bytes < 1000000000) {
      this.sizeFormat = 'MB'
      this.fileSize = Math.round((7539467 / 1000000) * 10)/10;
    }
    else if ( bytes >= 1000000000 && bytes < 1000000000000) {
      this.sizeFormat = 'GB'
      this.fileSize = Math.round((7539467 / 1000000000) * 10)/10;
    }
  }

  onChangeOriginalSize() {
    this.originalSize = !this.originalSize
  }

  onZoomIn(curZoom: number){
    this.zoom = Math.round((curZoom + 0.1) * 10)/10
    this.calcZoomPercentage()
  }

  onZoomOut(curZoom: number){
    if (curZoom > 0.1) {
      this.zoom = Math.round((curZoom - 0.1) * 10) / 10
      this.calcZoomPercentage()
    }
  }

  onResetZoom(){
    this.zoom = 1;
    this.calcZoomPercentage()
  }

  calcZoomPercentage() {
    this.zoomPercentage = Math.round(this.zoom * 100)
  }

  onPageNext(page) {
    if (page < this.numPages ) {
      this.page = page + 1;
    }
  }

  onPagePrev(page) {
    if (page > 1) {
      this.page = page - 1;
    }
  }

  onShowAll() {
    this.showAll = !this.showAll
  }

  onRotate(angle: number) {
    this.rotation += angle;
    this.rotation%=360; // keep it under 360 deg , always
  }

  public onClose(): void {
    this.bsModalRef.hide();
  }

}
