import { Injectable } from '@angular/core';
import { Document, SimpleApiResponse, DocumentUploadData
} from '../../../../../shared/api/interface/api.models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DocumentsApiService } from '../../../../../shared/api/services/documents-api.service';
import { Method } from '@bo/ng-api';
import { FieldOptions } from '@bo/ng-forms';
import { HttpParams } from '@angular/common/http';
import { DocumentCategory, DocumentResourceTypes, MeetingMinuteTypes
} from '../../../../../shared/api/interface/api.enums';
import { UserLinkedROCMapped } from 'src/app/modules/roc/store/models';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../store/states';
import { DocumentMapped, GroupedDocumentsMapped } from './document.models';
import { DocumentExpiryGroupLabels } from './document.enums';
import { requiredDocuments } from './document-utils';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  constructor(
    protected documentsApiService: DocumentsApiService,
  ) {}

  /**
   * These are the required platform documents for the associated entity
   */
  requiredDocuments = requiredDocuments;

  /**
   * Transforms a document models into an object usable in the template
   *
   * @private
   * @param document
   */
  protected transformDocument(document): Document {
    return {
      id: document.id,
      title: document.title,
      type: document.file_type,
      date_uploaded: document.date_uploaded,
      expire_on: document.expire_on,
      valid_from: document.valid_from,
      file: document.file,
      archived: document.archived
    } as Document;
  }

  /**
   * Gets general documents for the user
   *
   * @param documentType
   * @param resourceType
   * @param returnArchivedOnly
   * @param gearId
   * @param meetingType
   * @param crewMemberId
   * @return Observable
   */
  getDocumentsFor( documentType: DocumentCategory, resourceType: DocumentResourceTypes, returnArchivedOnly?: boolean,
                   gearId?: number, meetingType?: MeetingMinuteTypes, crewMemberId?: number ): Observable<Document[]> {
    let rocId: number;
    let rocUserId: number;
    let params  = new HttpParams();
    this.selectedROC$.subscribe(selectedROC => {
      // Check manually for belonging to an ROC, for independent user documents to return.
      if (selectedROC) {
        rocId = selectedROC.id;
        rocUserId = selectedROC.roc_user.id;
      }
    });
    params = params.appendAll( {
      category: documentType,
      resourcetype: resourceType,
      archived: returnArchivedOnly,
    });
    if (rocId) {
      params = params.appendAll({
        remote_operator: rocId,
        roc_user: rocUserId
      })
    }
    if (gearId) { params = params.append('drone', gearId); }
    if (crewMemberId) { params = params.append('uploaded_for', crewMemberId); }
    if (meetingType) { params = params.append('meeting_type', meetingType); }
    return this.documentsApiService.getDocuments(params).pipe(
      map(documents => documents as Document[])
    );
  }

  /**
   * Returns outstanding documents based on ROCOperator role
   *
   * @return
   * @param userType
   * @param existingDocuments
   */
  getOutstandingDocumentsFor(userType: string, existingDocuments: any[]): any[] {
    let requiredDocuments;
    switch (userType) {
      case 'user':
        requiredDocuments = this.requiredDocuments.user;
        break;
      case 'pilot':
        requiredDocuments = this.requiredDocuments.pilot;
        break;
      case 'safety_manager':
        requiredDocuments = this.requiredDocuments.safety_manager;
        break;
      case 'quality_manager':
        requiredDocuments = this.requiredDocuments.quality_manager;
        break;
      case 'drone':
        requiredDocuments = this.requiredDocuments.drone;
        break;
      case 'roc':
        requiredDocuments = this.requiredDocuments.roc;
        break;
      case 'crew':
        requiredDocuments = this.requiredDocuments.crew;
        break;
      case 'all':
        requiredDocuments = [
          ...this.requiredDocuments.user,
          ...this.requiredDocuments.pilot,
          ...this.requiredDocuments.safety_manager,
          ...this.requiredDocuments.quality_manager,
          ...this.requiredDocuments.drone,
          ...this.requiredDocuments.roc,
        ];
        break;
      default: requiredDocuments = [];
    }
    return requiredDocuments.filter(document => {
      if (document) {
        return !existingDocuments.some(item => (item.file_type === document.file_type));
      }
    })
  }

  /**
   * Gets the document upload options from the APIs
   *
   * @param queryParams
   * @return Observable<FieldOptions[]>
   */
  getUploadOptions(queryParams: string): Observable<FieldOptions[]> {
    return this.documentsApiService.getOptions(this.documentsApiService.URL_DOCUMENTS_OPTIONS + `?${queryParams}`, Method.POST);
  }

  /**
   * Upload the document
   *
   * @param documentMetadata
   * @return Observable<Document>
   */
  uploadDocument(documentMetadata: DocumentUploadData): Observable<Document> {
    return this.documentsApiService.uploadDocument(documentMetadata);
  }

  /**
   * Delete the document passed
   *
   * @param document
   * @return Observable<SimpleApiResponse>
   */
  deleteDocument(document: Document): Observable<SimpleApiResponse> {
    return this.documentsApiService.deleteDocument(document);
  }

  /**
   * Archives the document passed
   *
   * @param document
   * @return Observable<SimpleApiResponse>
   */
  archiveDocument(document: Document): Observable<SimpleApiResponse> {
    return this.documentsApiService.archiveDocument(document);
  }

  // use the returned data to add a group to each documents for displaying them in groups on the table
  mapGroupedDocuments(data: Observable<GroupedDocumentsMapped>) {
    return data.pipe(
      map( sections => {
        sections.praircraft_documents.map(document => { document.group = DocumentExpiryGroupLabels.praircraft_documents })
        sections.gear_documents.map(document => { document.group = DocumentExpiryGroupLabels.gear_documents })
        sections.operation_documents?.map(document => { document.group = DocumentExpiryGroupLabels.operation_documents })
        sections.personnel_documents.map(document => { document.group = DocumentExpiryGroupLabels.personnel_documents })
        sections.pilot_documents.map(document => { document.group = DocumentExpiryGroupLabels.pilot_documents })
        sections.quality_manager_documents.map(document => { document.group = DocumentExpiryGroupLabels.quality_manager_documents })
        sections.roc_documents.map(document => { document.group = DocumentExpiryGroupLabels.roc_documents })
        sections.safety_manager_documents.map(document => { document.group = DocumentExpiryGroupLabels.safety_manager_documents })
        sections.security_manager_documents.map(document => { document.group = DocumentExpiryGroupLabels.security_manager_documents })
        sections.user_documents.map(document => { document.group = DocumentExpiryGroupLabels.user_documents })
        return sections
      }),
      map( sections => {
        const data = [
          ...sections.user_documents,
          ...sections.roc_documents,
          ...sections.safety_manager_documents,
          ...sections.quality_manager_documents,
          ...sections.security_manager_documents,
          ...sections.personnel_documents,
          ...sections.gear_documents,
          ...sections.praircraft_documents,
          ...sections.pilot_documents,
        ]
        if (sections.operation_documents) {
          data.push(...sections.operation_documents)
        }
        return data
      })
    )
  }

  // return all data in one big array for the table
  getExpiringDocuments(): Observable<DocumentMapped[]> {
    const data$: Observable<GroupedDocumentsMapped> = this.documentsApiService.getExpiringDocuments();
    return this.mapGroupedDocuments(data$)
  }

  // return all data in one big array for the table
  getAllUserUploadedDocuments(returnArchived?: boolean): Observable<DocumentMapped[]> {
    const params = new HttpParams();
    const data$: Observable<GroupedDocumentsMapped> = this.documentsApiService.getAllDocuments(params, returnArchived);
    return this.mapGroupedDocuments(data$)
  }
}
