import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

/**
 * The config service is responsible to load App settings from the API.
 */
@Injectable()
export class ConfigService {
  private http;
  public loadedSettings = false;
  public BASE_URL: string;
  public APPLICATION_ID: string;

  URL_SETTINGS = 'settings';

  // Cyclic dependency error with HttpInterceptor
  // https://github.com/angular/angular/issues/18224
  constructor(private injector: Injector) { }

  private static getUrl(path: string) {
    return environment.apiRoot + path;
  }

  load(): Promise<boolean> {
    this.http = this.injector.get(HttpClient);
    return new Promise<boolean>((resolve) => {
      this.http.get(ConfigService.getUrl(this.URL_SETTINGS)).subscribe(
        response => {
          this.loadedSettings = true;
          this.BASE_URL = response.BASE_URL;
          this.APPLICATION_ID = response.APPLICATION_ID;
          resolve(true);
        },
        error => {
          console.error('Unable to load app settings from API!', error);
          // Resolve the promise so that the app loading continues.
          resolve(true);
        }
      );
    });
  }
}
