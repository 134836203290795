import { Component, Input, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { S3Destination } from '../../../api/interface/api.models';
import { UploadSettings } from '@bo/ng-uploads';
import { Store } from '@ngxs/store';
import { AppToastrService } from '../../../services/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppState } from '../../../../store/states';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentUploadComponent } from '../../upload-document/upload.component';
import { DocumentService } from '../../../../modules/roc/pages/documents/services/document.service';
import { EnumsService } from '@bo/ng-enums';
import { EnumLabelService } from '../../../services/enum-label.service';
import { OperationApiService } from '../../../../modules/roc/pages/operations/services/operation-api.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html'
})
export class FileUploadModalComponent extends DocumentUploadComponent implements OnInit {
  @Input() uploadDestination: S3Destination = S3Destination.incident_reports;

  public onClose: Subject<boolean>;
  protected fields = [];

  showFieldError: boolean;
  hasDescriptionField: boolean;

  constructor(
    protected store: Store,
    protected documentService: DocumentService,
    protected toastrService: AppToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected enumService: EnumsService,
    protected enumLabelService: EnumLabelService,
    protected operationApiService: OperationApiService,
    protected bsModalRef: BsModalRef
  ) {
    super(store, documentService, toastrService, router, route, enumService, enumLabelService, operationApiService);
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.hasDescriptionField = this.uploadDestination === S3Destination.incident_reports;
    super.ngOnInit();
  }

  override setUploadSettings() {
    this.uploadSettings$ = this.store.select(AppState.userProfile).pipe(
      map(userProfile => {
        return {
          destination: this.uploadDestination,
          fileOwner: { owner_id: userProfile.id},
        } as UploadSettings;
      })
    );
  }

  override createForm(): void {
    this.form = new FormGroup({
      description: new FormControl(''),
    });
    if (this.hasDescriptionField) {
      this.form.controls.description.setValidators(Validators.required);
      this.form.controls.description.updateValueAndValidity();
    } else {
      this.form.controls.description.clearValidators();
      this.form.controls.description.disable();
    }
  }

  override onSubmit() {
    if (!this.documentUrl) {
      this.showDocumentUrlError = true;
    } else {
      super.onSubmit();
      this.handleSubmitSuccess();
    }
  }

  override submitData() {
    // We handle the submission later.
    return of(null);
  }

  override handleSubmitSuccess() {
    this.overrideDeactivateGuard = true;
    if ( this.hasDescriptionField && this.form.get('description').value === '') {
      this.showFieldError = true;
    } else {
      this.onClose.next(true);
      this.bsModalRef.hide();
    }
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
