import {ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthApiService } from 'src/app/shared/api/services/auth-api.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html'
})
export class VerifyEmailComponent implements OnInit {
  isSuccess: boolean;
  isAdmin: boolean;
  adminEmail: string;
  isError: boolean;
  errorMessage: string;

  constructor(
    private authService: AuthApiService,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Email Verification Successful | Sign Up | Flyte Zone');
    this.meta.updateTag({
      name: 'description',
      content: 'Your email verification was successful. Seamlessly manage your RPAS flight operations with our all-in-one platform.'
    });
    const code = this.activatedRoute.snapshot.queryParamMap.get('code');
    if (code) {
      this.performEmailVerification(code);
    }
  }

  onCompleteRocDetails(){
    this.router.navigate(['sign-up', 'roc-details'], {queryParams: { adminEmail: encodeURI(this.adminEmail)}});
  }

  performEmailVerification(code: string) {
    this.authService.verifyEmail(code)
      .subscribe(
        result => {
          this.isSuccess = result.success;
          this.isAdmin = result.is_admin;
          this.adminEmail = result.admin_email;
        },
        error => {
          this.isError = true;
          this.errorMessage = error.error.non_field_errors;
        }
      );
  }
}
