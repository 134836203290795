import { Component, OnInit, ViewChild } from '@angular/core';
import { MeetingsApiService } from '../services/meetings-api.service';
import { OperationApiService } from '../../operations/services/operation-api.service';
import { CalendarTypes, DocumentResourceTypes, MeetingMinuteTypes, ROCRelatedDocumentType
} from '../../../../../shared/api/interface/api.enums';
import { ModalService } from '../../../../../shared/services/modal.service';
import { MeetingMinutesDocumentUploadModalComponent } from './upload/upload-document.component';
import moment from 'moment';
import { DocumentPreviewModalComponent } from '../../../../../shared/components/modals/document-preview-modal/document-preview-modal.component';
import { Router } from '@angular/router';
import { ROCMeetingsScheduleComponent } from '../meeting-schedule/meeting-schedule.component';
import { ConfirmationRemoveModalComponent } from '../../../../../shared/components/modals/confirmation-remove-modal/confirmation-remove-modal.component';
import { DocumentService } from '../../documents/services/document.service';
import { AppToastrService } from '../../../../../shared/services/toaster.service';
import { StatusBadgeType } from '../../../../../shared/components/status-badge/status-badge.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-meetings-minutes',
  templateUrl: './meeting-minutes.component.html',
  styleUrls: [],
})
export class ROCMeetingsMinutesComponent extends ROCMeetingsScheduleComponent {
  @ViewChild('table') table: any;

  badgeType = StatusBadgeType;
  dateFromControl: FormControl;
  dateToControl: FormControl;
  today = new Date();
  filterByCalendarTypes = [ CalendarTypes.SAFETY_MEETING, CalendarTypes.QA_MEETING]

  constructor(
    protected meetingApiService: MeetingsApiService,
    protected operationApiService: OperationApiService,
    protected modalService: ModalService,
    protected router: Router,
    protected documentService: DocumentService,
    protected toastrService: AppToastrService,
  ) {
    super( meetingApiService, operationApiService, router);
  }

  override setDatesLoadMeetings() {
    this.dateFrom = moment(this.today).subtract(1, 'month').format('YYYY-MM-DD')
    this.dateTo = moment(this.today).format('YYYY-MM-DD');
    this.dateFromControl = new FormControl();
    this.dateToControl = new FormControl();
    this.dateFromControl.setValue(this.dateFrom);
    this.dateToControl.setValue(this.dateTo);
    if(this.dateFrom && this.dateTo) {
      this.loadMeetingOccurrences(this.dateFrom, this.dateTo, this.filterByCalendarTypes);
    }
  }

  onApplyDateFilter() {
    this.dateFrom = moment(this.dateFromControl.value).format('YYYY-MM-DD');
    this.dateTo = moment(this.dateToControl.value).format('YYYY-MM-DD');
    this.loadMeetingOccurrences(this.dateFrom, this.dateTo, this.filterByCalendarTypes);
  }

  onViewCancelled() {
    this.showActive = !this.showActive;
    this.data = this.showActive ? this.occurrences : this.occurrencesCancelled;
  }

  onUploadDocument(event) {
    let modalRef, resourceType, documentType, meetingType;
    if (event.calendar === CalendarTypes.SAFETY_MEETING) {
      resourceType = DocumentResourceTypes.MEETING_MINUTE;
      documentType = ROCRelatedDocumentType.MEETING_MINUTE;
      meetingType = MeetingMinuteTypes.TYPE_SMS
    }
    if (event.calendar === CalendarTypes.QA_MEETING) {
      resourceType = DocumentResourceTypes.MEETING_MINUTE;
      documentType = ROCRelatedDocumentType.MEETING_MINUTE;
      meetingType = MeetingMinuteTypes.TYPE_QA;
    }
    modalRef = this.modalService.open(MeetingMinutesDocumentUploadModalComponent, {
      class: 'modal-lg',
      initialState: {
        fileType: documentType,
        documentResourceType: resourceType,
        meetingType: meetingType,
        meetingOccurrenceId: event.id,
        startDate: moment(event.start).format('YYYY-MM-DD'),
        endDate: moment(event.end).format('YYYY-MM-DD'),
        meetingTitle: event.title,
        allDay: event.allDay,
      }
    });
    modalRef.content.onClose.subscribe(
      onClose => {
        if (onClose) {
          this.loadMeetingOccurrences(this.dateFrom, this.dateTo, this.filterByCalendarTypes);
        }
      }
    );
  }

  onViewDocument(url, title) {
    let modalRef;
    modalRef = this.modalService.open(DocumentPreviewModalComponent, {class:'modal-fullscreen'});
    modalRef.content.src = url;
    modalRef.content.title = title;
  }

  public deleteDocument(document: any) {
    const modalRef = this.modalService.open(ConfirmationRemoveModalComponent);
    modalRef.content.heading = `Remove "${document.title}"`;
    modalRef.content.action = `remove this document`;
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.documentService.deleteDocument(document).subscribe(
          {
            next: () => {
              this.loadMeetingOccurrences(this.dateFromControl, this.dateTo, this.filterByCalendarTypes);
            },
            complete: () => {
              this.toastrService.success(`"${document.title}" has been removed.`);
            },
            error: () => {
              this.toastrService.error(`An error occurred while removing "${document.title}".`);
            }
          }
        );
      }
    });
  }

  sortArrDateDescending(arr) {
    arr.sort((obj1, obj2) => {
        return new Date(obj1.start).getTime() - new Date(obj2.start).getTime()
      }
    )
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

}
