import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnChanges {

  @Input() imageUrl: string;
  @Input() editModal: boolean;
  @Input() size: string; // expected 'sm', 'md', or 'lg'
  @Input() toolTip: string;
  @Input() class: string; // expected 'avatar--bg-primary', 'avatar--bg-secondary'
  @Input() type: string; // expected 'profile', 'icon', 'logo', 'initials'
  @Input() iconClass: string; // expected icon font class
  @Input() firstName: string;
  @Input() lastName: string;

  initialsColorClass: string;

  ngOnChanges() {
    if (this.firstName) {
      this.initialsColorClass = 'avatar__initials-bg-' + this.firstName[0]?.toLowerCase();
    }
  }
}


/* Usage examples
  <app-avatar type="profile"
              size="sm"
              imageUrl="http://www.venmond.com/demo/vendroid/img/avatar/big.jpg">
  </app-avatar>

  <app-avatar type="logo"
              class="avatar--border-custom"
              size="md"
              imageUrl="'https://byteorbit.com/static/img/byte-orbit-logo.6e2270b89a31.svg'">
  </app-avatar>

  <app-avatar type="icon"
              iconClass="bi bi-bell"
              class="avatar--bg-secondary"
              size="md">
  </app-avatar>

  <app-avatar type="initials"
              firstName="Elize"
              lastName="Vockerodt"
              size="md">
  </app-avatar>
*/
