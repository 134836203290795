import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnChanges {

  @Input() type: string;
  @Input() class: string;
  @Input() size?: string = 'sm';
  @Input() routerLink?: string;
  @Input() linkText?: string;
  iconClass: string;

  ngOnChanges() {
    switch (this.type) {
      case 'success':
        this.iconClass = 'exclamation-triangle-fill';
        break;
      case 'danger':
        this.iconClass = 'exclamation-triangle-fill';
        break;
      case 'info':
        this.iconClass = 'info-circle-fill';
        break;
      case 'info-dark':
        this.iconClass = 'info-circle-fill';
        break;
      case 'warning':
        this.iconClass = 'exclamation-triangle-fill';
        break;
    }
  }
}
