import { ActivatedRoute, Router } from '@angular/router';
import {Component, OnInit} from '@angular/core';
import { Store } from '@ngxs/store';
import { Login } from '@bo/ng-auth';
import { NavigateToDashboardFromLogin } from '../../../store/actions';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthApiService } from 'src/app/shared/api/services/auth-api.service';
import { AppFormComponent } from 'src/app/shared/components/forms/app-form-component';
import { Method } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { ModalService } from '../../../shared/services/modal.service';
import { LoginRocNotFoundModalComponent } from '../_components/modals/login-roc-not-found/login-roc-not-found.component';
import { FreeTrialModalComponent } from '../_components/modals/free-trial/free-trial.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends AppFormComponent<any> implements OnInit {
  protected fields = ['email', 'password'];

  protected pageLoading = false;
  protected invitationPK: string;
  showNonFieldErrors: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthApiService,
    private router: Router,
    private store: Store,
    private titleService: Title,
    private meta: Meta,
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
  ) {
    super();
    this.invitationPK = this.activatedRoute.snapshot.queryParamMap.get('invitationPK');

  }

  ngOnInit() {
    this.titleService.setTitle('Get Started | Sign In | Flyte Zone');
    this.meta.updateTag({
      name: 'description',
      content: 'Sign in to your Flyte Zone account to access our all-in-one platform for remote piloted aircraft systems (RPAS) flight operations.'
    });
    super.ngOnInit();
  }

  createForm(): void {
    const optionsURL = this.authService.URL_LOGIN;
    this.subscriptions.add(
      this.buildFromOptions(
        this.authService.getOptions(optionsURL, Method.POST),
      ).subscribe(),
    );
  }

  submitData(): Observable<any> {
    this.pageLoading = true;
    return this.authService.login(this.form.value.email, this.form.value.password);
  }

  openFreeTrialModal() {
    this.modalService.open(FreeTrialModalComponent, { class: 'modal-lg free-trial-modal' });
  }

  handleSubmitSuccess(result) {
    this.pageLoading = false;
    this.store.dispatch(new Login(result.token, this.form.value.email));
    this.store.dispatch(new NavigateToDashboardFromLogin());
  }

  handleSubmitError(error) {
    super.handleSubmitError(error);
    this.pageLoading = false;
    //Only show non field errors when there actually are ones returning. Other login errors are handled separately.
    this.showNonFieldErrors = !!error.error.non_field_errors;
    // If ROC Info not found, redirect to relevant page to finish onboarding
    if (!error.error?.non_field_errors && error.error?.rpas_information_found[0] === "False") {
      const modalRef = this.modalService.open(LoginRocNotFoundModalComponent);
      const adminEmail = error.error.admin_email;
      modalRef.content.onClose.subscribe((result) => {
        if (result) {
          this.router.navigate(['sign-up', 'roc-details'], {queryParams: { adminEmail: encodeURI(adminEmail)}});
        }
      });
    }
  }
}
