import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UpdateHeaderService } from '../../header/services/update-header.service';
import { PageTitleHeaderComponent } from '../../header/components/page-title/page-title-header.component';

@Component({
  selector: 'app-error-message',
  templateUrl: 'error-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageComponent implements OnInit {
  @Input() errorTitle: string;
  @Input() errorMessage: string;
  @Input() displayButton = true;
  @Input() errorImgName: string;
  @Input() errorImgWidth: number;
  @Input() errorCode: string;
  @Input() showNavigationOptions: boolean = true;
  @Input() showPersist: boolean = true;

  constructor(
    private router: Router,
    private location: Location,
    private updateHeaderService: UpdateHeaderService
  ) {
  }

  ngOnInit() {
    this.updateHeaderService.setHeaderComponent(PageTitleHeaderComponent);
  }

  goBack(): void {
    this.location.back();
  }
}
