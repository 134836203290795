import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitComponent {
  @Input() busy: boolean;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() btnClass: string;
  // tslint:disable:no-output-rename
  @Output() clicked = new EventEmitter();

  hasClicked() {
      this.clicked.emit();
  }

}
