import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: []
})
export class SideNavItemComponent {
  @Input() label: string;
  @Input() iconClass: string;
}
