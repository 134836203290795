import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-free-trial-modal',
  templateUrl: './free-trial.component.html',
})
export class FreeTrialModalComponent implements OnInit {
  public onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef,
  ) {}

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public onAccept(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

}
