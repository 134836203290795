import * as Enums from './api.enums';
import { Meta } from '@bo/ng-uploads';
import { UploadFile } from 'ngx-uploader';
import { Coordinate } from '../../components/google-maps/google-maps.model';
import {  DocumentCategory, DocumentResourceTypes, MeetingMinuteTypes, EntityType, ROCPostHolders, DelegationStatus,
  SubscriptionPaymentFrequency, SubscriptionStatus, InvestigationReviewStatus
} from './api.enums';
import {
  CorrectivePreventativeAction,
  ImplementationCorrectivePreventativeActionItem,
  DescriptionItem,
  InvestigationReportDocument,
  ReportableDescription,
  RiskSimilarOccurrenceDescription
} from '../../../modules/roc/pages/reports/store/report.models';
import { EventDetail } from '../../../modules/roc/pages/calendar/services/meeting.models';

export interface UserPermissions {
  gear?: {
    details: boolean | string;
    documents: boolean | string;
    maintenance: boolean | string;
    folio: boolean | string;
  };
  logbook?: boolean | string;
  operation?: {
    planning_phase: boolean | string;
    execution_phase: boolean | string;
  };
  profile_management?: boolean | string;
  reports?: {
    incident: boolean | string;
    audit: boolean | string;
  };
  user_document?: boolean | string;
  full_access?: boolean;
  subscription?: boolean; // to change
}

export interface UserProfile {
  id: number;
  email: string;
  email_verified_at?: string;
  newsletter_subscription_accepted_at?: string;
  terms_and_conditions_accepted_at?: string;
  first_name: string;
  last_name: string;
  preferred_name?: string;
  contact_number?: string;
  avatar?: string;
  is_new: boolean;
  is_recently_added: boolean;
  is_first_time_login: boolean;
  rpl_number?: string;
  rmt_licence_number?: string;
  roc_user_linked_entities: {
    roc: RemoteOperatorProfile;
    role?: Enums.ROCOperatorUsers;
    roc_user_profile?: ROCUserProfile; // Independent pilot with no ROC
  };
  signature?: string;
}

export interface UserBasic {
  avatar?: string;
  first_name: string;
  last_name: string;
  id?: number
}

export interface UserAccountEmail {
  email: string;
  id: number;
}

export interface UserProfileUpdateRequest {
  first_name: string;
  last_name: string;
  preferred_name?: string;
  contact_number?: string;
  rpl_number?: string;
  rmt_licence_number?: string;
}

export interface UserProfileSettings {
  document_reminder_period: number // days
}

export interface RemoteOperatorProfile {
  id?: number;
  avatar?: string;
  city?: string;
  city_id?: number;
  contact_number: string;
  email: string;
  name: string;
  vat_number: string;
  entity_type?: EntityType;
  registration_number: string;
  roc_registration_date: string;
  number_of_operations_completed: number;
  address_line: string;
  address_line_2?: string;
  roc_city_id?: number;
  city_information?: string;
  admin_email?: string; // FE use only
  remote_operator?: number;
  roc_user?: number;
}

export interface RemoteOperatorBillingDetails {
  name: string;
  contact_number: string;
  email: string;
  address_line: string;
  address_line_2?: string;
  city?: string;
  city_id?: number;
  roc_city?: number;
  roc_city_id?: number;
  remote_operator?: number;
  roc_user?: number;
}

export interface RemoteOperatorInvitation {
  id?: number;
  invited_by: UserProfile;
  roc_operator: UserProfile;
  remote_operator: RemoteOperatorProfile;
  role: Enums.ROCOperatorUsers;
  state: number;
  has_invitation_expired: boolean;
}

export interface UserRoles {
  has_accountable_manager_role: boolean;
  has_admin_role: boolean;
  has_aircraft_manager_role: boolean;
  has_flight_operation_manager_role: boolean;
  has_observer_role: boolean;
  has_pilot_role: boolean;
  has_quality_officer_role: boolean;
  has_rpas_maintenance_technician_role: boolean;
  has_safety_officer_role: boolean;
  has_secondary_admin_role: boolean;
  has_security_officer_role: boolean;
}

export interface SimpleApiResponse {
  detail: string;
}

export interface RegistrationData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  // password2: string;
  terms_and_conditions: boolean;
  terms_of_use: boolean;
  paia_manual: boolean;
  privacy_policy: boolean;
  // newsletter_subscription: boolean;
  invitation_id?: string;
}

export interface ResendVerificationEmail {
  email: string;
}

export interface PasswordChangeData {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

export interface PasswordResetRequest {
  email: string;
}

export interface PasswordResetConfirmRequest {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
}

export interface City {
  id: number;
  city_name: string;
  province_name: string;
  country_name: string;
  display_name: string;
}

// ------------------------------------------------- Gear -----------------------------------------------------

// TODO Refactor Gear models to use parent class
export interface Gear {
  id?: number;
  avatar?: string;
  gear_type: Enums.GearTypes;
  created_by?: UserProfile;
  nickname: string;
  resourcetype?: string;
}

// ------------------------------------------------- Drones -----------------------------------------------------

export interface Drone extends Gear {
  manufacturer: string;
  model: string;
  nickname: string;
  created_by: UserProfile;
  serial_number: string;
  flying_time: string;
  payload_capacity: number;
  radio_frequency: number;
  control_system: string;
  registration_reference?: string;
  operating_range?: number;
  is_removed?: false;
  is_compliant?: boolean;
  is_compliant_maintenance?: boolean;
  is_compliant_documents?: boolean;
  wind_speed?: number;
  drone_type?: string;
  drone_class?: string;
  endurance?: string;
  operating_temperature_min?: number;
  operating_temperature_max?: number;
  take_off_mass?: number;
  is_persisted?: boolean;   // Used for FE only
  is_used_current_operation?: boolean; // Used for FE only
  rpas_type: Enums.RPASType;
  power_source?: Enums.PowerSource;
  rpas_registration_date: string;
  is_used?: boolean;
  maintenance_overview_id: number;
  maintenance_plan_id: number;
}

export interface DroneRequest {
  avatar?: string;
  gear_type: string;
  manufacturer: string;
  model: string;
  nickname: string;
  serial_number: string;
  flying_time: string;
  payload_capacity: number;
  radio_frequency: number;
  control_system: string;
  registration_reference?: string;
  operating_range?: number;
  wind_speed?: number;
  drone_type?: string;
  drone_class?: string;
  endurance?: string;
  operating_temperature_min?: number;
  operating_temperature_max?: number;
  take_off_mass?: number;
  resourcetype?: string;
  rpas_type: Enums.RPASType;
  rpas_registration_date: string;
  power_source: Enums.PowerSource;
}

export interface DroneServiceLevelType {
  name: string;
}

// ------------------------------------------------- Batteries -----------------------------------------------------

export interface Battery extends Gear {
  manufacturer: string;
  model: string;
  nickname: string;
  serial_number: string;
  battery_type: string;
  battery_cells: string;
  rated_capacity: number;
  is_removed?: false;
  rated_voltage: number;
  compatible_drone?: string;
  last_full_charge?: number;
  charge_cycle_number?: number;
  is_persisted?: boolean;   // Used for FE only
  is_used_current_operation?: boolean; // Used for FE only
  is_used?: boolean;
}

export interface BatteryRequest {
  avatar?: string;
  id?: number;
  gear_type: string;
  manufacturer: string;
  model: string;
  nickname: string;
  serial_number: string;
  battery_type: string;
  battery_cells: string;
  rated_capacity: number;
  rated_voltage: number;
  compatible_drone?: string;
  last_full_charge?: number;
  charge_cycle_number: number;
  resourcetype?: string;
}

export interface BatteryChargeLog {
  id: number;
  battery: number;
  cycles: number;
  charge_date: string;
  completed_by: number;
  completed_by_details: {
    avatar: string;
    first_name: string;
    last_name: string;
    signature: string;
  }
  flight: {
    id: number;
    name: string;
  };
  folio: {
    id: number;
    doc_number: string;
  };
  operation: {
    id: number;
    name: string;
  };
  signed_at: Date;
  signature: string;
}

export interface BatteryChargeLogFormData {
  id?: number;
  cycles: number;
  charge_date: string;
  completed_by?: number;
  battery: number;
  folio: any;
}

export interface BatteryLatestChargeCycle {
  latest_cycle_number: number
}

// ------------------------------------------------- Payloads -----------------------------------------------------

export interface Payload extends Gear {
  manufacturer: string;
  model: string;
  nickname: string;
  serial_number: string;
  payload_type: string;
  is_removed?: false;
  is_used?: boolean;
  is_persisted?: boolean;   // Used for FE only
  is_used_current_operation?: boolean; // Used for FE only
}

export interface PayloadRequest {
  avatar?: string;
  id?: number;
  gear_type: string;
  manufacturer: string;
  model: string;
  nickname: string;
  serial_number: string;
  payload_type: string;
  resourcetype?: string;
}

// ------------------------------------------------- Equipment -----------------------------------------------------

export interface Equipment extends Gear {
  manufacturer: string;
  model: string;
  nickname: string;
  serial_number: string;
  function: string;
  description: string;
  is_removed?: false;
  is_persisted?: boolean;   // Used for FE only
  is_used_current_operation?: boolean; // Used for FE only
  is_used?: boolean;
}

export interface EquipmentRequest {
  avatar?: string;
  id?: number;
  gear_type: string;
  manufacturer: string;
  model: string;
  nickname: string;
  serial_number: string;
  function: string;
  description: string;
  resourcetype?: string;
}

// ------------------------------------------------- Gear maintenance -----------------------------------------------------

export interface TimeDuration {
  hours?: number;
  minutes?: number;
  seconds?: number;
}

// Drone Maintenance Overview

export interface DroneMaintenanceOverview {
  drone: number;
  drone_manual?: {
    file_name: string;
    url: string;
  };
  last_scheduled_maintenance_date?: string;
  due_date?: string;
  flight_hours_left_until_next_scheduled_maintenance?: number;
  next_scheduled_maintenance_date?: string;
  days_left_until_next_scheduled_maintenance?: number;
  total_flying_time: TimeDuration;
}

// Gear Maintenance Plan

export interface DroneMaintenanceServiceLevel {
  id?: number;
  description: string;
  type: string;
}

export interface GearMaintenancePlan {
  id?: number;
  description: string;
  service_levels: DroneMaintenanceServiceLevel[];
}

export interface GearMaintenanceServiceLevelFormData {
  description?: string;
  type?: string;
  id?: number;
  removed?: boolean;
}

export interface GearMaintenancePlanFormData {
  description: string;
  service_levels: GearMaintenanceServiceLevelFormData[];
}

// Gear Maintenance Checks

export interface GearMaintenanceHistoryFormData {
  date_completed?: string;
  accumulated_time: {
    hours: number;
    minutes?: number;
    seconds?: number;
  };
}

interface BaseGearMaintenanceCheck {
  title: string;
  accumulated_usage_time: {
    months: number;
    weeks: number;
    days: number;
  };
  due_date?: Date | string;
  accumulated_flight_hours: number;
  has_maintenance_history: boolean;
  maintenance_history?: GearMaintenanceHistoryFormData;
}

export interface GearMaintenanceCheckFormData extends BaseGearMaintenanceCheck {
  id?: number;
  maintenance_service_level: {
    maintenance_plan: number;
    service_level: number;
  };
}

export interface GearMaintenanceCheck extends BaseGearMaintenanceCheck {
  id: number;
  maintenance_service_level: {
    service_level: DroneMaintenanceServiceLevel;
    maintenance_plan: number;
  };
  maintenance_date?: Date | string;
  maintenance_log_id?: string;
}

// Gear Maintenance Logs

export interface GearMaintenanceLog {
  id: number;
  created: string;
  title?: string;
  technician: number;
  technician_first_name: string;
  technician_last_name: string;
  technician_status: boolean;
  maintenance_type: Enums.MaintenanceLogType;
  description: string;
  note?: string;
  service_duration: TimeDuration;
  maintenance_check?: GearMaintenanceCheck;
  maintenance_service_date: string;
}

export interface GearMaintenanceLogFormData {
  maintenance_log_id?: number;
  gear_id?: number;
  title?: string;
  technician: number;
  maintenance_type: Enums.MaintenanceLogType;
  description: string;
  note?: string;
  service_duration: TimeDuration;
  maintenance_check?: number;
  maintenance_service_date: string;
}

export interface AvailableMaintenanceChecks {
  id: number;
  title: string;
}

// ------------------------------------------------- Document Upload -----------------------------------------------------

/* Amazon S3 Service Related */

export enum S3Destination {
  images = 'images',
  roc_related_documents = 'roc-related-documents',
  general_documents = 'general-documents',
  account_specific_documents = 'role-specific-documents',
  incident_reports = 'incident-reports',
  investigation_reports = 'investigation-reports',
}

// .meta field is appended to an UploadFile as part of S3 upload from BO uploads library

export interface UploadFileWithMeta extends UploadFile {
  meta: Meta;
}

export interface UploadedFileWithMeta {
  file: UploadFileWithMeta;
  url: string;
}

export interface DocumentUploadData {
  title: string;
  file: string;
  owner: number;
  file_type: any;
  valid_from: string;
  expire_on: string;
  resourcetype?: DocumentResourceTypes;
  drone_id?: number;
  operation?: number;
  uploaded_for?: number;
}

//TODO: move to Audit report store when branches are merged
export interface AuditReportDocumentUploadData extends DocumentUploadData {
  folder_id?: number;
  audit_id?: number;
  occurrence?: number;
  meeting_type?: MeetingMinuteTypes;
}

export interface MeetingMinutesDocumentUploadData extends DocumentUploadData {
  meeting_type?: MeetingMinuteTypes;
  occurrence?: number;
}

export interface Document {
  id: number;
  category?: DocumentCategory;
  archived?: boolean;
  title: string;
  file_type: any;
  type?: Enums.DocumentType;
  meeting_type?: Enums.MeetingMinuteTypes;
  resourcetype?: DocumentResourceTypes;
  occurrence?: number;
  date_uploaded?: string;
  expire_on?: string;
  expires_in?: any; // FE use only
  expires_flag?: any; // FE use only
  has_expired?: any; // FE use only
  valid_from?: string;
  file: string;
  uploaded_for?: number;
  uploaded_by?: UserProfile;
  drone?: number;
  battery?: number;
  payload?: number;
  equipment?: number;
}

export interface GroupedDocuments {
  praircraft_documents?: Document[];
  gear_documents?: Document[];
  operation_documents?: Document[];
  personnel_documents?: Document[];
  pilot_documents?: Document[];
  quality_manager_documents?: Document[];
  roc_documents?: Document[];
  safety_manager_documents?: Document[];
  security_manager_documents?: Document[];
  user_documents?: Document[];
}

// --------------------------------------------- Operations Site Planning ----------------------------------------------

export interface Operation {
  id: number;
  created_by: number;
  permission_request_status: number;
  location: number;
  operation_status: Enums.OperationStatus;
  operation_location: OperationLocation;
  operation_detail: OperationDetail;
  operation_gear: OperationGear[];
  operation_crews: OperationCrew[];
  operation_documents: OperationDocument[];
  weather: any;
}

export interface MinimalOperation {
  id: number;
  created_by: UserProfile;
  name: string;
  start: string;
  end: string;
  operation_type: Enums.OperationType;
  operation_id: number;
  operation_status: Enums.OperationStatus;
  has_passed?: boolean; // FE use only
}

// Location

export interface Location {
  id?: number;
  shape?: Enums.AirspaceMapObjectType;
  polygon_coordinates?: Coordinate[];  // Polygon
  line_coordinates?: Coordinate[];  // Polyline
  center?: Coordinate;  // circle
  radius?: number;
  position?: Coordinate;  // marker
  position_dms?: string;
  title?: string;
}

export interface OperationLocationRequest {
  title: string;
  shape: Enums.AirspaceMapObjectType | string;
  // Circle
  center?: string;
  radius?: number;
  // Polygon
  path?: Coordinate[];
  // Zones
  take_off: string;
  landing: string;
  emergency_landing: string;
  operation_searched_location?: string;
  map_zoom: number;
  map_position: string;
  airspace?: any[];
}

export interface OperationLocation {
  id: number;
  title: string;
  shape: Enums.AirspaceMapObjectType;
  polygon_coordinates?: Coordinate[];  // Polygon
  line_coordinates?: Coordinate[];  // Polyline
  center?: Coordinate;  // circle
  radius?: number;
  map_position: Coordinate;
  take_off: Coordinate;
  take_off_dms: string;
  operation_searched_location?: string;
  landing: Coordinate;
  landing_dms: string;
  emergency_landing: Coordinate;
  emergency_landing_dms: string;
  flyway_radius: Coordinate;
  altitude: Coordinate;
  elevation: number;
  map_zoom: number;
  operation_id: number;
  airspace?: any[];
}

export interface AirspaceValidationRequest {
  type: Enums.AirspaceMapObjectType; // 'POLYGON'
  // POLYGON/POLYLINE
  // tslint:disable-next-line:max-line-length
  path?: string;    // "-24.155340338746292,24.41586167443112,-29.332924399067828,14.747892924431122,-36.44653147698699,16.242033549431124,-38.641387038325504,25.33871323693112,-29.600748004095674,37.77523667443112,-23.431563151402077,37.77523667443112,-24.155340338746292,24.41586167443112"
  // CIRCLE
  radius?: number;  // 283739.16333107604
  center?: string;  // "-37.08371683975855,35.23440311765526"
  point?: string;  // POINT [-31.604367480842182,18.25581121655274]
}

export interface AirspaceValidationResponse {
  airspace_type: Enums.AirspaceAreaType;
  description: string;
  frequency: string;
  id: number;
  name: string;
}

export interface KMLFile {
  name: string;
  active: boolean;
  file: string;
}

// Operation Details

export interface OperationDetailsRequest {
  name: string;
  start: string;
  end: string;
  purpose?: string;
  line_of_sight?: number;
  air_band_frequency?: number;
  closest_alternative_frequency?: number;
  operation_type: Enums.OperationType;
  operation_id: number;
}

export interface OperationDetail {
  id: number;
  operation_id: number;
  created_by: UserProfile;
  name: string;
  start: string;
  end: string;
  purpose?: string;
  line_of_sight?: number;
  air_band_frequency?: number;
  closest_alternative_frequency?: number;
  operation_type: Enums.OperationType;
  operation_status: Enums.OperationStatus;
  permission_request_status: Enums.PermissionRequestStatus;
}

// Weather
interface WeatherDetails {
  description: string;
  id: number;
  main: string;
}

interface WindDetails {
  degree: number;
  direction: string;
  speed: number;
}

interface BaseWeather {
  datetime: string;
  humidity: number;
  pressure: number;
  probability_of_precipitation: number;
  weather: WeatherDetails;
  wind: WindDetails;
}

interface ExtendedBaseWeather extends BaseWeather {
  kp_index: number;
  sunrise: number;
  sunset: number;
  uvi: number;
}

interface CurrentWeather extends ExtendedBaseWeather {
  temperature: number;
  visibility: number;
}

interface DailyWeather extends ExtendedBaseWeather {
  density_altitude: number;
  temperature: {
    minimum: number;
    maximum: number;
    day: number;
  };
}

interface HourlyWeather extends BaseWeather {
  temperature: number;
}

export interface OperationWeather {
  area_name: string;
  current: CurrentWeather;
  daily: DailyWeather[];
  hourly: HourlyWeather[];
  lat: number;
  long: number;
}

// Crew

export interface OperationCrew {
  id: number;
  role: Enums.ROCOperatorUsers;
  crew_member: OperationCrewMember;
  operation: MinimalOperation;
  is_used: boolean;
  is_compliant: boolean;
  is_active: boolean;
  is_pilot_command: boolean;
}

export interface OperationCrewMember {
  id: number;
  allowed_permissions: UserPermissions;
  admin_assumed_roles?: UserRoles;
  is_admin: boolean;
  is_pilot: boolean;
  is_post_holder: boolean;
  is_secondary_admin: boolean;
  user: ROCCrewMember;
}

export interface RemoveCrewMemberRequest {
  member_id: number;
  operation_id: number;
}

export interface ROCUserProfile {
  id: number;
  admin_assumed_roles: UserRoles;
  allowed_permissions: UserPermissions;
  is_accountable_manager: boolean;
  is_admin: boolean;
  is_pilot: boolean;
  is_post_holder: boolean;
  is_secondary_admin: boolean;
  is_compliant: boolean;
  state: number;
  user: UserProfile;
}

export interface LinkedOperator {
  enrolled_at: Date;
  invitation: RemoteOperatorInvitation;
  remote_operator: RemoteOperatorProfile;
  roc_user: ROCUserProfile;
  user_role: UserRoles;
  roles?: Enums.ROCOperatorUsers[];
  delegations?: Delegation[];
  is_active: boolean;
  is_compliant?: boolean;
  is_subscribed?: boolean; // indicates subscription status of the user's linked ROC
}

export interface ROCCrewMember {
  id: number;
  roc_user_id: number;
  avatar?: string;
  first_name: string;
  last_name: string;
  email: string;
  state?: string;
  is_active: boolean;
  is_admin: boolean;
  invitation_id: number;
  has_invitation_expired: boolean;
  is_compliant?: boolean;
  member_type: Enums.ROCMemberType;
  roles?: Enums.ROCOperatorUsers[];
  delegations?: Enums.ROCPostHolders[];
}

export interface Delegation {
  id: number;
  roles: UserRoles;
  role?: ROCPostHolders;
  user: number;
  date_from: Date;
  date_to: Date;
  remote_operator: number;
  status?: DelegationStatus;
  created_by?: number;
  document?: Document;
}

export interface DelegationDocumentUploadData extends DocumentUploadData {
  delegation: any; // expects delegation id in an array
}

// Gear

export interface OperationGear {
  id: number;
  drone: Drone;
  equipment: Equipment;
  payload: Payload;
  battery: Battery;
  payload_type: Enums.PayloadTypes;
  operation: OperationDetail;
  is_used: boolean;
}

export interface OperationGearRequest {
  operation_id: number;
  drones: Drone[];
  batteries: Battery[];
  payloads: Payload[];
  equipment: Equipment[];
}

export interface RemoveGearRequest {
  operation_id: number;
  drone_id?: number;
  battery_id?: number;
  equipment_id?: number;
  payload_id?: number;
}

// Airspace Requirements

export interface OperationDocument extends Document {
  operation: number;
  state?: Enums.DocumentState;
  uploaded_by: UserProfile;
}

export interface RemoveDocumentRequest {
  id: number;
  file_type: Enums.DocumentType;
}

// -------------------------------------------- Operations Site Inspection ---------------------------------------------

// List hazards

export interface OperationHazardResponse {
  approved_sign_off_users: UserProfile[];
  operation_hazards: OperationHazard[];
  sign_off_request: HazardSignOffRequest;
}

export interface OperationHazard {
  id: number; // RESPONSE only
  operation_id: number;
  title?: string;
  hazard: OperationHazardInline;
  mitigation: OperationMitigationInline;
  location: Location;
  completion_status: Enums.HazardCompletionStatus;
  risk_rating: Enums.HazardRiskRating;
  is_declined?: any // FE use only
}

// Hazard Detail

export interface OperationHazardRequest {
  operation: number;
  title?: string;
  hazard: OperationHazardInline;
  mitigation?: OperationMitigationInline;
  location?: OperationHazardLocationRequest;
  completion_status?: Enums.HazardCompletionStatus;
}

export interface OperationHazardLocationRequest {
  shape: Enums.AirspaceMapObjectType;
  // Polygon
  // Polyline
  path?: Coordinate[];
  // Circle
  center?: string;
  radius?: number;
  // marker
  position?: string;
}

export interface OperationHazardTag {
  name: string;
}

export interface RiskAssessmentBase {
  description: string;
  rating?: string;
  severity: Enums.HazardSeverity;
  probability: Enums.HazardProbability;
}

export interface OperationHazardInline extends RiskAssessmentBase {
  tag: string;
}

export interface OperationMitigationInline extends RiskAssessmentBase {
  title?: string;
}

// Hazard sign-off Settings

export interface HazardSignOffLevelData {
  risk_levels?: any[];
  sign_off_user_role: string;
}

export interface HazardSignOffSettingsRequest {
  acceptable_hazard: HazardSignOffLevelData;
  tolerable_hazard: HazardSignOffLevelData;
  intolerable_hazard: HazardSignOffLevelData;
}

export interface HazardSignOffSettings extends HazardSignOffSettingsRequest {
  id: number;
  created_by: UserProfile;
}

// Hazard Sign off Request

export interface HazardSignOffRequestData {
  operation: number;
  sign_off_requested_from: number;
  sign_off_requested_by: number;
  reasons?: HazardDeclineReasons[];
}

export interface HazardSignOffRequest {
  id: number;
  sign_off_requested_by: UserProfile;
  sign_off_requested_from: UserProfile;
  status?: string;
  signed_off_requested_at?: string;
  signed_off_at?: string; // only when approved
  resent_at?: string;
  reasons?: HazardDeclineReasons[];
}

export interface HazardDeclineReasons {
  sign_off: number;
  hazard: number;
  reason: string;
  user: UserProfile;
  created: Date;
  hazard_title?: string; // FE use only
}

export interface HazardDeclineData {
  operation: number;
  approved_by: number;
  remote_operator: number;
  reasons: HazardDeclineReasons[];
}

// List points of interest

export interface OperationPointOfInterest {
  id: number; // RESPONSE only
  operation_id: number;
  type: Enums.PointOfInterestType;
  title: string;
  description: string;
  location: OperationPointOfInterestLocation;
}

export interface OperationPointOfInterestRequest {
  operation_id: number;
  type: Enums.PointOfInterestType;
  title: string;
  description: string;
  location?: OperationPointOfInterestLocationRequest;
}

export interface OperationPointOfInterestLocation {
  id: number;
  shape: Enums.AirspaceMapObjectType.POINT;
  position: Coordinate;
  position_dms: string;
}

export interface OperationPointOfInterestLocationRequest {
  shape: Enums.AirspaceMapObjectType.POINT;
  position: string;
}

// Equipment item
export interface EmergencyEquipmentItem {
  id?: number;
  name: string;
}

// Get emergency information
export interface EmergencyInformation {
  id: number;
  operation_id: number;
  emergency_procedure: string;
  emergency_equipment: EmergencyEquipmentItem[];
}

// Post emergency information
export interface EmergencyInformationRequest {
  operation_id: number;
  emergency_procedure: string;
  emergency_equipment: EmergencyEquipmentItem[];
}

// Emergency Services
export interface EmergencyService {
  id: number;
  operation_id: number;
  type: string;
  title: string;
  description: string;
  contact_number: string;
  address: string;
}

export interface EmergencyServiceRequest {
  operation_id: number;
  type: string;
  title: string;
  description?: string;
  contact_number?: string;
  address?: string;
}

// ----------------------------------------------- Operation Pre-flight ------------------------------------------------

export interface PreflightOption {
  name: string; // NOTAMS
  checked: boolean;
}

export interface PreflightChecklistRequest {
  operation_id: number;
  checklist: PreflightOption[];
}

export interface PreflightComplianceCheck {
  name: string;
  status: boolean;
  details: PreflightComplianceCheckDetails[];
}

export interface PreflightComplianceCheckDetails {
  id: number;
  type?: string;
  validity_status?: string;
  validity_status_details?: any;
  user?: any;
  drone?: any;
  status?: any;
  sign_off_requested_from?: any;
  sign_off_requested_by?: any;
}


// ------------------------------------------------ Operation In-flight ------------------------------------------------

export interface OperationFlightWeatherAtTime {
  humidity: number;
  kp_index: number;
  pressure: number;
  probability_of_precipitation: number;
  sunrise: number;
  sunset: number;
  temperature: number;
  uvi: number;
  visibility: number;
  density_altitude: number;
  weather: {
    description: string;
    id: number;
    main: string;
  };
  wind: {
    degree: number;
    direction: string;
    speed: number;
  };
}

export interface OperationFlightWeather {
  id: number;
  start_flight_weather: OperationFlightWeatherAtTime;
  end_flight_weather: OperationFlightWeatherAtTime;
}

export interface OperationFlight {
  id: number;
  name: string;
  start: string;
  end: string;
  pilot: number;
  observer: number;
  pilot_details?: any;
  pilot_in_command?: UserBasic;
  observer_details?: any;
  payload_details?: any[];
  operation: MinimalOperation;
  take_off: {
    type: Enums.InFlightTakeoffType;
    position: Coordinate;
    position_dms: string;
  };
  landing: {
    type: Enums.InFlightLandingType;
    position: Coordinate;
    position_dms: string;
  };
  drone: {
    id: number;
    registration_reference: string;
    rpas_type: string;
    nickname: string;
  };
  battery: {
    id: number;
    start_voltage: string;
    end_voltage: string;
    serial_number: string;
  };
  charge_log: FlightChargeLogRequest;
  payloads: number[];
  equipment: number[];
  note: string;
  real_time_capture: boolean;
  flight_state: Enums.FlightStatus;
  weather: OperationFlightWeather;
}

export interface CreateFlightRequest {
  name: string;
  operation: number;
}

export interface RenameFlightRequest {
  name: string;
}

export interface CaptureFlightRequest {
  start: string;
  end: string;
  pilot: number;
  observer?: number;
  charge_log?: FlightChargeLogRequest;
  take_off: {
    type: Enums.InFlightTakeoffType;
    position: string;
  };
  landing: {
    type: Enums.InFlightLandingType;
    position: string;
  };
  drone: {
    id: number;
    registration_reference: string;
  };
  battery: {
    id: number;
    start_voltage: string;
    end_voltage: string;
  };
  payloads: number[];
  equipment: number[];
  note: string;
}

export interface StartFlightRequest {
  start: string;
  pilot: number;
  observer?: number;
  charge_log?: FlightChargeLogRequest;
  take_off: {
    type: Enums.InFlightTakeoffType;
    position: string;
  };
  drone: {
    id: number;
    registration_reference: string;
  };
  battery: {
    id: number;
    start_voltage: string;
  };
  payloads: number[];
  equipment: number[];
  note: string;
}

export interface EndFlightRequest {
  end: string;
}

export interface CompleteFlightRequest {
  landing: {
    type: Enums.InFlightLandingType;
    position?: string;
  };
  battery: {
    id: number;
    end_voltage: string;
  };
  note: string;
}

export interface FlightChargeLogRequest {
  charge_date?: string;
  cycles: number;
}

// Flight Folio
export interface MinimalFlightFolio {
  id: number;
  name: string;
  operation: OperationDetail;
}

export interface FlightFolio {
  id?: number;
  doc_number: string;
  revision_number: string;
  operation: OperationDetail;
  drone: Drone;
  crew_member: {
    id: number;
    crew_member_id: number;
    first_name: string;
    last_name: string;
    is_active: boolean;
    role: string;
    signed_at: null;
    is_pilot_command: boolean;
    rpl_number?: string;
    rmt_licence_number?: string;
  }[];
  next_scheduled_maintenance: {
    due_date: string;
    accumulated_flight_hours: number;
    title: string;
  };
  total_flying_time: {
    hours: number;
    minutes: number;
    seconds: number;
  };
  flight: {
    id: number;
    pilot_id: number;
    pilot_status: boolean; // roc member active/deactivated status
    takeoff_point: {
      type: Enums.InFlightTakeoffType;
      position: Coordinate;
      position_dms: string;
    };
    landing_point: {
      type: Enums.InFlightLandingType;
      position: Coordinate;
      position_dms: string;
    };
    flight_name: string;
    takeoff_time: string;
    landing_time: string;
    duration: number;
    start_voltage: string;
    end_voltage: string;
    signed_at: null;
  }[];
  incident: {
    id: number;
    date: string;
    description: string;
    rectification_action?: string;
    flight_name: string;
    incident: Omit<IncidentDetail, 'flight'>,
    signed_at: null;
  }[];
}

export interface FlightFolioSignOffTaskItem {
  date: string;
  drone_id: number;
  flight_folio_id: number;
  name: string;
}
export interface FlightFolioSignOffTasks {
  crew: FlightFolioSignOffTaskItem[];
  incident: FlightFolioSignOffTaskItem[];
  flight: FlightFolioSignOffTaskItem[];
}

// ------------------------------------------------ Reporting / Post Flight -----------------------------------------------------

// Incident
export interface IncidentPhoto {
  id?: number;
  description: string;
  file: string;
}

export interface IncidentBase {
  id?: number;
  flight: OperationFlight;
  incident_type: string;
  time: string;
  reporter: any;
  description: string; // on PDF: cause of incident
  location?: Location;
  received_by?: UserProfile;
  corrective_action: string;
  photos?: IncidentPhoto[];
}

export interface IncidentInjuryFatality {
  name?: string;
  contact?: string;
  next_of_kin?: string;
  next_of_kin_contact?: string;
  additional_information?: string;
}

export interface DamagePropertyDescription {
  building_name?: string;
  location?: string;
  landowner_name?: string;
  landowner_contact?: string;
  description?: string;
}

export interface IncidentWeatherConditions {
  wind_speed?: string;
  wind_direction?: string;
  temperature?: string;
  kp_index?: string;
  probability_of_precipitation?: string;
  additional_information?: string;
}

export interface IncidentReportRequest extends IncidentBase {
  incident_cause?: string;
  nature_of_incident?: string;
  aircraft_recovered: boolean;
  aircraft_recovered_description?: string;
  phase_of_flight?: string;
  operation_type?: string;
  people_injured: boolean;
  people_injured_description?: IncidentInjuryFatality[];
  people_killed: boolean;
  people_killed_description?: IncidentInjuryFatality[];
  damage_to_property: boolean;
  damage_to_property_description?: DamagePropertyDescription[];
  damage_to_aircraft: boolean;
  damage_to_aircraft_description?: string;
  operating_conditions?: string; // auto-populate from operation.
  weather_conditions: IncidentWeatherConditions;
  aircraft_mode?: string;
  height_agl?: string;
  speed?: string;
  heading?: string;
  distance_from_rps?: string;
  endurance_remaining?: string;
  within_controlled_airspace: boolean;
  within_controlled_airspace_description: string;
  atsu_contacted: boolean;
  atsu_contacted_description?: string;
  involve_manned_aircraft: boolean;
  involve_manned_aircraft_description?: string;
}

export interface IncidentDetail extends IncidentReportRequest {
  created?: string;
  category?: any[];
  document_name: string;
}

export interface HazardReportRequest extends IncidentBase {
  category?: any[];
  rectification_action?: string;
}

// ------------------------------------------------ Incident Investigation Management -----------------------------------------------------

export interface IncidentInvestigationReport {
  id: number;
  reference: string;
  status: Enums.IncidentInvestigationReportStatus;
  created: Date;
  incident: {
    id: number;
    reference_number: string;
    location: string;
    time: string;
    created: Date;
    flight: {
      name: string;
      start: Date;
      end: Date;
      pilot: UserBasic;
    },
  }
  operation: {
    id: number;
    name: string;
    start: Date;
    end: Date;
    operation_status: Enums.OperationStatus;
  }
  review?: {
    id: number;
    status: InvestigationReviewStatus;
    requested_by: UserBasic;
    requested_at: Date;
    requested_to?: UserBasic;
    declined_at?: Date;
    signed_off_at?: Date;
    decline_reason?: string;
  }
}

// Section 1 Step 1 ==> Details/Overview
export interface IncidentInvestigationDetailsRequest {
  investigator: number;
  investigation_date: Date;
  incident_reference: string;
  description: DescriptionItem[] | any[];
  is_reportable: boolean;
  reportable_description: ReportableDescription;
  proof_submission: InvestigationReportDocument[];
  is_investigation_signed: boolean;
}

export interface IncidentInvestigationOverview extends IncidentInvestigationDetailsRequest {
  investigator_profile: UserBasic;
  incident_reference: string;
  status: Enums.IncidentInvestigationReportStatus;
}

// Section 1 Step 2 ==> Risk Assessment
export interface IncidentInvestigationRiskRequest {
  risk_assessment?: RiskAssessmentBase[];
  persons_involved: any[];
  has_technical_report: boolean;
  reports?: InvestigationReportDocument[];
  has_similar_occurrence: boolean;
  similar_occurrence_description?: RiskSimilarOccurrenceDescription;
  has_safety_meeting: boolean;
  safety_meeting?: number;
  is_investigation_signed?: boolean;
}

export interface IncidentInvestigationRisk extends IncidentInvestigationRiskRequest {
  status: Enums.IncidentInvestigationReportStatus;
  safety_meeting_details?: EventDetail;
  incident_date: string;
}

// Section 1 Step 3 ==> Corrective Action
export interface IncidentInvestigationCorrectiveRequest {
  is_investigation_signed: boolean;
  planned_corrective_action: CorrectivePreventativeAction[];
  root_cause_determination: string;
}

export interface IncidentInvestigationCorrective extends IncidentInvestigationCorrectiveRequest {
  status: Enums.IncidentInvestigationReportStatus;
  investigation_signed_at?: Date;
  safety_manager_signature?: string;
  safety_manager?: UserBasic; // user who has signed off
}

// Section 2 ==> Implementation

export interface IncidentInvestigationImplementationRequest {
  corrective_action: ImplementationCorrectivePreventativeActionItem[];
  preventive_action: ImplementationCorrectivePreventativeActionItem[];
  is_action_acceptable: boolean
  actions_declined_reason: string;
  is_implementation_signed: boolean;
}

export interface IncidentInvestigationImplementation extends IncidentInvestigationImplementationRequest {
  status: Enums.IncidentInvestigationReportStatus;
  implementation_signed_at?: Date;
  safety_manager_signature?: string;
  safety_manager?: UserBasic; // user who has signed off
}

export interface IncidentInvestigationEvaluationRequest {
  evaluation_due_date: string;
  is_corrective_action_effective: boolean;
  corrective_action_description: DescriptionItem[];
  is_preventative_action_effective: boolean;
  preventative_action_description: DescriptionItem[];
  car_number: DescriptionItem[] | any[];
  evidence: InvestigationReportDocument[];
  is_evaluation_signed: boolean;
  safety_manager_signature: string;
  evaluation_signed_at: Date;
}

export interface IncidentInvestigationEvaluation extends IncidentInvestigationEvaluationRequest {
  risk_assessment: string;
  status: Enums.IncidentInvestigationReportStatus;
  is_report_signed: boolean; // summary of signatures from corrective, implementation and evaluation.
  account_manager_signature: string;
  accountable_manager: UserBasic;
  account_manager_signed_at: Date;
  safety_manager?: UserBasic; // user who has signed off
}

export interface InvestigationReviewRequest {
  reference: string;
  decline_reason?: string;
}

export interface InvestigationReportedToCaaRequest {
  is_submitted_to_caa: boolean;
  submitted_to_caa_description: any;
  caa_submission: InvestigationReportDocument[];
}

export interface InvestigationReportedToCaa extends InvestigationReportedToCaaRequest {
  status: Enums.IncidentInvestigationReportStatus;
}

// ------------------------------------------------ Audit Reports -----------------------------------------------------

interface BaseAuditReport {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
}

export interface AuditReport extends BaseAuditReport {
  created: string;
  folder_id: string;
  status: Enums.AuditStatus;
}

export interface AuditReportDetail extends BaseAuditReport {
  folders: AuditReportDescendantFolder[];
}

export interface CompiledAuditReport<T = any> {
  created: string;
  created_by: UserProfile;
  file: string;
  for_internal_use: boolean;
  modified: string;
  status: string;
  submitted_at?: string;
  submitted_by?: UserProfile;
}

export interface AuditReportRequest {
  name: string;
  start_date: string;
  end_date: string;
}

export interface AuditReportDescendantFolder {
  id?: number;
  folder_type?: Enums.AuditReportFolderTypes;
  name: string;
  status: Enums.AuditReportFolderStatus;
  upload_params?: AuditReportDocumentUploadParams; // used mostly for acquiring relevant ID's for data queries
  /* FE use only */
  slug?: string;
  drone_nickname?: string | any;
}
export interface AuditReportDocumentAdditionalInformation {
  title?: string;
  file_type?: string;
  date_uploaded?: string;
  expire_on?: string;
  id?: number;
  valid_from?:  string;
  uploaded_by?: UserProfile;
  flight_folio_id?: number;
  drone_id?: number;
}

export interface AuditReportDocument {
  name: string;
  status: Enums.AuditReportDocumentStatus;
  type?: Enums.AuditReportFolderGroup;
  type_generated?: Enums.AuditReportFolderGroup;
  type_other?: string;
  upload_params?: AuditReportDocumentUploadParams;
  additional_information?: AuditReportDocumentAdditionalInformation
  id?: number;
  date_uploaded?: string;
  file?: string;
  // FE use only for outstanding documents
  has_upload_permission?: boolean;
  crew_member?: {
    name?: string;
    last_name?: string;
  };
  drone_reg_ref?: string;
  operation_name?: string;
}

export interface AuditReportDocumentUploadParams {
  drone_id?: number;
  user_id?: number;
  file_type: string;
  operation_id?: number;
  remote_operator?: number;
  resource_type?: DocumentResourceTypes;
  meeting_end?: string;
  meeting_start?: string;
  meeting_type?: MeetingMinuteTypes;
}

// ------------------------------------------------ Notifications -----------------------------------------------------

export interface Notification<T = any> {
  id: number;
  level: string;
  sender: UserProfile;
  unread: boolean;
  timestamp: string;
  action_type: Enums.NotificationType;
  action: T;
  verb: string;
  data?: any;
}

//  ---------------------------------------------- Feedback -------------------
export interface SupportFile {
  file: string;
}

export interface SupportRequest {
  description: string;
  reason: string;
  images?: SupportFile[];
}

//  ---------------------------------------------- Subscription -------------------

export interface SubscriptionPackage {
  id: number;
  tier: Enums.SubscriptionTiers,
  description: string;
  base_cost: number,
  discount?: number,
  yearly_cost: number;
  monthly_cost: number;
  savings?: number
  is_published: boolean;
  operations_min?: number;
  operations_max?: number;
  remote_operator?: number;
}

export interface MySubscription {
  id: string;
  package: number;
  package_details: SubscriptionPackage,
  frequency: SubscriptionPaymentFrequency,
  status: SubscriptionStatus,
  created: Date,
  is_removed: boolean,
  start_date: Date,
  expiry_date: Date,
  last_bill_date: Date,
  next_bill_date: Date,
  token: string;
}

export interface CancelSubscriptionRequest {
  reason: string;
  description?: string;
}

export interface SubscriptionInvoice {
  id: number;
  amount_gross: number;
  billing_date: Date;
}

// ---------------------------------------------- Task Manager -------------------
export interface TaskMangerHazardSignOffRequest {
  operation: {
    id: number,
    name: string,
    start_date: Date
  },
  sign_off_requested_from: UserProfile,
  sign_off_requested_by: UserProfile,
  signed_off_requested_at: Date
}
