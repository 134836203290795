import { Component, Input, OnChanges } from '@angular/core';
import {
  AuditReportDocumentStatus,
  AuditReportFolderStatus,
  AuditStatus, DelegationStatus, IncidentInvestigationReportStatus, InvestigationReviewStatus,
  OperationStatus, RemoteOperatorInvitationStatus, SubscriptionStatus
} from '../../api/interface/api.enums';
import { SnakeToSentenceCasePipe } from '../../pipes/snake-to-sentence-case.pipe';

export enum StatusBadgeType {
  AUDIT_CHECKS = 'AUDIT_CHECKS',
  COMPLIANCE_CHECKS = 'COMPLIANCE_CHECKS',
  MEETING_DOCUMENTS = 'MEETING_DOCUMENTS',
  HAZARD_REQUEST_STATUS = 'HAZARD_REQUEST_STATUS',
  OPERATION_STATUS = 'OPERATION_STATUS',
  DELEGATION_STATUS = 'DELEGATION_STATUS',
  COMPLIANT_BOOLEAN = 'COMPLIANT_BOOLEAN',
  ROC_INVITATIONS = 'ROC_INVITATIONS',
  SUBSCRIPTION_STATUS = 'SUBSCRIPTION_STATUS',
  INVESTIGATION_REPORT_STATUS = 'INVESTIGATION_REPORT_STATUS',
  INVESTIGATION_REVIEW_STATUS = 'INVESTIGATION_REVIEW_STATUS',
}

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html'
})

export class StatusBadgeComponent implements OnChanges {
  @Input() status: any;
  @Input() showLabel: boolean = true;
  @Input() popOverTitle: string;
  @Input() popOverText: string;
  @Input() type: StatusBadgeType;
  @Input() displayClass: string;


  labelClass: string;
  label: string;
  icon: string;
  tooltipText: string;

  genericGreenCheck(label: string) {
    this.labelClass = 'bg-success';
    this.label = label;
    this.icon = 'bi-check-lg';
  }

  genericOrangeExclamation(label: string) {
    this.labelClass = 'bg-warning';
    this.label = label;
    this.icon = 'bi-exclamation-triangle-fill';
  }

  genericRedX(label: string) {
    this.labelClass = 'bg-danger';
    this.label = label;
    this.icon = 'bi-x-lg';
  }

  ngOnChanges(): void {
    this.label = new SnakeToSentenceCasePipe().transform((this.status?.toString()))

    switch (this.type) {
      case (StatusBadgeType.AUDIT_CHECKS): {
        this.setAuditStatusBadge();
        break;
      }
      case (StatusBadgeType.MEETING_DOCUMENTS): {
        this.setMeetingStatusBadge();
        break;
      }
      case (StatusBadgeType.COMPLIANCE_CHECKS): {
        this.setComplianceStatusBadge();
        break;
      }
      case (StatusBadgeType.HAZARD_REQUEST_STATUS): {
        this.setHazardRequestStatusBadge();
        break;
      }
      case (StatusBadgeType.OPERATION_STATUS): {
        this.setOperationStatusBadge();
        break;
      }
      case (StatusBadgeType.DELEGATION_STATUS): {
        this.setDelegationStatusBadge();
        break;
      }
      case (StatusBadgeType.COMPLIANT_BOOLEAN): {
        this.setIsCompliantStatusBadge();
        break;
      }
      case (StatusBadgeType.ROC_INVITATIONS): {
        this.setInvitationsStatusBadge();
        break;
      }
      case (StatusBadgeType.SUBSCRIPTION_STATUS): {
        this.setSubscriptionStatusBadge();
        break;
      }
      case (StatusBadgeType.INVESTIGATION_REPORT_STATUS): {
        this.setInvestigationReportStatusBadge();
        break;
      }
      case (StatusBadgeType.INVESTIGATION_REVIEW_STATUS): {
        this.setInvestigationReviewStatusBadge();
        break;
      }
      default: {
        this.label = 'label';
        this.labelClass = 'bg-secondary';
        break;
      }
    }
  }

  private setAuditStatusBadge() {
    switch (this.status) {
      case (AuditReportDocumentStatus.DOCUMENT_OUTSTANDING):
        this.genericOrangeExclamation('Outstanding');
        break;
      case (AuditReportDocumentStatus.DOCUMENT_EXPIRED):
        this.genericOrangeExclamation('Expired');
        break;
      case (AuditReportDocumentStatus.SIGNATURES_OUTSTANDING):
        this.genericOrangeExclamation('Signatures required')
        break;
      case (AuditReportDocumentStatus.ADDITIONAL_DOCUMENT_UPLOADED):
        this.genericGreenCheck('Ready');
        break;
      case (AuditReportDocumentStatus.REQUIREMENT_MET):
        this.genericGreenCheck('Ready');
        break;
      case (AuditReportDocumentStatus.OPTIONAL): {
        this.labelClass = 'bg-secondary';
        this.icon = 'bi-question-circle-fill';
        break;
      }
      case (AuditReportFolderStatus.ALL_REQUIREMENTS_MET):
        this.genericGreenCheck('Ready');
        break;
      case (AuditReportFolderStatus.OUTSTANDING):
        this.genericOrangeExclamation('Outstanding')
        break;
      case (AuditStatus.CREATED): {
        this.genericGreenCheck('Created')
        break;
      }
      case (AuditStatus.ALL_REQUIREMENTS_MET): {
        this.genericGreenCheck('Ready')
        break;
      }
      case (AuditStatus.OUTSTANDING):
        this.genericOrangeExclamation('Outstanding')
        break;
      case (AuditStatus.IN_PROGRESS): {
        this.labelClass = 'bg-warning';
        this.label = 'In Progress';
        this.icon = 'bi-hourglass-split';
        break;
      }
      default: break;
    }
  }

  private setMeetingStatusBadge() {
    switch (this.status) {
      case ('REQUIREMENT_MET'): {
        this.genericGreenCheck('Uploaded')
        break;
      }
      case ('DOCUMENT_OUTSTANDING'):
        this.genericOrangeExclamation('Outstanding')
        break;
    }
  }

  private setComplianceStatusBadge() {
    switch (this.status) {
      case ('OUTSTANDING'):
        this.genericOrangeExclamation('Outstanding');
        break;
      case ('WARNING'): {
        this.labelClass = 'bg-warning';
        this.label = 'Will Expire';
        this.icon = 'bi-hourglass-split';
        break;
      }
      case ('EXPIRED'): {
        this.labelClass = 'bg-danger';
        this.label = 'Expired';
        this.icon = 'bi-exclamation-triangle-fill';
        break;
      }
      case ('REQUIREMENT_MET'): {
        this.genericGreenCheck('Ready');
        break;
      }
      default: break;
    }
  }

  private setHazardRequestStatusBadge() {
    switch (this.status) {
      case ('Approved'): {
        this.genericGreenCheck('Approved');
        break;
      }
      case ('In Progress'): {
        this.labelClass = 'bg-warning';
        this.label = 'Pending';
        this.icon = 'bi-clock';
        break;
      }
      case ('Declined'): {
        this.labelClass = 'bg-danger';
        this.label = 'Declined';
        this.icon = 'bi-x-lg';
        break;
      }
    }
  }

  private setOperationStatusBadge() {
    switch (this.status) {
      case (OperationStatus.DRAFT): {
        this.labelClass = 'bg-operation-draft';
        this.label = 'Draft';
        this.icon = 'bi-pencil-square';
        break;
      }
      case (OperationStatus.ABORTED): {
        this.labelClass = 'bg-operation-aborted';
        this.label = 'Aborted';
        this.icon = 'bi-x-circle-fill';
        break;
      }
      case (OperationStatus.COMPLETED): {
        this.labelClass = 'bg-operation-completed';
        this.label = 'Closed';
        this.icon = 'bi-archive';
        break;
      }
      case (OperationStatus.PUBLISHED): {
        this.labelClass = 'bg-operation-published';
        this.label = 'Published';
        this.icon = 'bi-send';
        break;
      }
    }
  }

  private setDelegationStatusBadge() {
    switch (this.status) {
      case (DelegationStatus.CREATED): {
        this.labelClass = 'bg-secondary';
        this.label = 'Draft';
        this.icon = 'bi-pencil-square';
        break;
      }
      case (DelegationStatus.PENDING): {
        this.labelClass = 'bg-info';
        this.label = 'Upcoming';
        this.icon = 'bi-clock';
        break;
      }
      case (DelegationStatus.IN_PROGRESS): {
        this.genericGreenCheck('Active');
        break;
      }
      case (DelegationStatus.CANCELED): {
        this.labelClass = 'bg-danger';
        this.label = 'Cancelled';
        this.icon = 'bi-x-lg';
        break;
      }
      case (DelegationStatus.REVOKED): {
        this.labelClass = 'bg-warning';
        this.label = 'Revoked';
        this.icon = 'bi-slash-circle';
        break;
      }
      case (DelegationStatus.COMPLETE): {
        this.labelClass = 'bg-secondary';
        this.label = 'Completed';
        this.icon = 'bi-check-circle';
        break;
      }
    }
  }

  private setIsCompliantStatusBadge() {
    switch (this.status) {
      case true:
        this.genericGreenCheck('Compliant');
        break;
      case false:
        this.genericOrangeExclamation('Not Compliant')
        break;
    }
  }

  private setInvitationsStatusBadge() {
    switch (this.status) {
      case (RemoteOperatorInvitationStatus.PENDING): {
        this.labelClass = 'bg-info';
        this.label = 'Sent';
        this.icon = 'bi-hourglass-split';
        break;
      }
      case (RemoteOperatorInvitationStatus.EXPIRED): {
        this.labelClass = 'bg-warning';
        this.label = 'Expired';
        this.icon = 'bi-hourglass-bottom';
        break;
      }
      case (RemoteOperatorInvitationStatus.ACCEPTED):
        this.genericGreenCheck('Upcoming');
        break;
      case (RemoteOperatorInvitationStatus.DECLINED): {
        this.labelClass = 'bg-danger';
        this.label = 'Declined';
        this.icon = 'bi-x-lg';
        break;
      }
    }
  }

  private setSubscriptionStatusBadge() {
    switch (this.status) {
      case (SubscriptionStatus.ACTIVE):
        this.genericGreenCheck('Active');
        break;
      case (SubscriptionStatus.PENDING): {
        this.labelClass = 'bg-info';
        this.label = 'Upcoming';
        this.icon = 'bi-clock';
        break;
      }
      case (SubscriptionStatus.CANCELLED):
        this.genericRedX('Cancelled')
        break;
      case (SubscriptionStatus.PAYMENT_FAILED):
        this.genericRedX('Payment Failed')
        break;
      case (SubscriptionStatus.EXPIRED):
        this.genericRedX('Expired')
        break;
      case (SubscriptionStatus.OPERATIONS_EXCEEDED):
        this.genericOrangeExclamation('Active')
        break;
    }
  }

  private setInvestigationReportStatusBadge() {
    switch (this.status) {
      case (IncidentInvestigationReportStatus.NEW): {
        this.labelClass = 'bg-secondary';
        this.label = 'New';
        this.icon = 'bi-files';
        break;
      }
      case (IncidentInvestigationReportStatus.IN_PROGRESS): {
        this.labelClass = 'bg-info';
        this.label = 'In Progress';
        this.icon = 'bi-file-earmark-text';
        break;
      }
      case (IncidentInvestigationReportStatus.REVIEW): {
        this.labelClass = 'bg-warning';
        this.label = 'In Review';
        this.icon = 'bi-hourglass-split';
        break;
      }
      case (IncidentInvestigationReportStatus.COMPLETED): {
        this.labelClass = 'bg-success';
        this.label = 'Signed off';
        this.icon = 'bi-check';
        break;
      }
      case (IncidentInvestigationReportStatus.SUBMITTED): {
        this.labelClass = 'bg-success';
        this.label = 'Submitted to CAA';
        this.icon = 'i bi-send';
        break;
      }
    }
  }

  private setInvestigationReviewStatusBadge () {
    switch (this.status) {
      case (InvestigationReviewStatus.NEW): {
        this.labelClass = 'bg-secondary';
        this.label = 'Pending';
        this.icon = 'bi-hourglass-split';
        break;
      }
      case (InvestigationReviewStatus.APPROVED): {
        this.genericGreenCheck('Approved')
        break;
      }
      case (InvestigationReviewStatus.DECLINED): {
        this.genericOrangeExclamation('Changes requested')
        break;
      }
    }
  }
}
