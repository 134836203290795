import { Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { HeaderContainerDirective } from './directives/header-container.directive';
import { UpdateHeaderService } from './services/update-header.service';
import { Subscription } from 'rxjs';
import { BreadcrumbsService } from '@bo/ng-breadcrumbs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent implements OnInit, OnDestroy {

  headerComponentRef: ComponentRef<any>;
  disableBreadcrumbs = false;

  @ViewChild(HeaderContainerDirective, {static: true}) headerContainer: HeaderContainerDirective;

  private subscriptions = new Subscription();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private updateHeaderService: UpdateHeaderService,
    private breadcrumbsService: BreadcrumbsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.breadcrumbsService.crumbs$.subscribe());
    this.subscriptions.add(
      this.updateHeaderService.setHeaderComponent$.subscribe(newHeaderComponent => {
      this.headerComponentRef = this.loadComponent(newHeaderComponent);
      this.disableBreadcrumbs = this.headerComponentRef.instance.disableBreadcrumbs;
      this.updateHeaderService.headerComponentUpdated(this.headerComponentRef);
    })
    );
  }

  loadComponent(component: Type<any>): ComponentRef<any> {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.headerContainer.viewContainerRef;
    viewContainerRef.clear();

    return viewContainerRef.createComponent(componentFactory);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
