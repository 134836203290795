import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalRefs: BsModalRef[] = [];

  constructor(private modalService: BsModalService
  ) { }

  open<T>(content: string | TemplateRef<any> | (new(...args: any[]) => T),
          config?: ModalOptions<T>,
          componentInputs?: {[inputProp: string]: any}) {

    const modalRef = this.modalService.show(content, config);
    this.modalRefs.push(modalRef);
    // attach inputs to content's component instance
    if (componentInputs && modalRef.content) {
      Object.keys(componentInputs).forEach(prop => modalRef.content[prop] = componentInputs[prop]);
    }

    return modalRef;
  }

  closeAll() {
    this.modalRefs.forEach(modal => modal.hide());
  }

  onHidden(): EventEmitter<any> {
    return this.modalService.onHidden;
  }

}
