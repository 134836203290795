// Actions

import { UserProfile } from '../shared/api/interface/api.models';

/* ClearAppState */

export class ClearAppState {
  static readonly type = '[App] ClearAppState';
}

/* SetUserState */

export abstract class AbstractSetUserState {
  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  constructor(public userProfile?: UserProfile) {}
}

export class SetUserStateFromReloadAppState extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from ReloadAppState';
}

export class SetUserStateFromNavigateToDashboard extends AbstractSetUserState {
  static readonly type = '[App] SetUserState from NavigateToDashboard';
}

/* SideNav */

export class ToggleSideNav {
  static readonly type = '[App] ToggleSideNav';
}

export class CollapseSideNav {
  static readonly type = '[App] CollapseSideNav';
}

export class OpenSideNav {
  static readonly type = '[App] OpenSideNav';
}

/* Reload App State */

export class ReloadAppStateFromInit {
  static readonly type = '[App] ReloadAppState from Init';
}

export class ReloadAppStateFromLogin {
  static readonly type = '[App] ReloadAppState from Login';
}

/* DashboardsState */

export class NavigateToDashboardFromRootComponent {
  static readonly type = '[App] NavigateToDashboard from RootComponent';
}

export class NavigateToDashboardFromLogin {
  static readonly type = '[App] NavigateToDashboard from Login';
}

export class NavigateToDashboardFromCreateROC {
  static readonly type = '[App] NavigateToDashboard from Create ROC';
}
