import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvestigationReportDocument, InvestigationReportDocumentMapped
} from '../../../modules/roc/pages/reports/store/report.models';
import { combineLatest, Observable } from 'rxjs';
import { DocumentPreviewModalComponent } from '../modals/document-preview-modal/document-preview-modal.component';
import { ModalService } from '../../services/modal.service';
import { startWith } from 'rxjs/operators';
import { IncidentInvestigationReportDocumentTypes } from '../../api/interface/api.enums';

@Component({
  selector: 'app-files-attachment-list',
  templateUrl: './files-attachment-list.component.html',
  styleUrls: []
})
export class FilesAttachmentListComponent implements OnInit {
  @Input() uploadedFiles$: Observable<InvestigationReportDocument[]>;
  @Input() filesToUpload$: Observable<InvestigationReportDocument[]> = new Observable<InvestigationReportDocument[]>();
  @Input() fileLabel: string = 'Uploaded file';
  @Input() showDelete: boolean = true;
  @Output() deleteItem = new EventEmitter<number>();

  combinedFilesMapped: InvestigationReportDocumentMapped[] = [];

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.uploadedFiles$.pipe(startWith([])),
      this.filesToUpload$.pipe(startWith([])),
    ]).subscribe(([uploaded, toUpload, ]) => {
      const toUploadMapped = toUpload.map(file => {
        return {
          file: file.file,
          report_file: file.report_file,
          type: this.getType(file.file),
          label: this.getLabel(file.report_file),
        }
      });
      const uploadedMapped = uploaded.map(file => {
        return {
          id: file.id,
          file: file.file,
          report_file: file.report_file,
          type: this.getType(file.file),
          label: this.getLabel(file.report_file),
        }
      });
      this.combinedFilesMapped = [];
      this.combinedFilesMapped.push(...toUploadMapped, ...uploadedMapped)
    })
  }

  getType(url: string) {
    if (url.includes('.jpeg') || url.includes('.jpg') || url.includes('.png')) {
      return 'image'
    } else
    if (url.includes('.pdf')) {
      return 'pdf'
    } else { return 'other'}
  }

  getLabel(fileType: IncidentInvestigationReportDocumentTypes) {
    switch (fileType) {
      case (IncidentInvestigationReportDocumentTypes.PROOF_SUBMISSION): {
        return 'Proof of Submission';
      }
      case (IncidentInvestigationReportDocumentTypes.PREVIOUS_OCCURRENCE): {
        return 'Previous occurrences / actions';
      }
      case (IncidentInvestigationReportDocumentTypes.TECHNICAL): {
        return 'Technical Report';
      }
      case (IncidentInvestigationReportDocumentTypes.EVALUATION): {
        return 'Evaluation Document';
      }
      case (IncidentInvestigationReportDocumentTypes.CAA_SUBMISSION): {
        return 'Proof of Submission';
      }
      default: {
        return 'Supporting file';
      }
    }
  }

  onViewDocument(document) {
    let modalRef;
    modalRef = this.modalService.open(DocumentPreviewModalComponent, {class:'modal-fullscreen modal-dialog-scrollable'});
    modalRef.content.src = document.file;
    modalRef.content.title = document.title;
    modalRef.content.title = `Proof of Submission to CAA`;
  }
  onClickDeleteFile(file) {
    this.deleteItem.emit(file)
  }
}
