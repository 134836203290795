import { Component, HostBinding, Input } from '@angular/core';
import { PointOfInterestType } from '../../../api/interface/api.enums';

@Component({
  selector: 'app-point-of-interest-icon',
  templateUrl: './point-of-interest-icon.component.html'
})
export class PointOfInterestIconComponent {
  @Input() type: PointOfInterestType;
  @Input() size = 16;
  @Input() padding = 0;
  @Input() imgAlt: string;

  public PointOfInterestType = PointOfInterestType;

  @HostBinding('style.padding')
  get paddingStyle() {
    return `${this.padding}px`;
  }
}
