import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { SubscriptionApiService } from './subscription-api.service';
import { ROCState } from '../../../../store/states';
import { UserLinkedROCMapped } from '../../../../store/models';
import { SubscriptionStatus, SubscriptionTiers } from '../../../../../../shared/api/interface/api.enums';
import { HttpParams } from '@angular/common/http';
import { MySubscription } from '../../../../../../shared/api/interface/api.models';
import { map, tap} from 'rxjs/operators';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  params = new HttpParams();
  mySubscriptions$ = new BehaviorSubject<MySubscription[]>(null);

  public currentSubscription$: Observable<MySubscription>;
  public pendingSubscription$: Observable<MySubscription>;
  public isEligibleToSubscribe$: Observable<boolean>;
  public subscriptionIsTrial$: Observable<boolean>;

  constructor(
    private subscriptionApiService: SubscriptionApiService,
  ) {
    this.getMyCurrentSubscription();
    this.getPendingSubscription();
    this.getIsEligibleToSubscribe();
    this.subscriptionIsTrial();
  }

  private getMyCurrentSubscription() {
    this.currentSubscription$ = this.mySubscriptions$.pipe(
      map((subscriptions: MySubscription[]) => {
        if (subscriptions?.length) {
          // Data expectation from BE: EXPIRED subscriptions never return.
          // Expecting (only one of) either ACTIVE, CANCELLED or PAYMENT_FAILED
          return subscriptions?.find(s => s.status !== SubscriptionStatus.PENDING)
        } else {
          return {} as MySubscription
        }
      })
    )
  }

  private subscriptionIsTrial():void {
    this.subscriptionIsTrial$ = this.currentSubscription$.pipe(
      map( data => {
        return data?.package_details.tier === SubscriptionTiers.TRIAL
      })
    )
  }

  private getPendingSubscription() {
    this.pendingSubscription$ = this.mySubscriptions$.pipe(
      map((subscriptions: MySubscription[]) => {
        if (subscriptions?.length) {
          // Expecting only one of type PENDING if there is one.
          return subscriptions?.find(s => s.status === SubscriptionStatus.PENDING)
        } else {
          return {} as MySubscription
        }
      })
    )
  }

  private getIsEligibleToSubscribe() {
    this.isEligibleToSubscribe$ = this.currentSubscription$.pipe(
      map( subscription => {
        if (subscription.id) {
          const sixMonthsFromToday = moment(new Date()).add(6, 'M');
          const endDate = moment(new Date(subscription?.expiry_date));
          return endDate < sixMonthsFromToday;
        } else {
          return true
        }
      })
    )
  }

  getMySubscriptions(): Observable<MySubscription[]> {
    return this.queryMySubscriptions();
  }

  public queryMySubscriptions() {
    return this.subscriptionApiService.getMySubscription(this.params).pipe(
      tap( (subscription: MySubscription[]) => {
        this.mySubscriptions$.next(subscription)
      })
    )
  }

  getSubscriptionInvoices() {
    return this.subscriptionApiService.getSubscriptionInvoices(this.params)
  }

  getSubscriptionInvoice(params, id) {
    return this.subscriptionApiService.downloadSubscriptionInvoice(params, id)
  }
}
