import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AppState } from './store/states';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { CollapseSideNav, OpenSideNav } from './store/actions';
import { EnumsService } from '@bo/ng-enums';
import { JwtRenewService } from '@bo/ng-auth';
import { Title } from '@angular/platform-browser';
import { ModalService } from './shared/services/modal.service';

import { EnumMapping } from './shared/api/interface/api.enum-types';

import * as Enums from './shared/api/interface/api.enums';
import { environment } from '../environments/environment';
import { EnumLabelService } from './shared/services/enum-label.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit, OnDestroy {
  private appName = environment.appName;

  @Select(AppState.sideNavOpen) sideNavOpen$: Observable<boolean>;
  @HostBinding('class.sidenav-open') sideNavOpen: boolean;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  windowWidth: any;

  constructor(
    private store: Store,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private jwtService: JwtRenewService,
    private enumsService: EnumsService,
    private enumLabelService: EnumLabelService,
  ) {
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): void {

    // start JWT service
    this.jwtService.start();

    // Change the page title once the route change was successful.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      const title = data.title ? `${data.title} - ${this.appName}` : this.appName;
      this.titleService.setTitle(title);
    });

    // Scroll to top of page on navigation end, close side nav and set selected site.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      debounceTime(50)  // Prevent multiple state changes.
    ).subscribe(() => {
      window.scrollTo(0, 0);
    });

    // Close all modals on start of route changes.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
    ).subscribe(() => this.modalService.closeAll());

    // Scroll to top of page on navigation end, close side nav and set selected site.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      debounceTime(50)  // Prevent multiple state changes.
    ).subscribe(() => {
      window.scrollTo(0, 0);
    });

    // Check for side nav being open
    this.sideNavOpen$.subscribe(sideNavOpen => this.sideNavOpen = sideNavOpen);

    // Cache enums from the backend enums api endpoint, and check that all expected enums are provided.
    Object.values(EnumMapping).forEach(value => {
      this.enumsService.getEnum(value).subscribe(
        result => {
          if (!result) {
            console.warn('Enum matching "%s" was not detected in the cached enums.', value);
          }
        }
      );
    });

    // Validate API Enum
    this.validateApiEnums();

    // Close side-nav as default if we're on mobile or smaller screen.
    const ua = navigator.userAgent;
    this.windowWidth = window.innerWidth;
    if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) && this.windowWidth <= 992) {
      this.store.dispatch(new CollapseSideNav());
      window.alert('Flyte Zone: This platform is not fully supported yet for viewing on a mobile or smaller devices. ' +
        'For the best experience, please use a desktop or larger device.');
    }
    if (this.windowWidth <= 992) {
      this.store.dispatch(new CollapseSideNav());
    }

    // Collapse side nav on resize for smaller screens.
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe( () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 992) {
        this.store.dispatch(new CollapseSideNav());
      } else {
        this.store.dispatch(new OpenSideNav());
      }
    });
  }

  ngOnDestroy(): void {
    this.jwtService.stop();
    this.resizeSubscription$.unsubscribe();
  }

  // Check that members from the enum api are expected in the frontend in api.enums file are present in the cached enums.
  validateApiEnums() {
    Object.keys(Enums).forEach(moduleKey => {
        // for each enum module key in the api enums interface file
        const typesKey = EnumMapping[moduleKey];
        if (typesKey) {
          this.enumsService.getEnum(typesKey).subscribe(result => {
            // for each enum in the api enums interface file
            Object.values(Enums[moduleKey]).forEach(strValue => {
              // check that api enum name matches the interface file string value
              if (result && !result.filter((member) => member.name === strValue).length) {
                console.warn(
                  'Expected interface member "%s" of enum type "%s" was not detected in the cached enums.', strValue, moduleKey
                );
              } else if (result) {
                // Cache enum label
                const enumDef = result.find(e => e.name === strValue);
                if (enumDef) {
                  this.enumLabelService.set(strValue as string, enumDef.label);
                }
              }
            });
          });
        }
      }
    );
  }

}
