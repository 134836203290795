import { Component, forwardRef, OnInit } from '@angular/core';
import { FieldComponent } from '../field/field.component';
import { FieldPathDirective, FormFieldComponent, FormOptionsDirective } from '@bo/ng-forms';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '../../../api/services/api.service';

@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: [],
  providers: [
    { provide: FormFieldComponent, useExisting: forwardRef(() => CitySelectComponent) }
  ]
})
export class CitySelectComponent extends FieldComponent implements OnInit {

  citiesList = [];
  loading = true;

  constructor(
    protected formOptionsDirective: FormOptionsDirective,
    protected fieldPathDirective: FieldPathDirective,
    protected apiService: ApiService,
  ) {
    super(formOptionsDirective, fieldPathDirective);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.apiService.getCities().pipe(
      map( result => result.map( item => ({
          id: item.id,
          display_name: item.city_name + ', ' + item.province_name + ', ' + item.country_name,
        })
      )),
      tap( () => {
        this.loading = false;
      })
    ).subscribe(result => this.citiesList = result);
  }

}
