import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { InFlightLandingType, InFlightTakeoffType,
  PointOfInterestType } from '../../../api/interface/api.enums';
import { Coordinate, ZoneMarkerType } from '../../google-maps/google-maps.model';

@Component({
  selector: 'app-point-display',
  templateUrl: './point-display.component.html'
})

export class PointDisplayComponent implements OnInit, OnChanges {

  @Input() takeoffType: InFlightTakeoffType;
  @Input() landingType: InFlightLandingType;
  @Input() zoneMarkerType: ZoneMarkerType;
  @Input() incidentMarker: boolean;
  @Input() hazardMarker: boolean;
  @Input() title: string;
  @Input() coordinates: Coordinate | string | number;
  @Input() coordinatesDMS: string;
  @Input() disableSwop: boolean;
  @Input() button: boolean;
  @Input() btnTitle: string;
  @Input() poiType: PointOfInterestType;
  @Input() breakPointString: boolean;

  img: string;
  imgAlt: string;

  ngOnInit() {
    this.getTakeOffTitle(this.takeoffType);
    this.getLandingTitle(this.landingType);
    this.getZoneMarkerTitle(this.zoneMarkerType);
  }

  ngOnChanges() {
    this.getTakeOffTitle(this.takeoffType);
    this.getLandingTitle(this.landingType);
    this.getZoneMarkerTitle(this.zoneMarkerType);
  }

  getTakeOffTitle(type) {
    switch (type) {
      case (InFlightTakeoffType.PLANNED): {
        this.setTitles('Take-off', 'takeoff');
        break;
      }
      case (InFlightTakeoffType.ALTERNATIVE): {
        this.setTitles('Take-off (Alternative)', 'takeoff-custom');
        break;
      }
      default: break;
    }
  }

  getLandingTitle(type) {
    switch (type) {
      case (InFlightLandingType.PLANNED): {
        this.setTitles('Landing', 'landing');
        break;
      }
      case (InFlightLandingType.ALTERNATIVE): {
        this.setTitles('Landing (Alternative)', 'landing-custom');
        break;
      }
      case (InFlightLandingType.EMERGENCY): {
        this.setTitles('Emergency Landing', 'emergency');
        break;
      }
      default: break;
    }
  }

  getZoneMarkerTitle(type) {
    switch (type) {
      case (ZoneMarkerType.TAKEOFF): {
        this.setTitles(ZoneMarkerType.TAKEOFF, 'takeoff');
        break;
      }
      case (ZoneMarkerType.LANDING): {
        this.setTitles(ZoneMarkerType.LANDING, 'landing');
        break;
      }
      case (ZoneMarkerType.EMERGENCY_LANDING): {
        this.setTitles(ZoneMarkerType.EMERGENCY_LANDING, 'emergency');
        break;
      }
      default: break;
    }
  }

  private setTitles(typeTitle, imgName) {
    if (!this.title) { this.title = typeTitle; }
    this.img = imgName;
    this.imgAlt = typeTitle;
}
}
