import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeString'
})
export class CapitalizeStringPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
       const words = value.split(' ');
       return words.map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      }).join(' ');
    }
  }

}
