import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthStateModel } from '@bo/ng-auth/lib/store/models';
import {
  AUTH_CONFIG,
  AuthApi,
  AuthConfig,
  JwtHelper,
  Login,
  Logout,
  NavigateToAuthenticatedRootFromUnAuthenticatedGuard,
  RedirectService,
  RefreshJWT,
  RequireLoginFromAuthGuard,
  RequireLoginFromAuthInterceptor,
  SetTokenFromAuthInterceptor
} from '@bo/ng-auth';
import { Inject, Injectable } from '@angular/core';

@State<AuthStateModel>({
  name: 'auth'
})
@Injectable()
export class AuthState implements NgxsOnInit {
  private jwtHelper = new JwtHelper();

  constructor(
    private apiService: AuthApi,
    private redirectService: RedirectService,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
  ) {
  }

  @Selector()
  static token(state: AuthStateModel) {
    return state.token;
  }

  @Selector()
  static username(state: AuthStateModel) {
    return state.username;
  }

  @Selector()
  static loggedIn(state: AuthStateModel): boolean {
    return !!state.token;
  }

  /* Init */

  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    if (state.token && !this.jwtHelper.isTokenValid(state.token)) {
      ctx.setState({});
    }
  }

  /* Commands */

  @Action(Login)
  login({patchState, dispatch}: StateContext<AuthStateModel>, {token, username}: Login) {
    patchState({token, username});
  }

  @Action(SetTokenFromAuthInterceptor)
  setToken({patchState, dispatch}: StateContext<AuthStateModel>, {token}: Login) {
    patchState({token});
  }

  @Action(RefreshJWT)
  refresh({getState, patchState}: StateContext<AuthStateModel>) {
    const state: AuthStateModel = getState();
    return this.apiService.refreshToken(state.token).pipe(
      tap(data => {
        patchState({token: data.token});
      })
    );
  }

  @Action([Logout, RequireLoginFromAuthGuard, RequireLoginFromAuthInterceptor])
  logout({getState, setState, dispatch}: StateContext<AuthStateModel>, {returnToUrl}: Logout) {
    const state: AuthStateModel = getState();
    if (state.token) {
      this.apiService.logout()
        .pipe(take(1))
        .subscribe(() => null, error => {
        if (!(error instanceof HttpErrorResponse)) {
          throw error;
        }
      });
    }
    setState({});
    this.redirectService.redirect(this.authConfig.loginUrl, returnToUrl);
  }

  @Action(NavigateToAuthenticatedRootFromUnAuthenticatedGuard)
  toAuthenticatedRoot() {
    this.redirectService.navigateToReturn();
  }

}
