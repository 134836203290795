import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { UserLinkedROCMapped } from './models';

export interface UserSelectedROCMixin {
  selectedROC$: Observable<UserLinkedROCMapped>;
}

export function AddRocParamsToApiQuery<T>() {
  return function (_target: any, _propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    const originalMethod = descriptor.value as (queryParams: HttpParams, ...args: any[]) => Observable<T>;
    descriptor.value = function (this: UserSelectedROCMixin, queryParams: HttpParams, ...args: any[]) {
      return this['selectedROC$'].pipe(
        first((roc) => !!roc),
        map(roc => roc),
        switchMap((roc) => {
          queryParams = (queryParams ?? new HttpParams());
          // FIXME: temp hack to clear existing params before adding new ones after switching ROC's
          queryParams = queryParams.delete('remote_operator').delete('roc_user')
          queryParams = queryParams
            .append('remote_operator', roc.id)
            .append('roc_user', roc.roc_user.id);
          return originalMethod.apply(this, [queryParams, ...args]);
        }),
      );
    };
    return descriptor;
  };
}
