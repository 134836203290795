/**
 * Get the distance in Kilometers between two points.
 * @see: https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
 * @param pos1 Point A
 * @param pos2 Point B
 * @returns 7.81
 */
export function distanceBetween(pos1: google.maps.LatLng, pos2: google.maps.LatLng): number {
  const R = 6371; // Radius of the Earth in KM
  const pos1LatRadians = pos1.lat() * (Math.PI / 180); // Convert degrees to radians
  const pos2LatRadians = pos2.lat() * (Math.PI / 180); // Convert degrees to radians
  const latRadiansDiff = pos2LatRadians - pos1LatRadians; // Radian difference (latitudes)
  const lngRadiansDiff = (pos2.lng() - pos1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

  const distance = 2 * R * Math.asin(
    Math.sqrt(
      Math.sin(latRadiansDiff / 2) *
      Math.sin(latRadiansDiff / 2) +
      Math.cos(pos1LatRadians) *
      Math.cos(pos2LatRadians) *
      Math.sin(lngRadiansDiff / 2) *
      Math.sin(lngRadiansDiff / 2)
    )
  );
  return +distance.toFixed(2);
}


/**
 * Calculates the mid-point between two points of a map.
 * @param pos1 Point A
 * @param pos2 Point B
 */
export function midPoint(pos1: google.maps.LatLng, pos2: google.maps.LatLng): google.maps.LatLng {
  return google.maps.geometry.spherical.interpolate(pos1, pos2, 0.5);
}
