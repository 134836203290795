import { Router } from '@angular/router';

import { AuthState, JwtHelper, Logout, RedirectService } from '@bo/ng-auth';
import { EnumsService } from '@bo/ng-enums';

import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import * as Raven from 'raven-js';
import { Observable } from 'rxjs';
import { first, map, shareReplay, tap } from 'rxjs/operators';

import { ApiService } from '../shared/api/services/api.service';
import { ROCUserProfile, UserProfile} from '../shared/api/interface/api.models';
import {
  AbstractSetUserState,
  CollapseSideNav,
  ClearAppState,
  NavigateToDashboardFromRootComponent,
  ReloadAppStateFromInit,
  ReloadAppStateFromLogin,
  ToggleSideNav,
  NavigateToDashboardFromLogin,
  SetUserStateFromNavigateToDashboard,
  SetUserStateFromReloadAppState,
  NavigateToDashboardFromCreateROC, OpenSideNav
} from './actions';
import { AppStateModel } from './models';
import { Injectable } from '@angular/core';
import { AuthApiService } from '../shared/api/services/auth-api.service';

@State<AppStateModel>({
  name: 'app',
  defaults: { sideNavOpen: true, pageTitle: 'Default Title' }
})

@Injectable()
export class AppState implements NgxsOnInit {
  private userProfile$: Observable<UserProfile>;
  private ROCUser$: Observable<ROCUserProfile>;
  private jwtHelper = new JwtHelper();

  constructor(
    private router: Router,
    private store: Store,
    private enumService: EnumsService,
    private apiService: ApiService,
    private authApiService: AuthApiService,
    private redirectService: RedirectService,
  ) {}

  @Selector()
  static sideNavOpen(state: AppStateModel) {
    return state.sideNavOpen;
  }

  @Selector()
  static pageTitle(state: AppStateModel) {
    return state.pageTitle;
  }

  @Selector()
  static userProfile(state: AppStateModel) {
    return state.userProfile;
  }

  ngxsOnInit({dispatch}: StateContext<AppStateModel>) {
    const token = this.store.selectSnapshot(AuthState.token);
    if (token && this.jwtHelper.isTokenValid(token)) {
      dispatch(new ReloadAppStateFromInit());
    }
  }

  @Action(ToggleSideNav)
  toggleSideNav({getState, patchState}: StateContext<AppStateModel>) {
    patchState({sideNavOpen: !getState().sideNavOpen});
  }

  @Action(CollapseSideNav)
  collapseSideNav({patchState}: StateContext<AppStateModel>) {
    patchState({sideNavOpen: false});
  }

  @Action(OpenSideNav)
  openSideNav({patchState}: StateContext<AppStateModel>) {
    patchState({sideNavOpen: true});
  }

  @Action([
    NavigateToDashboardFromLogin,
    NavigateToDashboardFromRootComponent,
    NavigateToDashboardFromCreateROC
  ])
  navigateToDashboardFromLogin({dispatch}: StateContext<AppStateModel>) {
    return this.store.select(AppState.userProfile).pipe(first()).pipe(
      tap((_) => {
        dispatch(new SetUserStateFromNavigateToDashboard());
        this.redirectService.navigateToReturn();
      }),
    );
  }

  @Action([
    SetUserStateFromNavigateToDashboard,
    SetUserStateFromReloadAppState
  ])
  setUserProfile({patchState}: StateContext<AppStateModel>, {userProfile}: AbstractSetUserState) {

    function patchUser(accountDetails: UserProfile) {
      patchState({userProfile: accountDetails});
      Raven.setUserContext({username: accountDetails.email});
    }

    if (userProfile) {
      patchUser(userProfile);
    }

    this.userProfile$ = this.apiService.getUserProfile().pipe(
      tap(profileData => {
        patchUser(profileData);
      }),
      shareReplay(1)
    );
    return this.userProfile$;
  }

  @Action([
    ReloadAppStateFromInit,
    ReloadAppStateFromLogin,
  ])
  reloadAppState({dispatch}: StateContext<AppStateModel>) {
    return dispatch(new ClearAppState()).pipe(
      map(() => {
        dispatch(new SetUserStateFromReloadAppState());
      })
    );
  }

  @Action([ClearAppState])
  clearAppState({setState}: StateContext<AppStateModel>) {
    this.enumService.clearCache();
    this.userProfile$ = undefined;
    this.ROCUser$ = undefined;
    setState({sideNavOpen: true, pageTitle: 'Default Title'});
    Raven.setUserContext({username: null});
  }

  @Action(Logout)
  logout({dispatch}: StateContext<AppStateModel>) {
    this.authApiService.logout().subscribe(() => {
      return this.router.navigate(['login']);
    });
  }
}
