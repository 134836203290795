import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthApiService } from 'src/app/shared/api/services/auth-api.service';
import { AppToastrService } from 'src/app/shared/services/toaster.service';
import { AppFormComponent } from 'src/app/shared/components/forms/app-form-component';
import { Method } from '@bo/ng-api';
import { Observable, of } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ConfigService } from '../../../../shared/api/services/config.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent extends AppFormComponent<any> implements OnInit{
  protected fields = ['first_name', 'last_name', 'email', 'password', 'password2', 'privacy_policy', 'terms_and_conditions',
    'terms_of_use', 'paia_manual' ];

  protected password = '';
  protected password2 = '';
  invitationPK: string;
  protected email: string;
  baseUrl: string;
  accountType: string;
  submitText: string;

  constructor(
    protected authService: AuthApiService,
    protected toastrService: AppToastrService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    private config: ConfigService,
    private titleService: Title,
    private meta: Meta,
  ) {
    super();
    this.accountType = this.activatedRoute.snapshot.queryParamMap.get('type');
    this.invitationPK = this.activatedRoute.snapshot.queryParamMap.get('invitation_pk');
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
  }

  ngOnInit() {
    this.baseUrl = this.config.BASE_URL;
    this.submitText = this.accountType === 'roc' ? 'Next' : 'Create Account';
    this.titleService.setTitle('Sign Up Details | Sign Up | Flyte Zone');
    this.meta.updateTag({
      name: 'description',
      content: 'Sign up for your Flyte Zone profile as a pilot or ROC. Provide accurate and up-to-date information to optimise your experience.'
    });
    super.ngOnInit();
  }

  createForm(): void {
    const initialFormData = {
      email: this.email
    };
    const optionsURL = this.authService.URL_REGISTRATION;
    this.subscriptions.add(
      this.buildFromOptions(
        this.authService.getOptions(optionsURL, Method.POST),
        of(initialFormData as any)
      ).subscribe(),
    );
  }

  submitData(formData): Observable<any> {
    formData.is_admin = this.accountType === 'roc';
    formData.is_pilot = this.accountType === 'pilot';
    formData.is_rmt = this.accountType === 'rmt';
    if (this.invitationPK) {
      formData.invitation_pk = this.invitationPK;
    }
    const registrationData = {
      ...formData,
      newsletter_subscription: false, // Newsletter subscription removed before launch. BE still required field, hardcoded to false.
    };
    return this.authService.register(registrationData);
  }

  handleSubmitSuccess() {
    if (this.invitationPK) {
      this.toastrService.success('Sign Up has been completed. Please proceed to sign in.');
      this.router.navigate(['login'], {queryParams: { invitationPK: this.invitationPK}});
    } else {
      this.toastrService.success('Your account has been created. Please check your inbox to verify your email address.');
      this.router.navigate(['email-verification'], {queryParams: {email: encodeURI(this.form.value.email)}});
    }
  }

  onCancel() {
    const title = 'Cancel Sign Up';
    let message: string;
    if (this.invitationPK) {
      message = 'You need to complete this step in order to on board. Are you sure you want to cancel Sign Up?';
    } else {
      message = 'Are you sure you want to cancel Sign Up?';
    }
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.content.lightTheme = true;
    modalRef.content.heading = title;
    modalRef.content.message = `${message}`;
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.router.navigate(['sign-up', 'select-account-type']);
      }
    });
  }

}
