import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeToSentenceCase'
})
export class SnakeToSentenceCasePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      return value.charAt(0).toUpperCase() + value.toLowerCase().replace(/_/g, ' ').slice(1);
    }
  }

}
