import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ApiService } from '../../../api/services/api.service';
import { AppToastrService } from '../../../services/toaster.service';
import { DocumentService } from '../../../../modules/roc/pages/documents/services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ModalService } from '../../../services/modal.service';
import { EnumsService } from '@bo/ng-enums';
import { Observable, Subscription } from 'rxjs';
import { Document, Drone, ROCCrewMember, UserProfile } from '../../../api/interface/api.models';
import { HttpParams } from '@angular/common/http';
import { DocumentPreviewModalComponent } from '../../modals/document-preview-modal/document-preview-modal.component';
import { ConfirmationRemoveModalComponent } from '../../modals/confirmation-remove-modal/confirmation-remove-modal.component';
import { DocumentUploadModalComponent } from '../../modals/upload-modal/upload-document-modal.component';
import moment from 'moment';
import { DocumentMapped } from '../../../../modules/roc/pages/documents/services/document.models';
import { OperationApiService } from '../../../../modules/roc/pages/operations/services/operation-api.service';
import { ArchiveDocumentModalComponent } from '../../../../modules/roc/pages/documents/expiring-documents/archive-modal/archive-modal.component';
import { GearTypes } from '../../../api/interface/api.enums';
import { GearApiService } from '../../../../modules/roc/pages/gear/services/gear-api.service';
import { AppState } from '../../../../store/states';
import { ROCState } from '../../../../modules/roc/store/states';
import { UserLinkedROCMapped } from '../../../../modules/roc/store/models';
import { SnakeToSentenceCasePipe } from '../../../pipes/snake-to-sentence-case.pipe';
import { EnumLabelService } from '../../../services/enum-label.service';

@Component({
  selector: 'app-grouped-documents',
  templateUrl: './grouped-document-table.component.html'
})
export class GroupedDocumentTableComponent implements OnInit, OnDestroy {
  @Select(AppState.userProfile) userProfile$: Observable<UserProfile>;
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  @ViewChild('filterName') filterName: ElementRef;
  @ViewChild('table') table: any;

  subscriptions = new Subscription();
  collapseInfo = true;

  pageHeader: string = 'Documents';
  pageTitle: string;
  promptForAutoArchive: boolean;
  showRemainderColumn: boolean;
  showUploadButton: boolean;
  returnArchived: boolean;
  showAllDocsInfo: boolean;
  userRolesHasMeetingMinutes: boolean;

  //data-table vars
  tempData = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  isLoading = true;
  hideClearBtn = true;
  data: DocumentMapped[] = [];
  expanded = {}

  crewMembers: ROCCrewMember[];
  userPermissionAccess: boolean;
  userId: number;
  rocId: number;
  rocUserId: number;
  params = new HttpParams();
  expiryReminderSetting: number;
  droneParams = this.params.append('gear_type', GearTypes.DRONE).append('resourcetype', 'Drone');

  constructor(
    protected store: Store,
    protected apiService: ApiService,
    protected toastrService: AppToastrService,
    protected documentService: DocumentService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected modalService: ModalService,
    protected enumService: EnumsService,
    protected operationApiService: OperationApiService,
    protected gearApiService: GearApiService,
    protected enumLabelService: EnumLabelService,
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.selectedROC$.subscribe(selectedROC => {
        if (selectedROC) {
          this.rocId = selectedROC.id;
          this.rocUserId = selectedROC.roc_user.id;
          this.userRolesHasMeetingMinutes =
            selectedROC.is_admin ||
            selectedROC.user_role.has_safety_officer_role ||
            selectedROC.user_role.has_quality_officer_role;
          this.loadRocUserDocuments();
        } else {
          this.loadUserDocuments()
        }
      })
    )
    this.subscriptions.add(
      this.userProfile$.subscribe(userProfile => {
        if (userProfile) {
          this.userId = userProfile.id;
        }
      })
    )
    this.subscriptions.add(
      this.apiService.getUserSettings().subscribe( settings => {
        this.expiryReminderSetting = settings.document_reminder_period
      })
    )
  }

  loadRocUserDocuments() { }

  loadUserDocuments() { }

  calcRemainder(expDate) {
    const today = moment(new Date());
    // TODO: this is different from the other views - update to 23h59min to set end of day or undo the others
    // TODO: BE to confirm behaviour
    expDate = new Date(expDate).setHours(23, 59);
    const diff = moment(expDate).diff(today, 'minutes')
    let inp = new Date(0, 0, 0, 0, diff, 0); // assumes minutes as an input
    let m = inp.getMinutes();
    let h = inp.getHours();
    let d = inp.getDay();
    if (+diff > 1) {
      return `${d > 0 ? d+'d' : '' } ${h > 0 ? h+'h' : '' } ${m}m`;
    } else return null
  }

  calcRemainderFlag(expDate) {
    const today = moment(new Date());
    expDate = new Date(expDate).setHours(23, 59);
    const minutesRemaining = this.expiryReminderSetting * 60 * 24;
    const diff = moment(expDate).diff(today, 'minutes');
    if (+diff > 1) {
      return diff < minutesRemaining;
    }
  }

  getCrewMember(id, crewMembers: ROCCrewMember[]) {
    const crewMember = crewMembers.find(member => member.id === id);
    return {
      first_name: crewMember?.first_name,
      last_name: crewMember?.last_name,
      avatar: crewMember?.avatar
    }
  }

  getDrone(id, dronesList: Drone[]) {
    const drone = dronesList.find(drone => drone.id === id);
    return drone?.registration_reference;
  }

  public updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter & update the rows
    this.data = this.tempData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.hideClearBtn = !(!!val)
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0
    if (event.key === "Escape") {
      this.clearFilter();
    }
  }

  public onViewDocument(document) {
    let modalRef;
    modalRef = this.modalService.open(DocumentPreviewModalComponent, {class:'modal-fullscreen modal-dialog-scrollable'});
    modalRef.content.src = document.file;
    modalRef.content.title = document.title;
    modalRef.content.type = document.type;
  }

  public deleteDocument(document: any) {
    const modalRef = this.modalService.open(ConfirmationRemoveModalComponent);
    modalRef.content.heading = `Remove "${document.title}"`;
    modalRef.content.action = `remove this document`;
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.documentService.deleteDocument(document).subscribe(
          {
            next: () => {
              this.rocId ? this.loadRocUserDocuments() : this.loadUserDocuments();
            },
            complete: () => {
              this.toastrService.success(`"${document.title}" has been removed.`);
            },
            error: () => {
              this.toastrService.error(`An error occurred while removing "${document.title}".`);
            }
          }
        );
      }
    });
  }

  public onUploadDocument(document: Document) {
    const documentTitle = this.enumLabelService.get(document.file_type) ?? new SnakeToSentenceCasePipe().transform(document.file_type);
    let modalRef, modalRefArchive
    modalRef = this.modalService.open(DocumentUploadModalComponent, {
      class: 'modal-lg',
      initialState: {
        fileType: document.file_type,
        hideFileTypeField: true,
        documentResourceType: document.resourcetype,
        droneId: document.drone,
        userId: document.uploaded_for,
        titleField: documentTitle,
      }
    });
    modalRef.content.onClose.subscribe( onClose => {
      if (onClose) {
        if (this.promptForAutoArchive) {
          modalRefArchive = this.modalService.open(ArchiveDocumentModalComponent);
          modalRefArchive.content.onClose.subscribe(onClose => {
            if (onClose) {
              this.documentService.archiveDocument(document).subscribe({
                next: () => {
                  this.rocId ? this.loadRocUserDocuments() : this.loadUserDocuments();
                },
                complete: () => {
                  this.toastrService.success(`"${document.title}" has been archived.`);
                },
                error: () => {
                  this.toastrService.error(`An error occurred while archiving "${document.title}".`);
                }
              });
            }
          })
        } else {
          this.rocId ? this.loadRocUserDocuments() : this.loadUserDocuments();
        }
      }
    });
  }

  public archiveDocument(document: any) {
    const modalRef = this.modalService.open(ConfirmationRemoveModalComponent);
    modalRef.content.heading = `Archive "${document.title}"`;
    modalRef.content.action = `archive this document`;
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.documentService.archiveDocument(document).subscribe(
          {
            next: () => {
              this.rocId ? this.loadRocUserDocuments() : this.loadUserDocuments();
            },
            complete: () => { this.toastrService.success(`"${document.title}" has been archived.`); },
            error: () => { this.toastrService.error(`An error occurred while archiving "${document.title}".`); }
          }
        );
      }
    });
  }

  public clearFilter(){
    this.data = this.tempData;
    this.filterName.nativeElement.value = '';
    this.hideClearBtn = true;
  }

  toggleExpandGroup(group) {
    this.table.groupHeader.toggleExpandGroup(group);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
