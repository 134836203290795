import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sentry-test',
  template: '<h1>Sentry Works</h1>'
})
export class SentryTestComponent implements OnInit {

  ngOnInit(): void {
    console.error('Testing Sentry error logging.');

    throw Error('Testing Sentry exception handling.');
  }

}
