import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt-box.component.html'
})
export class PromptBoxComponent {
  @Input() boxStyle?: string;
  @Input() title: string;
  @Input() iconClass: string = 'bi bi-cloud-upload';
  @Input() url?: string;
  @Input() linkText?: string;
  @Input() subText?: string;
}
