import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { combineLatest, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { GoogleMapsValidationService, MapObjectValidation } from './service/google-maps-validation.service';
import {
  circleFactory,
  circleOptionsFactory,
  loadKmlLayer,
  markerFactory,
  markerOptionsFactory,
  polygonFactory,
  polygonOptionsFactory,
  polylineFactory,
  polylineOptionsFactory,
  searchMapZoom,
} from './map-helpers';
import {
  ChangeMapObjectIdentifier,
  Circle,
  CircleFieldChange,
  DeleteMapObject,
  EditableShape,
  FieldChange,
  MapObjectIdentifier,
  mapObjectIdentifierComparator,
  MapObjectProperties,
  MapSettings,
  Marker,
  MarkerFieldChange,
  mergeProperties,
  PolyFieldChange,
  Polygon,
  Polyline
} from './google-maps.model';
import { latLngBoundingBox, parsePathToLatLngLiteralArr, parsePointToLatLng } from './utils/value-utils';
import { debounceTime, skip, skipUntil, take, takeUntil, takeWhile } from 'rxjs/operators';
import { AtnsService } from '../../services/atns.service';
import { OperationService } from '../../../modules/roc/pages/operations/services/operation.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  providers: [GoogleMapsValidationService]
})
export class GoogleMapsComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  @ViewChild('googleMap') public mapElement: ElementRef;

  @Input() readonly = false;
  @Input() mapSettings$ = new ReplaySubject<MapSettings>(1);
  @Input() savedMapSettings$ = new ReplaySubject<MapSettings>(1);
  @Input() searchLocation$ = new Subject<string>();
  @Input() drawingMode$ = new Subject<FieldChange>();
  @Input() changeAppearance$ = new Subject<FieldChange>();
  @Input() focus$ = new Subject<MapObjectIdentifier>();
  @Input() changeIdentifier$ = new Subject<ChangeMapObjectIdentifier>();
  @Input() deleteShape$ = new Subject<DeleteMapObject>();
  @Input() circleFieldChange$ = new Subject<CircleFieldChange>();
  @Input() polygonFieldChange$ = new Subject<PolyFieldChange>();
  @Input() polylineFieldChange$ = new Subject<PolyFieldChange>();
  @Input() markerFieldChange$ = new Subject<MarkerFieldChange>();

  // Outbound subject for when the map has finished loading
  @Output() loaded$ = new Subject<google.maps.Map>();
  @Output() mapSettingsChange$ = new ReplaySubject<MapSettings>();

  @Output() invalidAirspaceTypes$ = new Subject<MapObjectValidation[]>();

  // Circle
  @Output() circleChange$ = new Subject<Circle>();
  /** Holds a references to the circles on the map. */
  circles: Circle[] = [];

  // Polygon
  @Output() polygonChange$ = new Subject<Polygon>();
  polygons: Polygon[] = [];

  // Polyline
  @Output() polylineChange$ = new Subject<Polyline>();
  polylines: Polyline[] = [];

  // Markers
  @Output() markerChange$ = new Subject<Marker>();
  /** Holds a references to the markers on the map. */
  markers: Marker[] = [];

  /** Holds the name and type of the map object the user is interacting with, used for creation. */
  @Output() selectedShape$ = new Subject<MapObjectIdentifier>();
  selectedMapObjectIdentifier: MapObjectIdentifier;

  // Map
  map: google.maps.Map;
  drawingManager: google.maps.drawing.DrawingManager;
  selectedShape: EditableShape;
  collapseMessages = false;

  // Loading indicators
  loading = true;
  mapLoaded$ = new Subject<void>();
  kmlLayersLoaded$ = new Subject<void>();
  operationId: Observable<number>;

  constructor(
    private validationService: GoogleMapsValidationService,
    private readonly atnsService: AtnsService,
    private operationService: OperationService,
  ) {}

  ngOnInit() {
    this.operationId = this.operationService.getOperationId();
  }

  ngAfterViewInit() {
    this.initMap();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.validationService.destroy();
    this.map = null;
  }

  setSelectedMapObjectIdentifier(value: MapObjectIdentifier, publish = false) {
    this.selectedMapObjectIdentifier = value;
    if (publish) {
      this.selectedShape$.next(value);
    }
  }

  // ------------------------------------------------------- Map -------------------------------------------------------

  initMap() {
    const loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places', 'drawing', 'geometry']
    });

    loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('googleMap') as HTMLElement, {
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoomControl: true,
      });

      this.initMapLoadedEvent();
      this.initMapSettingsSubscription();
      this.initSearchLocationSubscription();

      // Validation
      this.initMapKmlLayers();
      this.initAirspaceValidationSubscription();

      // Drawing
      this.initDrawingManager();
      this.initDrawingModeSubscription();
      this.initMapKeys();
      // Markers
      this.initMarkerFieldChangeSubscription();
      // Circle
      this.initCircleFieldChangeSubscription();
      // Polygon
      this.initPolygonFieldChangeSubscription();
      // Polyline
      this.initPolylineFieldChangeSubscription();
      // Shape
      this.initChangeAppearanceSubscription();
      this.initFocusSubscription();
      this.initChangeIdentifierSubscription();
      this.initDeleteShapeSubscription();

      // Loading indicators
      combineLatest( [this.mapLoaded$, this.kmlLayersLoaded$, this.mapSettings$])
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(([,, mapSettings]) => {
          this.map.panTo(mapSettings.coordinates);
          this.map.setZoom(mapSettings.zoom);
          this.loading = false;
          this.loaded$.next(this.map);
        });
    });
  }

  private initMapLoadedEvent() {
    // When the map has loaded only check once on startup. This event fires when the map navigation changes.
    // https://developers.google.com/maps/documentation/javascript/reference/map#Map.tilesloaded
    google.maps.event.addListener(this.map, 'tilesloaded', () => {
      this.mapLoaded$.next();
    });
  }

  private initMapSettingsSubscription() {
    const centerChanged$ = new Subject<void>();
    const zoomChanged$ = new Subject<void>();

    // https://developers.google.com/maps/documentation/javascript/reference/map#Map.center_changed
    google.maps.event.addListener(this.map, 'center_changed', () => {
      centerChanged$.next();
    });

    // https://developers.google.com/maps/documentation/javascript/reference/map#Map.zoom_changed
    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      zoomChanged$.next();
    });

    this.zoomToSavedLocation();

    combineLatest([centerChanged$, zoomChanged$])
      .pipe(
        skip(1), // Skips the initial random map configuration
        skipUntil(this.loaded$), // Also waits until the map has loaded
        debounceTime(200),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.mapSettingsChange$.next({
          coordinates: this.map.getCenter(),
          zoom: this.map.getZoom()
        });
      });

    this.mapSettings$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mapSettings) => {
        this.map.panTo(mapSettings.coordinates);
        this.map.setZoom(mapSettings.zoom);
      });
  }

  zoomToSavedLocation(){
    this.savedMapSettings$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((mapSettings) => {
      const coordinates = mapSettings.coordinates;
      const zoom  = mapSettings.zoom;
      this.map.panTo(coordinates);
      this.map.setZoom(zoom);
    });
  }

  private initSearchLocationSubscription() {
    this.searchLocation$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((query) => {
        const request = {
          fields: ['name', 'geometry'],
          query,
          locationBias: latLngBoundingBox(this.map.getCenter())
        } as google.maps.places.FindPlaceFromQueryRequest;
        const service = new google.maps.places.PlacesService(this.map);

        service.findPlaceFromQuery(request,
          (
            results: google.maps.places.PlaceResult[] | null,
            status: google.maps.places.PlacesServiceStatus
          ) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              const coordinates = results[0].geometry.location;
              this.mapSettings$.next({
                coordinates,
                zoom: searchMapZoom
              });
            }
          }
        );
      });
  }

  /**
   * Loads the KML Layers onto the map displaying FAD, FAR, and FAP areas.
   */
  private initMapKmlLayers() {
    this.atnsService.getKMLLayers().subscribe(kmlFiles => {
      const obs$ = [...kmlFiles.map(kmlFile => loadKmlLayer(this.map, kmlFile.file))];
      merge(obs$).subscribe({
        complete: () => {
          this.kmlLayersLoaded$.next();
        }
      });
    });
  }

  private initAirspaceValidationSubscription() {
    this.validationService.invalidAirspaceTypes$
      .pipe(
        takeWhile(() => !this.readonly), // emit values unless in readonly mode
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(val => {
        this.invalidAirspaceTypes$.next(val);
      });
  }

  get validationMessage$(): Subject<string> {
    return this.validationService.validationMessage$;
  }

  // ----------------------------------------------------- Drawing -----------------------------------------------------

  /**
   * Creates a drawing manager attached to the map that allows the user to place markers, draw lines, and shapes.
   */
  private initDrawingManager(properties?: MapObjectProperties) {
    properties = properties !== null ? properties : {};
    this.drawingManager = new google.maps.drawing.DrawingManager({
      map: this.map,
      drawingControl: false,   // drawing control visibility
      drawingMode: null,       // selected drawing tool
      drawingControlOptions: {
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.POLYLINE,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.CIRCLE,
        ]
      },
      rectangleOptions: { visible: false }, // We don't support this
      markerOptions: markerOptionsFactory(properties),
      polylineOptions: polylineOptionsFactory(properties),
      circleOptions: circleOptionsFactory(properties),
      polygonOptions: polygonOptionsFactory(properties),
    });

    google.maps.event.addListener(this.drawingManager, 'markercomplete', this.handleCreateMarker);

    google.maps.event.addListener(this.drawingManager, 'circlecomplete', this.handleCreateCircle);

    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', this.handleCreatePolygon);

    google.maps.event.addListener(this.drawingManager, 'polylinecomplete', this.handleCreatePolyline);

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.overlayComplete);

    google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', this.clearSelectedShape);
  }

  private initDrawingModeSubscription() {
    this.drawingMode$
      .pipe(
        takeWhile(() => !this.readonly), // emit values unless in readonly mode
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(({ identifier, properties }) => {
        this.setSelectedMapObjectIdentifier(identifier);
        this.resetDrawingManager(properties);
        this.drawingManager.setDrawingMode(identifier?.shapeType);
      });
  }

  private clearSelectedDrawingTool() {
    this.drawingManager.setDrawingMode(null);
  }

  /**
   * Discards a shape while it's being drawn.
   */
  resetDrawingManager(properties?: MapObjectProperties) {
    this.drawingManager.setMap(null);
    this.initDrawingManager(properties);
  }

  private initMapKeys() {
    // Handle when the user clicks on the map to deselect a shape.
    google.maps.event.addListener(this.map, 'click', this.clearSelectedShape);

    // Handle Escape and Delete keys on a selected shape.
    google.maps.event.addListener(this.mapElement.nativeElement, 'keyup', (e: KeyboardEvent) => {
      switch (e.code) {
        case 'Escape':
          this.resetDrawingManager();
          this.clearSelectedShape();
          break;
        case 'Delete':
          this.deleteSelectedShape();
          break;
      }
    });
  }

  // ----------------------------------------------------- Markers -----------------------------------------------------

  // Marker tracking
  addMarker(marker: Marker): void {
    this.markers.push(marker);
  }

  removeMarker(identifier: MapObjectIdentifier): void {
    const index = this.markers.findIndex(mapObjectIdentifierComparator(identifier));
    this.markers.splice(index, 1);
  }

  getMarker(identifier: MapObjectIdentifier): Marker {
    return this.markers.find(mapObjectIdentifierComparator(identifier));
  }

  /**
   * Handles events for when the user updates a marker's coordinates.
   * Can remove, update and create markers.
   */
  private initMarkerFieldChangeSubscription() {
    this.markerFieldChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(markerFieldChange => {
        const foundMarker = this.getMarker(markerFieldChange.identifier);
        if (foundMarker) {
          if (markerFieldChange.coordinates === '') {
            this.deleteSelectedMarker(foundMarker.identifier);
          } else {
            foundMarker.setPositionFromString(markerFieldChange.coordinates);
            // Trigger another validation.
            this.validationService.validate(foundMarker, google.maps.drawing.OverlayType.MARKER);
          }
        } else {
          this.createMarkerFromFormField(markerFieldChange);
        }
      });
  }

  /**
   * Recreates markers on the map for when the user decides to edit their location details form fields.
   */
  private createMarkerFromFormField(fieldChange: MarkerFieldChange) {
    this.setSelectedMapObjectIdentifier(fieldChange.identifier);
    const position = parsePointToLatLng(fieldChange.coordinates);
    const properties = mergeProperties(fieldChange, this.readonly);
    const marker = markerFactory(position, this.map, properties);

    this.handleCreateMarker(marker);

    const overlay = {
      overlay: marker,
      type: google.maps.drawing.OverlayType.MARKER
    } as google.maps.drawing.OverlayCompleteEvent;
    this.overlayComplete(overlay, properties);
  }

  // Marker creation via the draw tools
  handleCreateMarker = (mapMarker: google.maps.Marker) => {
    this.removeExistingMarker();
    const marker = this.addNewMarker(mapMarker);

    // Updates/validates the marker position.
    google.maps.event.addListener(mapMarker, 'dragend', (dragged: google.maps.MapMouseEvent) => {
      this.updateMarker(marker, dragged.latLng);
    });

    // Handles showing the marker tooltip.
    // Alias to the 'click' event, however 'mouseup' events handles "long clicks".
    google.maps.event.addListener(mapMarker, 'mouseup', (mouseup: google.maps.MapMouseEvent) => {
      if (this.drawingManager.getDrawingMode() !== null) {
        return;
      }
      // Store the markerName on the class to reference later to allow deletion.
      this.setSelectedMapObjectIdentifier(marker.identifier, true);
      this.updateMarkerTooltip(marker);
    });
  }

  private removeExistingMarker() {
    // Delete the previous marker for a zone, if present.
    // This only allows one marker per zone area: i.e. Take-off.
    const previousMarker = this.getMarker(this.selectedMapObjectIdentifier);
    if (previousMarker) {
      // Removes the marker from the map.
      previousMarker.remove();
      // Cleanup.
      this.validationService.clear(previousMarker);
      this.removeMarker(this.selectedMapObjectIdentifier);
    }
  }

  private addNewMarker(mapMarker: google.maps.Marker): Marker {
    const marker = new Marker(mapMarker, this.selectedMapObjectIdentifier);
    this.addMarker(marker);
    this.markerChange$.next(marker);
    this.validatedMarker(marker);
    return marker;
  }

  private updateMarker(marker: Marker, position: google.maps.LatLng) {
    marker.setPosition(position);
    // Notify the flight zone section that the marker has been updated.
    this.markerChange$.next(marker);
    this.validatedMarker(marker);
  }

  private updateMarkerTooltip(marker: Marker) {
    // close previous marker tooltip
    this.closeAllMarkerTooltips();
    marker.showTooltip();

    google.maps.event.addListener(this.map, 'click', () => {
      this.closeAllMarkerTooltips();
    });
  }

  /**
   * Closes all marker tooltips other than the selected one.
   */
  private closeAllMarkerTooltips(shape?: EditableShape) {
    if (shape?.type === google.maps.drawing.OverlayType.MARKER) {
      return;
    }
    this.markers.forEach(marker => marker.closeTooltip());
  }

  private validatedMarker(marker: Marker) {
    if (this.readonly) {
      return;
    }
    this.validationService.validate(marker, google.maps.drawing.OverlayType.MARKER);
  }

  // Marker deletion
  private deleteSelectedMarker(identifier: MapObjectIdentifier, redrawProperties?: MapObjectProperties) {
    const marker = this.getMarker(identifier);
    if (marker) {
      marker.remove();
      // Notify the flight zone section that the marker has been removed.
      this.markerChange$.next(marker);
      // Cleanup
      this.validationService.clear(marker);
      this.removeMarker(identifier);
    }
    // Change the drawing tool back to marker.
    if (redrawProperties) {
      this.drawingMode$.next({ identifier, properties: redrawProperties });
    }
  }

  // ----------------------------------------------------- Circle ------------------------------------------------------

  // Circle tracking
  addCircle(circle: Circle): void {
    this.circles.push(circle);
  }

  removeCircle(identifier: MapObjectIdentifier): void {
    const index = this.circles.findIndex(mapObjectIdentifierComparator(identifier));
    this.circles.splice(index, 1);
  }

  getCircle(identifier: MapObjectIdentifier): Circle {
    return this.circles.find(mapObjectIdentifierComparator(identifier));
  }

  // Receive updates from the circle fields
  private initCircleFieldChangeSubscription() {
    this.circleFieldChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(circleFieldChange => {
        // Handles existing circle update/deletion
        const foundCircle = this.getCircle(circleFieldChange.identifier);
        if (foundCircle) {
          if (circleFieldChange.coordinates === '' || circleFieldChange.radius === '' ) {
            this.deleteSelectedCircle(foundCircle.identifier);
          } else {
            if (circleFieldChange.coordinates !== '') {
              foundCircle.setCenterFromString(circleFieldChange.coordinates);
            }
            if (circleFieldChange.radius !== '') {
              foundCircle.setRadius(+circleFieldChange.radius);
            }
          }
        } else {
          // Create a new circle
          this.createCircleFromFormField(circleFieldChange);
        }
      });
  }

  private createCircleFromFormField(fieldChange: CircleFieldChange) {
    this.setSelectedMapObjectIdentifier(fieldChange.identifier);
    const center = parsePointToLatLng(fieldChange.coordinates);
    const properties = mergeProperties(fieldChange, this.readonly);
    const circle = circleFactory(+fieldChange.radius, center, this.map, properties);

    this.handleCreateCircle(circle);

    const overlay = {
      overlay: circle,
      type: google.maps.drawing.OverlayType.CIRCLE
    } as google.maps.drawing.OverlayCompleteEvent;
    this.overlayComplete(overlay, properties);
  }

  handleCreateCircle = (mapCircle: google.maps.Circle) => {
    this.removeExistingCircle();
    const circle = this.addNewCircle(mapCircle);

    const circleUpdates$ = new Observable<google.maps.Circle>(observer => {
      google.maps.event.addListener(mapCircle, 'dragend', () => {
        observer.next(mapCircle);
      });
      google.maps.event.addListener(mapCircle, 'center_changed', () => {
        observer.next(mapCircle);
      });
      google.maps.event.addListener(mapCircle, 'radius_changed', () => {
        observer.next(mapCircle);
      });
    });

    // Only emit values after 200ms has passed between the last emission,
    // throw away all other values.
    circleUpdates$.pipe(debounceTime(200)).subscribe((c) => {
      this.updateCircle(circle, mapCircle);
    });

    // Alias to the 'click' event, however 'mouseup' events handles "long clicks".
    google.maps.event.addListener(mapCircle, 'mouseup', (mouseup: google.maps.MapMouseEvent) => {
      // Prevent changing the "selectedMapObject" if the user is drawing on the map.
      if (this.drawingManager.getDrawingMode() !== null) {
        return;
      }
      this.setSelectedMapObjectIdentifier(circle.identifier, true);
      this.updateCircleTooltip(circle);
    });
  }

  private removeExistingCircle() {
    const previousCircle = this.getCircle(this.selectedMapObjectIdentifier);
    if (previousCircle) {
      // Removes the marker from the map.
      previousCircle.remove();
      // Cleanup.
      this.validationService.clear(previousCircle);
      this.removeCircle(this.selectedMapObjectIdentifier);
    }
  }

  private addNewCircle(mapCircle: google.maps.Circle): Circle {
    const circle = new Circle(mapCircle, this.selectedMapObjectIdentifier);
    this.addCircle(circle);
    this.circleChange$.next(circle);
    this.validatedCircle(circle);
    return circle;
  }

  private updateCircle(circle: Circle, mapCircle: google.maps.Circle) {
    circle.setCenter(mapCircle.getCenter());
    circle.setRadius(mapCircle.getRadius());
    this.circleChange$.next(circle);
    this.validatedCircle(circle);
  }

  private updateCircleTooltip(circle: Circle) {
    // close previous circle tooltips
    this.closeAllCircleTooltips();
    circle.showTooltip();

    google.maps.event.addListener(this.map, 'click', () => {
      this.closeAllCircleTooltips();
    });
  }

  /**
   * Closes all circle tooltips other than the selected one.
   */
  private closeAllCircleTooltips(shape?: EditableShape) {
    if (shape?.type === google.maps.drawing.OverlayType.CIRCLE) {
      return;
    }
    this.circles.forEach(circle => circle.closeTooltip());
  }

  private validatedCircle(circle: Circle) {
    if (this.readonly) {
      return;
    }
    this.validationService.validate(circle, google.maps.drawing.OverlayType.CIRCLE);
  }

  private deleteSelectedCircle(identifier: MapObjectIdentifier, redrawProperties?: MapObjectProperties) {
    const circle = this.getCircle(identifier);
    if (circle) {
      circle.remove();
      // Notify the flight zone section that the marker has been removed.
      this.circleChange$.next(circle);
      // Cleanup.
      this.validationService.clear(circle);
      this.removeCircle(identifier);
    }
    // Change the drawing tool back to circle.
    if (redrawProperties) {
      this.drawingMode$.next({ identifier, properties: redrawProperties });
    }
  }

  // ----------------------------------------------------- Polygon -----------------------------------------------------

  // Polygon tracking
  addPolygon(polygon: Polygon): void {
    this.polygons.push(polygon);
  }

  removePolygon(identifier: MapObjectIdentifier): void {
    const index = this.polygons.findIndex(mapObjectIdentifierComparator(identifier));
    this.polygons.splice(index, 1);
  }

  getPolygon(identifier: MapObjectIdentifier): Polygon {
    return this.polygons.find(mapObjectIdentifierComparator(identifier));
  }

  // Receive updates from the polygon fields
  private initPolygonFieldChangeSubscription() {
    this.polygonFieldChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(polyFieldChange => {
        // Handles existing polygon update/deletion
        const foundPolygon = this.getPolygon(polyFieldChange.identifier);
        if (foundPolygon) {
          if (polyFieldChange.paths === '') {
            this.deleteSelectedPolygon(foundPolygon.identifier);
          } else {
            foundPolygon.setPathFromString(polyFieldChange.paths);
            this.handlePolygonPathUpdates(foundPolygon);
          }
        } else {
          // Create a new polygon
          this.createPolygonFromFormField(polyFieldChange);
        }
      });
  }

  createPolygonFromFormField(fieldChange: PolyFieldChange) {
    this.setSelectedMapObjectIdentifier(fieldChange.identifier);
    const path = parsePathToLatLngLiteralArr(fieldChange.paths);
    const properties = mergeProperties(fieldChange, this.readonly);
    const polygon = polygonFactory(path, this.map, properties);

    this.handleCreatePolygon(polygon);

    const overlay = {
      overlay: polygon,
      type: google.maps.drawing.OverlayType.POLYGON
    } as google.maps.drawing.OverlayCompleteEvent;
    this.overlayComplete(overlay, properties);
  }

  handleCreatePolygon = (mapPolygon: google.maps.Polygon) => {
    this.removeExistingPolygon();
    const polygon = this.addNewPolygon(mapPolygon);
    this.handlePolygonPathUpdates(polygon);
  }

  private handlePolygonPathUpdates(polygon: Polygon) {
    const polygonUpdates$ = new Observable<Polygon>(observer => {
      // Event for when the polygon moves a vertex's position.
      google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        observer.next(polygon);
      });
      // Event for when the polygon adds a vertex.
      google.maps.event.addListener(polygon.getPath(), 'insert_at', insertAt => {
        observer.next(polygon);
      });
      // Event for when the polygon removes a vertex.
      google.maps.event.addListener(polygon.getPath(), 'remove_at', setAt => {
        observer.next(polygon);
      });
    });

    polygonUpdates$.pipe(debounceTime(200)).subscribe((p) => {
      this.notifyPolygonChangesAndValidate(polygon);
    });

    // Handles showing the polygon tooltip.
    // Alias to the 'click' event, however 'mouseup' events handles "long clicks".
    google.maps.event.addListener(polygon.getShape(), 'mouseup', (mouseup: google.maps.MapMouseEvent) => {
      if (this.drawingManager.getDrawingMode() !== null) {
        return;
      }
      this.setSelectedMapObjectIdentifier(polygon.identifier, true);
      this.updatePolygonTooltip(polygon);
    });
  }

  private removeExistingPolygon() {
    const previousPolygon = this.getPolygon(this.selectedMapObjectIdentifier);
    if (previousPolygon) {
      // Removes the marker from the map.
      previousPolygon.remove();
      // Cleanup.
      this.validationService.clear(previousPolygon);
      this.removePolygon(this.selectedMapObjectIdentifier);
    }
  }

  private addNewPolygon(mapPolygon: google.maps.Polygon): Polygon {
    const polygon = new Polygon(mapPolygon, this.selectedMapObjectIdentifier);
    this.addPolygon(polygon);
    this.notifyPolygonChangesAndValidate(polygon);
    return polygon;
  }

  private notifyPolygonChangesAndValidate(polygon: Polygon) {
    this.polygonChange$.next(polygon);
    if (this.readonly) {
      return;
    }
    this.validationService.validate(polygon, google.maps.drawing.OverlayType.POLYGON);
  }

  private updatePolygonTooltip(polygon: Polygon) {
    // close previous polygon tooltips
    this.closeAllPolygonTooltips();
    polygon.showTooltip();

    google.maps.event.addListener(this.map, 'click', () => {
      this.closeAllPolygonTooltips();
    });
  }

  /**
   * Closes all polygon tooltips other than the selected one.
   */
  private closeAllPolygonTooltips(shape?: EditableShape) {
    if (shape?.type === google.maps.drawing.OverlayType.POLYGON) {
      return;
    }
    this.polygons.forEach(polygon => polygon.closeTooltip());
  }

  private deleteSelectedPolygon(identifier: MapObjectIdentifier, redrawProperties?: MapObjectProperties) {
    const polygon = this.getPolygon(identifier);
    if (polygon) {
      polygon.remove();
      // Notify the flight zone section that the polygon has been removed.
      this.polygonChange$.next(polygon);
      // Cleanup.
      this.validationService.clear(polygon);
      this.removePolygon(identifier);
    }
    // Change the drawing tool back to polygon.
    if (redrawProperties) {
      this.drawingMode$.next({ identifier, properties: redrawProperties });
    }
  }

  // ----------------------------------------------------- Polyline ----------------------------------------------------

  // Polyline tracking
  addPolyline(polyline: Polyline): void {
    this.polylines.push(polyline);
  }

  removePolyline(identifier: MapObjectIdentifier): void {
    const index = this.polylines.findIndex(mapObjectIdentifierComparator(identifier));
    this.polylines.splice(index, 1);
  }

  getPolyline(identifier: MapObjectIdentifier): Polyline {
    return this.polylines.find(mapObjectIdentifierComparator(identifier));
  }

  // Receive updates from the polyline fields
  private initPolylineFieldChangeSubscription() {
    this.polylineFieldChange$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(polyFieldChange => {
        // Handles existing polyline update/deletion
        const foundPolyline = this.getPolyline(polyFieldChange.identifier);
        if (foundPolyline) {
          if (polyFieldChange.paths === '') {
            this.deleteSelectedPolyline(foundPolyline.identifier);
          } else {
            foundPolyline.setPathFromString(polyFieldChange.paths);
            this.handlePolylinePathUpdates(foundPolyline);
          }
        } else {
          // Create a new polyline
          this.createPolylineFromFormField(polyFieldChange);
        }
      });
  }

  createPolylineFromFormField(fieldChange: PolyFieldChange) {
    this.setSelectedMapObjectIdentifier(fieldChange.identifier);
    const path = parsePathToLatLngLiteralArr(fieldChange.paths);
    const properties = mergeProperties(fieldChange, this.readonly);
    const polyline = polylineFactory(path, this.map, properties);

    this.handleCreatePolyline(polyline);

    const overlay = {
      overlay: polyline,
      type: google.maps.drawing.OverlayType.POLYLINE
    } as google.maps.drawing.OverlayCompleteEvent;
    this.overlayComplete(overlay, properties);
  }

  handleCreatePolyline = (mapPolygon: google.maps.Polyline) => {
    this.removeExistingPolyline();
    const polyline = this.addNewPolyline(mapPolygon);
    this.handlePolylinePathUpdates(polyline);
  }

  private handlePolylinePathUpdates(polyline: Polyline) {
    const polylineUpdates$ = new Observable<Polyline>(observer => {
      // Event for when the polyline moves a vertex's position.
      google.maps.event.addListener(polyline.getPath(), 'set_at', () => {
        observer.next(polyline);
      });
      // Event for when the polyline adds a vertex.
      google.maps.event.addListener(polyline.getPath(), 'insert_at', insertAt => {
        observer.next(polyline);
      });
      // Event for when the polyline removes a vertex.
      google.maps.event.addListener(polyline.getPath(), 'remove_at', setAt => {
        observer.next(polyline);
      });
    });

    polylineUpdates$.pipe(debounceTime(200)).subscribe((p) => {
      this.notifyPolylineChangesAndValidate(polyline);
    });

    // Handles showing the polyline tooltip.
    // Alias to the 'click' event, however 'mouseup' events handles "long clicks".
    google.maps.event.addListener(polyline.getShape(), 'mouseup', (mouseup: google.maps.MapMouseEvent) => {
      if (this.drawingManager.getDrawingMode() !== null) {
        return;
      }
      this.setSelectedMapObjectIdentifier(polyline.identifier, true);
      this.updatePolylineTooltip(polyline);
    });
  }

  private removeExistingPolyline() {
    const previousPolyline = this.getPolyline(this.selectedMapObjectIdentifier);
    if (previousPolyline) {
      // Removes the marker from the map.
      previousPolyline.remove();
      // Cleanup.
      this.validationService.clear(previousPolyline);
      this.removePolyline(this.selectedMapObjectIdentifier);
    }
  }

  private addNewPolyline(mapPolyline: google.maps.Polyline): Polyline {
    const polyline = new Polyline(mapPolyline, this.selectedMapObjectIdentifier);
    this.addPolyline(polyline);
    this.notifyPolylineChangesAndValidate(polyline);
    return polyline;
  }

  private notifyPolylineChangesAndValidate(polyline: Polyline) {
    this.polylineChange$.next(polyline);
    if (this.readonly) {
      return;
    }
    this.validationService.validate(polyline, google.maps.drawing.OverlayType.POLYLINE);
  }

  private updatePolylineTooltip(polyline: Polyline) {
    // close previous polygon tooltips
    this.closeAllPolylineTooltips();
    polyline.showTooltip();

    google.maps.event.addListener(this.map, 'click', () => {
      this.closeAllPolylineTooltips();
    });
  }

  /**
   * Closes all polyline tooltips other than the selected one.
   */
  private closeAllPolylineTooltips(shape?: EditableShape) {
    if (shape?.type === google.maps.drawing.OverlayType.POLYLINE) {
      return;
    }
    this.polylines.forEach(polyline => polyline.closeTooltip());
  }

  private deleteSelectedPolyline(identifier: MapObjectIdentifier, redrawProperties?: MapObjectProperties) {
    const polyline = this.getPolyline(identifier);
    if (polyline) {
      polyline.remove();
      // Notify the flight zone section that the polygon has been removed.
      this.polylineChange$.next(polyline);
      // Cleanup.
      this.validationService.clear(polyline);
      this.removePolyline(identifier);
    }
    // Change the drawing tool back to polyline.
    if (redrawProperties) {
      this.drawingMode$.next({ identifier, properties: redrawProperties });
    }
  }

  // ------------------------------------------------------ Shape ------------------------------------------------------
  // Handles the editing and deletion of shapes/markers on the map.

  private closeShapeTooltips(shape?: EditableShape): void {
    this.closeAllMarkerTooltips(shape);
    this.closeAllCircleTooltips(shape);
    this.closeAllPolygonTooltips(shape);
    this.closeAllPolylineTooltips(shape);
  }

  overlayComplete = (shapeDrawn: google.maps.drawing.OverlayCompleteEvent, properties?: MapObjectProperties) => {
    const shape = shapeDrawn.overlay as EditableShape;
    shape.type = shapeDrawn.type;

    // Switch back to non-drawing mode after drawing a shape.
    this.clearSelectedDrawingTool();

    // Close all other open shape markers.
    google.maps.event.addListener(shape, 'click', (shapeClicked) => {
      this.closeShapeTooltips(shape);
    });

    // Prevent the user from selecting a shape in readonly mode.
    if (properties?.readonly || this.readonly) {
      return;
    }
    // Enables shape selection and deletion.
    this.setSelectedShape(shape);
    google.maps.event.addListener(shape, 'click', (shapeClicked) => {
      this.setSelectedShape(shape);
    });
  }

  /**
   * Allows the user to edit (resize)/focus on a shape they have selected.
   */
  setSelectedShape = (shape: EditableShape) => {
    this.closeShapeTooltips(shape);
    // Clear the selection of the previously selected shape.
    this.clearSelectedShape();
    // Enable the editable/focus affect for the shape (exclude Markers).
    if (shape.type !== google.maps.drawing.OverlayType.MARKER) {
      shape.setEditable(true);
    }
    this.selectedShape = shape;
  }

  /**
   * Clear the editable/focus effect on the shape the user has selected.
   */
  clearSelectedShape = () => {
    if (this.selectedShape && this.selectedShape.type !== google.maps.drawing.OverlayType.MARKER) {
      this.selectedShape.setEditable(false);
    }
    this.selectedShape = null;
  }

  deleteSelectedShape = () => {
    if (this.selectedShape) {
      switch (this.selectedShape.type) {
        case google.maps.drawing.OverlayType.MARKER:
          this.deleteSelectedMarker(this.selectedMapObjectIdentifier);
          break;
        case google.maps.drawing.OverlayType.CIRCLE:
          this.deleteSelectedCircle(this.selectedMapObjectIdentifier);
          break;
        case google.maps.drawing.OverlayType.POLYGON:
          this.deleteSelectedPolygon(this.selectedMapObjectIdentifier);
          break;
        case google.maps.drawing.OverlayType.POLYLINE:
          this.deleteSelectedPolyline(this.selectedMapObjectIdentifier);
          break;
      }
      this.selectedShape = null;
    }
  }

  // ------------------------------------------------- Inbound Commands ------------------------------------------------

  // TODO Refactor to use the Command design pattern, or something like that.

  private initChangeAppearanceSubscription() {
    this.changeAppearance$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((fieldChange: FieldChange) => {
        const identifier = fieldChange.identifier;
        switch (identifier.shapeType) {
          case google.maps.drawing.OverlayType.CIRCLE:
            const circle = this.getCircle(identifier);
            circle.changeAppearance(fieldChange.properties);
            break;
          case google.maps.drawing.OverlayType.POLYGON:
            const polygon = this.getPolygon(identifier);
            polygon?.changeAppearance(fieldChange.properties);
            break;
          case google.maps.drawing.OverlayType.POLYLINE:
            const polyline = this.getPolyline(identifier);
            polyline?.changeAppearance(fieldChange.properties);
            break;
          case google.maps.drawing.OverlayType.MARKER:
            const marker = this.getMarker(identifier);
            marker?.changeAppearance(fieldChange.properties);
            break;
        }
      });
  }

  private initFocusSubscription() {
    this.focus$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((identifier: MapObjectIdentifier) => {
        this.closeShapeTooltips();
        switch (identifier?.shapeType) {
          case google.maps.drawing.OverlayType.CIRCLE:
            const circle = this.getCircle(identifier);
            circle?.focus();
            break;
          case google.maps.drawing.OverlayType.POLYGON:
            const polygon = this.getPolygon(identifier);
            polygon?.focus();
            break;
          case google.maps.drawing.OverlayType.POLYLINE:
            const polyline = this.getPolyline(identifier);
            polyline?.focus();
            break;
          case google.maps.drawing.OverlayType.MARKER:
            const marker = this.getMarker(identifier);
            marker?.focus();
            break;
        }
      });
  }

  private initChangeIdentifierSubscription() {
    this.changeIdentifier$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({oldIdentifier, newIdentifier}) => {
        switch (oldIdentifier.shapeType) {
          case google.maps.drawing.OverlayType.CIRCLE:
            const circle = this.getCircle(oldIdentifier);
            circle?.setIdentifier(newIdentifier);
            break;
          case google.maps.drawing.OverlayType.POLYGON:
            const polygon = this.getPolygon(oldIdentifier);
            polygon?.setIdentifier(newIdentifier);
            break;
          case google.maps.drawing.OverlayType.POLYLINE:
            const polyline = this.getPolyline(oldIdentifier);
            polyline?.setIdentifier(newIdentifier);
            break;
          case google.maps.drawing.OverlayType.MARKER:
            const marker = this.getMarker(oldIdentifier);
            marker?.setIdentifier(newIdentifier);
            break;
        }
      });
  }

  private initDeleteShapeSubscription() {
    this.deleteShape$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({identifier, redrawProperties}) => {
        this.closeShapeTooltips();
        switch (identifier.shapeType) {
          case google.maps.drawing.OverlayType.MARKER:
            this.deleteSelectedMarker(identifier, redrawProperties);
            break;
          case google.maps.drawing.OverlayType.CIRCLE:
            this.deleteSelectedCircle(identifier, redrawProperties);
            break;
          case google.maps.drawing.OverlayType.POLYGON:
            this.deleteSelectedPolygon(identifier, redrawProperties);
            break;
          case google.maps.drawing.OverlayType.POLYLINE:
            this.deleteSelectedPolyline(identifier, redrawProperties);
            break;
        }
      });
  }
}
