import { Directive, HostListener } from '@angular/core';
import { FormFieldComponent } from '@bo/ng-forms';

@Directive({
  selector: '[appUntouchedOnBlur]'
})
export class UntouchedOnBlurDirective {

  constructor(private field: FormFieldComponent) {}

  @HostListener('focusout')
  onFocusOut() {
    this.field.fieldControl.markAsUntouched();
  }

}
