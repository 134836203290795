import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appBackgroundImage]'
})
export class BackgroundImageDirective implements OnChanges, AfterViewInit {
  private el: HTMLElement;
  @Input() appBackgroundImage: string;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngAfterViewInit(): void {
    if (this.appBackgroundImage) {
      this.setBackgroundImage(this.appBackgroundImage);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appBackgroundImage) {
      if (changes.appBackgroundImage.currentValue) {
        this.setBackgroundImage(changes.appBackgroundImage.currentValue);
      }
    } else {
      this.removeBackgroundImage();
    }
  }

  setBackgroundImage(url: string) {
    this.el.style.backgroundImage = `url(${url})`;
  }

  removeBackgroundImage() {
    this.el.style.backgroundImage = undefined;
  }

}
