import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MeetingsApiService } from '../../../../modules/roc/pages/calendar/services/meetings-api.service';
import { OperationApiService } from '../../../../modules/roc/pages/operations/services/operation-api.service';
import { Router } from '@angular/router';
import { ROCMeetingsMinutesComponent } from '../../../../modules/roc/pages/calendar/meeting-minutes/meeting-minutes.component';
import { ModalService } from '../../../services/modal.service';
import { DocumentService } from '../../../../modules/roc/pages/documents/services/document.service';
import { AppToastrService } from '../../../services/toaster.service';
import { EventDetail } from '../../../../modules/roc/pages/calendar/services/meeting.models';
import { CalendarTypes } from '../../../api/interface/api.enums';
import moment from 'moment/moment';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-meeting-modal',
  templateUrl: './select-meeting-modal.component.html'
})

export class SelectMeetingModalComponent extends ROCMeetingsMinutesComponent implements OnInit {
  public onClose: Subject<boolean>;

  pageSize = 5;
  pageSizeOptions = [5];
  filterByCalendarTypes = [CalendarTypes.SAFETY_MEETING]
  selectedData: EventDetail[] = [];
  incidentDate: Date;

  constructor(
    protected meetingApiService: MeetingsApiService,
    protected operationApiService: OperationApiService,
    protected modalService: ModalService,
    protected router: Router,
    protected documentService: DocumentService,
    protected toastrService: AppToastrService,
    private bsModalRef: BsModalRef,
  ) { super(meetingApiService, operationApiService, modalService, router, documentService, toastrService )}

  ngOnInit(): void {
    this.onClose = new Subject();
    super.ngOnInit();
  }

  override setDatesLoadMeetings() {
    this.dateTo = moment(this.incidentDate).format('YYYY-MM-DD');
    this.dateFrom = moment(this.dateTo).subtract(1, 'month').format('YYYY-MM-DD')
    this.dateFromControl = new FormControl();
    this.dateToControl = new FormControl();
    this.dateFromControl.setValue(this.dateFrom);
    this.dateToControl.setValue(this.dateTo);
    if(this.dateFrom && this.dateTo) {
      this.loadMeetingOccurrences(this.dateFrom, this.dateTo, this.filterByCalendarTypes);
    }
  }

  onSelect({selected}) {
    this.selectedData = selected;
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public onAccept(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
