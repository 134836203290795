/* tslint:disable:variable-name */
/*
Please follow the Angular Style guide and best practices.
https://angular.io/guide/styleguide#data-services
 */

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Select } from '@ngxs/store';
import { first, map, pluck, switchMap } from 'rxjs/operators';
import * as Models from 'src/app/shared/api/interface/api.models';
import {
  CaptureFlightRequest,
  CompleteFlightRequest,
  CreateFlightRequest,
  EmergencyInformation,
  EmergencyInformationRequest,
  EmergencyService,
  EmergencyServiceRequest,
  EndFlightRequest,
  HazardSignOffRequest,
  HazardSignOffRequestData,
  HazardSignOffSettings,
  HazardSignOffSettingsRequest,
  LinkedOperator,
  MinimalOperation, OperationCrew,
  OperationFlight,
  OperationGear,
  OperationGearRequest,
  OperationHazard,
  OperationHazardRequest,
  OperationHazardResponse,
  OperationHazardTag,
  OperationLocation,
  OperationLocationRequest,
  OperationPointOfInterest,
  OperationPointOfInterestRequest,
  PreflightChecklistRequest, PreflightComplianceCheck,
  PreflightOption,
  RemoveGearRequest,
  RenameFlightRequest,
  ROCCrewMember,
  SimpleApiResponse,
  StartFlightRequest, TaskMangerHazardSignOffRequest
} from 'src/app/shared/api/interface/api.models';
import { ROCState } from '../../../store/states';
import { ROCOperatorUserAdditional, ROCOperatorUsers } from 'src/app/shared/api/interface/api.enums';
import { AddRocParamsToApiQuery, UserSelectedROCMixin } from '../../../store/helpers';
import { UserLinkedROCMapped } from '../../../store/models';

@Injectable()
export class OperationApiService extends BaseApiService implements UserSelectedROCMixin {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;
  baseUrl = environment.apiRoot;

  // Operation Detailed (full Operation with related objects)
  URL_OPERATION = 'operations/operation';

  // Operation Create (list, create, update, complete & abort)
  URL_OPERATION_CREATE = 'operations/operation-create';
  URL_OPERATION_LIST = 'operations/operation-list';
  URL_OPERATION_ABORT_OPERATION = 'operations/operation-list/abort-operation';
  URL_OPERATION_COMPLETE_OPERATION = 'operations/operation-create/complete-operation';
  URL_OPERATION_PUBLISH_OPERATION = 'operations/operation-create/publish-operation';

  // Location
  URL_LOCATION = 'operations/location';

  // Location Airspace Action
  URL_OPERATIONS_VALIDATED = 'operations/airspace/validate_coordinates';

  // Weather
  URL_WEATHER_GET = 'operations/weather';

  // Crew
  URL_LINKED_OPERATORS = 'roc-association/roc-linked-operators';
  URL_ROC_CREW_MEMBERS = 'roc-association/roc-crew-members';
  URL_OPERATION_CREW = 'operations/crew';
  URL_CREW_MEMBERS_DELETE = 'operations/crew/delete-crew-member';

  // Gear
  URL_GEAR_CREATE = 'operations/operation-gear';
  URL_GEAR_DELETE = 'operations/operation-gear/delete-gear';

  // Hazards
  URL_HAZARDS = 'operations/operation-hazard';
  URL_HAZARD_TAGS = 'operations/hazard-tags';

  // Points of Interest
  URL_POINTS_OF_INTEREST = 'operations/point-of-interest';

  // Emergency Information
  URL_EMERGENCY_INFORMATION = 'operations/emergency-information';

  // Emergency Services
  URL_EMERGENCY_SERVICES = 'operations/emergency-services';

  // Pre-flight
  URL_PRE_FLIGHT = 'operations/pre-flight';
  URL_PRE_FLIGHT_UPDATE = 'operations/pre-flight/update';
  URL_PRE_FLIGHT_CHECKS = 'operations/pre-flight/preflight_checks'

  // In-flight
  URL_IN_FLIGHT = 'operations/in-flight';

  // Hazard Sign-off rules
  URL_HAZARD_SIGN_OFF_RULES = 'operations/hazard-sign-off-rules';
  URL_HAZARD_SIGN_OFF_REQUEST_TASKS = 'operations/hazard-sign-off-request/pending_requests';

  //Hazard Sign-off request
  URL_HAZARD_SIGN_OFF_REQUEST_SEND = 'operations/hazard-sign-off-request/send/'
  URL_HAZARD_SIGN_OFF_REQUEST = 'operations/hazard-sign-off-request'

  URL_APPROVE = 'approve' // relative to URL_HAZARD_SIGN_OFF_REQUEST
  URL_DECLINE = 'decline' // relative to URL_HAZARD_SIGN_OFF_REQUEST
  URL_RESEND = 'resend' // relative to URL_HAZARD_SIGN_OFF_REQUEST

  rocId: number;
  rocUser: number;

  static readonly roleMapping: Record<keyof Models.UserRoles, (ROCOperatorUsers | ROCOperatorUserAdditional)> = {
    has_security_officer_role: ROCOperatorUsers.SECURITY_MANAGER,
    has_safety_officer_role: ROCOperatorUsers.SAFETY_MANAGER,
    has_rpas_maintenance_technician_role: ROCOperatorUsers.RPAS_MAINTENANCE_TECHNICIAN,
    has_pilot_role: ROCOperatorUsers.PILOT,
    has_quality_officer_role: ROCOperatorUsers.QUALITY_MANAGER,
    has_flight_operation_manager_role: ROCOperatorUsers.PR_FLIGHT_OPERATIONS,
    has_aircraft_manager_role: ROCOperatorUsers.PR_AIRCRAFT,
    has_accountable_manager_role: ROCOperatorUsers.ACCOUNTABLE_MANAGER,
    has_admin_role: ROCOperatorUserAdditional.ADMIN,
    has_secondary_admin_role: ROCOperatorUsers.ROC_SECONDARY_ADMIN,
    has_observer_role: ROCOperatorUserAdditional.OBSERVER,
  };

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  getROCData() {
    this.selectedROC$.pipe(
      first(roc => !!roc),
      map(roc => {
        this.rocId = roc.id;
        this.rocUser = roc.roc_user.id;
      }),
    ).subscribe();
  }

  private mapDataWithParams<T>(data:T): T & { remote_operator: number; roc_user: number } {
    this.getROCData();
    return {...data, remote_operator: this.rocId, roc_user: this.rocUser};
  }

  // ---------------------------------------------------- Operation ----------------------------------------------------

  /**
   * Returns all the details of the Operation and all related objects data.
   */
  @AddRocParamsToApiQuery()
  getOperation(queryParams: HttpParams, id: number): Observable<Models.Operation> {
    const url = this.getUrl([this.URL_OPERATION, id.toString()]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as Models.Operation)
    );
  }

  // ------------------------------------------------- Operation Create ------------------------------------------------

  /**
   * Returns a list of Operation Detail data.
   */
  @AddRocParamsToApiQuery()
  getOperationsList(queryParams?: HttpParams): Observable<MinimalOperation[]> {
    return this.http
      .get(this.getUrl(this.URL_OPERATION_LIST), {params: queryParams})
      .pipe(
        map(data => data as MinimalOperation[])
      );
  }

  /**
   * Returns the Operation Details of the Operation.
   */
  getOperationDetails(id: number, queryParams?: HttpParams): Observable<Models.OperationDetail> {
    const url = this.getUrl([this.URL_OPERATION_CREATE, id.toString()]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as Models.OperationDetail)
    );
  }

  postOperationDetails(operationRequest: Models.OperationDetailsRequest): Observable<Models.OperationDetail> {
    const url = this.getUrl([this.URL_OPERATION_CREATE]);
    return this.http.post(url, this.mapDataWithParams(operationRequest))
      .pipe(
        map(data => data as Models.OperationDetail)
      );
  }

  updateOperationDetails(id: number, operationRequest: Models.OperationDetailsRequest): Observable<Models.OperationDetail> {
    const url = this.getUrl([this.URL_OPERATION_CREATE, id]);
    return this.http.put(url, this.mapDataWithParams(operationRequest))
      .pipe(
        map(data => data as Models.OperationDetail)
      );
  }

  publishOperation(operationId: number): Observable<Models.SimpleApiResponse> {
    const url = this.getUrl(this.URL_OPERATION_PUBLISH_OPERATION);
    const data = {operation: operationId};
    return this.http.put(url, this.mapDataWithParams(data)).pipe(
      map(response => response as Models.SimpleApiResponse)
    );
  }

  completeOperation(operationId: number): Observable<Models.SimpleApiResponse> {
    const url = this.getUrl(this.URL_OPERATION_COMPLETE_OPERATION);
    const data = {operation_id: operationId};
    return this.http.put(url, this.mapDataWithParams(data)).pipe(
      map(response => response as Models.SimpleApiResponse)
    );
  }

  abortOperation(operationId: number): Observable<Models.SimpleApiResponse> {
    const url = this.getUrl(this.URL_OPERATION_ABORT_OPERATION);
    const data = {operation_id: operationId};
    return this.http.put(url, this.mapDataWithParams(data)).pipe(
      map(response => response as Models.SimpleApiResponse)
    );
  }

  // ----------------------------------------------- Operation Location ------------------------------------------------

  validateAirspace(request: Models.AirspaceValidationRequest): Observable<HttpResponse<Models.AirspaceValidationResponse>> {
    const url = this.getUrl(this.URL_OPERATIONS_VALIDATED);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map((response: any) => response as HttpResponse<Models.AirspaceValidationResponse>)
    );
  }

  getLocation(id: number, queryParams?: HttpParams): Observable<Models.OperationLocation> {
    const url = this.getUrl([this.URL_LOCATION, id.toString()]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as Models.OperationLocation)
    );
  }


  postLocation(locationRequest: OperationLocationRequest, queryParams?: HttpParams): Observable<OperationLocation> {
    const url = this.getUrl(this.URL_LOCATION);
    return this.http.post(url, this.mapDataWithParams(locationRequest), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as Models.OperationLocation)
    );
  }

  // tslint:disable-next-line:max-line-length
  updateLocation(id: number, request: OperationLocationRequest, queryParams?: HttpParams): Observable<OperationLocation> {
    const url = this.getUrl([this.URL_LOCATION, id.toString()]);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as Models.OperationLocation)
    );
  }

  // ----------------------------------------------------- Weather -----------------------------------------------------

  @AddRocParamsToApiQuery()
  getWeather(queryParams: HttpParams): Observable<any> {
    const url = this.getUrl(this.URL_WEATHER_GET);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body)
    );
  }

  // --------------------------------------------------- Crew Members --------------------------------------------------

  /**
   * Get all crew members of the ROC including invitations. Minimal Data for list view
   *
   * @param queryParams
   * @return Observable<ROCCrewMember[]>
   */

  @AddRocParamsToApiQuery()
  getRocCrewMembers(queryParams: HttpParams): Observable<ROCCrewMember[]> {
    const url = this.getUrl([this.URL_ROC_CREW_MEMBERS]);
    return this.http.get<ROCCrewMember[]>(url, {params: queryParams});
  }

  /**
   * Get crew member detail of the ROC.
   *
   * @param queryParams
   * @param rocUserId
   * @return Observable<ROCCrewMember>
   */

  @AddRocParamsToApiQuery()
  getRocCrewMember(queryParams: HttpParams, rocUserId: number): Observable<LinkedOperator> {
    const url = this.getUrl([this.URL_ROC_CREW_MEMBERS, rocUserId]);
    return this.http.get<LinkedOperator>(url, {params: queryParams}).pipe(
      map(operator => ({
        ...operator,
        roles:  Object.keys(operator.user_role ?? {})
          .filter(key => operator.user_role[key])
          .map(key => OperationApiService.roleMapping[key]),
      } as LinkedOperator))
    );
  }

  deactivateCrewMember(rocUserId: number): Observable<any> {
    const data = {
      is_active: false
    }
    const url = this.getUrl([this.URL_ROC_CREW_MEMBERS, rocUserId]);
    return this.http.put(url, this.mapDataWithParams(data));
  }

  reactivateCrewMember(rocUserId: number): Observable<any> {
    const data = {
      is_active: true
    }
    const url = this.getUrl([this.URL_ROC_CREW_MEMBERS, rocUserId]);
    return this.http.put(url, this.mapDataWithParams(data));
  }

  /**
   * Get all linked / onboarded crew members of the ROC.
   *
   * @param queryParams
   * @return Observable<LinkedOperator[]>
   */

  @AddRocParamsToApiQuery()
  getLinkedOperators(queryParams: HttpParams): Observable<LinkedOperator[]> {
    return this.selectedROC$.pipe(
      first(selectedROC => !!selectedROC),
      pluck('id'),
      switchMap((rocId) => {
        const url = this.getUrl([this.URL_LINKED_OPERATORS, rocId]);
        return this.http.get<LinkedOperator[]>(url, {params: queryParams});
      }),
      map((operators) =>
        operators.map(operator => ({
            ...operator,
            roles: Object.keys(operator.user_role ?? {})
              .filter(key => operator.user_role[key])
              .map(key => OperationApiService.roleMapping[key]),
          } as LinkedOperator)
        )
      ),
    );
  }

  postCrewMembers(crew: any): Observable<any> {
    const url = this.getUrl(this.URL_OPERATION_CREW);
    return this.http.post(url, this.mapDataWithParams(crew));
  }

  @AddRocParamsToApiQuery()
  getOperationCrewMembers(queryParams: HttpParams): Observable<OperationCrew[]> {
    const url = this.getUrl(this.URL_OPERATION_CREW);
    return this.http.get(url,{params: queryParams}).pipe(
      map(data => data as OperationCrew[])
    );
  }

  // ------------------------------------------------------ Gear -------------------------------------------------------

  postGear(request: OperationGearRequest): Observable<OperationGear> {
    const url = this.getUrl(this.URL_GEAR_CREATE);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map((response: any) => response.body as Models.OperationGear)
    );
  }

  deleteGear(request: RemoveGearRequest): Observable<SimpleApiResponse> {
    const url = this.getUrl(this.URL_GEAR_DELETE);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map((response: any) => response.body as SimpleApiResponse)
    );
  }

  // ----------------------------------------------------- Hazards -----------------------------------------------------

  /**
   * Returns a list of Hazards.
   */
  @AddRocParamsToApiQuery()
  getOperationHazard(queryParams?: HttpParams): Observable<OperationHazardResponse> {
    const url = this.getUrl(this.URL_HAZARDS);
    return this.http.get(url,{params: queryParams}).pipe(
      map(data => data as OperationHazardResponse)
    );
  }

  getHazardsTags(queryParams?: HttpParams): Observable<OperationHazardTag[]> {
    const url = this.getUrl(this.URL_HAZARD_TAGS);
    return this.http.get(url, {params: queryParams}).pipe(
      map(data => data as OperationHazardTag[])
    );
  }

  postHazard(request: OperationHazardRequest): Observable<OperationHazard> {
    const url = this.getUrl(this.URL_HAZARDS);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationHazard)
    );
  }

  updateHazard(id: number, request: OperationHazardRequest): Observable<OperationHazard> {
    const url = this.getUrl([this.URL_HAZARDS, id]);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationHazard)
    );
  }

  @AddRocParamsToApiQuery()
  deleteHazard(queryParams: HttpParams, id: number): Observable<any> {
    const url = this.getUrl([this.URL_HAZARDS, id]);
    return this.http.delete(url, {params: queryParams});
  }

  /**
   * Create Hazard Sign-off Rules
   * @param request
   */
  postHazardSignOffRule(request: HazardSignOffSettingsRequest): Observable<HazardSignOffSettingsRequest> {
    const url = this.getUrl(this.URL_HAZARD_SIGN_OFF_RULES);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.HazardSignOffSettingsRequest)
    );
  }

  updateHazardSignOffRule(id: number, request: HazardSignOffSettingsRequest): Observable<HazardSignOffSettingsRequest> {
    const url = this.getUrl([this.URL_HAZARD_SIGN_OFF_RULES, id]);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.HazardSignOffSettingsRequest)
    );
  }

  @AddRocParamsToApiQuery()
  getHazardSignOffRule(queryParams?: HttpParams, id?: number): Observable<HazardSignOffSettings> {
    if (id) {
      return this.http.get<HazardSignOffSettings>(this.getUrl([this.URL_HAZARD_SIGN_OFF_RULES, id]),
        { params: queryParams });
    } else {
      return this.http.get<HazardSignOffSettings>(this.getUrl(this.URL_HAZARD_SIGN_OFF_RULES),
        { params: queryParams }).pipe(map( response => { return response[0]}));
    }
  }

  /**
   * Send a request to a user to sign off hazards
   */
  sendHazardSignOffRequest(request: HazardSignOffRequestData): Observable<HazardSignOffRequestData> {
    const url = this.getUrl(this.URL_HAZARD_SIGN_OFF_REQUEST_SEND);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.HazardSignOffRequestData)
    );
  }

  /**
   * Resend a request to a user to sign off hazards after a decline
   */
  resendHazardSignOffRequest(id: number, request: HazardSignOffRequestData): Observable<HazardSignOffRequestData> {
    const url = this.getUrl([this.URL_HAZARD_SIGN_OFF_REQUEST, id, this.URL_RESEND]);
    return this.http.patch(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response) => response.body as Models.HazardSignOffRequestData)
    );
  }

  /**
   * Sign off Operation Hazards.
   */
  signOffOperationHazardRequest(id: number, request: any): Observable<HazardSignOffRequest> {
    const url = this.getUrl([this.URL_HAZARD_SIGN_OFF_REQUEST, id, this.URL_APPROVE])
    return this.http.patch(url, this.mapDataWithParams(request)).pipe(
      map((response) => response as HazardSignOffRequest)
    )
  }

  /**
   * Decline signing off Operation Hazards.
   */
  declineSignOffOperationHazardRequest(id: number, request: any): Observable<HazardSignOffRequest> {
    const url = this.getUrl([this.URL_HAZARD_SIGN_OFF_REQUEST, id, this.URL_DECLINE])
    return this.http.patch(url, this.mapDataWithParams(request)).pipe(
      map((response) => response as HazardSignOffRequest)
    )
  }

  /**
   * Returns a list of Points of Interest.
   */
  @AddRocParamsToApiQuery()
  getPointOfInterestList(queryParams?: HttpParams): Observable<OperationPointOfInterest[]> {
    const url = this.getUrl(this.URL_POINTS_OF_INTEREST);
    return this.http.get<OperationPointOfInterest[]>(url, {params: queryParams});
  }

  /**
   * Return a single Point of Interest.
   */
  getPointOfInterest(id: number, queryParams?: HttpParams): Observable<OperationPointOfInterest | undefined> {
    return this.http.get<OperationPointOfInterest>(this.getUrl([this.URL_POINTS_OF_INTEREST, id]), {params: queryParams});
  }

  /**
   * Creates a new Point of Interest.
   */
  postPointOfInterest(request: OperationPointOfInterestRequest): Observable<OperationPointOfInterest> {
    const url = this.getUrl(this.URL_POINTS_OF_INTEREST);
    return this.http.post<OperationPointOfInterest>(url, this.mapDataWithParams(request));
  }

  /**
   * Updates a Point of Interest.
   */
  updatePointOfInterest(id: number, request: OperationPointOfInterestRequest): Observable<OperationPointOfInterest> {
    const url = this.getUrl([this.URL_POINTS_OF_INTEREST, id]);
    return this.http.put<OperationPointOfInterest>(url, this.mapDataWithParams(request));
  }

  /**
   * Deletes a Point of Interest.
   */
  @AddRocParamsToApiQuery()
  deletePointOfInterest(queryParams: HttpParams, id: number): Observable<null> {
    return this.http.delete<null>(this.getUrl([this.URL_POINTS_OF_INTEREST, id]), {params: queryParams});
  }

  // ---------------------------------------------- Emergency Information ----------------------------------------------

  /**
   * Returns the Emergency Information of the Operation.
   */
  @AddRocParamsToApiQuery()
  getEmergencyInformation(queryParams?: HttpParams): Observable<EmergencyInformation[]> {
    const url = this.URL_EMERGENCY_INFORMATION;
    return this.http.get(this.getUrl(url), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as EmergencyInformation[])
    );
  }

  /**
   * Posts the Emergency Information of the Operation.
   */
  postEmergencyInformation(request: EmergencyInformationRequest): Observable<EmergencyInformation> {
    const url = this.getUrl(this.URL_EMERGENCY_INFORMATION);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as EmergencyInformation)
    );
  }

  /**
   * Posts the Emergency Information of the Operation.
   */
  updateEmergencyInformation(id: number, request: EmergencyInformationRequest): Observable<EmergencyInformation> {
    const url = this.getUrl([this.URL_EMERGENCY_INFORMATION, id]);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as EmergencyInformation)
    );
  }

  // ----------------------------------------------- Emergency Services ------------------------------------------------

  @AddRocParamsToApiQuery()
  getEmergencyService(queryParams: HttpParams, id: number): Observable<EmergencyService> {
    const url = this.getUrl([this.URL_EMERGENCY_SERVICES, id]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as EmergencyService)
    );
  }

  /**
   * Returns the Emergency Information of the Operation.
   */
  @AddRocParamsToApiQuery()
  getEmergencyServices(queryParams?: HttpParams): Observable<EmergencyService[]> {
    const url = this.getUrl(this.URL_EMERGENCY_SERVICES);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as EmergencyService[])
    );
  }

  /**
   * Posts the Emergency Information of the Operation.
   */
  postEmergencyService(request: EmergencyServiceRequest): Observable<EmergencyService> {
    const url = this.getUrl(this.URL_EMERGENCY_SERVICES);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.EmergencyService)
    );
  }

  /**
   * Posts the Emergency Information of the Operation.
   */
  updateEmergencyService(id: number, request: EmergencyServiceRequest): Observable<EmergencyService> {
    const url = this.getUrl([this.URL_EMERGENCY_SERVICES, id]);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.EmergencyService)
    );
  }

  @AddRocParamsToApiQuery()
  deleteEmergencyService(queryParams: HttpParams, id: number): Observable<any> {
    const url = this.getUrl([this.URL_EMERGENCY_SERVICES, id]);
    return this.http.delete(url, {params: queryParams});
  }

  // --------------------------------------------------- Pre-Flight ----------------------------------------------------

  /**
   * Returns the Pre-flight checklist.
   */
  @AddRocParamsToApiQuery()
  getPreflightChecklist(queryParams?: HttpParams): Observable<PreflightOption[]> {
    return this.http.get(this.getUrl(this.URL_PRE_FLIGHT), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as PreflightOption[])
    );
  }

  /**
   * Update the Pre-flight checklist of the Operation.
   */
  updatePreflightChecklist(request: PreflightChecklistRequest): Observable<PreflightOption[]> {
    const url = this.getUrl(this.URL_PRE_FLIGHT_UPDATE);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.PreflightOption[])
    );
  }


  /**
   * Returns the Pre-flight Detail (Compliance checks) for other areas that need attention.
   */
  @AddRocParamsToApiQuery()
  getOperationComplianceChecks(queryParams?: HttpParams): Observable<PreflightComplianceCheck[]> {
    return this.http.get(this.getUrl(this.URL_PRE_FLIGHT_CHECKS), {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as PreflightComplianceCheck[])
    );
  }

  // ---------------------------------------------------- In-Flight ----------------------------------------------------

  /**
   * Returns the In-flight flights of an operation.
   */
  @AddRocParamsToApiQuery()
  getFlight(queryParams: HttpParams, flightId: number): Observable<OperationFlight> {
    const url = this.getUrl([this.URL_IN_FLIGHT, flightId]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as OperationFlight)
    );
  }

  /**
   * Returns the In-flight flights of an operation.
   */
  @AddRocParamsToApiQuery()
  getFlights(queryParams: HttpParams): Observable<OperationFlight[]> {
    const url = this.getUrl(this.URL_IN_FLIGHT);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as OperationFlight[])
    );
  }

  /**
   * Creates a new In-flight flight for an Operation.
   */
  createFlight(request: CreateFlightRequest): Observable<OperationFlight> {
    const url = this.getUrl(this.URL_IN_FLIGHT);
    return this.http.post(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationFlight)
    );
  }

  /**
   * Renames an In-flight flight for an Operation.
   */
  renameFlight(id: number, request: RenameFlightRequest): Observable<OperationFlight> {
    const url = this.getUrl([this.URL_IN_FLIGHT, id]);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationFlight)
    );
  }

  /**
   * Deletes an In-flight flight for an Operation.
   */
  @AddRocParamsToApiQuery()
  deleteFlight(queryParams, id: number): Observable<null> {
    const url = this.getUrl([this.URL_IN_FLIGHT, id]);
    return this.http.delete<null>(url, {params: queryParams});
  }

  /**
   * Captures an In-flight flight for an Operation.
   */
  captureFlight(id: number, request: CaptureFlightRequest): Observable<OperationFlight> {
    const url = this.getUrl([this.URL_IN_FLIGHT, id, 'capture']);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationFlight)
    );
  }

  /**
   * Starts an In-flight flight for an Operation.
   */
  startFlight(id: number, request: StartFlightRequest): Observable<OperationFlight> {
    const url = this.getUrl([this.URL_IN_FLIGHT, id, 'start']);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationFlight)
    );
  }

  /**
   * Ends an In-flight flight for an Operation.
   */
  endFlight(id: number, request: EndFlightRequest): Observable<OperationFlight> {
    const url = this.getUrl([this.URL_IN_FLIGHT, id, 'end']);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationFlight)
    );
  }

  /**
   * Completes an In-flight flight for an Operation.
   */
  completeFlight(id: number, request: CompleteFlightRequest): Observable<OperationFlight> {
    const url = this.getUrl([this.URL_IN_FLIGHT, id, 'complete']);
    return this.http.put(url, this.mapDataWithParams(request), {observe: 'response'}).pipe(
      map( (response: any) => response.body as Models.OperationFlight)
    );
  }

  // ---------------------------------------------------- Tasks ----------------------------------------------------

  /**
   * Get Hazard Sign-off tasks
   */
  @AddRocParamsToApiQuery()
  getHazardSignOffRequestTasks(queryParams?: HttpParams): Observable<TaskMangerHazardSignOffRequest[]> {
    const url = this.getUrl(this.URL_HAZARD_SIGN_OFF_REQUEST_TASKS);
    return this.http.get(url,{params: queryParams}).pipe(
      map(data => data as TaskMangerHazardSignOffRequest[])
    );
  }
}
