import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-coordinates-swop',
  templateUrl: './coordinates-swop.component.html'
})
export class CoordinatesSwopComponent implements OnChanges {

  @Input() label: string;
  @Input() coordinates: any;
  @Input() coordinatesDms: string;
  @Input() breakString: boolean;

  showDMS = true;
  DMSArray = [];
  coordinateArray = [];

  toggleCoordinates() {
    this.showDMS = !this.showDMS;
  }

  ngOnChanges() {
    this.DMSArray = this.stringToArray(this.coordinatesDms);
    if (typeof this.coordinates === 'string') {
      this.coordinateArray = this.stringToArray(this.coordinates);
    } else {
      this.coordinateArray = this.coordinates;
    }
  }

  stringToArray(stringValue) {
    if (stringValue) {
      const arr = stringValue.split(',');
      for (let i = 0; i < arr.length; i++) {
        // Trim the excess whitespace.
        arr[i] = arr[i].replace(/^\s*/, '').replace(/\s*$/, '');
      }
      return arr;
    }
  }
}
