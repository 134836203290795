import { Component, Input, OnInit } from '@angular/core';
import { FieldComponent } from '../field/field.component';
import { FieldPathDirective, FormOptionsDirective, InputType } from '@bo/ng-forms';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: []
})
export class PasswordFieldComponent extends FieldComponent implements OnInit {
  @Input() boFieldPath: string;
  @Input() label: string;
  passwordShow: boolean;

  constructor(
    protected formOptionsDirective: FormOptionsDirective,
    protected fieldPathDirective: FieldPathDirective) {
    super(formOptionsDirective, fieldPathDirective);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  toggleShowPassword(input: FieldComponent) {
    input.fieldOption.inputType = input.fieldOption.inputType === InputType.password ? InputType.text : InputType.password;
    this.passwordShow = !this.passwordShow;
  }
}
