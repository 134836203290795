import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-login-roc-not-found',
  templateUrl: './login-roc-not-found.component.html'
})
export class LoginRocNotFoundModalComponent implements OnInit {
  public onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef,
  ) {}

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public onAccept(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }
}
