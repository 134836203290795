import { FieldOptions, FieldType, FormComponent } from '@bo/ng-forms';
import moment from 'moment';
import cloneDeep from 'lodash-es/cloneDeep';
import { paths } from '@bo/ng-utils';


export abstract class AppFormComponent<T> extends FormComponent<T> {
  protected enumFields: string[];

  onSubmit(): void {
    super.onSubmit();
  }

  // Serializes data for the API request body (POST & PUT).
  protected serializeField(option: FieldOptions, value: any): any {
    if (option.type === FieldType.date) {
      // Convert date field values to ISO string.
      return moment(value).format('YYYY-MM-DD');
    }
    if (option.type === FieldType.datetime) {
      // Convert date field values to ISO string.
      return moment(value).toISOString();
    }
    if (option.type === FieldType.range) {
      // Convert date field values to ISO string.
      return {lower: value[0], upper: value[1]};
    }
    return value;
  }

  // Deserializes data from the API (GET) to be used in form fields.
  protected deserializeField(option: FieldOptions, value: any): any {
    // Todo: look into this. It might be better to have the entire object available
    // return Enum.name as value instead of the whole object
    if (value && this.enumFields && this.enumFields.includes(option.name)) {
      return value.name;
    }
    if (option.type === FieldType.datetime && value) {
      return new Date(value);
    }
    return super.deserializeField(option, value);
  }

  protected getFormData(): T {
    return this.form.value;
  }


  protected serializeFields(): any {
    const formData = cloneDeep(this.getFormData());
    for (const field of this.fields) {
      const value = paths.getIn(formData, field);
      if (value) {
        const serializedValue = this.serializeField(FormComponent.getOption(this.options, field), value);
        paths.mutIn(formData, field, serializedValue);
      }
    }
    return formData;
  }
}
