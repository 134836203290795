import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { OperationApiService } from './operation-api.service';
import {
  Battery,
  Document,
  Drone,
  EmergencyInformation,
  EmergencyInformationRequest,
  EmergencyService,
  EmergencyServiceRequest,
  Equipment,
  HazardSignOffRequest,
  Operation,
  OperationCrew,
  OperationDetail,
  OperationDetailsRequest,
  OperationGear,
  OperationGearRequest,
  OperationHazard,
  OperationHazardRequest,
  OperationLocation,
  OperationLocationRequest,
  OperationPointOfInterest,
  OperationPointOfInterestRequest,
  Payload, PreflightComplianceCheck,
  RemoveGearRequest,
  UserProfile
} from '../../../../../shared/api/interface/api.models';
import { catchError, filter, first, map, mergeAll, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AddCrewMembersRequest, OperationCrewMemberMapped, RequiredDocument } from './operation.models';
import { HttpParams } from '@angular/common/http';
import {
  DocumentType,
  OperationRelatedDocumentTypes,
  OperationStatus, ROCOperatorUsers
} from '../../../../../shared/api/interface/api.enums';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../store/states';
import { UserLinkedROCMapped } from '../../../store/models';
import { ConfirmationRemoveModalComponent
} from '../../../../../shared/components/modals/confirmation-remove-modal/confirmation-remove-modal.component';
import { ModalService } from '../../../../../shared/services/modal.service';
import { AppToastrService } from '../../../../../shared/services/toaster.service';
import { Router } from '@angular/router';
import { ROCOperationCompleteOperationModalComponent
} from '../components/modals/complete-operation-modal/complete-operation-modal.component';

type Gear = Drone | Battery | Payload | Equipment;

@Injectable({
  providedIn: 'root'
})
export class OperationService {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  // Emit `null` so the .pipe() can execute.
  operationId$ = new BehaviorSubject<number>(null);
  // Emit `null` so the .pipe() can execute.
  operation$ = new BehaviorSubject<Operation>(null);
  location$ = new ReplaySubject<OperationLocation>(1);
  operationDetail$ = new ReplaySubject<OperationDetail>(1);
  crewMembers$ = new ReplaySubject<OperationCrewMemberMapped[]>(1);
  drones$ = new BehaviorSubject<Drone[]>([]);
  batteries$ = new BehaviorSubject<Battery[]>([]);
  payloads$ = new BehaviorSubject<Payload[]>([]);
  equipment$ = new BehaviorSubject<Equipment[]>([]);
  documents$ = new ReplaySubject<Document[]>(1);
  requiredDocuments$ = new ReplaySubject<RequiredDocument[]>(1);
  hazards$ = new BehaviorSubject<OperationHazard[]>(null);
  signOffUsers$ = new BehaviorSubject<UserProfile[]>(null);
  signOffRequest$ = new BehaviorSubject<HazardSignOffRequest>(null);
  pointsOfInterest$ = new BehaviorSubject<OperationPointOfInterest[]>(null);
  rocId: number;
  rocUserId: number;
  userId: number;

  public gearLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public locationDetailsComplete$: Observable<boolean>;
  public operationDetailsComplete$: Observable<boolean>;
  public weatherDetailsComplete$: Observable<boolean>;
  public crewDetailsComplete$: Observable<boolean>;
  public gearDetailsComplete$: Observable<boolean>;
  public airspaceRequirementsComplete$: Observable<boolean>;
  public operationIsDraft$: Observable<boolean>;
  public operationIsPublished$: Observable<boolean>;
  public operationStatus$: Observable<OperationStatus>;
  public operationName$: Observable<string>;

  private emergencyInformation$ = new BehaviorSubject<EmergencyInformation>(null);

  constructor(
    private operationApiService: OperationApiService,
    private modalService: ModalService,
    private toastrService: AppToastrService,
    private router: Router,
  ) {
    this.initialisePlanningStatus();
    this.getOperationStatus();
    this.operationIsDraft();
    this.operationIsPublished();
    this.getOperationName();
    this.getRocId();
  }

  private getRocId() {
    this.selectedROC$.subscribe(selectedROC => {
      if (selectedROC) {
        this.rocId = selectedROC.id;
        this.rocUserId = selectedROC.roc_user.id;
        this.userId = selectedROC.roc_user.user.id;
      }
    });
  }

  private operationIsDraft(): void {
    this.operationIsDraft$ = this.operationStatus$.pipe(
      map(status => status === OperationStatus.DRAFT)
    );
  }

  private operationIsPublished(): void {
    this.operationIsPublished$ = this.operationStatus$.pipe(
      map(status => status === OperationStatus.PUBLISHED)
    );
  }

  private getOperationStatus(): void {
    this.operationStatus$ = this.operationDetail$.pipe(
      filter(detail => !!detail),
      map(detail => detail.operation_status || OperationStatus.DRAFT)
    );
  }

  private getOperationName(): void {
    this.operationName$ = this.operationDetail$.pipe(
      filter(detail => !!detail),
      map(detail => detail.name || `Untitled Operation`),
    );
  }

  private initialisePlanningStatus(): void {
    this.locationDetailsComplete$ = this.location$.pipe(
      map(location => !!location?.shape && !!location?.take_off && !!location?.landing && !!location?.emergency_landing),
    );
    this.operationDetailsComplete$ = this.operationDetail$.pipe(
      map(detail => !!detail?.name && !!detail?.start && !!detail?.end && !!detail?.operation_type),
    );

    this.weatherDetailsComplete$ = this.operationDetail$.pipe(
      // check that start date fall into available weather details.
      map(detail => {
          const weekWindow = new Date(new Date().setDate( new Date().getDate() + 8 ));
          const yesterday = new Date(new Date().setDate( new Date().getDate() - 1 ));
          const start = new Date(detail.start);
          return ( (start < weekWindow) && (start > yesterday))
        }
      ),
    );

    this.crewDetailsComplete$ = this.crewMembers$.pipe(
      map(crew => {
        const hasPilots = crew?.filter(crewMember => crewMember.role === ROCOperatorUsers.PILOT).length > 0;
        return !!crew.length && hasPilots
      }),
    );
    this.gearDetailsComplete$ = this.drones$.pipe(
      map(drones => !!drones.length),
    );
    this.airspaceRequirementsComplete$ = combineLatest([
      this.requiredDocuments$.pipe(startWith([])),
      this.documents$,
    ]).pipe(
      map(([requiredDocuments, documents]) => {
        const outstandingDocs = requiredDocuments.filter(document => {
          if (document) {
            return !documents.some(item => item.file_type === document.file_type);
          }
        })
        return !outstandingDocs.length;
      })
    )
  }

  getOperation(id?: number): Observable<Operation> {
    if (id) {
      this.operationId$.next(id);
    }
    return this.operationId$.pipe(
      filter(operationId => operationId !== null),
      switchMap(operationId => {
        return this.operation$.pipe(
          take(1), // Stop the loop that occurs after updating the `operation$`
          switchMap(existingOperation => {
            if (!existingOperation) {
              return this.queryOperation(operationId);
            }
            return of(existingOperation);
          }),
        );
      }),
      take(1), // Stop the loop that occurs after updating the `operation$`
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  public queryOperation(operationId: number): Observable<Operation> {
    const queryParams = new HttpParams();
    return this.operationApiService.getOperation(queryParams, operationId).pipe(
      tap((operation: Operation) => {
        this.operation$.next(operation);
        this.cacheLocation(operation);
        this.cacheOperationDetails(operation);
        this.cacheCrews(operation);
        this.cacheGear(operation);
        this.cacheDocuments(operation);
      }),
    );
  }

  private cacheLocation(operation: Operation) {
    if (operation.operation_location) {
      this.location$.next(operation.operation_location);
    } else {
      this.location$.next({} as OperationLocation);
    }
  }

  private cacheOperationDetails(operation: Operation) {
    if (operation.operation_detail) {
      this.operationDetail$.next(operation.operation_detail);
    } else {
      this.operationDetail$.next({} as OperationDetail);
    }
  }

  private mapCrewMembers(operationCrew: OperationCrew[]): OperationCrewMemberMapped[] {
    return operationCrew.map(crewMember => {
      return {
        id: crewMember.id,
        avatar: crewMember.crew_member.user.avatar,
        member_id: crewMember.crew_member.user.id,
        role: crewMember.role,
        is_used: crewMember.is_used,
        is_active: crewMember.is_active,
        first_name: crewMember.crew_member.user.first_name,
        last_name: crewMember.crew_member.user.last_name,
        full_name: `${crewMember.crew_member.user.first_name} ${crewMember.crew_member.user.last_name}`,
        is_admin: crewMember.crew_member.is_admin,
        is_secondary_admin: crewMember.crew_member.is_secondary_admin,
        is_pilot: crewMember.crew_member.is_pilot,
        is_persisted: true,
        is_compliant: crewMember.is_compliant,
        is_pilot_command: crewMember.is_pilot_command,
      } as OperationCrewMemberMapped;
    });
  }

  private cacheCrews(operation: Operation) {
    if (operation.operation_crews) {
      this.crewMembers$.next(this.mapCrewMembers(operation.operation_crews));
    }
  }

  private cacheGear(operation: Operation) {
    if (operation.operation_gear.length) {
      this.loadGearType<Drone>('drone', this.drones$, operation.operation_gear);
      this.loadGearType<Battery>('battery', this.batteries$, operation.operation_gear);
      this.loadGearType<Payload>('payload', this.payloads$, operation.operation_gear);
      this.loadGearType<Equipment>('equipment', this.equipment$, operation.operation_gear);
    }
    this.gearLoaded$.next(true);
  }

  private cacheDocuments(operation: Operation) {
    if (operation.operation_documents) {
      this.documents$.next(operation.operation_documents as Document[]);
    }
  }

  /**
   * Loads a specific gear type (Drone, Battery, Payload, Equipment) into the cache.
   * @param gearType: 'drone' or 'battery' or 'payload' or 'equipment'.
   * @param obs$: BehaviorSubject acting as the cache for the relevant `gearType`.
   * @param operationGearArr: The OperationGear[] returned from the API.
   */
  loadGearType<T>(gearType: string, obs$: BehaviorSubject<T[]>, operationGearArr: OperationGear[]): void {
    const filteredGear: T[] = operationGearArr
      .filter((opGear: OperationGear) => opGear[gearType])
      .map((opGear: OperationGear) => {
        return {
          ...opGear[gearType],
          is_used_current_operation: opGear.is_used,
          is_persisted: true
        };
      });
    if (filteredGear.length) {
      obs$.next(filteredGear);
    }
  }

  newOperation() {
    this.location$.next({} as OperationLocation);
    this.operationDetail$.next({} as OperationDetail);
    this.crewMembers$.next([]);
    this.drones$.next([]);
    this.batteries$.next([]);
    this.payloads$.next([]);
    this.equipment$.next([]);
    this.documents$.next([]);
  }

  resetService() {
    this.operationId$.next(null);
    this.operation$.next(null);
    // Publish empty objects as the below are use for forms fields and cannot be `null`.
    this.location$.next(null);
    this.operationDetail$.next(null);
    this.crewMembers$.next([]);
    this.drones$.next([]);
    this.batteries$.next([]);
    this.payloads$.next([]);
    this.equipment$.next([]);
    this.documents$.next([]);
    this.hazards$.next(null);
    this.signOffUsers$.next([]);
    this.signOffRequest$.next(null);
    this.pointsOfInterest$.next(null);
  }

  /**
   * If the `OperationDetails` has been updated, then "evict" the `Operation` subject to retrieval new values
   * from the BE. This is because the `OperationDetails` and the `Operation` interfaces represent the same model
   * on the BE. So we want both of the cached versions to be in-sync.
   */
  evictOperation() {
    this.operation$.next(null);
  }

  getOperationId(): Observable<number> {
    return this.operationId$;
  }

  publishOperation(id: number) {
    return this.operationApiService.publishOperation(id).pipe(
      tap(() => {
        this.evictOperation();
      })
    );
  }

  abortOperation(id: number) {
    const modalRef = this.modalService.open(ConfirmationRemoveModalComponent);
    modalRef.content.heading = 'Abort operation';
    modalRef.content.action = 'abort this operation';
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.operationApiService.abortOperation(id).subscribe(
          () => {
            this.toastrService.success('Operation has been aborted.');
            this.router.navigate(['roc', 'operations', 'closed']);
          },
          (error) => {
            if (error.error) { this.toastrService.error(error.error[0]); }
            this.toastrService.error('An error occurred while aborting the operation');
          }
        );
      }
    });
  }

  completeOperation(id: number) {
    const crew = this.crewMembers$.pipe( map((member: OperationCrewMemberMapped[]) => member.filter((crew) => !crew.is_active)))
    const operationName = this.operationName$;
    const modalRef = this.modalService.open(ROCOperationCompleteOperationModalComponent, {
      class: 'modal-lg',
      initialState: {
        heading: operationName,
        deactivatedUsers: crew,
      }
    });
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.operationApiService.completeOperation(id).subscribe(apiResult => {
          if (apiResult) {
            this.toastrService.success(`This operation has now been closed and archived.` );
            this.router.navigate(['roc', 'operations', 'closed']);
          } else {
            this.toastrService.error(`An error occurred while closing this operation.`);
          }
        }, (error) => {
          this.toastrService.error(`${error.error}`);
        });
      }
    });
  }



  // Publishable status is based on if Site inspection areas has been completed.
  getOperationSitePlanningComplete() {
    let hasDrones, hasBatteries, hasPilots, hasOperationDetail;
    this.drones$.subscribe( drones => hasDrones = drones?.length > 0);
    this.batteries$.subscribe( bats => hasBatteries = bats?.length > 0);
    this.getCrewMembers(false).pipe(
      tap(crewMembers => {
        hasPilots = crewMembers?.filter(crewMember => crewMember.role === ROCOperatorUsers.PILOT).length > 0;
      }),
    ).subscribe();
    this.operationDetail$.subscribe( operation => {
      hasOperationDetail = !!operation?.start;
    })
    return hasDrones && hasBatteries && hasPilots && hasOperationDetail;
  }

  // ----------------------------------------------- Operation Location ------------------------------------------------

  getLocation(): Observable<OperationLocation> {
    return this.location$;
  }

  /**
   * Handles creation and updating of Operation Location details.
   */
  saveLocation(locationRequest: OperationLocationRequest): Observable<OperationLocation> {
    return this.location$.pipe(
      take(1),
      switchMap(location => {
        if (location.id) {
          return this.operationApiService.updateLocation(location.id, locationRequest).pipe(
            tap(newLocation => {
              this.location$.next(newLocation);
            })
          );
        }
        return this.operationApiService.postLocation(locationRequest).pipe(
          tap(operation => {
            this.location$.next(operation);
            this.calcRequiredDocuments();
          })
        );
      })
    );
  }

  // ------------------------------------------------ Operation Detail -------------------------------------------------

  getOperationDetail(): Observable<OperationDetail> {
    return this.operationDetail$;
  }

  saveOperationDetail(request: OperationDetailsRequest): Observable<OperationDetail> {
    return this.operationDetail$.pipe(
      take(1),
      switchMap(currentOperationDetail => {
        if (currentOperationDetail.id) {
          return this.operationApiService.updateOperationDetails(currentOperationDetail.id, request).pipe(
            tap(newOperationDetail => {
              this.operationDetail$.next(newOperationDetail);
              this.evictOperation();
            })
          );
        }
        return this.operationApiService.postOperationDetails(request).pipe(
          tap(newOperationDetail => {
            this.operationDetail$.next(newOperationDetail);
            this.evictOperation();
          })
        );
      })
    );
  }

  // ------------------------------------------------------ Crew -------------------------------------------------------

  getCrewMembers(distinct = true): Observable<OperationCrewMemberMapped[]> {
    return distinct ? this.crewMembers$.pipe(
      map((crewMembers) => crewMembers.reduce((previousValue, currentValue) => {
        const existing = previousValue.find(crewMember => crewMember.member_id === currentValue.member_id);
        if (existing) {
          return previousValue;
        }
        return [...previousValue, currentValue];
      }, [] as OperationCrewMemberMapped[]))
    ) : this.crewMembers$;
  }

  /**
   * Save the crew members and then store the members from the request in the cached after they have been saved.
   */
  saveCrewMembers(request: AddCrewMembersRequest): Observable<OperationCrewMemberMapped[]> {
    return this.operationApiService.postCrewMembers(request).pipe(
      map((newMember) => {
        return request.crew_members.map(crewMember => {
          crewMember.is_persisted = true;
          crewMember.id = newMember.crew_member.user.id;
          crewMember.full_name = `${crewMember.first_name} ${crewMember.last_name}`;
          return crewMember;
        });
      }),
      tap((nextMembers: OperationCrewMemberMapped[]) => {
        this.crewMembers$.next(nextMembers);
      })
    );
  }

  // ------------------------------------------------------ Gear -------------------------------------------------------

  /**
   * Generic method to keep existing (Drone, Battery, Payload, Equipment) items in the cache after the user has saved.
   */
  private saveGearType<T extends Gear>(gearArray: T[], obs$: BehaviorSubject<T[]>): void {
    const nextArray = gearArray.map(gearItem => {
      return {
        ...gearItem,
        is_persisted: true,
        is_used_current_operation: gearItem.is_used_current_operation,
      };
    });
    obs$.next(nextArray.filter(gear => !gear.is_removed));
  }

  saveGear(operationId: number,
           drones: Drone[],
           batteries: Battery[],
           payloads: Payload[],
           equipment: Equipment[]): Observable<OperationGear> {

    const request = {
      operation_id: operationId,
      drones,
      batteries,
      payloads,
      equipment,
    } as OperationGearRequest;

    return this.operationApiService.postGear(request).pipe(
      tap(() => {
        this.saveGearType(drones, this.drones$);
        this.saveGearType(batteries, this.batteries$);
        this.saveGearType(payloads, this.payloads$);
        this.saveGearType(equipment, this.equipment$);
      }),
    );
  }

  /**
   * Generic method to add an new (Drone, Battery, Payload, Equipment) to the cache after being POSTed to the API.
   */
  private addGearType<T extends Gear>(newGear: T[], obs$: BehaviorSubject<T[]>): Observable<T[]> {
    return obs$.pipe(
      take(1),
      map((existingGear: T[]) => {
        const nextGearArr: T[] = [...existingGear];
        newGear.forEach(newGearItem => {
          const isExistingGear = existingGear.find(existingGearItem => existingGearItem.id === newGearItem.id);
          if (!isExistingGear) {
            newGearItem.is_persisted = false;
            nextGearArr.push(newGearItem);
          }
        });
        return nextGearArr;
      }),
      tap((nextGearArr: T[]) => {
        obs$.next(nextGearArr);
      })
    );
  }

  /**
   * Generic method to remove any (Drone, Battery, Payload, Equipment) from the cache, and from the BE (if it has been
   * persisted already) being POSTed to the API.
   */
  private removeGearType<T extends Gear>(gearId: number, request: RemoveGearRequest, obs$: BehaviorSubject<T[]>): Observable<T[]> {
    return obs$.pipe(
      take(1),
      switchMap((existingGear: T[]) => {
        const toDelete = existingGear.find(gearItem => gearItem.id === gearId);
        if (toDelete && toDelete.is_persisted) {
          return this.operationApiService.deleteGear(request).pipe(
            switchMap(_ => of(existingGear))
          );
        } else {
          return of(existingGear);
        }
      }),
      map((existingGear: T[]) => {
        return existingGear.filter(gearItem => gearItem.id !== gearId);
      }),
      tap((nextMembers: T[]) => {
        obs$.next(nextMembers);
      })
    );
  }

  // Drones

  getDrones(): Observable<Drone[]> {
    return this.drones$;
  }

  setDrones(drones: Drone[]): Observable<Drone[]> {
    this.drones$.next(drones);
    return this.drones$;
  }

  addDrones(newDrones: Drone[]): Observable<Drone[]> {
    return this.addGearType(newDrones, this.drones$);
  }

  removeDrone(droneId: number): Observable<Drone[]> {
    return this.operationId$.pipe(
      map(operationId => {
        return {
          operation_id: operationId,
          drone_id: droneId
        } as RemoveGearRequest;
      }),
      switchMap((request: RemoveGearRequest) => {
        return this.removeGearType(droneId, request, this.drones$);
      })
    );
  }

  // Batteries

  getBatteries(): Observable<Battery[]> {
    return this.batteries$;
  }

  setBatteries(batteries: Battery[]): Observable<Battery[]> {
    this.batteries$.next(batteries);
    return this.batteries$;
  }

  addBatteries(newBatteries: Battery[]): Observable<Battery[]> {
    return this.addGearType(newBatteries, this.batteries$);
  }

  removeBattery(batteryId: number): Observable<Battery[]> {
    return this.operationId$.pipe(
      map(operationId => {
        return {
          operation_id: operationId,
          battery_id: batteryId
        } as RemoveGearRequest;
      }),
      switchMap((request: RemoveGearRequest) => {
        return this.removeGearType(batteryId, request, this.batteries$);
      })
    );
  }

  // Payloads

  getPayloads(): Observable<Payload[]> {
    return this.payloads$;
  }

  setPayloads(payloads: Payload[]): Observable<Payload[]> {
    this.payloads$.next(payloads);
    return this.payloads$;
  }

  addPayloads(newPayloads: Payload[]): Observable<Payload[]> {
    return this.addGearType(newPayloads, this.payloads$);
  }

  removePayload(payloadId: number): Observable<Payload[]> {
    return this.operationId$.pipe(
      map(operationId => {
        return {
          operation_id: operationId,
          payload_id: payloadId
        } as RemoveGearRequest;
      }),
      switchMap((request: RemoveGearRequest) => {
        return this.removeGearType(payloadId, request, this.payloads$);
      })
    );
  }

  // Equipment

  getEquipment(): Observable<Equipment[]> {
    return this.equipment$;
  }

  setEquipment(newEquipment: Equipment[]): Observable<Equipment[]> {
    this.equipment$.next(newEquipment);
    return this.equipment$;
  }

  addEquipment(newEquipment: Equipment[]): Observable<Equipment[]> {
    return this.addGearType(newEquipment, this.equipment$);
  }

  removeEquipment(equipmentId: number): Observable<Equipment[]> {
    return this.operationId$.pipe(
      map(operationId => {
        return {
          operation_id: operationId,
          equipment_id: equipmentId
        } as RemoveGearRequest;
      }),
      switchMap((request: RemoveGearRequest) => {
        return this.removeGearType(equipmentId, request, this.equipment$);
      })
    );
  }

  // ---------------------------------------------------- Documents ----------------------------------------------------

  getDocuments(): Observable<Document[]> {
    return this.documents$;
  }

  calcRequiredDocuments() {
    const docs: RequiredDocument[] = [];
    this.location$.subscribe(
      location => {
        if (location) {
          if (location.airspace?.includes('FAR')) {
            docs.push({
              file_type: DocumentType.LAND_OWNER_PERMISSION,
              required_reason: 'FAR'
            });
          }
          if (location.airspace?.includes('FAP')) {
            docs.push({
              file_type: DocumentType.LAND_OWNER_PERMISSION,
              required_reason: 'FAP'
            });
          }
          if (location.airspace?.includes('FAD')) {
            docs.push({
              file_type: DocumentType.LAND_OWNER_PERMISSION,
              required_reason: 'FAD'
            });
          }
          if (location.airspace?.includes('CTR')) {
            docs.push(
              {
                file_type: DocumentType.FLEXIBLE_USE_OF_AIRSPACE,
                required_reason: 'CTR'
              },
              {
                file_type: DocumentType.FLIGHT_PLAN,
                required_reason: 'CTR'
              }
            );
          }
        }
      }
    )
    this.requiredDocuments$.next(docs)
  }

  getRequiredDocuments(): Observable<RequiredDocument[]> {
    this.calcRequiredDocuments()
    return this.requiredDocuments$;
  }

  addDocument(newDocument: Document): Observable<Document[]> {
    return this.documents$.pipe(
      take(1),
      map((initialDocuments: Document[]) => {
        return [...initialDocuments, newDocument];
      }),
      tap((nextDocuments: Document[]) => {
        this.documents$.next(nextDocuments);
      })
    );
  }

  removeDocument(removeDocument: Document): Observable<Document[]> {
    return this.documents$.pipe(
      take(1),
      map((existingDocuments: Document[]) => {
        return existingDocuments.filter(existingDoc => existingDoc.id !== removeDocument.id);
      }),
      tap((nextDocuments: Document[]) => {
        this.documents$.next(nextDocuments);
      })
    );
  }

  getAirspaceDocumentTitle(documentType: OperationRelatedDocumentTypes): string {
    switch (documentType) {
      case OperationRelatedDocumentTypes.LAND_OWNER_PERMISSION:
        return 'Land Owner Permission';
      case OperationRelatedDocumentTypes.FLEXIBLE_USE_OF_AIRSPACE:
        return 'FUA';
      case OperationRelatedDocumentTypes.FLIGHT_PLAN:
        return 'Flight Plan';
      case OperationRelatedDocumentTypes.CA10120:
        return 'CA10120';
      case OperationRelatedDocumentTypes.CA10118:
        return 'CA10118';
      case OperationRelatedDocumentTypes.AD_HOC:
        return 'Additional Document';
      case OperationRelatedDocumentTypes.CREW_BRIEFING:
        return 'Crew Briefing';
    }
  }

  // ----------------------------------------------------- Hazards -----------------------------------------------------

  /**
   * Evict the hazards list cache, forcing the retrieval of the hazards list from the API.
   */
  evictHazards(): void {
    this.hazards$.next(null);
    this.signOffRequest$.next(null);
    this.signOffUsers$.next([]);
  }

  /**
   * Returns a cached hazards list, if it doesn't exist, then retrieve it from the API.
   */
  getHazards(queryParams?: HttpParams): Observable<OperationHazard[]> {
    return this.hazards$.pipe(
      take(1),
      switchMap(hazards => {
        if (!hazards) {
          return this.queryHazards(queryParams);
        }
        return this.hazards$;
      })
    );
  }

  private queryHazards(queryParams: HttpParams) {
    return this.operationId$.pipe(
      switchMap(operationId => {
        queryParams = queryParams.append('operation', operationId);
        return this.operationApiService.getOperationHazard(queryParams).pipe(
          map( hazards => hazards.operation_hazards),
          tap( hazards => this.hazards$.next(hazards))
        );
      })
    );
  }

  getSignOffUsers(queryParams?: HttpParams): Observable<UserProfile[]> {
    // Force a re-fetch everytime as any edit can cause this to change.
    return this.querySignOffUsers(queryParams);
  }

  private querySignOffUsers(queryParams: HttpParams) {
    return this.operationId$.pipe(
      switchMap(operationId => {
        queryParams = queryParams.appendAll({
          operation: operationId
        })
        return this.operationApiService.getOperationHazard(queryParams).pipe(
          map( operationHazard => operationHazard.approved_sign_off_users),
          tap(users => this.signOffUsers$.next(users))
        );
      })
    );
  }

  getSignOffRequest(queryParams?: HttpParams): Observable<HazardSignOffRequest> {
    // Force a re-fetch everytime as any edit can cause this to change.
    return this.querySignOffRequest(queryParams);
  }

  private querySignOffRequest(queryParams: HttpParams) {
    return this.operationId$.pipe(
      switchMap(operationId => {
        queryParams = queryParams.append('operation', operationId)
        return this.operationApiService.getOperationHazard(queryParams).pipe(
          map( operationHazard => operationHazard.sign_off_request),
          tap(request => this.signOffRequest$.next(request))
        );
      })
    );
  }

  /**
   * Retrieve a particular hazard from the hazards list.
   */
  getHazardDetail(hazardId: number): Observable<OperationHazard> {
    return this.hazards$.pipe(
      filter(hazard => !!hazard),
      mergeAll(),
      filter(hazard => hazard.id === hazardId),
      first(),
    );
  }

  /**
   * Adds a new hazard to the existing hazards list cache.
   * @return The same hazard.
   */
  private addHazard(newHazard: OperationHazard, replace = false): Observable<OperationHazard> {
    return this.hazards$.pipe(
      take(1),
      map((existingHazards: OperationHazard[]) => {
        if (replace) {
          // If existing, then remove the existing hazard so that we can add the new version.
          const foundHazardIndex = existingHazards.findIndex(hazard => hazard.id === newHazard.id);
          if (foundHazardIndex !== -1) {
            // Replace existing hazard.
            const newHazards = [...existingHazards];
            newHazards[foundHazardIndex] = newHazard;
            return newHazards;
          }
        }
        return [...existingHazards, newHazard];
      }),
      tap((hazards: OperationHazard[]) => {
        this.hazards$.next(hazards);
      }),
      mergeAll(),
      filter(hazard => hazard.id === newHazard.id),
      first()
    );
  }

  saveHazard(hazardId: number, request: OperationHazardRequest): Observable<OperationHazard> {
    if (hazardId) {
      return this.operationApiService.updateHazard(hazardId, request).pipe(
        switchMap(newHazard => this.addHazard(newHazard, true))
      );
    }
    return this.operationApiService.postHazard(request).pipe(
      switchMap(newHazard => this.addHazard(newHazard))
    );
  }

  removeHazard(hazardId: number): Observable<OperationHazard[]> {
    const params = new HttpParams();
    return this.operationApiService.deleteHazard(params, hazardId).pipe(
      switchMap(() => this.hazards$),
      take(1),
      map((existingHazards: OperationHazard[]) => {
        return existingHazards.filter(hazard => hazard.id !== hazardId);
      }),
      tap((existingHazards: OperationHazard[]) => {
        this.hazards$.next(existingHazards);
      })
    );
  }

  // ----------------------------------------------------- Hazards Sign Off Request-----------------------------------------------------

  sendHazardSignOffRequest(selectedUserId: number) {
    return this.operationId$.pipe(
      switchMap(operationId => {
        const requestData = {
          operation: operationId,
          sign_off_requested_from: selectedUserId,
          sign_off_requested_by: this.userId,
        }
        return this.operationApiService.sendHazardSignOffRequest(requestData);
      })
    );
  }

  resendHazardSignOffRequest(requestId: number, selectedUserId: number) {
    return this.operationId$.pipe(
      switchMap(operationId => {
        const requestData = {
          operation: operationId,
          sign_off_requested_from: selectedUserId,
          sign_off_requested_by: this.userId,
        }
        return this.operationApiService.resendHazardSignOffRequest(requestId, requestData);
      })
    );
  }

  // ----------------------------------------------- Points of Interest ------------------------------------------------

  /**
   * Evict the points of interest list cache, forcing retrieval of the points of interest from the API.
   */
  evictPointsOfInterest(): void {
    this.pointsOfInterest$.next(null);
  }

  /**
   * Returns a cached points of interest list, fetching it from the API if the cache does not exist.
   */
  getPointsOfInterest(): Observable<OperationPointOfInterest[]> {
    return this.queryPointsOfInterest().pipe(
      tap(pointsOfInterest => this.pointsOfInterest$.next(pointsOfInterest)),
    );
  }

  private queryPointsOfInterest() {
    return this.operationId$.pipe(
      switchMap(operationId => {
        let queryParams = new HttpParams().set('operation', operationId);
        return this.operationApiService.getPointOfInterestList(queryParams).pipe(
          tap(pointsOfInterest => this.pointsOfInterest$.next(pointsOfInterest))
        );
      }));
  }

  /**
   * Retrieve a particular point of interest from the points of interest list.
   */
  getPointOfInterestDetail(pointOfInterestId: number): Observable<OperationPointOfInterest> {
    return this.pointsOfInterest$.pipe(
      filter(pointOfInterest => !!pointOfInterest),
      mergeAll(),
      filter(pointOfInterest => pointOfInterest.id === pointOfInterestId),
      first(),
    );
  }

  /**
   * Adds a new point of interest to the existing point of interests list cache.
   * @return The same point of interest.
   */
  addPointOfInterest(newPointOfInterest: OperationPointOfInterest, replace = false): Observable<OperationPointOfInterest> {
    return this.pointsOfInterest$.pipe(
      take(1),
      map((existingPointsOfInterest: OperationPointOfInterest[]) => {
        if (replace) {
          // If existing, then remove the existing point of interest so that we can add the new version.
          const foundPointOfInterestIndex =
            existingPointsOfInterest.findIndex(pointOfInterest => pointOfInterest.id === newPointOfInterest.id);
          if (foundPointOfInterestIndex !== -1) {
            // Remove existing point of interest.
            existingPointsOfInterest.splice(foundPointOfInterestIndex, 1);
          }
        }
        return existingPointsOfInterest;
      }),
      map((existingPointsOfInterest: OperationPointOfInterest[]) => {
        // Add the new PointOfInterest.
        return [...existingPointsOfInterest, newPointOfInterest];
      }),
      tap((pointsOfInterest: OperationPointOfInterest[]) => {
        this.pointsOfInterest$.next(pointsOfInterest);
      }),
      mergeAll(),
      filter(pointOfInterest => pointOfInterest.id === newPointOfInterest.id),
      first()
    );
  }

  savePointOfInterest(pointOfInterestId: number, request: OperationPointOfInterestRequest): Observable<OperationPointOfInterest> {
    if (pointOfInterestId) {
      return this.operationApiService.updatePointOfInterest(pointOfInterestId, request).pipe(
        switchMap(newPointOfInterest => this.addPointOfInterest(newPointOfInterest, true))
      );
    }
    return this.operationApiService.postPointOfInterest(request).pipe(
      switchMap(newPointOfInterest => this.addPointOfInterest(newPointOfInterest))
    );
  }

  removePointOfInterest(pointOfInterestId: number): Observable<OperationPointOfInterest[]> {
    const params = new HttpParams();
    return this.operationApiService.deletePointOfInterest(params, pointOfInterestId).pipe(
      switchMap(() => this.pointsOfInterest$),
      take(1),
      map(existingPointsOfInterest =>
        existingPointsOfInterest.filter(pointOfInterest => pointOfInterest.id !== pointOfInterestId)
      ),
      tap(existingPointsOfInterest => this.pointsOfInterest$.next(existingPointsOfInterest)),
    );
  }

  // ---------------------------------------------- Emergency Information ----------------------------------------------

  getEmergencyInformation(): Observable<EmergencyInformation> {
    return this.operationId$.pipe(
      switchMap(operationId => {
        const queryParams = new HttpParams().set('operation', operationId);
        return this.emergencyInformation$.pipe(
          switchMap((emergencyInformation) => {
            if (emergencyInformation) {
              return of(emergencyInformation);
            } else {
              return this.operationApiService.getEmergencyInformation(queryParams)
                .pipe(
                  map(arr => {
                    if (arr.length) {
                      return arr[0] as EmergencyInformation;
                    }
                    return {
                      emergency_equipment: []
                    } as EmergencyInformation;
                  }),
                  tap(fetchedInfo => {
                    this.emergencyInformation$.next(fetchedInfo);
                  })
                );
            }
          }),
        );
      }),
    );
  }

  saveEmergencyInformation(request: EmergencyInformationRequest): Observable<EmergencyInformation> {
    // TODO: Adrian use cache
    return this.getEmergencyInformation()
      .pipe(
        switchMap(emergencyInformation => {
          if (emergencyInformation.id) {
            return this.operationApiService.updateEmergencyInformation(emergencyInformation.id, request);
          }
          return this.operationApiService.postEmergencyInformation(request);
        }),
        tap((emergencyInfo) => this.emergencyInformation$.next(emergencyInfo)),
        catchError((err) => {
          return of(err);
        })
      );
  }

  // ----------------------------------------------- Emergency Services ------------------------------------------------

  getEmergencyServices(): Observable<EmergencyService[]> {
    return this.operationId$.pipe(
      switchMap(operationId => {
        const queryParams = new HttpParams().set('operation', operationId);
        return this.operationApiService.getEmergencyServices(queryParams);
      })
    );
  }

  saveEmergencyService(request: EmergencyServiceRequest, emergencyServiceId?: number): Observable<EmergencyService> {
    if (emergencyServiceId) {
      return this.operationApiService.updateEmergencyService(emergencyServiceId, request);
    }
    return this.operationApiService.postEmergencyService(request);
  }

  getComplianceChecks(): Observable<PreflightComplianceCheck[]> {
    return this.operationId$.pipe(
      switchMap( operationId => {
        const queryParams = new HttpParams().set('operation', operationId);
        return this.operationApiService.getOperationComplianceChecks(queryParams);
      })
    )
  }
}
