import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseApiService, PaginatedResults } from '@bo/ng-api';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, first, map, tap } from 'rxjs/operators';
import {  Battery, Drone, GearMaintenanceCheck, GearMaintenanceCheckFormData,
  DroneMaintenanceOverview, GearMaintenancePlan, GearMaintenancePlanFormData,
  GearMaintenanceLog, GearMaintenanceLogFormData, Equipment, Payload, AvailableMaintenanceChecks,
  FlightFolio, MinimalFlightFolio, DroneRequest, BatteryChargeLog, BatteryLatestChargeCycle, BatteryChargeLogFormData,
  FlightFolioSignOffTasks
} from 'src/app/shared/api/interface/api.models';
import { FileDownloaderService } from '../../../../../shared/services/file-downloader.service';
import { AddRocParamsToApiQuery, UserSelectedROCMixin } from '../../../store/helpers';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../store/states';
import { UserLinkedROCMapped } from '../../../store/models';
import { Router } from '@angular/router';

@Injectable()
export class GearApiService extends BaseApiService implements UserSelectedROCMixin {
  baseUrl = environment.apiRoot;

  /**
   * Besides the retrieval of schema definition, one single API is needed for
   * the CRUD functionalities of all Gear child polymorphic models i.e.,
   *  i. Drones
   *  ii. Batteries
   *  iii. Payloads
   *  iv. Equipment
   *
   * As for the Schema, considering the fact that OPTIONS cannot be used from the
   * parent polymorphic model retrieve child schema, separate endpoints are
   * required to create reactive forms with OPTIONS from the BE
   *
   * FIXME: REFINE
   */

    // Base Gear Elements
  URL_GEAR = 'gear/gear';

  // Get Drone schema definition
  URL_DRONE_OPTIONS = 'gear/drone';

  // Get Battery schema definition
  URL_BATTERY_OPTIONS = 'gear/battery';

  // Get Payload schema definition
  URL_PAYLOAD_OPTIONS = 'gear/payload';

  // Get Equipment schema definition
  URL_EQUIPMENT_OPTIONS = 'gear/equipment';

  // Get Gear Maintenance overview
  URL_MAINTENANCE_OVERVIEW = 'gear/maintenance-overview';

  // Get Gear Maintenance plan
  URL_MAINTENANCE_PLAN = 'gear/maintenance-plan';

  // Get Gear Maintenance Checks
  URL_MAINTENANCE_CHECK = 'gear/maintenance-check';

  // Get Gear Maintenance Logs List
  URL_MAINTENANCE_LOG = 'gear/maintenance-log';

  // Get Available Gear Maintenance Checks
  URL_AVAILABLE_MAINTENANCE_CHECKS = 'gear/available-maintenance-checks';

  // Flight folio base URL
  URL_FLIGHT_FOLIO = 'flight-folio';

  // Get flight folio
  URL_GET_FLIGHT_FOLIO = 'flight_folio'; // Relative to URL_FLIGHT_FOLIO

  // Sign off crew member on flight folio
  URL_FLIGHT_FOLIO_SIGN_OFF_CREW_MEMBER = 'flight_folio/sign_off_crew_member'; // Relative to URL_FLIGHT_FOLIO

  // Sign off flight on flight folio
  URL_FLIGHT_FOLIO_SIGN_OFF_FLIGHT = 'flight_folio/sign_off_flight'; // Relative to URL_FLIGHT_FOLIO

  // Sign off incident on flight folio
  URL_FLIGHT_FOLIO_SIGN_OFF_INCIDENT = 'flight_folio/sign_off_incident'; // Relative to URL_FLIGHT_FOLIO

  URL_FLIGHT_FOLIO_SIGN_OFF_TASKS = 'flight_folio/pending_tasks';

  // Battery Charge Logs
  URL_CHARGE_LOG = 'charge-logs/charge_logs';

  URL_GET_LATEST_CYCLE = 'get_latest_cycle'; // relative to URL_CHARGE_LOG

  rocId: number;
  rocUser: number;

  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  constructor(
    protected http: HttpClient,
    private fileDownloader: FileDownloaderService,
    protected router: Router,
  ) { super(http);}

  getROCData() {
    this.selectedROC$.pipe(
      first(roc => !!roc),
      map(roc => {
        this.rocId = roc.id;
        this.rocUser = roc.roc_user.id;
      }),
    ).subscribe();
  }

  private mapDataWithParams<T>(data:T): T & { remote_operator: number; roc_user: number } {
    this.getROCData();
    return {...data, remote_operator: this.rocId, roc_user: this.rocUser};
  }

  /****************************** Gear general *****************************/

  /**
   * Delete a gear item based on its id and gear_type
   */
  deleteGear(gear) {
    let data = {
      id: gear.id,
      gear_type: gear.gear_type
    };
    data = this.mapDataWithParams(data);
    return this.http.delete(this.getUrl([this.URL_GEAR, data.id]), {body: data});
  }

  /****************************** I. Drone *****************************/

  /**
   * Get all the drones uploaded by the current (pilot) user
   *
   * @return Observable<PaginatedResults<Drone>>
   */
  @AddRocParamsToApiQuery()
  getDrones(queryParams: HttpParams): Observable<PaginatedResults<Drone>> {
    const url = this.getUrl([this.URL_GEAR])
    return this.http.get(url,{observe: 'response', params: queryParams}).pipe(
      map(data => { return new PaginatedResults<Drone>(data) })
    );
  }

  /**
   * Get a drone's information based on its id
   *
   * @return Observable<Drone>
   */
  @AddRocParamsToApiQuery()
  getDroneDetail(queryParams: HttpParams, id: string | number): Observable<Drone> {
    return this.http.get(this.getUrl([this.URL_GEAR, id]), {params: queryParams}).pipe(
      map(respData => respData as Drone),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    )
  }

  /**
   * Create a new drone
   *
   * @return Observable<Drone>
   */
  postDrone(data: DroneRequest, queryParams?: HttpParams): Observable<Drone> {
    const url = this.getUrl([this.URL_GEAR]);
    return this.http.post(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map(data => data as Drone)
    );
  }

  /**
   * Update a drone's information based on its id
   *
   * @return Observable<Drone>
   */
  updateDrone(id: number | string, data, queryParams: HttpParams): Observable<Drone> {
    const url = this.getUrl([this.URL_GEAR, id]);
    return this.http.patch(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map((respData: Drone) => respData)
    );
  }

  /********************* I.i Drone Maintenance Overview ***************/

  /**
   * Get drone Maintenance Overview
   * @return Observable<DroneMaintenanceOverview>
   */
  @AddRocParamsToApiQuery()
  getDroneMaintenanceOverview(queryParams: HttpParams, overviewId: number): Observable<DroneMaintenanceOverview> {
    const url = this.getUrl([this.URL_MAINTENANCE_OVERVIEW, overviewId]);
    return this.http.get<DroneMaintenanceOverview>(url,{params: queryParams});
  }

  /**
   * Update drone Maintenance Overview
   * @return Observable<DroneMaintenanceOverview>
   */
  updateDroneMaintenanceOverview(overview): Observable<DroneMaintenanceOverview> {
    const url = this.getUrl([this.URL_MAINTENANCE_OVERVIEW]);
    return this.http.post<DroneMaintenanceOverview>(url, this.mapDataWithParams(overview));
  }

  /********************* I.ii Flight Folio ***************/

  /**
   * Fetches a list of flight folios belonging to a drone.
   */
  @AddRocParamsToApiQuery()
  getFlightFolios(queryParams: HttpParams): Observable<PaginatedResults<MinimalFlightFolio>> {
    const url = this.getUrl([this.URL_FLIGHT_FOLIO, this.URL_GET_FLIGHT_FOLIO]);
    return this.http.get<MinimalFlightFolio[]>(url, { observe: 'response', params: queryParams }).pipe(
      map((response) => { return new PaginatedResults<MinimalFlightFolio>(response) })
    );
  }

  /**
   * Fetches a drone's flight folio.
   */
  @AddRocParamsToApiQuery()
  getFlightFolio(queryParams: HttpParams, id: number): Observable<FlightFolio> {
    const url = this.getUrl([this.URL_FLIGHT_FOLIO, this.URL_GET_FLIGHT_FOLIO, id]);
    return this.http.get<FlightFolio>(url,{params: queryParams});
  }

  /**
   * Sign off a flight folio crew member.
   */
  signOffFlightFolioCrewMember(id: number): Observable<void> {
    const url = this.getUrl([this.URL_FLIGHT_FOLIO, this.URL_FLIGHT_FOLIO_SIGN_OFF_CREW_MEMBER]);
    return this.http.put<void>(url, this.mapDataWithParams({id}));
  }

  /**
   * Sign off a flight folio flight.
   */
  signOffFlightFolioFlight(id: number): Observable<void> {
    const url = this.getUrl([this.URL_FLIGHT_FOLIO, this.URL_FLIGHT_FOLIO_SIGN_OFF_FLIGHT]);
    return this.http.put<void>(url, this.mapDataWithParams({id}));
  }

  /**
   * Sign off a flight folio incident.
   */
  signOffFlightFolioIncident(id: number): Observable<void> {
    const url = this.getUrl([this.URL_FLIGHT_FOLIO, this.URL_FLIGHT_FOLIO_SIGN_OFF_INCIDENT]);
    return this.http.put<void>(url, this.mapDataWithParams({id}));
  }

  /**
   * Download the flight folio as a PDF.
   */
  @AddRocParamsToApiQuery()
  downloadFlightFolioPDF(queryParams: HttpParams, folio: FlightFolio) {
    const url = this.getUrl([this.URL_FLIGHT_FOLIO, this.URL_GET_FLIGHT_FOLIO, folio.id]);
    return this.http.get(url, {responseType: 'blob', params: queryParams}).pipe(
      tap((blob: Blob) => {
        const filename = `FlightFolio-${folio.doc_number}.pdf`;
        this.fileDownloader.download(blob, filename);
      }),
    );
  }

  @AddRocParamsToApiQuery()
  getSignOffFlightFolioTasks(queryParams: HttpParams): Observable<FlightFolioSignOffTasks> {
    const url = this.getUrl([this.URL_FLIGHT_FOLIO, this.URL_FLIGHT_FOLIO_SIGN_OFF_TASKS]);
    return this.http.get<FlightFolioSignOffTasks>(url,{params: queryParams});
  }

  /****************************** II. Battery *****************************/

  /**
   * Get all the batteries uploaded by the current (pilot) user
   * @return Observable<PaginatedResults<Battery>>
   */
  @AddRocParamsToApiQuery()
  getBatteries(queryParams?: HttpParams): Observable<PaginatedResults<Battery>> {
    const url = this.getUrl([this.URL_GEAR]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map(data => { return new PaginatedResults<Battery>(data) })
    );
  }

  /**
   * Get a battery's information based on its id
   * @return Observable<Battery>
   */
  @AddRocParamsToApiQuery()
  getBatteryDetail(queryParams: HttpParams, id: string | number): Observable<Battery> {
    const url = this.getUrl([this.URL_GEAR, id]);
    return this.http.get(url, {params: queryParams}).pipe(
      map(respData => respData as Battery),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Create a new battery
   * @return Observable<Battery>
   */
  postBattery(data: Battery, queryParams?: HttpParams): Observable<Battery> {
    const url = this.getUrl([this.URL_GEAR]);
    return this.http.post(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map(data => data as Battery)
    );
  }

  /**
   * Update a battery's information based on its id
   * @return Observable<Battery>
   */
  updateBattery(id: number | string, data, queryParams: HttpParams): Observable<Battery> {
    const url = this.getUrl([this.URL_GEAR, id])
    return this.http.patch(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map((respData: Battery) => respData)
    );
  }

  /****************************** II.i Battery Charge Logs *****************************/

  /**
   * Get a battery's charge log information based on given battery id
   * @return Observable<BatteryChargeLog[]>
   */
  @AddRocParamsToApiQuery()
  getBatteryChargeLogs(queryParams: HttpParams): Observable<BatteryChargeLog[]> {
    const url = this.getUrl(this.URL_CHARGE_LOG);
    return this.http.get(url, {params: queryParams}).pipe(
      map(respData => respData as BatteryChargeLog[]));
  }

  /**
   * Create new battery's charge log
   * @return Observable<BatteryChargeLogFormData>
   */

  createBatteryChargeLog(requestData: BatteryChargeLogFormData): Observable<BatteryChargeLog> {
    const url = this.getUrl([this.URL_CHARGE_LOG]);
    return this.http.post<BatteryChargeLog>(url, this.mapDataWithParams(requestData));
  }

  /**
   * Update a battery's charge log information
   * @return Observable<BatteryChargeLogFormData>
   */

  updateBatteryChargeLog(requestData: BatteryChargeLogFormData, logId: number): Observable<BatteryChargeLogFormData> {
    const url = this.getUrl([this.URL_CHARGE_LOG, logId]);
    return this.http.put<BatteryChargeLog>(url, this.mapDataWithParams(requestData));
  }

  /**
   * Delete a battery's charge log that is not linked to a flight
   */

  deleteBatteryChargeLog(gearId: number, logId: number): Observable<void> {
    const url = this.getUrl([this.URL_CHARGE_LOG, logId]);
    let data = { battery_id: gearId }
    data = this.mapDataWithParams(data);
    return this.http.delete<void>(url, { body: data});
  }

  /**
   * Get a battery's latest battery log cycle (for In-flight to display)
   * @return Observable<BatteryChargeLog[]>
   */
  @AddRocParamsToApiQuery()
  getBatteryLatestChargeCycle(queryParams: HttpParams): Observable<BatteryLatestChargeCycle> {
    const url = this.getUrl([this.URL_CHARGE_LOG, this.URL_GET_LATEST_CYCLE]);
    return this.http.get(url, {params: queryParams}).pipe(
      map(respData => respData as BatteryLatestChargeCycle));
  }

  /****************************** III. Payload *****************************/

  /**
   * Get all the payloads uploaded by the current (pilot) user
   * @return Observable<PaginatedResults<Payload>>
   */
  @AddRocParamsToApiQuery()
  getPayloads(queryParams?: HttpParams): Observable<PaginatedResults<Payload>> {
    const url = this.getUrl([this.URL_GEAR]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map(data => { return new PaginatedResults<Payload>(data) })
    );
  }

  /**
   * Get a payload's information based on its id
   * @return Observable<Payload>
   */
  @AddRocParamsToApiQuery()
  getPayloadDetail(queryParams: HttpParams, id: string | number): Observable<Payload> {
    const url = this.getUrl([this.URL_GEAR, id]);
    return this.http.get(url, {params: queryParams}).pipe(
      map(respData => respData as Payload),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Create a new payload
   * @return Observable<Payload>
   */
  postPayload(data: Payload, queryParams?: HttpParams): Observable<Payload> {
    const url = this.getUrl([this.URL_GEAR]);
    return this.http.post(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map(data => data as Payload)
    );

  }

  /**
   * Update a payload's information based on its id
   * @return Observable<Payload>
   */
  updatePayload(id: number | string, data, queryParams: HttpParams): Observable<Payload> {
    const url = this.getUrl([this.URL_GEAR, id]);
    return this.http.patch(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map((respData: Payload) => respData)
    );
  }

  /****************************** IV. Equipment *****************************/

  /**
   * Get all the equipment uploaded by the current (pilot) user
   * @return Observable<PaginatedResults<Equipment>>
   */
  @AddRocParamsToApiQuery()
  getEquipment(queryParams?: HttpParams): Observable<PaginatedResults<Equipment>> {
    const url = this.getUrl([this.URL_GEAR]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map(data => {
        return new PaginatedResults<Equipment>(data);
      })
    );
  }

  /**
   * Get an equipment item's information based on its id
   * @return Observable<Equipment>
   */
  @AddRocParamsToApiQuery()
  getEquipmentDetail(queryParams: HttpParams, id: string | number): Observable<Equipment> {
    const url = this.getUrl([this.URL_GEAR, id]);
    return this.http.get(url, {params: queryParams}).pipe(
      map(respData => respData as Equipment),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/roc/**'], {skipLocationChange: true});
        }
        return throwError(error);
      })
    );
  }

  /**
   * Create a new equipment item
   * @return Observable<Equipment>
   */
  postEquipment(data: Equipment, queryParams?: HttpParams): Observable<Equipment> {
    const url = this.getUrl([this.URL_GEAR]);
    return this.http.post(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map(data => data as Equipment)
    );
  }


  /**
   * Update an equipment item's information based on its id
   * @return Observable<Equipment>
   */
  updateEquipment(id: number | string, data, queryParams: HttpParams): Observable<Equipment> {
    const url = this.getUrl([this.URL_GEAR, id]);
    return this.http.patch(url, this.mapDataWithParams(data), {params: queryParams}).pipe(
      map((respData: Equipment) => respData)
    );
  }

  /****************************** V. Gear Maintenance *****************************/

  /*************************** V.i Gear Maintenance Plan ***************************/

  /**
   * Get gear Maintenance Plan
   * @return Observable<GearMaintenancePlan>
   */
  @AddRocParamsToApiQuery()
  getGearMaintenancePlan(queryParams: HttpParams, gearId: number): Observable<GearMaintenancePlan> {
    const url = this.getUrl([this.URL_MAINTENANCE_PLAN, gearId]);
    return this.http.get<GearMaintenancePlan>(url, {params: queryParams});
  }

  /**
   * Create gear Maintenance Plan
   * @return Observable<GearMaintenancePlanFormData>
   */
  createGearMaintenancePlan(maintenancePlan: GearMaintenancePlanFormData) {
    const url = this.getUrl([this.URL_MAINTENANCE_PLAN]);
    return this.http.post<GearMaintenancePlan>(url, this.mapDataWithParams(maintenancePlan)).pipe(
      map(data => data as GearMaintenancePlanFormData)
    );
  }

  /**
   * Update gear Maintenance Plan
   * @return Observable<GearMaintenancePlanFormData>
   */
  updateGearMaintenancePlan(planId: number, maintenancePlan: GearMaintenancePlanFormData) {
    const url = this.getUrl([this.URL_MAINTENANCE_PLAN, planId]);
    return this.http.put<GearMaintenancePlan>(url, this.mapDataWithParams(maintenancePlan));
  }

  /************************** V.ii Gear Maintenance Checks *************************/

  /**
   * Get gear Maintenance Checks
   * @return Observable<GearMaintenanceCheck>
   */
  @AddRocParamsToApiQuery()
  getGearMaintenanceChecks(queryParams?: HttpParams): Observable<GearMaintenanceCheck[]> {
    const url = this.getUrl([this.URL_MAINTENANCE_CHECK]);
    return this.http.get(url,{params: queryParams}).pipe(
      map ( data => data as GearMaintenanceCheck[])
    );
  }

  /**
   * Create gear Maintenance Check
   * @return Observable<GearMaintenanceCheckFormData>
   */
  createGearMaintenanceCheck(maintenanceCheck: GearMaintenanceCheckFormData): Observable<GearMaintenanceCheck> {
    const url = this.getUrl([this.URL_MAINTENANCE_CHECK]);
    return this.http.post<GearMaintenanceCheck>(url, this.mapDataWithParams(maintenanceCheck));
  }

  /**
   * Update gear Maintenance Check
   * @return Observable<GearMaintenanceCheckFormData>
   */
  updateGearMaintenanceCheck(maintenanceCheck: GearMaintenanceCheckFormData, checkId?: number): Observable<GearMaintenanceCheck> {
    const url = this.getUrl([this.URL_MAINTENANCE_CHECK, checkId]);
    return this.http.put<GearMaintenanceCheck>(url, this.mapDataWithParams(maintenanceCheck));
  }

  /**
   * Deletes a gear Maintenance Check
   * @return Observable<GearMaintenanceCheck>
   */
  deleteGearMaintenanceCheck(gearId: number, checkId: number): Observable<void> {
    const url = this.getUrl([this.URL_MAINTENANCE_CHECK, checkId]);
    let data = { gear_id: gearId }
    data = this.mapDataWithParams(data);
    return this.http.delete<void>(url, { body: data});
  }

  /************************** V.iii Gear Maintenance Logs *************************/

  /**
   * Get gear Maintenance Logs
   * @return Observable<GearMaintenanceLog>
   */
  @AddRocParamsToApiQuery()
  getGearMaintenanceLogs(queryParams?: HttpParams): Observable<PaginatedResults<GearMaintenanceLog>> {
    const url = this.getUrl([this.URL_MAINTENANCE_LOG]) ;
    return this.http.get<GearMaintenanceLog[]>(url, {observe: 'response', params: queryParams}).pipe(
      map(data => {
        return new PaginatedResults<GearMaintenanceLog>(data);
      })
    );
  }

  /**
   * Get a gear Maintenance Log detail
   * @return Observable<GearMaintenanceLog>
   */
  @AddRocParamsToApiQuery()
  getGearMaintenanceLogDetail(queryParams: HttpParams, logId: number): Observable<GearMaintenanceLog> {
    const url = this.getUrl([this.URL_MAINTENANCE_LOG, logId]);
    return this.http.get(url, {observe: 'response', params: queryParams}).pipe(
      map((response: any) => response.body as GearMaintenanceLog)
    );
  }

  /**
   * Get available maintenance checks
   * @return Observable<AvailableMaintenanceChecks>
   */
  @AddRocParamsToApiQuery()
  getAvailableMaintenanceChecks(queryParams: HttpParams): Observable<AvailableMaintenanceChecks[]> {
    const url = this.getUrl([this.URL_AVAILABLE_MAINTENANCE_CHECKS]);
    return this.http.get<AvailableMaintenanceChecks[]>(url, {params: queryParams}
    );
  }

  /**
   * Create gear Maintenance Log
   * @return Observable<GearMaintenanceLogFormData>
   */
  createGearMaintenanceLog(maintenanceLog: GearMaintenanceLogFormData): Observable<GearMaintenanceLogFormData> {
    const url = this.getUrl([this.URL_MAINTENANCE_LOG]);
    return this.http.post<GearMaintenanceLogFormData>(url, this.mapDataWithParams(maintenanceLog));
  }

  /**
   * Update gear Maintenance log
   * @return Observable<GearMaintenanceLogFormData>
   */
  updateGearMaintenanceLog(logId: number, maintenanceLog): Observable<GearMaintenanceLogFormData> {
    const url = this.getUrl([this.URL_MAINTENANCE_LOG, logId]);
    return this.http.put<GearMaintenanceLogFormData>(url, this.mapDataWithParams(maintenanceLog));
  }

  /**
   * Delete gear Maintenance Log
   */
  deleteGearMaintenanceLog(gearId: number, logId: number): Observable<any> {
    const url = this.getUrl([this.URL_MAINTENANCE_LOG, logId]);
    let data = { gear_id: gearId }
    data = this.mapDataWithParams(data);
    return this.http.delete(url, {observe: 'response', body: data});
  }
}
