import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, throwError } from 'rxjs';
import { EventDetail, Meeting } from '../../services/meeting.models';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../../store/states';
import { UserLinkedROCMapped } from '../../../../store/models';
import { CalendarTypes } from '../../../../../../shared/api/interface/api.enums';
import { ConfirmationRemoveModalComponent } from '../../../../../../shared/components/modals/confirmation-remove-modal/confirmation-remove-modal.component';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { MeetingsApiService } from '../../services/meetings-api.service';
import { AppToastrService } from '../../../../../../shared/services/toaster.service';
import { HttpParams } from '@angular/common/http';
import moment from 'moment';
import { StatusBadgeType } from '../../../../../../shared/components/status-badge/status-badge.component';
import { ROCCrewMember } from '../../../../../../shared/api/interface/api.models';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-occurrence-detail-modal',
  templateUrl: './occurrence-detail-modal.component.html'
})
export class ROCOccurrenceDetailModalComponent implements OnInit, OnDestroy {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  @Input() public eventId: number;
  @Input() public occId: number;
  @Input() public crewMembers: ROCCrewMember[];
  @Input() public eventOccDetail: EventDetail;
  @Input() public hideDelete: boolean;
  public onClose: Subject<boolean>;
  public reloadData: Subject<boolean>;
  hasError: boolean;
  isSameDay: boolean;
  badgeType = StatusBadgeType;
  params = new HttpParams();
  meetingDetail$: Observable<Meeting>;
  attendeeDetails: any;
  creatorDetail: any;
  collapseOccurrences = true;

  //Permissions
  isQualityOfficer: boolean;
  isSafetyOfficer: boolean;
  userPermissionFullAccess: boolean;
  hasFeatureAccess: boolean;

  isOperationCalendar: boolean;
  isMaintenanceCalendar: boolean;
  isSafetyCalendar: boolean;
  isQualityCalendar: boolean;

  constructor(
    private bsModalRef: BsModalRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected modalService: ModalService,
    protected meetingsApiService: MeetingsApiService,
    protected toastrService: AppToastrService,
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.reloadData = new Subject();

    this.selectedROC$.subscribe((selectedROC) => {
      if (selectedROC) {
        this.isQualityOfficer = !!selectedROC.user_role.has_quality_officer_role;
        this.isSafetyOfficer = !!selectedROC.user_role.has_safety_officer_role;
        this.userPermissionFullAccess = !!selectedROC.user_permissions?.full_access;
        this.hasFeatureAccess = (
          (this.isQualityOfficer && this.isQualityCalendar) ||
          (this.isSafetyOfficer  && this.isSafetyCalendar) ||
          this.userPermissionFullAccess
        ) && selectedROC.has_roc_subscription;
      }
    });

    this.meetingDetail$ = this.meetingsApiService.getMeetingDetail(this.params, this.eventId).pipe(
      tap( meetingEvent => {
        this.isOperationCalendar = meetingEvent.calendar === CalendarTypes.OPERATION;
        this.isMaintenanceCalendar = meetingEvent.calendar === CalendarTypes.MAINTENANCE;
        this.isSafetyCalendar = meetingEvent.calendar === CalendarTypes.SAFETY_MEETING;
        this.isQualityCalendar = meetingEvent.calendar === CalendarTypes.QA_MEETING;
        this.attendeeDetails = this.getAttendeesList(meetingEvent?.attendees)
        this.isSameDay = moment(meetingEvent.start).isSame(meetingEvent.end, 'day');
        this.creatorDetail = this.getCrewMember(meetingEvent.creator,  this.crewMembers)
        // if no eventDetail received from calendar extendedProps, and we just have an occId, find occurrence.
        if(!!this.eventOccDetail === false && this.occId) {
          this.eventOccDetail = meetingEvent.occurrences.find(occ => occ.id === this.occId)
          this.attendeeDetails = this.getAttendeesList(this.eventOccDetail?.attendees)
        }
      }),
      catchError((error) => {
        if (error.status) {
          this.hasError = true;
        }
        return throwError(error);
      })
    )
  }

  getAttendeesList(array) {
    const attendeeDetails = [];
    array.forEach( attendee => {
      attendeeDetails.push(this.getCrewMember(attendee, this.crewMembers))
    });
    return attendeeDetails
  }

  private getCrewMember(id, crewMembers: ROCCrewMember[]) {
    const crewMember = crewMembers.find(member => member.id === id);
    return {
      name: crewMember?.first_name,
      last_name: crewMember?.last_name,
      email: crewMember?.email,
      avatar: crewMember?.avatar,
      is_active: crewMember?.is_active,
    }
  }

  onEditOccurrence(eventId, occId) {
    const queryParams = { occId: occId };
    this.router.navigate(['roc', 'meetings', 'schedule', eventId, 'occurrence', 'edit'], {queryParams});
  }

  public onCancel(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  onDeleteMeeting(eventId, count) {
    const params = new HttpParams();
    const modalRef = this.modalService.open(ConfirmationRemoveModalComponent);
    modalRef.content.heading = `Remove Main Event `;
    modalRef.content.action = `remove this main event`;
    if (count > 1) {
      modalRef.content.additionalMessage1 = `This action will remove ${count} occurrences of this event from your calendar and any attached Meeting Minutes Documents.`;
    } else {
      modalRef.content.additionalMessage1 = `This action will also remove any attached Meeting Minutes Documents that have been uploaded to this event.`;
    }
    modalRef.content.onClose.subscribe(onClose => {
      if (onClose) {
        this.meetingsApiService.deleteMeeting(params, eventId).subscribe( () => {
            this.toastrService.success(`"Main event and ${count} occurrences have been removed.`);
            this.onCancel();
            this.reloadData.next(true);
          },
          () => {
            this.toastrService.error(`An error occurred while removing main event.`);
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.onClose.next(true);
  }

}
