import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthApiService } from 'src/app/shared/api/services/auth-api.service';
import { HttpParams } from '@angular/common/http';
import { AppToastrService } from '../../../shared/services/toaster.service';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html'
})
export class AcceptInvitationComponent implements OnInit {
  hasAcceptedInvitation: boolean;
  showError: boolean;
  error: { title: string, message: string, registered_at: string, user_state: string | number };
  invitationPK: string;
  email: string;
  tokenInvalid: boolean;
  tokenAccepted: boolean;
  userRegistered: boolean;
  show500Error: boolean;

  constructor(
    private authService: AuthApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected toastrService: AppToastrService,
  ) { }

  ngOnInit(): void {
    this.invitationPK = this.activatedRoute.snapshot.queryParamMap.get('invitation_pk');
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (this.invitationPK && token) {
      this.acceptInvitation(this.invitationPK, token);
    }
  }

  acceptInvitation(invitationPK: string, token: string) {
    let queryParams = new HttpParams();
    const acceptedInvitationState = 2;
    queryParams = queryParams.append('invitation_pk', invitationPK);
    queryParams = queryParams.append('token', token);
    this.authService.acceptInvitation(queryParams)
      .subscribe(
        result => {
          this.hasAcceptedInvitation = result.success
          this.email = result.email;
          if (result.user_status === acceptedInvitationState) {
            this.onRegisterInvitee();
            this.toastrService.success('Invitation has been accepted.')
          }
          if (result.is_registered) {
            this.onLoginInvitee();
            this.toastrService.success('Invitation has been accepted.')

          }
        },
        error => {
          this.showError = true;
          if (this.error) {
            this.error = error.error;
            if (error.error.email) {
              this.email = error.error.email[0];
            }
            if (error.error.title) {
              this.tokenInvalid = error.error.title[0] === 'Invalid Token';
              this.tokenAccepted = error.error.title[0] === 'Already accepted.';
            }
            if (error.error.registered_at) {
              this.userRegistered = error.error.registered_at[0] !== 'None';
              if (!this.userRegistered && this.tokenAccepted) {
                this.onRegisterInvitee();
              }
            }
          } else {
            this.show500Error = true;
          }
        }
      );
  }

  onRegisterInvitee() {
    let queryParams = {};
    if (this.invitationPK) {
      queryParams = {
        invitation_pk: this.invitationPK,
        email: encodeURI(this.email)
      };
    }
    this.router.navigate(['sign-up', 'personal-details'], {queryParams});
  }

  onLoginInvitee() {
    this.router.navigate(['login'], {queryParams: {invitation_pk: this.invitationPK}});
  }
}
