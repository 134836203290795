import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { ModalService } from '../services/modal.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UnsavedModalComponent } from '../components/modals/unsaved-changes/unsaved-modal.component';
import { AuthState } from '@bo/ng-auth';
import { CanDeactivateComponent } from '@bo/ng-forms';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<CanDeactivateComponent> {

  constructor(
    private store: Store,
    private modalService: ModalService,
  ) {}

  canDeactivate(component: CanDeactivateComponent): Observable<any> | boolean {
    // When the user is not authenticated we should not prevent redirects to the login page.
    return this.store.selectOnce(AuthState.token).pipe(
      map(token => Boolean(token)),
      switchMap(isAuthenticated => {
        if (isAuthenticated && !component.canDeactivate()) {
          const modalRef = this.modalService.open(UnsavedModalComponent);
          return modalRef.content.onClose.asObservable();
        }
        return of(true);
      })
    );
  }
}
