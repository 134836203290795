import { Component, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { AppState } from '../../../../store/states';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../../api/services/api.service';
import { UserProfile } from '../../../api/interface/api.models';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html'
})
export class AppLoginModalComponent implements OnInit {
  @Select(AppState.userProfile) userProfile$: Observable<UserProfile>;

  public onClose: Subject<boolean>;

  constructor(
    private router: Router,
    private bsModalRef: BsModalRef,
    protected apiService: ApiService,
    protected store: Store,
  ) {
  }

  ngOnInit() {
    this.onClose = new Subject<boolean>();
    this.bsModalRef.onHidden.pipe(first()).subscribe(() => this.onClose.next(false));
  }

  onReauthenticate(): void {
    this.bsModalRef.hide();
    this.onClose.next();
  }

  onCancel(): void {
    this.bsModalRef.hide();
    this.onClose.next(false);
  }

}
