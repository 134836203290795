import { Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { EventDetail } from '../services/meeting.models';
import { MeetingsApiService } from '../services/meetings-api.service';
import { OperationApiService } from '../../operations/services/operation-api.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { CalendarTypes } from '../../../../../shared/api/interface/api.enums';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { ROCState } from '../../../store/states';
import { UserLinkedROCMapped } from '../../../store/models';
import { HttpParams } from '@angular/common/http';
import { ROCCrewMember } from '../../../../../shared/api/interface/api.models';

@Component({
  selector: 'app-meetings-schedule',
  templateUrl: './meeting-schedule.component.html',
  styleUrls: [],
})
export class ROCMeetingsScheduleComponent implements OnInit, OnDestroy {
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;
  @ViewChild('filterName') filterName: ElementRef;
  @ViewChild('table') table: any;
  protected subscriptions = new Subscription();

  //Permissions
  userPermissionFullAccess: boolean;
  isQualityOfficer: boolean;
  isSafetyOfficer: boolean;
  userPermissionSubscribed: boolean;
  hasWriteAccess: boolean;

  safetyCalendar = CalendarTypes.SAFETY_MEETING;
  qaCalendar = CalendarTypes.QA_MEETING;

  params = new HttpParams();
  occurrences: EventDetail[] = [];
  occurrencesCancelled: EventDetail[] = [];
  attendeeDetails = [];
  dateFrom: string;
  dateTo: string;
  filterByCalendarTypes: CalendarTypes[] = [ CalendarTypes.SAFETY_MEETING, CalendarTypes.QA_MEETING];

  tempData = [];
  pageSize = 10;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  isLoading = true;
  pageSizeOptions = [5, 10, 15];
  pageSizeClass: string;
  hideClearBtn = true;
  data = [];
  showActive = true;

  constructor(
    protected meetingApiService: MeetingsApiService,
    protected operationApiService: OperationApiService,
    protected router: Router,
  ) { }

  ngOnInit() {
    this.selectedROC$.subscribe((selectedROC) => {
      if (selectedROC) {
        this.isQualityOfficer = !!selectedROC.user_role?.has_quality_officer_role;
        this.isSafetyOfficer = !!selectedROC.user_role?.has_safety_officer_role;
        this.userPermissionFullAccess = !!selectedROC.user_permissions?.full_access === true;
        this.userPermissionSubscribed = selectedROC.has_roc_subscription;
        this.hasWriteAccess = this.isQualityOfficer || this.isSafetyOfficer || this.userPermissionFullAccess;
      }
    });
    this.setDatesLoadMeetings()
  }

  setDatesLoadMeetings() {
  }

  loadMeetingOccurrences(dateFrom, dateTo, calendar: CalendarTypes[]){
    let params = new HttpParams();
    params = params.appendAll({
      date_from: dateFrom,
      date_to: dateTo,
      calendar: calendar
    });

    this.occurrences = [];
    combineLatest([
      this.operationApiService.getRocCrewMembers(this.params),
      this.meetingApiService.getEventOccurrences(params)
    ]).subscribe(([crewMembers, meetings]) => {
      meetings.forEach( (meeting: EventDetail) => {
        this.attendeeDetails = [];
        meeting.attendees.forEach( attendee => {
          this.attendeeDetails.push(this.getCrewMember(attendee, crewMembers))
          meeting.attendee_details = this.attendeeDetails;
        })
       this.occurrences = meetings.filter(meeting => !meeting.cancelled)
      })
      this.sortArrDateDescending(this.occurrences);
      this.data = this.occurrences;
      this.tempData = [...this.occurrences];
      this.isLoading = false;
      this.getPageSizeClass(this.pageSize);
    })
  }

  private getCrewMember(id, crewMembers: ROCCrewMember[]) {
    const crewMember = crewMembers.find(member => member.id === id);
    return {
      name: crewMember?.first_name,
      last_name: crewMember?.last_name,
      email: crewMember?.email,
      avatar: crewMember?.avatar,
      is_active: crewMember.is_active
    }
  }

  onViewCancelled() {
    this.showActive = !this.showActive;
    this.data = this.showActive ? this.occurrences : this.occurrencesCancelled;
  }

  onEditOccurrence(eventId, occId) {
    const queryParams = { occId: occId };
    this.router.navigate(['roc', 'meetings', 'schedule', eventId, 'occurrence', 'edit'], {queryParams});
  }

  sortArrDateDescending(arr) {
    arr.sort((obj1, obj2) => {
        return new Date(obj1.start).getTime() - new Date(obj2.start).getTime()
      }
    )
  }

  public updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter & update the rows
    this.data = this.tempData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.hideClearBtn = !(!!val)
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0
  }

  public clearFilter(){
    this.data = this.tempData;
    this.filterName.nativeElement.value = '';
    this.hideClearBtn = true;
  }

  public onChangePageSize(event){
    this.getPageSizeClass(event);
  }

  protected getPageSizeClass(size){
    if (this.data.length >= size) {
      this.pageSizeClass = 'min-height-' + size;
    } else {
      this.pageSizeClass = '';
    }
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  ngOnDestroy(){
    this.subscriptions.unsubscribe();
  }
}
