import { Component, forwardRef, OnInit } from '@angular/core';
import { FieldPathDirective, FormFieldComponent, FormOptionsDirective } from '@bo/ng-forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-intl-tel-input',
  templateUrl: './intl-tel-input.component.html',
  providers: [
    { provide: FormFieldComponent, useExisting: forwardRef(() => IntlTelInputComponent) },
  ]
})
export class IntlTelInputComponent extends FieldComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  constructor(
    protected formOptionsDirective: FormOptionsDirective,
    protected fieldPathDirective: FieldPathDirective) {
    super(formOptionsDirective, fieldPathDirective);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
