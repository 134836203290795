import { Component, OnInit } from '@angular/core';
import { AuthApiService } from '../../../shared/api/services/auth-api.service';
import { AppToastrService } from '../../../shared/services/toaster.service';
import { Method } from '@bo/ng-api';
import { Observable } from 'rxjs';
import { PasswordResetRequest } from '../../../shared/api/interface/api.models';
import { AppFormComponent } from '../../../shared/components/forms/app-form-component';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html'
})
export class PasswordResetRequestComponent extends AppFormComponent<PasswordResetRequest> implements OnInit {

  protected fields = ['email'];

  isRequested: boolean;

  constructor(
    protected authService: AuthApiService,
    protected toastrService: AppToastrService,
    private titleService: Title,
    private meta: Meta,
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle('Password Reset | Request | Flyte Zone');
    this.meta.updateTag({
      name: 'description',
      content: 'Can\'t log in? Reset your Flyte Zone password here and quickly regain access to our all-in-one platform for RPAS flight operations.'
    });
    super.ngOnInit();
  }


  createForm(): void {
    const optionsURL = this.authService.URL_PASSWORD_RESET_REQUEST;
    this.subscriptions.add(
      this.buildFromOptions(
        this.authService.getOptions(optionsURL, Method.POST),
      ).subscribe()
    );
  }

  submitData(formData: any): Observable<PasswordResetRequest> {
    const passwordResetRequestData: PasswordResetRequest = {
      email: this.form.value.email,
    };
    return this.authService.passwordResetRequest(passwordResetRequestData);
  }

  handleSubmitSuccess() {
    this.isRequested = true;
  }

  handleSubmitError(error: any) {
    super.handleSubmitError(error);
  }
}
