import { Injectable } from '@angular/core';
import { AuthApi, SkipAuthInterceptorLoginModal, TokenResponse } from '@bo/ng-auth';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {
  PasswordChangeData,
  PasswordResetConfirmRequest,
  PasswordResetRequest,
  RegistrationData, RemoteOperatorProfile,
  ResendVerificationEmail
} from '../interface/api.models';
import * as Models from '../interface/api.models';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends AuthApi {
  baseUrl = environment.apiRoot;
  URL_LOGIN = 'account/login';
  URL_LOGOUT = 'account/logout';
  URL_TOKEN_REFRESH = 'account/token-refresh';
  URL_REGISTRATION = 'account/register';
  URL_ROC_CREATE = 'roc-entity/roc-entity-create';
  URL_EMAIL_VERIFICATION = 'account/verify-email';
  URL_ACCEPT_INVITATION = 'roc-association/prospect-invitation-accept';
  URL_EMAIL_VERIFICATION_RESEND = 'account/resend-verification-email';
  URL_CHANGE_PASSWORD = 'account/change-password';
  URL_PASSWORD_RESET_CONFIRM = 'account/password-reset-confirm';
  URL_PASSWORD_RESET_REQUEST = 'account/password-reset-request';

  constructor(protected http: HttpClient) {
    super(http);
  }

  protected serializeRefreshToken(token: string) {
    return {token};
  }

  protected deserializeRefreshToken(data): TokenResponse {
    return data as TokenResponse;
  }

  protected deserializeLogin(data: any): TokenResponse {
    return undefined;
  }

  protected serializeLogin(email: string, password: string): any {
    // This is intentional
  }

  /**  User Log in
   *
   * @param email
   * @param password
   * @return Observable<any>
   *
   */
  login(email: string, password: string): Observable<any> {
    return this.http.post(
      this.getUrl(this.URL_LOGIN), { email, password }
    );
  }

  /**  Register a user
   *
   * @param registrationData
   * @return Observable<any>
   *
   */
  register(registrationData: RegistrationData): Observable<any> {
    return this.http.post(
      this.getUrl(this.URL_REGISTRATION), registrationData
    );
  }

  /**  Resend verification email to the user
   *
   * @param resendVerificationEmail
   * @return Observable<any>
   *
   */
  resendEmailVerification(resendVerificationEmail: ResendVerificationEmail): Observable<any> {
    return this.http.post(
      this.getUrl(this.URL_EMAIL_VERIFICATION_RESEND), resendVerificationEmail
    );
  }

  /**
   * Create a new remote operator profile
   *
   * @return Observable<RemoteOperatorProfile>
   */
  postRemoteOperator(remoteOperatorData: Models.RemoteOperatorProfile, queryParams?: HttpParams): Observable<RemoteOperatorProfile> {
    return this.http
      .post<RemoteOperatorProfile>(this.getUrl([this.URL_ROC_CREATE]), remoteOperatorData, {params: queryParams});
  }

  /**  Verify a user's email address based on a token
   *
   * @param code
   * @return Observable<any>
   *
   */
  verifyEmail(code: string): Observable<any> {
    return this.http.post(
      this.getUrl(this.URL_EMAIL_VERIFICATION), {code}
    );
  }

  /**  Accept an invitation
   *
   * @param queryParams
   * @return Observable<any>
   *
   */
  acceptInvitation(queryParams?: HttpParams): Observable<any> {
    return this.http.get(
      this.getUrl([this.URL_ACCEPT_INVITATION]),  {params: queryParams}
    );
  }

  /**  Change password
   *
   * @param passwordChangeData
   * @return Observable<any>
   *
   */
  changePassword(passwordChangeData: PasswordChangeData): Observable<any> {
    return this.http.post(
      this.getUrl(this.URL_CHANGE_PASSWORD), passwordChangeData
    );
  }

  /**  Request to rest password
   *
   * @param passwordResetRequestData
   * @return Observable<any>
   *
   */
  passwordResetRequest(passwordResetRequestData: PasswordResetRequest): Observable<any> {
    return this.http.post(
      this.getUrl(this.URL_PASSWORD_RESET_REQUEST), passwordResetRequestData
    );
  }

  /**  Confirm a new password
   *
   * @param passwordResetData
   * @return Observable<any>
   *
   */
  passwordResetConfirm(passwordResetData: PasswordResetConfirmRequest): Observable<any> {
    return this.http.post(
      this.getUrl(this.URL_PASSWORD_RESET_CONFIRM), passwordResetData
    );
  }

  /**  Log out
   *
   * @return Observable<any>
   *
   */
  logout(): Observable<any> {
    // Logout should not show login modal if unauthenticated
    const headers = new HttpHeaders().set(SkipAuthInterceptorLoginModal, '');
    return this.http.post(this.getUrl(this.URL_LOGOUT), null, { headers });
  }
}
