import { Component, OnInit } from '@angular/core';
import { AppFormComponent } from '../forms/app-form-component';
import { Select, Store } from '@ngxs/store';
import { AppToastrService } from '../../services/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FieldOptions } from '@bo/ng-forms';
import { HttpParams } from '@angular/common/http';
import { UploadSettings } from '@bo/ng-uploads';
import {
  Document,
  DocumentUploadData,
  ROCCrewMember,
  S3Destination,
  UserProfile
} from '../../api/interface/api.models';
import { EnumsService } from '@bo/ng-enums';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../../store/states';
import { DocumentService } from '../../../modules/roc/pages/documents/services/document.service';
import { SetUserStateFromNavigateToDashboard } from 'src/app/store/actions';
import { ROCState } from 'src/app/modules/roc/store/states';
import { UserLinkedROCMapped } from 'src/app/modules/roc/store/models';
import {
  DocumentOptionTypes,
  DocumentResourceTypes,
  DocumentType,
  MeetingMinuteTypes
} from '../../api/interface/api.enums';
import moment from 'moment';
import { OperationApiService } from '../../../modules/roc/pages/operations/services/operation-api.service';
import { EnumLabelService } from '../../services/enum-label.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './upload.component.html'
})
export class DocumentUploadComponent extends AppFormComponent<any> implements OnInit {
  @Select(AppState.userProfile) userProfile$: Observable<UserProfile>;
  @Select(ROCState.selectedROC) selectedROC$: Observable<UserLinkedROCMapped>;

  params = new HttpParams();
  //Permissions
  userPermissionGearDocuments: boolean;
  userPermissionDocuments: boolean;
  userPermissionFullAccess: boolean;
  isPilot: boolean;
  userUploadPermission: boolean;
  isQualityOfficer: boolean;
  isSafetyOfficer: boolean;
  isSecurityManager: boolean;
  isPRAircraftManager: boolean;
  meetingType: MeetingMinuteTypes

  rocId: number;
  documentTypeSelectOptions = [];
  documentTypes: DocumentOptionTypes;
  overrideDeactivateGuard = false;
  uploadSettings$: Observable<UploadSettings>;
  documentUrl: string;
  documentIsPDF: boolean;
  documentIsImage: boolean;
  showDocumentUrlError: boolean;
  fileTypeErrorFlag = false;
  singleDocumentType: DocumentType;
  documentResourceType: DocumentResourceTypes
  selectedMemberId: number;
  crewMembers: ROCCrewMember[];
  membersLoading = true

  protected fields = ['title', 'file_type', 'valid_from', 'expire_on'];

  constructor(
    protected store: Store,
    protected documentService: DocumentService,
    protected toastrService: AppToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected enumService: EnumsService,
    protected enumLabelService: EnumLabelService,
    protected operationApiService: OperationApiService,
  ) { super() }

  ngOnInit() {
    super.ngOnInit();
    if (this.documentResourceType === DocumentResourceTypes.PERSONNEL) {
      this.subscriptions.add(
        this.operationApiService.getRocCrewMembers(this.params).pipe(
          tap(members => {
            this.crewMembers = members.filter(member => member.state !== 'PENDING');
            this.membersLoading = false
          }),
        ).subscribe()
      );
    }
    this.setUploadSettings();
    this.selectedROC$.subscribe(selectedROC => {
      if (selectedROC) {
        this.userPermissionFullAccess = selectedROC.user_permissions?.full_access;
        this.userPermissionGearDocuments = selectedROC.user_permissions.gear?.documents === true;
        this.userPermissionDocuments = selectedROC.user_permissions.user_document === true;
        this.isPilot = selectedROC.is_pilot;
        this.isQualityOfficer = selectedROC.user_role.has_quality_officer_role;
        this.isSafetyOfficer = selectedROC.user_role.has_safety_officer_role;
        this.isSecurityManager = selectedROC.user_role.has_security_officer_role;
        this.isPRAircraftManager = selectedROC.user_role.has_aircraft_manager_role;
        this.rocId = selectedROC.id;
      } else {
        // Check user profile for independent pilots not belonging to an ROC
        this.userProfile$.subscribe( profile => {
          this.isPilot = profile.roc_user_linked_entities.roc_user_profile.is_pilot;
        })
      }
    });
    this.userUploadPermission = this.userPermissionFullAccess ?
      true : this.getUploadPermissionsFor(this.documentResourceType);
  }

  setUploadSettings(){
    this.uploadSettings$ = this.store.select(AppState.userProfile).pipe(
      map(userProfile => {
        return {
          destination: S3Destination.general_documents,
          fileOwner: {owner_id: userProfile.id},
        } as UploadSettings;
      })
    );
  }

  getUploadPermissionsFor(resourceType) {
    switch (resourceType) {
      case DocumentResourceTypes.USER_SPECIFIC: return true;
      case DocumentResourceTypes.RPAS_OPERATOR: return false;
      case DocumentResourceTypes.QA_MANAGER: return this.isQualityOfficer;
      case DocumentResourceTypes.SAFETY_MANAGER: return this.isSafetyOfficer;
      case DocumentResourceTypes.SECURITY_MANAGER: return this.isSecurityManager;
      case DocumentResourceTypes.GENERATED_AUDIT_REPORT: return false;
      case DocumentResourceTypes.OPERATION: return this.isPilot;
      case DocumentResourceTypes.GEAR: return (this.userPermissionGearDocuments);
      case DocumentResourceTypes.PILOT_SPECIFIC: return this.isPilot;
      case DocumentResourceTypes.PILOT_LOGBOOK: return this.isPilot;
      case DocumentResourceTypes.PERSONNEL: return false;
      case DocumentResourceTypes.PR_AIRCRAFT: return this.isPRAircraftManager;
      case DocumentResourceTypes.MEETING_MINUTE: return (
        this.isSecurityManager && (this.meetingType === MeetingMinuteTypes.TYPE_SMS) ||
        this.isQualityOfficer && (this.meetingType === MeetingMinuteTypes.TYPE_QA)
      )
    }
  }

  protected getDocumentsTypes(documentTypes) {
    this.store.select(AppState.userProfile).subscribe(
      userProfile => {
        if (userProfile) {
          return this.enumService.getEnum(documentTypes).pipe(
            map(result => result.map(item => ({id: item.name, display_name: item.label })))
          ).subscribe(result => {
            // remove types that shouldn't be uploadable from the modal
            this.documentTypeSelectOptions = result
              .filter( item => item.id !== 'MEETING_MINUTE')
              .filter( item => item.id !== 'QMS_MEETING_MINUTES')
              .filter( item => item.id !== 'SMS_MEETING_MINUTES')
              .filter( item => item.id !== 'BATTERY_LOG');
          });
        }
        this.store.dispatch(new SetUserStateFromNavigateToDashboard());
      }
    );
  }

  getFormOptions(): Observable<FieldOptions[]> {
    return this.documentService.getUploadOptions(this.getQueryParams().toString());
  }

  getQueryParams(): HttpParams {
    // Override this method to specify the specific query params to use create the form (OPTIONS request), and to submit
    // the form (POST request),
    return new HttpParams();
  }

  createForm(): void {
    this.subscriptions.add(
      this.buildFromOptions(
        this.getFormOptions(),
        this.getInitialData()).subscribe()
    );
  }

  //TODO: establish if this is necessary? seems like it has no purpose.
  protected getInitialData(): Observable<Partial<Document>> {
    return of({
      title: this.route.snapshot.queryParams.title ? this.route.snapshot.queryParams.title : '',
    });
  }

  documentUploaded($event: any) {
    this.documentUrl = $event.file.meta.download_url;
    this.documentIsPDF = ($event.file.type as string).includes('pdf');
    this.documentIsImage = ($event.file.type as string).includes('image');
    this.showDocumentUrlError = false;
  }

  onSubmit() {
    if (!this.documentUrl && !this.fileTypeErrorFlag) {
      this.showDocumentUrlError = true;
    } else {
      super.onSubmit();
    }
  }

  submitData() {
    let userProfile: UserProfile;
    this.store.select(AppState.userProfile).pipe(
      map(profile => {
        return profile;
      })
    ).subscribe(result => userProfile = result);
    return this.documentService.uploadDocument(this.getNewDocumentUploadData(userProfile));
  }

  handleSubmitSuccess(document) {
    this.overrideDeactivateGuard = true;
    this.toastrService.success(`Document has been uploaded.`);
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  onChange() {
    this.documentUrl = undefined;
    this.showDocumentUrlError = false;
    this.fileTypeErrorFlag = false;
  }

  getNewDocumentUploadData(userProfile: UserProfile): DocumentUploadData {
    const data = {
      title: this.form.value.title,
      file: this.documentUrl,
      owner: userProfile.id,
      file_type: this.singleDocumentType ?? this.form.value.file_type,
      valid_from: this.form.value.valid_from ? moment(this.form.value.valid_from).format('YYYY-MM-DD') : undefined,
      expire_on: this.form.value.expire_on ? moment(this.form.value.expire_on).format('YYYY-MM-DD') : undefined,
      resourcetype: this.documentResourceType,
    };
    return data as DocumentUploadData;
  }

  canDeactivate(): boolean {
    if (this.documentUrl && !this.overrideDeactivateGuard) {
      return false;
    }
    return super.canDeactivate() || this.overrideDeactivateGuard;
  }

  handleSubmitError(error: any): void {
    super.handleSubmitError(error);
    if (error.error.date_to) {
      this.toastrService.error('Expiry date can not precede issued date.')
    }
    if (error.error.hasOwnProperty('Unsupported content type')) {
      this.fileTypeErrorFlag = true;
    }
  }
}
