import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'drfTime'
})
export class DrfTimePipe implements PipeTransform {

  /**
   * @param value Expects a DFT date string in the format 00:00:00 (24hr)
   * @return Date object.
   */
  transform(value: string): Date {
    const [hours, minutes, seconds] = value.split(':');
    const date = new Date();
    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setSeconds(+seconds);
    return date;
  }
}
