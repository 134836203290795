import { ComponentRef, Injectable, Type } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateHeaderService {

  setHeaderComponent$ = new ReplaySubject<Type<any>>(1);
  headerComponentUpdated$ = new ReplaySubject<ComponentRef<any>>(1);

  setHeaderComponent(component: Type<any>) {
    this.setHeaderComponent$.next(component);
  }

  headerComponentUpdated(componentRef: ComponentRef<any>) {
    this.headerComponentUpdated$.next(componentRef);
  }

}
