import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormFieldErrorsComponent } from '@bo/ng-forms';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.css'],
  providers: [
    { provide: FieldErrorsComponent, useExisting: forwardRef(() => FormFieldErrorsComponent) }
  ]
})
export class FieldErrorsComponent extends FormFieldErrorsComponent implements OnInit {
  @Input() requiredErrorMsg: string;

  reservedErrors = ['required', 'maxlength', 'min', 'max', 'email', 'server'];

  ngOnInit(): void {
    super.ngOnInit();
  }

  hasFieldErrors(): boolean {
    return Boolean(this.fieldControl.errors && (this.fieldControl.touched || this.fieldControl.dirty));
  }

  /**
   * Returns the field errors if the field has been touched.
   */
  getFieldError(type: string): string {
    if (this.hasFieldErrors()) {
      const errors = this.fieldControl.errors;
      if (type === 'server' && errors.server > 0) {
        return errors[type][0];
      }
      return errors[type];
    }
    return null;
  }

  getAlternativeErrors(): string[] {
    if (!this.hasFieldErrors()) {
      return null;
    }
    const errors = this.fieldControl.errors as ValidationErrors;

    // Has reservedErrors, then return no alternative errors because we only want to display one error at a time.
    const hasReservedErrors = Object.keys(errors).some(errorKey => this.reservedErrors.includes(errorKey));
    if (hasReservedErrors) {
      return [];
    }

    return Object.keys(errors).map(errorKey => errors[errorKey]); // Map to error description
  }

}
