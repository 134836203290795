import { Component, Input } from '@angular/core';
import { AirspaceMapObjectType } from 'src/app/shared/api/interface/api.enums';

@Component({
  selector: 'app-location-shape-icon',
  templateUrl: './location-shape-icon.component.html',
})
export class LocationShapeIconComponent {
  @Input() location: any;
  @Input() locationHazard: boolean;
  @Input() locationIncident: boolean;
  @Input() pxHeight: number;
  @Input() class: string;

  AirspaceMapObjectType = AirspaceMapObjectType;
}
