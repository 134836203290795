import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent {
  @Input() deniedForPermission: boolean;
  @Input() deniedForRole: boolean;
  @Input() deniedForPublished: boolean;
  @Input() deniedForDraft: boolean;
  @Input() deniedForClosed: boolean;
  @Input() deniedForSubscription: boolean;
  @Input() backURL: string = '../';
}
